import { MouseEventHandler } from 'react'

export const openModal: MouseEventHandler<HTMLAnchorElement> = (e) => {
  e.preventDefault()
  const target = e.currentTarget as HTMLElement
  const dataHref = target.getAttribute('data-href')
  window.open(dataHref || '', '_blank', 'width=640,height=480')
}

export const socialShareModal = () => {
  const socialShareContainers = document.querySelectorAll('.social-share')
  socialShareContainers.forEach((socialShareContainer) => {
    const shareLinks = socialShareContainer.querySelectorAll('.social-link')
    shareLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        openModal(e as any)
      })
    })
  })
}
