import { CUSTOM_CTA_HEADER_BUTTON_IDS } from '~/library/Header/CustomCTAHeader/types'
import { throttle } from './throttle'

export const customCTAHeaderActions = () => {
  //comments button is handled in ~/library/Article/smoothScroll.ts

  const subscribeButtonId = CUSTOM_CTA_HEADER_BUTTON_IDS.SUBSCRIBE
  const subscribeButtons = [
    document.getElementById(subscribeButtonId),
    document.getElementById(`${subscribeButtonId}-scrolled`),
  ]

  const newsletterButton = document.getElementById(
    CUSTOM_CTA_HEADER_BUTTON_IDS.NEWSLETTER,
  )

  const shareButton = document.getElementById(
    CUSTOM_CTA_HEADER_BUTTON_IDS.SHARE,
  )

  subscribeButtons.forEach((btn) => {
    btn?.addEventListener('click', () => {
      document.location = '/subscriptions'
    })
  })

  newsletterButton?.addEventListener('click', () => {
    document.location = '/newsletter'
  })

  shareButton?.addEventListener('click', handleNativeShare)

  window.addEventListener('scroll', throttle(getAppropriateHeader, 200))
}

const handleNativeShare = () => {
  try {
    navigator.share({
      title: document.title,
      url: document.location.href,
    })
  } catch (error) {
    console.error('There was a problem sharing this article.')
  }
}

const getAppropriateHeader = () => {
  const scrolledHeader = document.querySelector('#custom-cta-header-scrolled')

  if (!scrolledHeader) return

  const currentScrollPosition = window.scrollY

  if (currentScrollPosition > 0) {
    if (scrolledHeader.classList.contains('show')) return
    scrolledHeader.classList.add('showing')
    scrolledHeader.addEventListener(
      'animationend',
      () => {
        scrolledHeader.classList.remove('showing')
        scrolledHeader.classList.add('show')
      },
      { once: true },
    )
  } else {
    if (!scrolledHeader.classList.contains('show')) return
    scrolledHeader.classList.add('hiding')
    scrolledHeader.addEventListener(
      'animationend',
      () => {
        scrolledHeader.classList.remove('hiding')
        scrolledHeader.classList.remove('show')
      },
      { once: true },
    )
  }
}
