import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import SocialShare from './SocialShare'
import dayjs from 'dayjs'

import { getTimezone } from '~/util/get-timezone'
import { ArticleData, Configuration, FC } from '~/types'
import Image from '~/library/Article/Image'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-direction: column;

  @media ${device.mobileL} {
    flex-direction: row;
  }
`
const TopWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr 0fr;
  @media ${device.maxTablet} {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .social-share {
    align-items: center;
    @media ${device.maxTablet} {
      justify-content: center;
    }
  }
`
const InnerWrapper = styled.div`
  justify-content: center;
  flex-direction: column;
  flex-grow: 0;
  display: flex;
  margin: 0;
  min-height: 50px;
  width: 100%;

  @media ${device.mobileL} {
    width: auto;
  }
`

const PartnerLink = styled.a`
  text-decoration: none;
`

const PartnerName = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-align: left;
  line-height: 1.2;
  font-weight: bold;
  text-decoration: none;
`

const PartnerLogoWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;

  @media ${device.mobileL} {
    width: auto;
  }
`

const PartnerLogo = styled.a`
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.fontColorLight};
  width: 100%;
  padding: 11px 0;
  margin: 0 0 20px 0;

  img {
    object-fit: contain;
    width: 200px;
    height: 100px;
    background-color: ${({ theme }) => theme.lightContrast};
  }
  .logo {
    margin: 0;
  }
  .logo div {
    @media ${device.maxTablet} {
      justify-content: initial;
      margin: initial;
      flex-direction: row;
      padding: 0;
    }
  }

  @media ${device.mobileL} {
    margin: 0 20px;
    padding: 0;
    border: none;
    width: auto;
  }
`

const PublishedDate = styled.div`
  color: ${({ theme }) => theme.fontColorLight};
  text-align: left;
  line-height: 19px;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: 12px;
`

type PartnerBannerProps = {
  article: ArticleData
  configuration: Configuration
  date: number
  updatedDate: number
}

const PartnerBanner: FC<PartnerBannerProps> = ({
  article,
  configuration,
  date,
  updatedDate,
}) => {
  if (!article.partners?.length) return null
  const { name, path, image } = article.partners[0]
  const logoSrc = image?.data?.url

  return (
    <Wrapper>
      <TopWrapper>
        <InnerWrapper>
          <PartnerLink href={path}>
            <PartnerName>Paid Member Content By {name}</PartnerName>
          </PartnerLink>
          <PublishedDate>
            Published {date}
            {' ' + getTimezone(dayjs().toDate())}
          </PublishedDate>
          {updatedDate && (
            <PublishedDate>
              Updated {updatedDate}
              {' ' + getTimezone(dayjs().toDate())}
            </PublishedDate>
          )}
        </InnerWrapper>
        <PartnerLogoWrapper>
          {image && logoSrc && (
            <PartnerLogo href={path}>
              <Image
                data={image?.data}
                srcSet={[320, 640, 990]}
                className="logo"
                isPartnerLogo={true}
              />
            </PartnerLogo>
          )}
        </PartnerLogoWrapper>
        <SocialShare
          position="in article - top"
          article={article}
          configuration={configuration}
          className="social-share"
        />
      </TopWrapper>
    </Wrapper>
  )
}

export default PartnerBanner
