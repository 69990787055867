/*globals JSGlobals*/

import { DAILYMOTION_CONTEXTUAL_PLAYER_ID } from './get-contextual-video/config'

export const getPlayerId = (
  isContextualVideo = false,
  isArticleTypeVideo = false,
) => {
  const { article } = JSGlobals
  const isSponsoredArticle =
    article.sponsorName !== undefined && article.sponsorName !== ''

  switch (true) {
    case isContextualVideo:
      return DAILYMOTION_CONTEXTUAL_PLAYER_ID
    case article.isPhotoArticle:
      return 'x8cdk'
    case isArticleTypeVideo:
      return 'xo1pp'
    case isSponsoredArticle:
      return 'xplqm'
    default:
      return article.videoPlayerId
  }
}
