import React from 'react'
import styled from 'styled-components'
import { getPrimaryColorBasedOffAllSections } from '~/config/theme/JPIMStyles'
import ShareIcon from '~/icons/Share'
import { ArticleData } from '~/types'

type SvgProps = {
  theme: any
  article: ArticleData
}

const getSvgColor = ({ theme, article }: SvgProps) => {
  // Set white color for Photo Articles
  const shareIconColor = article?.photoArticle
    ? theme.lightContrast
    : theme.darkestContrast

  if (theme.domain === 'farminglife.com') {
    return getPrimaryColorBasedOffAllSections(theme, article?.sections)
  }

  return shareIconColor
}

const ShareButton = styled.button<{ article: ArticleData }>`
  display: flex;
  gap: 8px;
  padding: 4px 16px;
  border: 2px solid ${({ theme }) => theme.fontColorLight};
  border-radius: 25px;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({ theme, article }) => getSvgColor({ theme, article })};
    stroke: ${({ theme, article }) => getSvgColor({ theme, article })};
    transition: 0.2s all ease-in;
  }

  span {
    pointer-events: none;
  }
`

type SocialShareMobileProps = {
  article: ArticleData
  position: string
  onClick?: () => void | undefined
  shareClass:
    | 'top-share-btn'
    | 'bottom-share-btn'
    | 'sponsor-share-btn'
    | 'live-blog-share-btn'
    | 'gallery-share-btn'
}

const SocialShareMobile = ({
  article,
  position,
  onClick,
  shareClass,
}: SocialShareMobileProps) => {
  return (
    <ShareButton
      id="social-share-mobile"
      className={shareClass}
      article={article}
      onClick={onClick}
      data-position={position}
    >
      <ShareIcon />
      <span>Share</span>
    </ShareButton>
  )
}

export default SocialShareMobile
