import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { trimImageParam } from '@brightsites/flow-core/lib/utils/cropImage'
import { device } from '~/config/theme/JPIMStyles'
import { Img } from '~/library/Img'
import { ArticleHeadlineItem } from './ArticleItem'
import ArticleRelated from './ArticleRelated'
import ArticleTitle from './ArticleTitle'

export const Content = styled.div`
  flex: 1;
  background-color: #e5e5e5;
  padding: 20px;
  position: relative;

  .standfirst {
    color: ${({ theme }) => theme.fontColor};
  }
`
export const SecondaryImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media ${device.laptop} {
    margin-top: 24px;
  }
  img {
    width: 50px;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
    @media ${device.laptop} {
      width: 90px;
      height: 85px;
    }
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.fontColor};

    @media ${device.laptop} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`
export const Highlight = styled.span`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2em;
  padding: 18px 30px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  background-color: rgba(255, 255, 255, 0.9);
  color: ${({ theme }) => theme.fontColor};
  position: absolute;
  top: 0;
  left: 0;
  @media ${device.maxLaptop} {
    display: none;
  }
`
export const HighlightMobile = styled(Highlight)`
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  @media ${device.laptop} {
    display: none;
  }
`

const HeroPlusLinksContent = ({
  capsuleHighlight,
  path,
  title,
  titleShort,
  maxTitleLength,
  secondaryImage,
  relatedLinks,
  isHeroRelated,
  standfirst,
}) => {
  if (!isHeroRelated) return null

  const secondaryImageUrl = secondaryImage?.url
  const { trimData } = secondaryImage?.extra ?? {}

  const secondaryImageUrlParams = {
    ...(trimImageParam({ trimData }) ?? { crop: '1:1,smart' }),
    quality: '70',
    width: '270',
  }

  return (
    <>
      {capsuleHighlight && (
        <HighlightMobile>{capsuleHighlight?.name}</HighlightMobile>
      )}
      <Content>
        {capsuleHighlight && <Highlight>{capsuleHighlight?.name}</Highlight>}
        <ArticleHeadlineItem>
          <ArticleTitle
            path={path}
            title={title}
            titleShort={titleShort}
            maxTitleLength={maxTitleLength}
            className={'article-title'}
          />
        </ArticleHeadlineItem>
        {secondaryImage && (
          <SecondaryImageWrapper>
            {secondaryImageUrl && (
              <Img
                src={secondaryImageUrl}
                urlParams={secondaryImageUrlParams}
              />
            )}
          </SecondaryImageWrapper>
        )}
        {standfirst && (
          <span
            className="standfirst"
            dangerouslySetInnerHTML={{ __html: standfirst }}
          />
        )}
        {relatedLinks && <ArticleRelated relatedLinks={relatedLinks} />}
      </Content>
    </>
  )
}

HeroPlusLinksContent.propTypes = {
  capsuleHighlight: PropTypes.object,
  isHeroRelated: PropTypes.bool,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  titleShort: PropTypes.string,
  maxTitleLength: PropTypes.number,
  relatedLinks: PropTypes.array,
  secondaryImage: PropTypes.object,
  standfirst: PropTypes.string,
}

export default HeroPlusLinksContent
