export default () => {
  const headerLogo = document.getElementById('header')
  const scrollNode = document.scrollingElement
    ? document.scrollingElement
    : document.body
  if (headerLogo) {
    const lastScrollTop = 0
    const $header = document.getElementById('header')
    const scrollClass = 'scroll'
    window.addEventListener('scroll', function () {
      const st = window.pageYOffset || document.documentElement.scrollTop
      if (scrollNode.scrollTop > 350) {
        $header?.classList.add(scrollClass)
      }
      if (st === lastScrollTop) {
        $header?.classList.remove(scrollClass)
      }
    })
  }
}
