export const THIRD_PARTY_MODE_PARAM = '3rd-party-mode'

/**
 * The function `isThirdPartyModeEnabled` checks if 3rd-party-mode is enabled in the URL query
 * parameters and returns whether it is enabled and the specific enabled values.
 * @returns The function `isThirdPartyModeEnabled` returns an object with two properties:
 * 1. `isEnabled`: a boolean value indicating whether the third-party mode is enabled or not.
 * 2. `enabledValues`: an array of strings containing the names of the enabled third-party modes (with
 * the '-mode' suffix removed).
 */
export const isThirdPartyModeEnabled = () => {
  const queryParams = new URLSearchParams(location.search)
  const enabledValues: string[] = []
  let isEnabled = false

  if (queryParams.has(THIRD_PARTY_MODE_PARAM)) {
    isEnabled = true

    queryParams.forEach((value, key) => {
      if (value === 'enabled') {
        enabledValues.push(key.replace(/-mode$/, '')) // Remove '-mode' suffix
      }
    })
  }

  return { isEnabled, enabledValues }
}
