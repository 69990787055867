import React, { memo } from 'react'
import styled from 'styled-components'

type MPUProps = {
  className?: string
  id?: string
  isDouble?: boolean
}

const Wrapper = styled.div`
  max-width: 100%;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DoubleMPUSlot = styled.div`
  min-height: 600px;
`

const SingleMPUSlot = styled.div`
  /* min-height: 250px; */
`

export const MPU = memo(
  ({ className, id, isDouble }: MPUProps) => {
    const Slot = isDouble ? DoubleMPUSlot : SingleMPUSlot

    return (
      <Wrapper className={className}>
        <Slot id={id} />
      </Wrapper>
    )
  },
  () => true,
)

export const MobileMpu = styled(MPU)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default MPU
