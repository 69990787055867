import { setCookie, getCookie } from '~/js/settings'
import { randomString } from '../routes/helper'

// Parsely personalized article recommendation
export const parselyProfileScript = () => {
  if (!getCookie('uuid')) {
    setCookie('uuid', window.JSGlobals?.domain + randomString(), 365)
  }
  if (window.JSGlobals?.article) {
    const canonical = document
      .querySelector('link[rel=canonical]')
      ?.getAttribute('href')
    fetch(
      `https://api.parsely.com/v2/profile?uuid=${getCookie(
        'uuid',
      )}&url=${canonical}&apikey=${window.JSGlobals.domain}`,
    )
  }
}
