import { jsLoader } from './fileLoaders'

/*
 * Manually copying and pasting the contents of the linked script tag below
 * does not seem to work, as embedded Facebook posts do not render. Hence,
 * this function is used to dynamically create the script tag and append it
 * to the document body.
 */

export const facebookSDK = () => {
  const embeds = document.getElementsByClassName('fb-post')
  if (embeds.length > 0) {
    jsLoader(
      ['https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0'],
      'facebook',
    )
  }
}
