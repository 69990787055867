import { jsLoader } from './fileLoaders'

export const optaScript = () => {
  const selectorAttr = 'opta-data'
  const dmElements = document.getElementById(selectorAttr)

  if (dmElements) {
    return jsLoader(
      ['https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js'],
      'opta',
    )
  }
}
