let performanceLog: boolean

const showPerformanceLog = () => {
  if (typeof performanceLog === 'undefined') {
    const params = new URLSearchParams(window?.location?.search)
    performanceLog = params.has('__DEBUG__')
  }

  return performanceLog
}

export const log = (...args: string[]) => {
  showPerformanceLog() && console.log(...args)
}

export const group = (...args: string[]) => {
  showPerformanceLog() && console.group(...args)
}

// groupEnd() does not accept a parameter, it will
// close the most recently created logging group
export const groupEnd = () => {
  showPerformanceLog() && console.groupEnd()
}

export const warn = (...args: string[]) => {
  showPerformanceLog() && console.warn(...args)
}
