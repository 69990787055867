import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

const Wrapper = styled.div`
  font-size: 19px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  width: calc(100% - 40px);

  @media ${device.mobileL} {
    display: none;
  }

  &#sponsored-teaser {
    .polar-rhr-wrapper {
      display: flex;
      flex-direction: column;
    }

    .polar-rhr-disclosure,
    .polar-rhr-title {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: ${({ theme }) => theme.lightContrast};
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
      text-decoration: none;
      letter-spacing: -0.6px;
      font-weight: 700;
    }

    .polar-rhr-image-wrapper {
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .polar-rhr-link {
      position: relative;
      width: 100%;
      min-width: 100px;
      height: 280px;

      & img {
        object-fit: cover;
        display: block;
        height: 0;
        max-height: 100%;
        max-width: 100%;
        min-height: 100%;
        min-width: 100%;
        width: 0;
        margin: auto;
      }
    }
    .polar-rhr-wrapper:before,
    .polar-rhr-title:before {
      content: 'Read More';
      color: ${({ theme }) => theme.primaryColor};
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
      font-size: 20px;
      background-color: ${({ theme }) => theme.greyContrast};
      display: block;
      padding: 15px;
      margin: 0;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .polar-rhr-title:before {
      color: ${({ theme }) => theme.lightContrast};
      background-color: ${({ theme }) => theme.primaryColor};
      padding: 0;
      margin-bottom: 0;
      font-weight: normal;
      font-size: 18px;
    }
    .polar-rhr-content {
      color: ${({ theme }) => theme.lightContrast};
      background-color: ${({ theme }) => theme.primaryColor};
      padding: 15px;
      font-size: 18px;
      margin-top: 10px;
      letter-spacing: -0.68px;
      line-height: 28px;
      font-weight: normal;
    }
  }
`

const ReadmoreTeaser = ({
  id = '', // id is injected from gpt
}) => {
  return <Wrapper id={id} className="polar-placeholder polar-mobile" />
}

export default ReadmoreTeaser
