type Payload = {
  [key: string]: string | number | boolean
}

export const serialize = (obj: Payload) => {
  const str = []
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(
        encodeURIComponent(p) +
          '=' +
          encodeURIComponent(obj[p] as string | number | boolean),
      )
    }
  return str.join('&')
}
