import React from 'react'
import DailymotionHydratedPost from '~/js/components/LiveblogHydrated/DailymotionHydratedPost'
import { Twitter } from '~/js/components/Twitter'
import Blockquote from '~/library/Article/Blockquote'
import Image from '~/library/Article/Image'
import ListBulleted from '~/library/Article/ListBulleted'
import ListNumbered from '~/library/Article/ListNumbered'
import { Markup } from '~/library/Article/Markup'
import RelatedArticles from '~/library/Article/RelatedArticles'
import {
  ArticleData,
  Configuration,
  PostContentData,
  PostContentItem,
} from '~/types'
import ArticleLink from './ArticleLink'
import Facebook from './Facebook'
import { Instagram } from './Instagram'
import Youtube from './Youtube'

const doesHaveLinks = (data: PostContentData) => {
  const { links } = data
  return !!links && links.length >= 1
}

export const liveBlogComponent = (
  item: PostContentItem,
  index: number,
  configuration: Configuration,
  article: ArticleData,
) => {
  const { data } = item
  switch (item.type) {
    case 'image':
      return (
        <Image
          key={item.type + index}
          data={data}
          srcSet={[640]}
          className="image"
        />
      )
    case 'H2':
    case 'H3':
    case 'H4':
    case 'markup':
      return (
        <Markup
          key={item.type + index}
          article={article}
          data={data}
          hierarchy={article.hierarchy}
          isSponsored={false}
          className="markup"
        />
      )
    case 'listbulleted':
      return (
        <ListBulleted
          key={item.id}
          data={data}
          className="list-bulleted"
          isSponsored={!!article.sponsorName}
          currentDomain={article.publication?.domain}
        />
      )
    case 'listnumbered':
      return <ListNumbered key={item.id} data={data} />
    case 'related':
      if (!doesHaveLinks(data)) {
        return null
      }
      return (
        <RelatedArticles
          key={item.id + index}
          data={data}
          hierarchy={article.hierarchy}
        />
      )
    case 'blockquote':
      return <Blockquote key={item.id + index} data={data} />
    case 'twitter':
      return <Twitter key={item.id + index} data={data} />
    case 'instagram':
      return <Instagram key={item.id + index} data={data} />
    case 'facebook':
      return <Facebook key={item.id + index} data={data} />
    case 'article-link':
      return <ArticleLink key={item.type + index} data={data} />
    case 'video-youtube':
      return <Youtube key={item.type + index} data={data} />
    case 'video-dm':
    case 'dailymotion':
      return (
        <DailymotionHydratedPost
          key={item.type + index}
          article={article}
          configuration={configuration}
          data={data}
        />
      )
    default:
      console.error(`Unhandled component type: ${item.type}`)
      return null
    // return <p key={'unknownComp' + index}>Unhandled: {item.type}</p>
  }
}
