import prop from 'ramda/src/prop'
import { ArticleData, Section } from '~/types'

export const pageTitle = (
  {
    article,
    section = undefined,
  }: { article: ArticleData; section?: { section: Section } },
  siteName: string,
) => {
  const title = article
    ? prop('titleSEO', article)
    : section?.section?.extra?.titleSEO
  const titleFallback = prop('title', article)

  if (title) return title
  if (!title && titleFallback) return titleFallback

  if (typeof article === 'undefined' && typeof section === 'undefined') {
    return `Page not found | ${siteName}`
  }

  return siteName
}
