import React from 'react'
import styled from 'styled-components'
import { customColorLogicSites } from '~/config/JPIMConfig'
import {
  getPrimaryColorBasedOffAllSections,
  getSectionPrimaryColor,
} from '~/config/theme/JPIMStyles'
import quote from '~/icons/quote'
import { ArticleData, FC, Section } from '~/types'
import { formatString } from '../Article/utils/formatString'

const ArticleTitleLabel = styled.span<{
  hierarchyName: string | undefined
  article?: ArticleData
}>`
  color: ${({ theme, article, hierarchyName }) => {
    if (customColorLogicSites.includes(theme.domain)) {
      const formattedHierarchyName = formatString(hierarchyName)
      return getSectionPrimaryColor(theme, formattedHierarchyName)
    }
    if (theme.domain === 'farminglife.com') {
      return getPrimaryColorBasedOffAllSections(theme, article?.sections)
    }
    return theme.primaryColor
  }};
`

export const ColouredQuote = styled.span<{
  hierarchyName: string | undefined
  article?: ArticleData
}>`
  margin-right: 5px;
  display: inline-flex;
  svg {
    width: 1.2em;
    height: auto;

    path {
      fill: ${({ theme, article, hierarchyName }) => {
        if (theme.domain === 'farminglife.com') {
          return getPrimaryColorBasedOffAllSections(theme, article?.sections)
        }
        return getSectionPrimaryColor(theme, hierarchyName)
      }} !important;
    }
  }
`

type ArticleTitleProps = {
  className: string
  title?: string
  titleShort?: string
  path: string
  isRemotePublication?: boolean
  isAnalysis: boolean
  isExplainer: boolean
  hierarchy: Section[] | undefined
  isOpinion: boolean
  maxTitleLength?: number
  manualListName?: string
  article?: ArticleData
}

const ArticleTitle = styled<FC<ArticleTitleProps>>(
  ({
    className,
    title,
    titleShort,
    path,
    isRemotePublication,
    isAnalysis,
    isExplainer,
    isOpinion,
    hierarchy,
    article,
  }) => {
    const hierarchyName = hierarchy?.[0]?.name
    const titleLabel = (hierarchyName: string | undefined) => {
      if (isAnalysis || isExplainer) {
        return (
          <ArticleTitleLabel
            className="articleTitleLabel"
            hierarchyName={hierarchyName}
            article={article}
          >
            {isAnalysis ? 'Analysis' : 'Explainer'}
            {''}
          </ArticleTitleLabel>
        )
      }
    }
    return (
      <a
        className={className}
        title={title}
        href={path}
        // For external links (ie. 3AM)…
        {...(isRemotePublication && {
          target: '_blank',
          rel: 'noopener noreferrer',
        })}
      >
        {isOpinion && (
          <ColouredQuote
            dangerouslySetInnerHTML={{ __html: quote }}
            hierarchyName={hierarchyName}
            article={article}
          />
        )}
        {titleLabel(hierarchyName)}{' '}
        {titleShort
          ? titleShort.length > 80
            ? titleShort.slice(0, 80) + '...'
            : titleShort
          : title && title.length > 80
            ? title.slice(0, 80) + '...'
            : title}{' '}
      </a>
    )
  },
)`
  text-decoration: none;
  color: ${({ isRemotePublication, theme }) =>
    isRemotePublication ? theme.lightContrast : theme.fontColor};

  &.opinion {
    display: inline-table;
  }
`

export default ArticleTitle
