import React from 'react'
import { Domain, Section } from '~/types'

type FormData = {
  title?: string
  page: string
  portalId: string
  section?: string
  list: string
  newsletterName: string
  guid: string
  customText?: string
  newsletterTagline?: string
}

export const getNewsletterHeading = (
  isVideo: boolean,
  videoArticleNewsletterRule: { customText: string; list: string },
  data: { sections?: Section[]; section?: Section },
  formData: FormData,
  domain: Domain,
) => {
  switch (true) {
    case isVideo:
      return (
        <div className="newsletterHeading">
          {videoArticleNewsletterRule.customText}
        </div>
      )
    case !!((data?.sections || data?.section) && formData.customText):
      // Detailed Menu Newsletter
      return (
        <div
          className="newsletterHeading"
          dangerouslySetInnerHTML={{ __html: formData.customText || '' }}
        />
      )
    case domain === 'rotherhamadvertiser.co.uk' ||
      domain === 'newryreporter.com':
      return (
        <div
          className="newsletterComp"
          dangerouslySetInnerHTML={{ __html: formData.newsletterName }}
        />
      )
    case !!formData.customText:
      // SC Newsletter
      return (
        <div
          className="newsletterHeading"
          dangerouslySetInnerHTML={{ __html: formData.customText || '' }}
        />
      )

    default:
      return (
        <>
          <span>Sign up</span> to our {formData.newsletterName} newsletter
          {formData.newsletterTagline}
        </>
      )
  }
}
