import React from 'react'
import PropTypes from 'prop-types'
import { Markup } from '~/library/Article/Markup'

const Faq = ({ data }) => {
  if (!data || !data?.question) {
    return null
  }
  return (
    <>
      <h2>{data.question}</h2>
      <Markup data={data} className="markup" />
    </>
  )
}

Faq.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Faq
