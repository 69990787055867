export const jpiFonts = `
/* Overpass */
    @font-face {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: local('Overpass Regular'),
      url('/fonts/overpass-regular.woff2') format('woff2'),
      url('/fonts/overpass-regular.woff') format('woff');
      unicode-range: U+0020-007F, U+00A0, U+00A3, U+00A9, U+2026;
    }
    @font-face {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: local('Overpass Regular'),
      url('/fonts/overpass-regular-ext.woff2') format('woff2'),
      url('/fonts/overpass-regular-ext.woff') format('woff');
      unicode-range: U+00B0-00FF, U+0100-017F;
    }
    @font-face {
      font-family: 'Adjusted Overpass Fallback';
      src: local(Arial);
      size-adjust: 100%;
      ascent-override: 88%;
      descent-override: 37%;
      line-gap-override: normal;
    }
    @font-face {
      font-family: 'Overpass';
      font-style: italic;
      font-weight: 400;
      font-display: swap;
      src: local('Overpass Regular'),
      url('/fonts/overpass-italic.woff2') format('woff2'),
      url('/fonts/overpass-italic.woff') format('woff');
      unicode-range: U+0020-007F, U+00A0, U+00A3, U+00A9, U+2026;
    }
    @font-face {
      font-family: 'Overpass';
      font-style: italic;
      font-weight: 400;
      font-display: swap;
      src: local('Overpass Regular'),
      url('/fonts/overpass-italic-ext.woff2') format('woff2'),
      url('/fonts/overpass-italic-ext.woff') format('woff');
      unicode-range: U+00B0-00FF, U+0100-017F;
    }

    @font-face {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: bold;
      font-display: swap;
      src: local('Overpass SemiBold'),
      url('/fonts/overpass-600.woff2') format('woff2'),
      url('/fonts/overpass-600.woff') format('woff');
      unicode-range: U+0020-007F, U+00A0, U+00A3, U+00A9, U+2026;
    }
    @font-face {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: bold;
      font-display: swap;
      src: local('Overpass SemiBold'),
      url('/fonts/overpass-600-ext.woff2') format('woff2'),
      url('/fonts/overpass-600-ext.woff') format('woff');
      unicode-range: U+00B0-00FF, U+0100-017F;
    }
    @font-face {
      font-family: 'Overpass';
      font-style: italic;
      font-weight: bold;
      font-display: swap;
      src: local('Overpass SemiBold'),
      url('/fonts/overpass-600-italic.woff2') format('woff2'),
      url('/fonts/overpass-600-italic.woff') format('woff');
      unicode-range: U+0020-007F, U+00A0, U+00A3, U+00A9, U+2026;
    }
    @font-face {
      font-family: 'Overpass';
      font-style: italic;
      font-weight: bold;
      font-display: swap;
      src: local('Overpass SemiBold'),
      url('/fonts/overpass-600-italic-ext.woff2') format('woff2'),
      url('/fonts/overpass-600-italic-ext.woff') format('woff');
      unicode-range: U+00B0-00FF, U+0100-017F;
    }

    /* Open Sans */

    @font-face {
      font-family: 'OpenSans';
      src: url('/fonts/OpenSans-Regular.woff2') format('woff2');
      font-weight: normal;
      font-display: swap;
      font-style: normal;
    }
    @font-face {
      font-family: 'Adjusted OpenSans Fallback';
      src: local(Arial);
      size-adjust: 102%;
      ascent-override: 99%;
      descent-override: 33%;
      line-gap-override: normal;
    }

    @font-face {
      font-family: 'OpenSans-Semibold';
      src: url('/fonts/OpenSans-SemiBold.woff2') format('woff2'), url('/fonts/OpenSans-SemiBold.woff') format('woff');;
      font-weight: normal;
      font-display: swap;
      font-style: normal;
    }
    @font-face {
      font-family: 'Adjusted OpenSans-Semibold Fallback';
      src: local(Arial);
      size-adjust: 108%;
      ascent-override: 92%;
      descent-override: 32%;
      line-gap-override: normal;
  }
`

export const merriweatherFonts = `
 /* Merriweather */
    @font-face {
      font-family: 'Merriweather';
      src: url('/fonts/merriweather-regular.woff2') format('woff2'),
      url('/fonts/merriweather-regular.woff') format('woff');
      font-weight: normal;
      font-display: swap;
      font-style: normal;
    }
    @font-face {
      font-family: 'Adjusted Merriweather Fallback';
      src: local(Georgia);
      size-adjust: 108%;
      ascent-override: 86%;
      descent-override: 31%;
      line-gap-override: normal;
    }

    @font-face {
      font-family: 'Merriweather-Bold';
      src: url('/fonts/merriweather-700.woff2') format('woff2'),
      url('/fonts/merriweather-700.woff') format('woff');
      font-weight: bold;
      font-display: swap;
      font-style: normal;
    }
    @font-face {
      font-family: 'Adjusted Merriweather-Bold Fallback';
      src: local(Georgia);
      size-adjust: 116%;
      ascent-override: 87%;
      descent-override: 20%;
      line-gap-override: normal;
  }
    

    
    /* Open Sans */
    @font-face {
      font-family: 'OpenSans';
      src: url('/fonts/OpenSans-Regular.woff2') format('woff2');
      font-weight: normal;
      font-display: swap;
      font-style: normal;
    }
    @font-face {
      font-family: 'OpenSans-Semibold';
      src: url('/fonts/OpenSans-SemiBold.woff2') format('woff2'), url('/fonts/OpenSans-SemiBold.woff') format('woff');;
      font-weight: normal;
      font-display: swap;
      font-style: normal;
    }

    /* Overpass */
    @font-face {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: local('Overpass Regular'),
      url('/fonts/overpass-regular.woff2') format('woff2'),
      url('/fonts/overpass-regular.woff') format('woff');
      unicode-range: U+0020-007F, U+00A0, U+00A3, U+00A9, U+2026;
    }
`
