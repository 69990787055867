import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

const StyledDivider = styled.div`
  @media ${device.laptop} {
    height: 100%;
    width: 1px;
    background-color: var(--primary-500);
    margin: 0 8px;
  }
`

export const NewsletterSignupDivider = () => {
  return <StyledDivider className="newsletter-signup-divider" />
}
