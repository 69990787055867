import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

const HiddenRecaptchaStyle = createGlobalStyle`
 .grecaptcha-badge {
    display: none;
  }
    `

const StyledNotice = styled.div`
  width: 100%;
  font-size: 8px;
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  line-height: 17px;
  color: ${({ theme }) => theme.fontColorMedium};

  a {
    color: ${({ theme }) => theme.fontColorMedium};
    text-decoration: none;
  }
`

export const ReCaptchaNotice = () => {
  return (
    <>
      <HiddenRecaptchaStyle />
      <StyledNotice
        className="newsletter-message-footer"
        data-testid="newsletter-msg-footer"
      >
        This site is protected by reCAPTCHA and the Google{' '}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>{' '}
        and{' '}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{' '}
        apply.
      </StyledNotice>
    </>
  )
}
