import { IslandForRender } from '@brightsites/island-hydration/lib/components'
import React from 'react'
import Facebook from '~/embeds/Facebook'
import { Instagram } from '~/embeds/Instagram'
import { Twitter } from '~/js/components/Twitter'
import FactBox from '~/library/Article/FactBox'
import { Markup } from '~/library/Article/Markup'
import HorizontalRule from '../HorizontalRule'
import Table from '../Table'

export const removeEmptySnippet = () => {
  return (comp) => {
    switch (comp.type) {
      case 'markup': {
        if (!comp.data.markup) {
          return false
        }
      }
    }
    return true
  }
}

// @todo replace with generic renederBody for
export const renderBody = (body) => {
  return body
    .filter((x) => x.type)
    .filter(removeEmptySnippet())
    .map((snippet, index) => {
      switch (snippet.type) {
        case 'H2':
        case 'H3':
        case 'H4':
        case 'H5':
        case 'H6':
        case 'listbulleted':
        case 'markup':
          return <Markup key={snippet.type + index} data={snippet.data} />
        case 'HR':
          return (
            <HorizontalRule
              key={`${snippet.type}-${index}`}
              className="hr-article"
            />
          )
        case 'table':
          return (
            <Table
              key={snippet.type + index}
              data={snippet.data}
              currentDomain={snippet.domain}
            />
          )
        case 'twitter':
          return (
            <IslandForRender
              key="twitter"
              name="Twitter"
              props={{
                data: snippet.data,
              }}
            >
              <Twitter key={snippet.type + index} data={snippet.data} />
            </IslandForRender>
          )

        case 'facebook':
          return <Facebook key={snippet.type + index} data={snippet.data} />
        case 'instagram':
          return <Instagram key={snippet.type + index} data={snippet.data} />
        case 'factbox':
          return <FactBox key={snippet.type + index} data={snippet.data} />
        default:
          // temporarily warn the client if missing
          console.warn('TODO: define component type ----->', snippet.type)
          return (
            <div key={snippet.type + index} style={{ color: 'red' }}>
              TODO: define component type {snippet.type}
            </div>
          )
      }
    })
}
