import React from 'react'
import styled from 'styled-components'

const ListNumbered = styled(({ className, data: { title, markup } }) => {
  return (
    <div className={className}>
      <excessremovablewrapper dangerouslySetInnerHTML={{ __html: title }} />
      <excessremovablewrapper dangerouslySetInnerHTML={{ __html: markup }} />
    </div>
  )
})`
  ol {
    padding: 0;
    margin: 0;
    line-height: 28px;
    list-style: none;
    counter-reset: item;
  }
  li {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    margin-bottom: 10px;
    counter-increment: item;
    font-size: 19px;
    line-height: 32px;
    letter-spacing: -0.11px;
    &:before {
      content: counter(item) ':';
      text-align: center;
      display: inline-block;
      font-weight: bold;
      margin-right: 5px;
      -webkit-font-smoothing: initial;
      font-weight: bold;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ListNumbered
