import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

export const Headline = styled.h1<{ isPremiumArticleLayout: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.titleFont};
  text-align: ${({ isPremiumArticleLayout }) =>
    isPremiumArticleLayout ? 'center' : 'left'};
  font-size: 24px;
  line-height: 32px;
  margin: 8px 0;
  word-break: break-word;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0;
  }
`

export const HeadlineContrast = styled(Headline)`
  color: ${({ theme }) => theme.lightContrast};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-align: left;
  letter-spacing: -0.6px;
  font-weight: normal;

  @media ${device.tablet} {
    margin: 16px 0 8px;
    font-size: 50px;
    line-height: 57px;
  }
`
