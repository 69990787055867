import { IslandForRender } from '@brightsites/island-hydration/lib/components'
import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import { getAdConfigSetting } from '~/providers/AdManager/utils/getAdConfigSetting'
import { device } from '~/config/theme/JPIMStyles'
import {
  ADCONFIG_DESKTOP,
  ADCONFIG_DESKTOP_LITE,
  ADSLOT_OUTBRAIN_FEED,
  ADSLOT_OUTBRAIN_MID,
} from '~/constants/ads'
import isCommentingEnabled from '~/util/commenting'
import { getPageId } from '~/util/getAdData'
import AxateArticleBottom from '~/library/ThirdParty/AxateArticleBottom'
import Taboola from '~/js/components/Taboola'
import PianoArticleBottom from '~/library/ThirdParty/PianoArticleBottom'
import PreAxateContent from '~/providers/ArticleContent/components/PreAxateContent'
import MarkupAds, { InlineAdsContainer } from './Ads/MarkupAds'
import ReadmoreTeaser from './Ads/ReadmoreTeaser'
import MantisAds from './Ads/MantisAds'
import { AffiliateMessage } from './AffiliateMessage'
import { ArticleContent } from './ArticleContent'
import { StyledArticleMeta as ArticleMeta } from './ArticleMeta'
import { Axate } from './Axate'
import Blockquote from './Blockquote'
import Breadcrumb from './Breadcrumb'
import ContentRoot from './ContentRoot'
import CommentButton from './Embeds/CommentButton'
import Dailymotion from './Embeds/Dailymotion'
import DisqusComments from './Embeds/DisqusComments'
import Facebook from './Embeds/Facebook'
import Faq from './Embeds/Faq'
import HTMLEmbed from './Embeds/HTML'
import { Instagram } from './Embeds/Instagram'
import TikTok from './Embeds/TikTok'
import { Twitter } from '~/js/components/Twitter'
import Youtube from './Embeds/Youtube'
import Entale from './Entale'
import FactBox from './FactBox'
import Flourish from './Flourish'
import { Headline } from './Headline'
import HeadlineLabel from './HeadlineLabel'
import { HeroGrid } from './HeroGrid'
import HomeDetail from './HomeDetail'
import HorizontalRule from './HorizontalRule'
import IFrame from './IFrame'
import Image from './Image'
import { LiveblogHydrated } from '~/js/components/LiveblogHydrated/LiveblogHydrated'
import { Markup } from './Markup'
import MostPopularInline from './MostPopularInline'
import NewsletterSignup from '~/library/NewsletterSignup/NewsletterSignup'
import Products from './Products/Products'
import ProductVerdict from './Products/ProductVerdict'
import StarRating from './Products/StarRating'
import { ReadMore } from './ReadMore'
import RelatedArticles from './RelatedArticles'
import SocialShare from './SocialShare'
import SocialShareMobile from './SocialShareMobile'
import Subheadline from './Subheadline'
import { TabbedContent } from './TabbedContent'
import Table from './Table'
import Topics from './Topics'
import {
  ensureCommercialElementsAreAfterParagraphs,
  stripNonTaboolaFriendlyElements,
} from './utils/article-utils'
import InfoBox from './InfoBox'
import {
  domainsWithNativeShare,
  increasedWidthSites,
  ViafouraCarouselSites,
} from '~/config/JPIMConfig'
import { AdManagerConfig, ArticleData, LiveblogData } from '~/types'
import ViafouraCommentButton from '~/embeds/ViafouraCommentButton'
import ViafouraComments from '~/library/ThirdParty/ViafouraComments'
import ViafouraCarousel from '~/library/ThirdParty/ViafouraCarousel'
import ViafouraConversationStarter from '~/library/ThirdParty/ViafouraConversationStarter'
import { AdManagerContext } from '~/providers/AdManager'
import { ArticleContentContext } from '~/providers/ArticleContent'
import { ContentContext } from '~/providers/Content'
import striptags from 'striptags'
import AboutPartner from '~/library/Article/AboutPartner'
import { containsAffiliateLinks } from './utils/article-utils'
import renderPianoPaywall from './utils/piano-paywall'
import PianoArticlePaywall from '../ThirdParty/PianoArticlePaywall'
import { ARTICLE_MAX_LENGTH, excludedDomains } from '~/util/continueReading'

const Wrapper = styled.div<{
  isPremiumArticleLayout: boolean
  isWideArticle: boolean
}>`
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.lightContrast};
  @media ${device.laptop} {
    max-width: ${({ isPremiumArticleLayout }) =>
      isPremiumArticleLayout ? '100%' : '630px'};
  }
  @media ${device.laptopM} {
    max-width: ${({ isPremiumArticleLayout, isWideArticle }) =>
      isPremiumArticleLayout || isWideArticle ? '1000px' : '630px'};
    margin: ${({ isPremiumArticleLayout, isWideArticle }) =>
      isPremiumArticleLayout || isWideArticle ? '0 auto' : '0 20px'};
  }

  ${({ isPremiumArticleLayout }) => {
    if (!isPremiumArticleLayout) {
      return
    }

    return `
      .article-header {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        column-gap: 4px;

        & > * {
          grid-column: 1/span 4;
          @media ${device.tablet} {
            grid-column: 2/span 9;
          }
        }

        @media ${device.tablet} {
          grid-template-columns: repeat(11, minmax(0, 1fr));
        }
      }
    `
  }}

  .read-more {
    @media ${device.maxMobileL} {
      max-height: ${ARTICLE_MAX_LENGTH}vh;
      overflow-y: clip;
      position: relative;
    }
  }

  #continue-reading-overlay {
    display: none;
    @media ${device.maxMobileL} {
      display: none;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.46) 8%,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 1) 99%
      );
      bottom: -1px;
      left: -15px;
      position: absolute;
      right: 0;
      text-align: center;
      width: 100vw;
      z-index: 2;
    }
  }

  #continue-reading-btn {
    @media ${device.maxMobileL} {
      background-color: white;
      border: 1px solid #61b4d6;
      border-radius: 20px;
      color: #61b4d6;
      display: inline-block;
      height: inherit;
      margin: 50px 10px 16px 10px;
      padding-bottom: 5px;
      padding-top: 5px;
      text-align: center;
      width: 90%;
    }
  }

  #continue-reading-btn:hover {
    @media ${device.maxMobileL} {
      border: 1px solid grey;
      color: grey;
      transition: 0.3s;
    }
  }

  .feedIdx-0 .ob-widget,
  .ob-widget.ob-grid-layout {
    border-top: 1px solid ${({ theme }) => theme.greyContrast};
    border-bottom: 1px solid ${({ theme }) => theme.greyContrast};
    padding: 0 0 8px !important;
    margin: 32px 0 !important;
  }

  .social-comments {
    width: 100%;
  }

  // .axate-standfirst {
  //   width: calc(100% - 40px);
  // }

  &.sponsored-article {
    img,
    video {
      max-width: 602px;
      height: auto;
      width: 100%;
    }

    iframe {
      max-width: 602px;
      max-height: 400px;
    }
  }

  &.blocked-article {
    position: relative;
  }

  @media ${device.maxLaptop} {
    padding-right: 0;
    margin-right: 0;
  }
`

const BreadcrumbContainer = styled.div<{ isPremiumArticleLayout: boolean }>`
  justify-content: ${({ isPremiumArticleLayout }) =>
    isPremiumArticleLayout ? 'center' : 'start'};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 28.5px;
`

export const SocialShareAndCommentWrapper = styled.div<{
  isPremiumArticleLayout: boolean
}>`
  width: 100%;
  position: relative;
  margin-top: ${({ isPremiumArticleLayout }) =>
    isPremiumArticleLayout && '10px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid ${({ theme }) => theme.greyContrast};
  border-bottom: ${({ isPremiumArticleLayout, theme }) =>
    isPremiumArticleLayout
      ? `1px solid ${theme.greyContrast}`
      : `double ${theme.greyContrast}`};

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    row-gap: 16px;

    .socials-comments {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media ${device.tablet} {
        width: ${({ isPremiumArticleLayout }) =>
          isPremiumArticleLayout ? 'auto' : '100%'};
      }
    }

    .social-share {
      display: none;

      @media ${device.tablet} {
        display: flex;
        width: ${({ isPremiumArticleLayout }) =>
          isPremiumArticleLayout ? 'auto' : 'calc(100% - 40px)'};
        align-items: center;
        justify-content: ${({ isPremiumArticleLayout }) =>
          isPremiumArticleLayout ? 'end' : 'start'};
      }
    }

    @media ${device.tablet} {
      flex-direction: row;
    }
  }

  #comment-counter-wording {
    @media ${device.maxMobileL} {
      display: none;
    }
  }
`

export const TaboolaAndDisqusWrapper = styled.div`
  max-width: 630px;
  margin: 0 auto;
`

const InvisibleDivider = styled(({ className }) => (
  <div className={className} />
))`
  clear: both;
  display: none; // TODO - completely remove InvisibleDivider component if no problems found on live
`

export const ExclusiveLabel = styled.span`
  color: ${({ theme }) => theme.redContrast};
  display: inline-block;
  margin: 0 4px 0 0;
`
type ContentWrapperProps = {
  adTargeting: boolean
  article: ArticleData
  articleTitle: string
  configuration: any
  adConfig?: AdManagerConfig
  domain: string
  isPremiumArticleLayout?: boolean
  isInfiniteScrollEnabled?: boolean
  isLastInfiniteScrollArticle?: boolean
  isVideoArticleType?: boolean
}

const ContentWrapper: React.FC<ContentWrapperProps> = memo<ContentWrapperProps>(
  ({
    adTargeting,
    adConfig,
    configuration,
    domain,
    isPremiumArticleLayout = false,
    isInfiniteScrollEnabled = false,
    isLastInfiniteScrollArticle = false,
    isVideoArticleType = false,
  }) => {
    const {
      article,
      title,
      isPremium: contextIsPremium,
      isExclusive,
      hierarchyName,
      isAxateEnabled,
      isReviewPresent,
      isInfiniteScroll: contextIsInfiniteScroll,
      products,
      addDropCap,
    } = useContext(ArticleContentContext)
    const isPremium =
      isPremiumArticleLayout !== undefined
        ? isPremiumArticleLayout
        : contextIsPremium
    const isInfiniteScroll =
      isInfiniteScrollEnabled !== undefined
        ? isInfiniteScrollEnabled
        : contextIsInfiniteScroll

    const watchThisId = configuration.publicationConfig?.watchThisTid

    const { contentArray, inArticleProducts } = useContext(ContentContext)

    const { piano, adLiteEnabled, hubspot, viafoura } = configuration

    adConfig = adConfig || useContext(AdManagerContext)

    const hasArticleCommenting = isCommentingEnabled(article)
    const isSubscriber = configuration.isSubscriber

    let content: Array<JSX.Element> = []

    // Strip HTML tags from lead text
    const strippedLeadText = striptags(article.lead)

    const canShortenArticle =
      !configuration.isAdFree &&
      !excludedDomains.includes(domain) &&
      !article.liveblog

    const isNewProductTemplate =
      products &&
      /^\/recommended\/garden-furniture\/.+/.test(article.path) &&
      domain === 'scotsman.com'

    if (contentArray?.length) {
      const lastIndex = contentArray.length - 1
      let taboolaIndex = 0
      let mantisIndex = 0

      content = contentArray.map((snippet: any, index: number) => {
        switch (snippet.type) {
          case 'blockquote':
            return (
              <Blockquote
                key={`${snippet.type}-${index}`}
                data={snippet.data}
                hierarchyName={hierarchyName}
              />
            )
          case 'brightcove':
            return null
          case 'video-dm':
          case 'dailymotion':
            return (
              <Dailymotion
                key={snippet.type + index}
                configuration={configuration}
                article={article}
                data={snippet.data}
              />
            )
          case 'facebook':
            return <Facebook key={snippet.type + index} data={snippet.data} />
          case 'factbox':
            return <FactBox key={snippet.type + index} data={snippet.data} />
          case 'faq':
            return <Faq data={snippet.data} key={snippet.type + index} />
          case 'flourish':
            return (
              <Flourish
                key={snippet.type + index}
                data={snippet.data}
                domain={domain}
              />
            )
          case 'homedetail':
            return <HomeDetail key={snippet.type + index} data={snippet.data} />
          case 'HR':
            return (
              <HorizontalRule
                key={`${snippet.type}-${index}`}
                className="hr-article"
              />
            )
          case 'html':
            return <HTMLEmbed key={snippet.type + index} data={snippet.data} />
          case 'iframe':
            return <IFrame key={snippet.type + index} data={snippet.data} />
          case 'image':
            return (
              <Image
                key={snippet.type + index}
                srcSet={[320, 640]}
                data={snippet.data}
                className="metroArticleImage"
              />
            )
          case 'infobox':
            return <InfoBox key={snippet.type + index} data={snippet.data} />
          case 'instagram':
            return <Instagram key={snippet.type + index} data={snippet.data} />
          case 'divider':
            return <InvisibleDivider key={'divider' + index} />
          case 'H2':
          case 'H3':
          case 'H4':
          case 'H5':
          case 'H6':
          case 'listnumbered':
          case 'listbulleted':
          case 'markup':
            return (
              <Markup
                key={snippet.type + index}
                article={article}
                data={snippet.data}
                polar={article.polar}
                hierarchy={article.hierarchy}
                isSponsored={!!article.sponsorName}
                className={[
                  'markup',
                  `${addDropCap && !index ? 'first' : ''}`, // Drop-cap on specific article types and The Scotsman
                ].join(' ')}
                isPremiumArticleLayout={isPremium}
              />
            )
          case 'markupAds':
            return (
              <InlineAdsContainer key={snippet.type + index}>
                <MarkupAds {...snippet.data} />
              </InlineAdsContainer>
            )
          case 'taboola':
            return (
              <IslandForRender
                id={`taboola-mid-${taboolaIndex++}`}
                key={`taboola-${taboolaIndex++}`}
                name="Taboola"
                props={{
                  mode: 'thumbs-ma-01',
                  containerId: 'taboola-mid-article-thumbnails',
                  placement: 'Mid Article Thumbnails',
                }}
              >
                <Taboola
                  key={snippet.type + index}
                  mode="thumbs-ma-01"
                  containerId="taboola-mid-article-thumbnails"
                  placement="Mid Article Thumbnails"
                />
              </IslandForRender>
            )
          case 'readMore':
            return <ReadMore key={snippet.type + index} data={snippet.data} />
          case 'readmoreteaser':
            return (
              <ReadmoreTeaser
                key={'readmoreteaser' + index}
                id={snippet.data.id}
              />
            )
          case 'related':
            return (
              !isInfiniteScroll &&
              snippet.data.links?.length > 0 && (
                <RelatedArticles
                  key={snippet.type + index}
                  data={snippet.data}
                  hierarchy={article.hierarchy}
                />
              )
            )
          case 'mantisAd':
            return (
              <MantisAds
                key={`mantis-ad-${mantisIndex}`}
                index={mantisIndex++}
                dataConfig={{
                  offset: mantisIndex,
                  title: 'Recommended Reads',
                }}
              />
            )
          case 'tiktok':
            return <TikTok key={snippet.type + index} data={snippet.data} />
          case 'twitter':
            return (
              <IslandForRender
                key="twitter"
                name="Twitter"
                props={{
                  data: snippet.data,
                }}
              >
                <Twitter key={snippet.type + index} data={snippet.data} />
              </IslandForRender>
            )
          case 'video-youtube':
          case 'youtube':
            return <Youtube key={snippet.type + index} data={snippet.data} />
          case 'table':
            return (
              <Table
                key={snippet.type + index}
                data={snippet.data}
                isSponsored={!!article.sponsorName}
                currentDomain={domain}
              />
            )
          case 'components': // hack to avoid ad duplication @todo this whole file should be deprecated
            return (
              <React.Fragment key={snippet.type + index}>
                {snippet.data}
              </React.Fragment>
            )
          default:
            // temporarily warn the client if missing
            console.error(
              'TODO: define component type',
              snippet.type,
              lastIndex,
              index,
            )
        }
      })

      const midPianoPaywallIndex = content.findIndex(
        (content) => content.key === 'piano-article-paywall-mid',
      )

      // Inject Piano Article Paywall
      if (!article.polar && !article.sponsorName && !article.partners) {
        // Calls piano paywall function and injects paywall component
        // Just returns the unmodified content if Axate is enabled
        content = renderPianoPaywall({
          article,
          content,
          contentArray,
          domain,
          isPremium,
          piano,
        })

        // Inject inline most popular component
        const hideMostPopular = ['The Scotsman', 'BristolWorld']
        const isRecommendedSection = article?.hierarchy?.some(({ name }) =>
          /recommended/i.test(name),
        )
        if (
          contentArray.length >= 5 &&
          !hideMostPopular.includes(configuration?.name) &&
          !isRecommendedSection &&
          !isInfiniteScroll &&
          !hasArticleCommenting &&
          !viafoura
        ) {
          content.splice(
            midPianoPaywallIndex ? midPianoPaywallIndex + 2 : 5,
            0,
            <MostPopularInline
              key="most-popular-inline"
              configuration={configuration}
              hierarchy={article.hierarchy}
            />,
          )
        }
      }

      // Inject Newsletter signup after piano-article-paywall-mid div for mobile only
      if (contentArray.length >= 4 && hubspot && !isInfiniteScroll) {
        content.splice(
          midPianoPaywallIndex ? midPianoPaywallIndex + 1 : 4,
          0,
          <NewsletterSignup
            key="newsletter-signup"
            domain={domain}
            data={article}
            formName="mobile-article"
            configuration={configuration}
            pathName={article.path}
            mobileOnly
            isVideo={article?.articleTypes?.[0]?.name === 'Video'}
          />,
        )
      }
    }

    if (inArticleProducts.length > 0) {
      content.push(
        <Products
          key="inArticleProducts"
          products={inArticleProducts}
          articleTitle={title}
        />,
      )
      content.push(
        <ProductVerdict
          key="product-verdict"
          article={article}
          newTemplate={!!isNewProductTemplate}
          configuration={configuration}
          domain={domain || ''}
        />,
      )
    } else if (products && inArticleProducts.length == 0) {
      content.push(
        <Products key="products" products={products} articleTitle={title} />,
      )
      content.push(
        <ProductVerdict
          key="product-verdict"
          article={article}
          newTemplate={!!isNewProductTemplate}
          configuration={configuration}
          domain={domain || ''}
        />,
      )
    }

    const url = configuration.baseUrl + article.path
    const liveBlogId = article?.liveblog?.data?.id
    let liveblogs: LiveblogData

    // this is from cms
    if (article.liveblog) {
      liveblogs = article.liveblog
      content.push(
        <IslandForRender
          key="live-blog"
          name="LiveblogHydrated"
          props={{
            adConfig,
            adTargeting,
            article,
            configuration,
            liveBlogId,
            liveblogs,
            url,
          }}
        >
          <LiveblogHydrated
            adConfig={adConfig}
            adTargeting={adTargeting}
            article={article}
            configuration={configuration}
            liveBlogId={+(liveBlogId || '')}
            liveblogs={liveblogs}
            url={url}
          />
        </IslandForRender>,
      )
    }

    content = stripNonTaboolaFriendlyElements(content)

    const markupParagraphs = content.filter(
      ({ key }) => typeof key === 'string' && key.startsWith('markup'),
    )

    const filterParagraphsByLength = (
      paragraphs: JSX.Element[],
      minLength: number,
    ): JSX.Element[] => {
      return paragraphs.filter(
        (markupObject: JSX.Element): boolean =>
          markupObject?.props?.data?.markup?.length > minLength,
      )
    }

    const eligibleParagraphs: JSX.Element[] = filterParagraphsByLength(
      markupParagraphs,
      60,
    )

    const afterIndexParagraph = (index: number) =>
      eligibleParagraphs.length >= index
        ? eligibleParagraphs[index - 1]
        : undefined

    const addViafouraConversationStarter = (
      content: JSX.Element[],
      afterParagraph: JSX.Element | undefined,
    ): JSX.Element[] => {
      const hasEnoughEligibleParagraphs = eligibleParagraphs.length >= 5

      if (afterParagraph && !isInfiniteScroll && hasEnoughEligibleParagraphs) {
        const paragraphIndex = eligibleParagraphs.findIndex(
          (p) => p.key === afterParagraph?.key,
        )

        if (paragraphIndex >= 3) {
          let totalLength = 0

          for (let i = paragraphIndex + 1; i < eligibleParagraphs.length; i++) {
            const nextParagraph = eligibleParagraphs[i]
            totalLength += nextParagraph?.props?.data?.markup?.length || 0
          }

          if (totalLength >= 200) {
            return content.flatMap((item: JSX.Element) => {
              if (item.key === afterParagraph.key) {
                return [
                  item,
                  <ViafouraConversationStarter
                    isCommentingEnabled={hasArticleCommenting}
                    key={'viafouraConversationStarter'}
                  />,
                ]
              }
              return item
            })
          }
        }
      }

      return content
    }

    const addViafouraCarousel = (
      content: JSX.Element[],
      afterParagraph: JSX.Element | undefined,
    ): JSX.Element[] => {
      const shouldRenderCarousel =
        !isInfiniteScroll &&
        afterParagraph &&
        isSubscriber &&
        ViafouraCarouselSites.includes(domain)

      if (shouldRenderCarousel) {
        const paragraphIndex = eligibleParagraphs.findIndex(
          (p) => p.key === afterParagraph?.key,
        )

        if (paragraphIndex >= 6) {
          let totalLength = 0

          for (let i = paragraphIndex + 1; i < eligibleParagraphs.length; i++) {
            const nextParagraph = eligibleParagraphs[i]
            totalLength += nextParagraph?.props.data?.markup?.length || 0
          }

          if (totalLength >= 200) {
            return content.flatMap((item: JSX.Element) => {
              if (item.key === afterParagraph.key) {
                return [
                  item,
                  <ViafouraCarousel
                    isCommentingEnabled={hasArticleCommenting}
                    key={'viafouraCarousel'}
                    pageId={getPageId(article)}
                  />,
                ]
              }
              return item
            })
          }
        }
      }

      return content
    }

    content = addViafouraConversationStarter(content, afterIndexParagraph(4))
    content = addViafouraCarousel(content, afterIndexParagraph(7))

    content = ensureCommercialElementsAreAfterParagraphs(content)

    // debugging tool - prints component order
    // content.forEach((a) => (a.key ? console.log(a.key) : console.log(a)))

    const taboolaPlacementText =
      adLiteEnabled || isSubscriber
        ? 'Below Article Thumbnails - Subscription'
        : 'Below Article Thumbnails'

    return (
      <Wrapper
        id="content-wrapper"
        className={article.polar ? 'sponsored-article' : ''}
        isPremiumArticleLayout={isPremium}
        isWideArticle={increasedWidthSites.includes(domain)}
      >
        <div className="article-header" data-testid="article-header">
          <BreadcrumbContainer isPremiumArticleLayout={isPremium}>
            <HeadlineLabel article={article} />
            <Breadcrumb hierarchy={article.hierarchy} article={article} />
          </BreadcrumbContainer>
          <Headline
            id={article.polar && 'native-content-title'}
            data-testid="article-title"
            isPremiumArticleLayout={isPremium}
            className="article-title"
          >
            {isExclusive && <ExclusiveLabel>Exclusive:</ExclusiveLabel>}
            {article.title}
          </Headline>
          {isReviewPresent && (
            <StarRating
              rating={+(article?.extra?.starRating || '')}
              withoutPercentage={true}
            />
          )}
          {isPremium && (
            <Subheadline
              id={article.polar && 'native-content-summary'}
              data-testid="article-sub-title"
              dangerouslySetInnerHTML={{ __html: strippedLeadText }}
              isPremiumArticleLayout={isPremium}
              className="subheadline"
            />
          )}
        </div>
        {!isPremium && (
          <ArticleMeta
            className="article-meta"
            data-testid="article-meta"
            article={article}
            configuration={configuration}
            isPremiumArticleLayout={isPremium}
            isInfiniteScrollEnabled={isInfiniteScroll}
          />
        )}
        {isAxateEnabled && <Axate domain={domain} />}
        {(() => {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <ContentRoot
              adLiteEnabled={adLiteEnabled}
              premium={article.premium}
              className={`article-content ${canShortenArticle && 'read-more'}`}
              isPremiumArticleLayout={isPremium}
              isAxateEnabled={isAxateEnabled}
            >
              <PreAxateContent configuration={configuration} />
              {!isPremium && containsAffiliateLinks(article) && (
                <AffiliateMessage />
              )}
              {isVideoArticleType && watchThisId ? (
                <TabbedContent
                  article={article}
                  hierarchy={article.hierarchy}
                  tabLabels={['This video', 'More videos']} // Array of labels for the tab buttons
                  // Array of components and their props for the tabs
                  tabComponents={[
                    {
                      component: ArticleContent,
                      props: { article, content, isPremium, strippedLeadText },
                    },
                    {
                      component: HeroGrid,
                      props: { configuration, watchThisId },
                    },
                  ]}
                  hydratedComponent="HeroGrid"
                />
              ) : (
                <ArticleContent
                  article={article}
                  content={content}
                  isPremium={isPremium}
                  strippedLeadText={strippedLeadText}
                />
              )}
              {canShortenArticle && (
                <div id="continue-reading-overlay">
                  <button id="continue-reading-btn">Continue Reading</button>
                </div>
              )}
            </ContentRoot>
          )
        })()}
        {!article.polar && !article.sponsorName && !article.partners && (
          <PianoArticleBottom />
        )}
        {isAxateEnabled && <AxateArticleBottom />}
        {isAxateEnabled && isPremium && <PianoArticlePaywall />}
        {article.partners && <AboutPartner article={article} />}
        {article.topics && !isPremium && !isInfiniteScroll && (
          <Topics
            article={article}
            topics={article.topics.filter(
              ({ path }) => !/matrix|essentials5$/.test(path),
            )}
            hierarchy={article.hierarchy}
          />
        )}
        {article?.extra?.entaleUrl && <Entale url={article.extra.entaleUrl} />}
        {!isInfiniteScroll && (
          <>
            <SocialShareAndCommentWrapper isPremiumArticleLayout={isPremium}>
              <div className="inner">
                {article.topics && isPremium && (
                  <>
                    <Topics
                      article={article}
                      topics={article.topics.filter(
                        ({ path }) => !/matrix|essentials5$/.test(path),
                      )}
                      hierarchy={article.hierarchy}
                      isPremiumArticleLayout={isPremium}
                    />
                  </>
                )}
                <div className="socials-comments">
                  {domainsWithNativeShare.includes(configuration.domain) && (
                    <SocialShareMobile
                      position="in article - bottom"
                      article={article}
                      shareClass="bottom-share-btn"
                    />
                  )}
                  <SocialShare
                    position="in article - bottom"
                    article={article}
                    configuration={configuration}
                  />
                  {hasArticleCommenting && configuration.disqus ? (
                    <CommentButton
                      pageId={getPageId(article)}
                      className="commentButtonDisqusCounter"
                      position="bottom"
                      article={article}
                      hierarchy={article.hierarchy}
                    />
                  ) : viafoura && !isInfiniteScroll ? (
                    <ViafouraCommentButton
                      article={article}
                      isCommentingEnabled={hasArticleCommenting}
                      className="commentButtonViafouraCounter"
                      position="top"
                      hierarchy={article.hierarchy}
                    />
                  ) : null}
                </div>
              </div>
            </SocialShareAndCommentWrapper>

            {/* Viafoura commenting for subscribed users only */}
            {hasArticleCommenting && viafoura && isSubscriber && (
              <ViafouraComments
                configuration={configuration}
                article={article}
                belowArticle
              />
            )}

            <TaboolaAndDisqusWrapper>
              {getAdConfigSetting(
                adConfig,
                ADSLOT_OUTBRAIN_FEED,
                adLiteEnabled ? ADCONFIG_DESKTOP_LITE : ADCONFIG_DESKTOP,
              ) && (
                <IslandForRender
                  key="taboola"
                  name="Taboola"
                  props={{
                    mode: 'alternating-thumbnails-a',
                    containerId: 'taboola-below-article-thumbnails',
                    placement: taboolaPlacementText,
                  }}
                >
                  <Taboola
                    mode="alternating-thumbnails-a"
                    containerId="taboola-below-article-thumbnails"
                    placement={taboolaPlacementText}
                  />
                </IslandForRender>
              )}

              {hasArticleCommenting && configuration.disqus && (
                <DisqusComments pageId={getPageId(article)} />
              )}
            </TaboolaAndDisqusWrapper>

            {/* Viafoura commenting for non-subscribed users only */}
            {hasArticleCommenting && viafoura && !isSubscriber && (
              <ViafouraComments
                configuration={configuration}
                article={article}
              />
            )}
          </>
        )}
        {isInfiniteScroll && !isLastInfiniteScrollArticle && (
          <>
            {getAdConfigSetting(
              adConfig,
              ADSLOT_OUTBRAIN_MID,
              adLiteEnabled ? ADCONFIG_DESKTOP_LITE : ADCONFIG_DESKTOP,
            ) && (
              <IslandForRender
                key="taboola"
                name="Taboola"
                props={{
                  mode: 'thumbs-ma-01',
                  containerId: 'taboola-mid-article-thumbnails',
                  placement: 'Mid Article Thumbnails',
                }}
              >
                <Taboola
                  mode="thumbs-ma-01"
                  containerId="taboola-mid-article-thumbnails"
                  placement="Mid Article Thumbnails"
                />
              </IslandForRender>
            )}
          </>
        )}
      </Wrapper>
    )
  },
  () => true,
)

export default ContentWrapper
