import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

const Subheadline = styled.div<{
  isPremiumArticleLayout: boolean
}>`
  color: ${({ theme }) => theme.fontColorMedium};
  font-size: 16px;
  line-height: 24px;
  margin: ${({ isPremiumArticleLayout }) =>
    isPremiumArticleLayout ? '8px 0 16px 0' : '8px 0 0 0 '};
  text-align: ${({ isPremiumArticleLayout }) =>
    isPremiumArticleLayout ? 'center' : 'left'};

  p {
    font-size: 16px;
    margin: 8px 0 16px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    margin-top: initial;
  }

  &.below-image {
    border-bottom: 1px solid #ececec;
    color: ${({ theme }) => theme.darkContrast};
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    padding-bottom: 16px;

    p {
      font-size: 18px;
    }

    @media ${device.tablet} {
      font-size: 18px;
      margin-top: 12px;
    }
  }
`

export const SubheadlineContrast = styled(Subheadline)`
  color: ${({ theme }) => theme.lightContrast} !important;

  &.below-image {
    border-bottom: none;
    padding-bottom: 0;
  }
`

export default Subheadline
