import { Dispatch, SetStateAction } from 'react'
import { VisiblePosts } from '../LiveblogHydrated'

/**
 * This function updates the visible posts by concatenating the new visible posts with the
 * previous visible posts.
 *
 * It then sorts to the most recent and slices to show only the first 10 posts.
 *
 * It also clears the new visible posts array, and hides the button.
 */
export const updateVisiblePosts = (
  setVisiblePosts: Dispatch<SetStateAction<VisiblePosts>>,
  newVisiblePosts: VisiblePosts,
  setNewVisiblePosts: Dispatch<SetStateAction<VisiblePosts>>,
  setButtonVisible: Dispatch<SetStateAction<boolean>>,
) => {
  if (
    !setVisiblePosts ||
    !newVisiblePosts ||
    !setNewVisiblePosts ||
    !setButtonVisible
  ) {
    return
  }

  setVisiblePosts((prevVisiblePosts) => {
    const allVisiblePosts = newVisiblePosts?.concat(prevVisiblePosts || [])

    // Add sorting to ensure only the latest ones appears
    const sortedVisiblePosts = allVisiblePosts?.sort((a, b) => {
      const createdA = parseFloat(a.data.created) // 'created' is a string
      const createdB = parseFloat(b.data.created)
      return createdB - createdA
    })
    const slicedVisiblePosts = sortedVisiblePosts?.slice(0, 10)

    return slicedVisiblePosts
  })

  setNewVisiblePosts([])
  setButtonVisible(false)
}
