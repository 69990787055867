import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { modifyMarkupContent } from './Markup.utils'
import { dropCapSites } from '~/config/JPIMConfig'
import {
  getSectionPrimaryColor,
  getPrimaryColorBasedOffAllSections,
} from '~/config/theme/JPIMStyles'
import {
  encodedExternalLinkIconBlack,
  encodedExternalLinkIconWhite,
} from '~/icons/externalLinkIcon'
import { ComponentThemeProvider } from '~/library/providers/ComponentThemeProvider'

const ParagraphWrapper = styled.div`
  &.markup.first p:first-of-type::first-letter {
    float: left;
    line-height: 46px;
    font-size: 57px;
    padding: 6px 8px 5px 0;
    margin-bottom: -5px;
    font-family: ${({ theme }) =>
      dropCapSites.includes(theme.domain)
        ? theme.fontFamily.openSans
        : theme.fontFamily.primaryFont};
  }

  p {
    color: ${({ theme }) => theme.fontColor};
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    word-wrap: break-word;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    margin: ${({ isPremiumArticleLayout }) =>
      isPremiumArticleLayout ? '10px 0' : '20px 0'};
  }

  a {
    color: ${({ theme }) => theme.fontColor};
    font-weight: 700;
    text-decoration: none;
    position: relative;
  }

  a:hover {
    color: ${({ theme, hierarchyName, article }) => {
      if (theme.domain === 'farminglife.com') {
        return getPrimaryColorBasedOffAllSections(theme, article?.sections)
      }
      return getSectionPrimaryColor(theme, hierarchyName)
    }};
  }

  a::before {
    content: ''; /* pseudo-element for the underline */
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #333333;
  }

  a:hover::before {
    background-color: ${({ theme, hierarchyName, article }) => {
      if (theme.domain === 'farminglife.com') {
        return getPrimaryColorBasedOffAllSections(theme, article?.sections)
      }
      return getSectionPrimaryColor(theme, hierarchyName)
    }};
  }

  h2 {
    color: ${({ theme }) => theme.fontColor};
  }

  a[rel*='nofollow']:after {
    display: none;
  }

  .external-link[rel*='nofollow']:after,
  .external-link[rel*='sponsored']:after,
  .external-link[rel*='ugc']:after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 14px;
    background: url(${encodedExternalLinkIconBlack}) 50% 50% no-repeat;
    background-size: 100%;
    margin-left: 2px;
  }

  ul {
    margin-left: 16px;
    padding: 0;
    li {
      line-height: 28px;
    }
  }

  ol {
    padding-left: 0;
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 28px;

    li {
      margin-left: 20px;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const ParagraphWrapperContrast = styled(ParagraphWrapper)`
  p {
    color: ${({ theme }) => theme.lightContrast};
  }

  a {
    text-decoration: underline ${({ theme }) => theme.lightContrast};
    color: ${({ theme }) => theme.lightContrast};
  }

  a:hover {
    color: ${({ theme }) => theme.lightContrast};
  }

  a::before {
    display: none;
  }

  h2 {
    color: ${({ theme }) => theme.lightContrast};
  }

  .external-link[rel*='nofollow']:after,
  .external-link[rel*='sponsored']:after,
  .external-link[rel*='ugc']:after {
    background: url(${encodedExternalLinkIconWhite}) 50% 50% no-repeat;
  }

  ol {
    font-size: 16px;
    line-height: 28px;
  }
`

export const Markup = ({
  article,
  data: { markup },
  polar,
  hierarchy,
  isSponsored = false,
  className,
  isPremiumArticleLayout,
}) => {
  const currentDomain = article?.publication?.domain
  const hierarchyName =
    hierarchy && hierarchy.length > 0 ? hierarchy[0]?.name : undefined

  return (
    <ParagraphWrapper
      className={className}
      id={polar && 'native-content-content'}
      isPremiumArticleLayout={isPremiumArticleLayout}
      article={article}
      hierarchyName={hierarchyName}
      data-testid="article-content article-paragraph"
    >
      <excessremovablewrapper
        dangerouslySetInnerHTML={{
          __html: modifyMarkupContent(markup, isSponsored, currentDomain),
        }}
      />
    </ParagraphWrapper>
  )
}

Markup.propTypes = {
  data: PropTypes.any.isRequired,
  polar: PropTypes.bool,
  isSponsored: PropTypes.bool,
  theme: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  isPremiumArticleLayout: PropTypes.bool,
}

export const GalleryMarkup = ({
  data: { markup },
  isSponsored = false,
  polar,
  theme = 'default',
}) => {
  // https://github.com/facebook/react/issues/12014
  // until fragments support setInnerHTML we wrap our content
  // in a removable wrapper and strip it at build
  const MarkupContainer = ComponentThemeProvider(
    theme,
    ParagraphWrapperContrast,
    ParagraphWrapper,
  )

  return (
    <MarkupContainer className="markup" id={polar && 'native-content-content'}>
      <excessremovablewrapper
        dangerouslySetInnerHTML={{
          __html: modifyMarkupContent(markup, isSponsored),
        }}
      />
    </MarkupContainer>
  )
}

GalleryMarkup.propTypes = {
  data: PropTypes.any.isRequired,
  polar: PropTypes.bool,
  isSponsored: PropTypes.bool,
  theme: PropTypes.string,
}
