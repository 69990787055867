export const lpsos = {
  '': 'home',
  'read-this': 'readthis',
  'arts-and-culture': 'artsandculture',
  'arts-and-culture/art': 'art',
  'arts-and-culture/books': 'books',
  'arts-and-culture/edinburgh-festivals': 'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/art': 'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/books': 'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/cabaret-and-spoken-word':
    'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/childrens-shows': 'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/comedy': 'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/dance-and-physical-theatre':
    'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/film-and-tv': 'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/music': 'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/opera-and-musical-theatre':
    'edinburghfestivals',
  'arts-and-culture/edinburgh-festivals/theatre-and-stage':
    'edinburghfestivals',
  'arts-and-culture/film-and-tv': 'filmandtv',
  'arts-and-culture/music': 'music',
  'arts-and-culture/theatre-and-stage': 'theatreandstage',
  'country-and-farming': 'countryandfarming',
  equestrian: 'equestrian',
  'future-scotland': 'futurescotland',
  'future-scotland/big-ideas': 'bigideas',
  'future-scotland/data': 'data',
  'future-scotland/fintech': 'fintech',
  'future-scotland/innovators': 'innovators',
  'future-scotland/life-sciences': 'lifesciences',
  'future-scotland/start-up-scale-up': 'startupscaleup',
  'future-scotland/tech': 'tech',
  'heritage-and-retro': 'heritageandretro',
  'heritage-and-retro/heritage': 'heritage',
  'heritage-and-retro/retro': 'retro',
  'lifestyle/eating-out': 'eatingout',
  'market-reports': 'marketreports',
  regions: 'regions',
  'regions/aberdeen-and-north-east': 'aberdeenandnortheast',
  'regions/dumfries-and-borders': 'dumfriesandborders',
  'regions/dundee-and-tayside': 'dundeeandtayside',
  'regions/edinburgh-fife-and-lothians': 'edinburghfifeandloth',
  'regions/glasgow-and-strathclyde': 'glasgowandstrathclyd',
  'regions/inverness-highlands-and-islands': 'invernesshighlandsan',
  'ulster-farmers-union-watch': 'ulsterfarmersunionwa',
  'yorkshire-heritage': 'yorkshireheritage',
  'young-farmers-club': 'youngfarmersclub',
  retro: 'retro',
  sport: 'sport',
  'sport/athletics': 'athletics',
  'sport/boxing': 'boxing',
  'sport/cycling': 'cycling',
  'sport/gaa': 'gaa',
  'sport/football/harrogate-town': 'football',
  'sport/horse-racing': 'horseracing',
  'sport/motorcycling': 'motorcycling',
  'sport/nostalgia': 'nostalgia',
  'sport/olympics': 'olympics',
  'sport/snooker': 'snooker',
  'sport/speedway': 'speedway',
  'sport/cricket': 'cricket',
  'sport/cricket/sussex-ccc': 'cricket',
  'sport/football': 'football',
  'sport/football/barnsley-fc': 'football',
  'sport/football/berwick-rangers': 'football',
  'sport/football/blackpool-fc': 'football',
  'sport/football/bradford-city': 'football',
  'sport/football/brighton-and-hove-albion': 'football',
  'sport/football/burnley-fc': 'football',
  'sport/football/celtic': 'football',
  'sport/football/celtic/latest-celtic-news': 'football',
  'sport/football/celtic/match-reports': 'football',
  'sport/football/celtic/transfer-news': 'football',
  'sport/football/champions-league': 'football',
  'sport/football/chesterfield-fc': 'football',
  'sport/football/chorley-fc': 'football',
  'sport/football/crawley-town': 'football',
  'sport/football/derry-city': 'football',
  'sport/football/doncaster-rovers': 'football',
  'sport/football/east-fife': 'football',
  'sport/football/edinburgh-city': 'football',
  'sport/football/falkirk-fc': 'football',
  'sport/football/fleetwood-town-fc': 'football',
  'sport/football/halifax-town': 'football',
  'sport/football/hartlepool-united': 'football',
  'sport/football/hearts': 'football',
  'sport/football/hearts/latest-hearts-news': 'football',
  'sport/football/hearts/match-reports': 'football',
  'sport/football/hearts/transfer-news': 'football',
  'sport/football/hibs': 'football',
  'sport/football/hibs/latest-hibs-news': 'football',
  'sport/football/hibs/match-reports': 'football',
  'sport/football/hibs/transfer-news': 'football',
  'sport/football/huddersfield-town': 'football',
  'sport/football/hull-city': 'football',
  'sport/football/international': 'football',
  'sport/football/international-football': 'football',
  'sport/football/irish-league-football': 'football',
  'sport/football/leeds-united': 'football',
  'sport/football/leeds-united/latest-leeds-united-news': 'football',
  'sport/football/leeds-united/lufc-match-reports': 'football',
  'sport/football/leeds-united/lufc-transfer-news': 'football',
  'sport/football/liverpool': 'football',
  'sport/football/local-football': 'football',
  'sport/football/luton-town': 'football',
  'sport/football/manchester-united': 'football',
  'sport/football/mansfield-town': 'football',
  'sport/football/middlesbrough-fc': 'football',
  'sport/football/mk-dons': 'football',
  'sport/football/morecambe-fc': 'football',
  'sport/football/motherwell-fc': 'football',
  'sport/football/newcastle-united': 'football',
  'sport/football/non-league': 'football',
  'sport/football/northampton-town': 'football',
  'sport/football/northern-ireland': 'football',
  'sport/football/peterborough-united': 'football',
  'sport/football/portsmouth-fc': 'football',
  'sport/football/portsmouth-fc/latest-pompey-news': 'football',
  'sport/football/portsmouth-fc/pompey-match-reports': 'football',
  'sport/football/portsmouth-fc/pompey-transfer-news': 'football',
  'sport/football/premier-league': 'football',
  'sport/football/preston-north-end': 'football',
  'sport/football/preston-north-end/pne-match-reports': 'football',
  'sport/football/preston-north-end/pne-news': 'football',
  'sport/football/preston-north-end/pne-transfer-news': 'football',
  'sport/football/raith-rovers': 'football',
  'sport/football/rangers': 'football',
  'sport/football/rangers/latest-rangers-news': 'football',
  'sport/football/rangers/match-reports': 'football',
  'sport/football/rangers/transfer-news': 'football',
  'sport/football/rotherham-united': 'football',
  'sport/football/scottish-championship': 'football',
  'sport/football/scottish-premiership': 'football',
  'sport/football/scottish-premiership/latest-premiership-news': 'football',
  'sport/football/scottish-premiership/premiership-match-reports': 'football',
  'sport/football/scottish-premiership/premiership-transfer-news': 'football',
  'sport/football/sheffield-united': 'football',
  'sport/football/sheffield-united/latest-blades-news': 'football',
  'sport/football/sheffield-united/match-reports': 'football',
  'sport/football/sheffield-united/transfer-news': 'football',
  'sport/football/sunderland-afc': 'football',
  'sport/football/sunderland-afc/latest-sunderland-afc-news': 'football',
  'sport/football/sunderland-afc/match-reports': 'football',
  'sport/football/sunderland-afc/transfer-news': 'football',
  'sport/football/wigan-athletic': 'football',
  'sport/football/womens-football': 'football',
  'sport/football/world-cup': 'football',
  'sport/football/york-city': 'football',
  'sport/football/sheffield-wednesday': 'football',
  'sport/football/sheffield-wednesday/latest-owls-news': 'football',
  'sport/football/sheffield-wednesday/match-reports': 'football',
  'sport/football/sheffield-wednesday/transfer-news': 'football',
  'sport/golf': 'golf',
  'sport/tennis': 'tennis',
  'sport/sport-opinion': 'sportopinion',
  'sport/rugby-union': 'rugbyunion',
  'sport/rugby-union/northampton-saints': 'rugbyunion',
  'sport/rugby-league': 'rugbyleague',
  'sport/rugby-league/leeds-rhinos': 'rugbyleague',
  'sport/rugby-league/wigan-warriors': 'rugbyleague',
  'sport/other-sport': 'othersport',
  'sport/ice-hockey': 'icehockey',
  'best-in': 'bestin',
  'best-in/bars-and-pubs': 'barsandpubs',
  'best-in/shopping': 'shopping',
  'best-in/outdoors': 'outdoors',
  'best-in/eating-out': 'eatingout',
  'whats-on': 'whatson',
  'whats-on/culture': 'culture',
  'whats-on/great-south-run': 'greatsouthrun',
  'whats-on/portsmouth-historic-dockyard': 'portsmouthhistoricdo',
  'whats-on/theatre-and-stage': 'theatreandstage',
  'whats-on/television': 'television',
  'whats-on/showbiz': 'showbiz',
  'whats-on/nightlife': 'nightlife',
  'whats-on/music': 'music',
  'whats-on/gaming': 'gaming',
  'whats-on/film': 'film',
  'whats-on/edinburgh-festivals': 'edinburghfestivals',
  'whats-on/books': 'books',
  'whats-on/arts-and-entertainment': 'artsandentertainment',
  'whats-on/things-to-do': 'thingstodo',
  health: 'health',
  'health/coronavirus': 'coronavirus',
  lifestyle: 'lifestyle',
  'lifestyle/christmas': 'christmas',
  'lifestyle/family': 'family',
  'lifestyle/fashion': 'fashion',
  'lifestyle/outdoors': 'outdoors',
  'lifestyle/shopping': 'shopping',
  'lifestyle/staycation': 'staycation',
  'lifestyle/family-and-parenting': 'familyandparenting',
  'lifestyle/cars': 'cars',
  'lifestyle/cars/local-cars': 'cars',
  'lifestyle/health-and-fitness': 'healthandfitness',
  'lifestyle/food-and-drink': 'foodanddrink',
  'lifestyle/homes-and-gardens': 'homesandgardens',
  'lifestyle/homes-and-gardens/private-rentals': 'homesandgardens',
  'lifestyle/work': 'work',
  'lifestyle/travel': 'travel',
  'lifestyle/tech': 'tech',
  'lifestyle/relationships': 'relationships',
  'lifestyle/pets': 'pets',
  'lifestyle/money': 'money',
  heritage: 'heritage',
  education: 'education',
  'education/schools': 'schools',
  'education/colleges-and-universities': 'collegesanduniversit',
  business: 'business',
  'business/consumer': 'consumer',
  news: 'news',
  'news/consumer': 'consumer',
  'news/farming': 'farming',
  'news/health': 'health',
  'news/obituaries': 'obituaries',
  'news/scotland': 'scotland',
  'news/scottish-news': 'scottishnews',
  'news/technology': 'technology',
  'news/transport': 'transport',
  'news/troubles': 'troubles',
  'news/uk-news': 'uknews',
  'news/world': 'world',
  'news/weather': 'weather',
  'news/uk': 'uk',
  'news/traffic-and-travel': 'trafficandtravel',
  'news/politics': 'politics',
  'news/politics/alex-salmond-trial': 'politics',
  'news/politics/brexit': 'politics',
  'news/politics/budget': 'politics',
  'news/politics/council': 'politics',
  'news/politics/scottish-independence': 'politics',
  'news/bizarre': 'bizarre',
  'news/people': 'people',
  'news/opinion': 'opinion',
  'news/opinion/letters': 'opinion',
  'news/opinion/columnists': 'opinion',
  'news/latest': 'latest',
  'news/environment': 'environment',
  'news/defence': 'defence',
  'news/crime': 'crime',
  money: 'money',
  recommended: 'recommended',
  culture: 'culture',
  'culture/arts': 'arts',
  'culture/arts-and-books': 'artsandbooks',
  'culture/books': 'books',
  'culture/film': 'film',
  'culture/gaming': 'gaming',
  'culture/music': 'music',
  'culture/radio': 'radio',
  'culture/television': 'television',
  opinion: 'opinion',
  'opinion/columnists': 'columnists',
  'opinion/comment': 'comment',
  'opinion/editor': 'editor',
  'sport/rugby': 'rugby',
  'news/offbeat': 'offbeat',
}
