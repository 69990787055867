const quote = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="70px" height="48px" viewBox="0 0 70 48" enable-background="new 0 0 70 48" xml:space="preserve">
<g>
	<path fill="#222222" d="M33.4,0H14.9L6.7,12.6C3,18.2,0,24.2,0,30.8C0,41.9,7.8,48,16.5,48c9.5,0,16.6-6.5,16.6-15.5
		c0-8.1-5.6-13-11.5-14.3L33.4,0z M69.3,0H50.8l-8.2,12.6c-3.7,5.6-6.7,11.7-6.7,18.2c0,11.2,7.8,17.2,16.5,17.2
		C61.8,48,69,41.5,69,32.5c0-8.1-5.6-13-11.5-14.3L69.3,0z"/>
</g>
</svg>
`
export default quote
