const tabContainer = document.getElementById('tab-container') as HTMLElement
const tabLinks = tabContainer?.querySelectorAll('.tab')
const tabContents = tabContainer?.querySelectorAll('.tab-content')

export const toggleActiveTab = (content: HTMLElement, tabName: string) => {
  return content.classList.toggle('active-tab', content.id === tabName)
}

export const toggleTabbedContent = () => {
  tabContainer?.addEventListener('click', (event) => {
    const clickedTab = event.target as HTMLElement

    if (clickedTab?.classList.contains('tab')) {
      const tabName = clickedTab.dataset.tab

      tabContents?.forEach((content) => {
        toggleActiveTab(content as HTMLElement, tabName as string)
      })

      tabLinks?.forEach((link) => {
        link.classList.toggle('active', false)
      })

      clickedTab.classList.toggle('active', true)
    }
  })
}
