import { theme } from '~/config/theme/JPIMStyles'
const UNSTYLED = 'unstyled'

class JPThemeProvider {
  constructor(domain) {
    this.domain = domain
  }

  get() {
    if (typeof theme[this.domain] !== 'undefined') {
      return Object.assign({}, theme[this.domain])
    }

    return Object.assign({}, theme[UNSTYLED])
  }
}

export default JPThemeProvider
