export const generateColorVariation = (primaryColor: string) => {
  const createColorMix = (color1: string, color2: string, percentage: number) =>
    `color-mix(in srgb, ${color1} ${percentage}%, ${color2} ${
      100 - percentage
    }%)`

  return {
    light: createColorMix(primaryColor, 'white', 40),
    lighter: createColorMix(primaryColor, 'white', 20),
    dark: createColorMix(primaryColor, 'black', 80),
    darker: createColorMix(primaryColor, 'black', 20),
  }
}
