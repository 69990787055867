import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  .tp-container-inner {
    margin: 20px auto;
  }
`

const PianoArticleBottom = () => <Wrapper className="piano-article-bottom" />

export default PianoArticleBottom
