import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  ADCONFIG_DESKTOP,
  ADCONFIG_DESKTOP_LITE,
  ADCONFIG_MOBILE,
  ADCONFIG_MOBILE_LITE,
  ADSETTING_LIVEBLOG_FREQ,
  ADSETTING_LIVEBLOG_SIZE,
  getAMPAdParams,
} from '~/constants/ads'
import { handleClickTracking } from '~/js/components/LiveblogHydrated/util/handle-click-tracking'
import { postHelper } from '~/js/components/LiveblogHydrated/util/post-helper'
import MarkupAds from '~/library/Article/Ads/MarkupAds'
import { liveBlogComponent } from '~/library/Article/Embeds/Liveblog'
import { Pin } from '~/library/Article/Pin'
import SocialShare from '~/library/Article/SocialShare'
import SocialShareMobile from '~/library/Article/SocialShareMobile'
import { getAdConfigSetting } from '~/providers/AdManager/utils/getAdConfigSetting'
import {
  LITE_LIVEBLOGPOST,
  LIVEBLOGPOST,
  adConfiguration,
  adConfigurationByType,
  ampAdData,
} from '~/services/adsDeprecated'
import { AdConfigData, ArticleData, Post, PostContentItem } from '~/types'
import isMobileDevice from '~/util/check-for-mobile-device'
import shareLiveBlog from '~/util/shareLiveBlog'
import { VisiblePosts } from './LiveblogHydrated'
import { LiveblogTime } from './LiveblogTime'
import {
  PostAnchor,
  PostContent,
  PostHeader,
  PostHeaderTop,
  PostTitle,
  StyledPost,
} from './styles'
import { domainsWithNativeShare } from '~/config/JPIMConfig'
import { getSlots } from '~/constants/ads'

const FlexSocialShare = styled(SocialShare)`
  display: flex !important;
`

export const LiveblogRows = ({
  article,
  adConfig,
  visiblePosts,
  adTargeting,
  containerData,
  configuration,
  url,
}: {
  article: ArticleData
  adConfig: AdConfigData
  visiblePosts: VisiblePosts
  adTargeting: boolean
  containerData: { status?: number }
  configuration: {
    adLiteEnabled?: boolean
    domain: string
    facebookAppId: string
    isAdFree: boolean
    isSubscriber: boolean
  }
  url?: string
}) => {
  /*
  @todo create ticket for ads to be refactored
  */
  const { adLiteEnabled, isAdFree, isSubscriber } = configuration
  const { GPT_BLOGMPU } = getSlots()

  let mpuPos = 1
  let mpuPosDesktop = 1
  let mpuPosMobile = 1

  const AD_CONFIG_KEY = isSubscriber ? LITE_LIVEBLOGPOST : LIVEBLOGPOST
  const adConfigType = adConfigurationByType(article)
  const liveblogConfig: { frequency: number; maximum: number } = adConfigType(
    AD_CONFIG_KEY,
  ) as { frequency: number; maximum: number }
  const hasAdConfig = !!adConfig?.desktop // @todo remove once hardcoded deprecated
  const adDesktop = isSubscriber ? ADCONFIG_DESKTOP_LITE : ADCONFIG_DESKTOP
  const adMobile = isSubscriber ? ADCONFIG_MOBILE_LITE : ADCONFIG_MOBILE

  const adFreqMobile = getAdConfigSetting(
    adConfig,
    ADSETTING_LIVEBLOG_FREQ,
    adMobile,
  )
  const adSizeMobile = getAdConfigSetting(
    adConfig,
    ADSETTING_LIVEBLOG_SIZE,
    adMobile,
  )
  const adFreqDesktop = getAdConfigSetting(
    adConfig,
    ADSETTING_LIVEBLOG_FREQ,
    adDesktop,
  )
  const adSizeDesktop = getAdConfigSetting(
    adConfig,
    ADSETTING_LIVEBLOG_SIZE,
    adDesktop,
  )

  const adConfigToggle = adConfiguration(article)
  const adFrequency = liveblogConfig.frequency || 5
  const adMaxSize = liveblogConfig.maximum
  const adSlotParam = getAMPAdParams(adTargeting, configuration, article)

  // Check if Web Share API is supported and update state to return the correct share button
  const [isNativeShareSupported, setIsNativeShareSupported] = useState(false)
  useEffect(() => {
    if (typeof navigator.share === 'function') {
      return setIsNativeShareSupported(true)
    }
  }, [])

  return (
    <>
      {visiblePosts?.map((post: Post, index: number) => {
        const {
          isPinned,
          articleWithPostAffix,
          sortTime,
          updateTime,
          sortTimeAd,
          updateTimeAd,
        } = postHelper(post, article, containerData)

        const Rows = [
          <StyledPost
            key={post.data.id}
            data-sort-time={sortTime}
            data-update-time={updateTime}
            className={[
              isPinned ? 'pinned' : '',
              post.updated ? 'updated' : '',
              post.new ? 'new' : '',
            ]
              .join(' ')
              .trimEnd()}
          >
            <PostAnchor id={`post-${post.data.id}`} />
            <PostHeader>
              <PostHeaderTop>
                {isPinned && <Pin />}
                <LiveblogTime updateTime={updateTime} post={post} />
              </PostHeaderTop>
              {post.data.title && <PostTitle>{post.data.title}</PostTitle>}
            </PostHeader>
            <PostContent>
              {post.postJson.map((item: PostContentItem, index: number) =>
                liveBlogComponent(item, index, configuration, article),
              )}
              {domainsWithNativeShare.includes(configuration.domain) &&
              isNativeShareSupported &&
              isMobileDevice() ? (
                <SocialShareMobile
                  position="in article - item"
                  article={article}
                  shareClass="live-blog-share-btn"
                  onClick={() => {
                    shareLiveBlog(post)
                    handleClickTracking(
                      article?.id,
                      article?.type,
                      'in article - item',
                      null,
                    )
                  }}
                />
              ) : (
                <FlexSocialShare
                  url={`${url}#post-${post.data.created}`}
                  position="in article - item"
                  article={articleWithPostAffix}
                  className="local social-icons"
                  configuration={configuration}
                />
              )}
            </PostContent>
          </StyledPost>,
        ]

        const isLastPost = index === visiblePosts.length - 1 // To ensure an ad is never displayed at the end of the posts array

        /*
      @todo create ticket for ads to be refactored
      */
        if (
          !isLastPost &&
          !hasAdConfig &&
          !adLiteEnabled &&
          !isAdFree &&
          adConfigToggle(AD_CONFIG_KEY) &&
          (index + 1) % adFrequency === 0 &&
          (!Number.isInteger(adMaxSize) || mpuPos <= adMaxSize)
        ) {
          Rows.push(
            <div
              key={post.data.id + 'ad'}
              data-sort-time={sortTimeAd}
              data-update-time={updateTimeAd}
              id={'adLiveblogPost' + post.data.id}
              className="ad-container"
            >
              <MarkupAds
                id={`${GPT_BLOGMPU}${mpuPos}`}
                key={'markupAds' + index}
                width="300"
                height="250"
                {...ampAdData(adSlotParam(`blog${mpuPos}`))}
              />
            </div>,
          )
          Rows.push(
            <div
              key={post.data.id + 'adMobile'}
              data-sort-time={sortTimeAd}
              data-update-time={updateTimeAd}
              id={'adMobileLiveblogPost' + post.data.id}
              className="ad-container-mobile"
            >
              <MarkupAds
                id={`${GPT_BLOGMPU}${mpuPos}-mobile`}
                key={'mobileAds' + index}
                width="300"
                height="250"
                {...ampAdData(adSlotParam(`blog${mpuPos}`))}
              />
            </div>,
          )
          mpuPos++
        }
        if (
          !isLastPost &&
          hasAdConfig &&
          !adLiteEnabled &&
          !isAdFree &&
          (index + 1) % adFreqMobile === 0 &&
          (!Number.isInteger(adSizeMobile) || mpuPosMobile <= adSizeMobile)
        ) {
          Rows.push(
            <div
              key={post.data.id + 'adMobile'}
              data-sort-time={sortTimeAd}
              data-update-time={updateTimeAd}
              id={'adMobileLiveblogPost' + post.data.id}
              className="ad-container-mobile"
            >
              <MarkupAds
                id={`${GPT_BLOGMPU}${mpuPosMobile}-mobile`}
                key={'mobileAds' + index}
              />
            </div>,
          )
          mpuPosMobile++
        }
        if (
          !isLastPost &&
          hasAdConfig &&
          !adLiteEnabled &&
          !isAdFree &&
          (index + 1) % adFreqDesktop === 0 &&
          (!Number.isInteger(adSizeDesktop) || mpuPosDesktop <= adSizeDesktop)
        ) {
          Rows.push(
            <div
              key={post.data.id + 'ad'}
              data-sort-time={sortTimeAd}
              data-update-time={updateTimeAd}
              id={'adLiveblogPost' + post.data.id}
              className="ad-container"
            >
              <MarkupAds
                id={`${GPT_BLOGMPU}${mpuPosDesktop}`}
                key={'markupAds' + index}
              />
            </div>,
          )
          mpuPosDesktop++
        }
        return Rows
      })}
    </>
  )
}
