import React from 'react'
import styled from 'styled-components'
import { FC } from '~/types'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.lightGreyContrast};
  border: 2px solid ${({ theme }) => theme.greyContrast};
  padding: 16px;
  border-top: 2px solid ${({ theme }) => theme.primaryColor};

  span {
    font-size: 20px;
    color: ${({ theme }) => theme.primaryColor};
    font-family: ${({ theme }) => theme.fontFamily.componentFont};
    margin: 0;
  }
  p:first-of-type {
    margin: 8px 0 16px;
  }
  p {
    color: ${({ theme }) => theme.fontColor};
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    margin: 20px 0;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    a {
      font-weight: 700;
      color: ${({ theme }) => theme.fontColor};
      text-decoration: underline ${({ theme }) => theme.primaryColor};
      text-decoration-thickness: 2px;
      text-decoration-skip-ink: auto;
      text-underline-offset: 3px;
    }
  }
`

type InfoBoxProps = {
  data: {
    title: string
    subHeadline: string
    markup: string
  }
}

const InfoBox = styled<FC<InfoBoxProps>>(
  ({ data: { title, subHeadline, markup } }) => {
    return (
      <Wrapper className="info-box">
        <span>{title}</span>
        <p>{subHeadline}</p>
        <div dangerouslySetInnerHTML={{ __html: markup }} />
      </Wrapper>
    )
  },
)``

export default InfoBox
