import React from 'react'
import styled from 'styled-components'
import audio from '~/icons/audio'

const Audio = styled(({ className }) => (
  <span
    className={className}
    dangerouslySetInnerHTML={{ __html: audio }}
  ></span>
))`
  display: flex;
  align-items: center;
  max-height: 57px;
  margin-right: 20px;
`
export default Audio
