import { trimImageParam } from '@brightsites/flow-core/lib/utils/cropImage'
import React from 'react'
import styled from 'styled-components'
import { FC, ReadMoreData } from '~/types'
import { device } from '~/config/theme/JPIMStyles'
import { getIdFromUrl } from '~/library/helper'
import { Img } from '~/library/Img'

const Wrapper = styled.div`
  width: auto;
  clear: both;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  margin: 24px 0;
`

const Link = styled.a`
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: none;
  width: 100%;
  display: flex;
`

const ImageWrapper = styled.div`
  flex: 1;

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .placeholder {
    object-fit: contain;
  }
`

const Text = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.componentFont};
  flex: 3;
  color: ${({ theme }) => theme.lightContrast};
  background-color: ${({ theme }) => theme.primaryColor};
  padding: 15px;
  font-size: 14px;
  line-height: 20px;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 28px;
  }
`

const Title = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.titleFont};
  flex-grow: 1;
  padding: 0;
`

type ReadMoreProps = {
  className?: string
  data: ReadMoreData['data']
}

export const ReadMore = styled<FC<ReadMoreProps>>(
  ({ className, data: { href, imageUrl, text, trimData } }) => {
    if (!text) return null

    const title = text.replace('READ MORE: ', '')
    const matchedId = getIdFromUrl(href)

    const urlParams = {
      ...(trimImageParam({ trimData }) ?? { crop: '4:3,smart' }),
      auto: 'webp',
      width: '320',
    }

    return (
      <Wrapper className={'article-read-more ' + className}>
        <Link
          data-vars-event="gaEvent"
          data-vars-ec="navigation"
          data-vars-ea="in article"
          data-vars-el="read more"
          data-vars-aidclick={matchedId || ''}
          href={href ?? ''}
          role="link"
          data-testid="read-more"
          data-vars-titleclick={title}
          data-vars-urlclick={href}
          target="_blank"
          title={title}
        >
          <ImageWrapper>
            {(imageUrl && (
              <Img
                src={imageUrl}
                urlParams={urlParams}
                className={'main-image'}
                height="240"
                width="320"
              >
                <img
                  className="placeholder"
                  src="/img/placeholder.png"
                  height="240"
                  width="320"
                />
              </Img>
            )) ?? (
              <img
                className="placeholder"
                src="/img/placeholder.png"
                height="240"
                width="320"
              />
            )}
          </ImageWrapper>

          <Text>
            <Title>Read More</Title>
            {title.length > 80 ? title.slice(0, 80) + '...' : title}
          </Text>
        </Link>
      </Wrapper>
    )
  },
)``
