import PropTypes from 'prop-types'
import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

export const GlobalStyle = createGlobalStyle`
  #comments.viafoura, .viafoura .vf-tray-wrapper {
    --accent-color-light: ${({ theme }) => theme.primaryColor};
    --accent-color: ${({ theme }) => theme.primaryColor};
    --accent-color-dark: ${({ theme }) => theme.primaryColor};
    --text-on-accent-color: ${({ theme }) => theme.contrastLight};

    outline-color: var(----accent-color);
    .vf-default-avatar {
      --default-avatar-color-0: ${({ theme }) => theme.primaryColor};
    }

    .vf-dropdown-button {
      box-sizing: border-box;
    }    

    .vf-frontwise-tray-trigger--floating,
    .vf-tray-trigger-widget button {
      left: 30px!important;
    }

    .vf3-conversations-footer__branding {
      display: none !important;
    }
  }
`

const Wrapper = styled.div`
  .CommentingGuidelines {
    background-color: ${({ theme }) => theme.lightGreyContrast};
    padding: 24px;
    margin-bottom: 48px;
    .CommentingGuideliesTitle {
      margin-bottom: 12px;
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
      font-size: 24px;
      font-weight: bold;
    }
    .CommunityGuidelinesText {
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
      .CommentingGuidelinesLink {
        font-family: ${({ theme }) => theme.fontFamily.primaryFont};
        color: ${({ theme }) => theme.darkContrast};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &.viafoura.vf3-comments.vf-heading-text {
    visibility: hidden;
    ::after {
      content: 'Comments';
      color: ${({ theme }) => theme.darkestContrast};
      font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
      font-weight: normal;
      font-size: 32px;
      line-height: 1.4;
      position: absolute;
      top: 0;
      left: 0;
      visibility: visible;

      @media ${device.maxTablet} {
        font-size: 22px;
      }
    }
  }
`

const OuterWrapper = styled.div`
  margin-top: ${({ belowArticle }) => (belowArticle ? '0' : '300px')};

  #comments {
    padding: ${({ photoArticle }) => (photoArticle ? '10px' : '48px 0')};
    border-top: 4px solid ${({ theme }) => theme.darkContrast};

    .vf-trending-articles-list {
      width: 100%;
    }
  }
`

const ViafouraComments = ({
  article,
  configuration,
  belowArticle = false,
  photoArticle = false,
}) => {
  const { hero, titleShort } = article
  let imagePath = ''
  if (Array.isArray(hero)) {
    hero
      .filter(({ type }) => type === 'image')
      .forEach((media, index) => {
        if (index === 0) {
          imagePath = /^http/.test(media.data.url)
            ? media.data.url
            : configuration.baseUrl + media.data.url
          return imagePath + '?width=2048&enable=upscale'
        }
      })
  }

  return (
    <OuterWrapper belowArticle={belowArticle} photoArticle={photoArticle}>
      <Wrapper className="viafoura" id="comments">
        <div className="CommentingGuidelines">
          <h3 className="CommentingGuideliesTitle">Comment Guidelines</h3>
          <p className="CommunityGuidelinesText">
            National World encourages reader discussion on our stories. User
            feedback, insights and back-and-forth exchanges add a rich layer of
            context to reporting. Please review our{' '}
            <a
              className="CommentingGuidelinesLink"
              href="/community-guidelines"
              target="blank"
            >
              Community Guidelines
            </a>{' '}
            before commenting.
          </p>
        </div>
        <GlobalStyle />
        <vf-tray-trigger floating="true" />
        <vf-conversations
          id="commentsWidget"
          first-promo-position="0"
          promo-interval="7"
          limit="5"
          vf-title={titleShort}
        />
      </Wrapper>
    </OuterWrapper>
  )
}

ViafouraComments.propTypes = {
  article: PropTypes.object,
  configuration: PropTypes.object.isRequired,
}

export default ViafouraComments
