/*globals videohubSetup, JSGlobals, googletag*/
import { serialize } from './serialize'
import compose from 'ramda/src/compose'

const getGoogleAdsParam = () => {
  const pubads = googletag.pubads()
  return pubads.getTargetingKeys().reduce((acc, key) => {
    return {
      ...acc,
      [key]: pubads.getTargeting(key),
      ['videoSite']: JSGlobals.title,
      ['siteSection']: 'nationalworldtv',
      ['video']: true,
    }
  }, {})
}

const setTargeting = compose(encodeURIComponent, serialize, getGoogleAdsParam)

const videohubSetupWithAdParams = () => {
  const formattedPublisherChildId = `${
    JSGlobals.publisherChildId ? `,${JSGlobals.publisherChildId}` : ''
  }`
  const adUnitId = `${JSGlobals.networkId}${formattedPublisherChildId}/VideoNetwork/`
  videohubSetup({
    adParams: adUnitId + setTargeting(),
  })
}

export const initVideohub = () => {
  if (!location.pathname.includes('/nationalworldtv')) {
    return
  }
  if (googletag.pubadsReady !== true) {
    return setTimeout(() => initVideohub(), 300)
  }
  if (typeof videohubSetup === 'function') {
    //videohub already loaded, therefore event has already fired; too late to set up a listener
    videohubSetupWithAdParams()
  } else {
    //videohub not loaded yet, set up a listener for the event that will be dispatched once it loads
    document.addEventListener('videohubSetupReady', () => {
      videohubSetupWithAdParams()
    })
  }
}
