import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 500px;
  min-height: 400px;
  margin-inline: auto;
`

const Facebook = ({ data: { url } }: { data: { url: string } }) => (
  <Wrapper>
    <div className="fb-post" data-href={url} data-show-text="true" />
    <div id="fb-root" />
  </Wrapper>
)

export default Facebook
