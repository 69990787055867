const accordion = () => {
  const acc = document.querySelectorAll('.accordion')
  const playBtn = document.querySelectorAll('.prev-puzzle-play-btn')
  const iframe = document.querySelector('#pa-iframe') as HTMLIFrameElement
  const puzzleTypeTitle = document.querySelector(
    '#puzzle-type-title',
  ) as HTMLElement
  const prevPuzzleDate = document.querySelector(
    '#prev-puzzle-date',
  ) as HTMLElement

  acc.forEach((element) => {
    element.addEventListener('click', () => {
      element.classList.toggle('active')
      const panel = element.nextElementSibling as HTMLElement
      if (panel.style.display === 'flex') {
        panel.style.display = 'none'
      } else {
        panel.style.display = 'flex'
      }
    })
  })

  playBtn.forEach((element: HTMLElement) => {
    element.addEventListener('click', () => {
      iframe.src = element.dataset.url ?? ''
      puzzleTypeTitle.innerHTML = element.dataset.type ?? ''
      prevPuzzleDate.innerHTML = element.dataset.date ?? ''
      prevPuzzleDate.style.display = 'block'
    })
  })
}

export default accordion
