/* globals JSGlobals */

export function isInView(el) {
  var rect = el.getBoundingClientRect()
  var elemTop = rect.top
  var elemBottom = rect.bottom
  return elemTop < window.innerHeight && elemBottom >= 0 // partially visible
}

// @todo move to api if this gets rolled out
const ARBITRAGE_AD_IDS = {
  'sunderlandecho.com': 'content_arb.jp/sunderland_echo',
}

export const getAdSlot = () => {
  if (!JSGlobals.addUnitId)
    throw 'Unable to find ad unit id, please check the ad unit id for this publication.'

  const { pathname } = window.location
  const path = pathname.split('/').filter((item) => item)
  const childId = JSGlobals.publisherChildId
    ? `,${JSGlobals.publisherChildId}`
    : ''

  const galleriesRegex = new RegExp('/galleries(/.*)?$')
  const isArbitrage =
    JSGlobals.pageType === 'photo gallery' ||
    galleriesRegex.test(location.pathname)

  const arbitrageId = ARBITRAGE_AD_IDS[JSGlobals.domain]
  let bottomLevelSection = 'home'

  if (pathname.length > 1 && path.length > 0) {
    if (JSGlobals.pageType !== 'category') path.pop()
    bottomLevelSection = path.join('/')
  }

  if (isArbitrage && arbitrageId) {
    return `/${JSGlobals.networkId}${childId}/${arbitrageId}/${bottomLevelSection}`
  }

  return `/${JSGlobals.networkId}${childId}/${JSGlobals.addUnitId}/${bottomLevelSection}`
}

/**
 * The function generates a random identifier, sets it as a cookie and sets it as the publisher
 * provided ID for Google ads.
 */
const setIdentifier = () => {
  var googletag = window.googletag || {}
  let identifier = ''
  const min = 32
  const max = 150
  const randomNum = Math.floor(Math.random() * (max - min + 1)) + min
  let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  for (let i = 0; i < randomNum; i++) {
    let numOrLetter = Math.floor(Math.random() * 2)
    numOrLetter === 1
      ? (identifier += alphabet.charAt(Math.floor(Math.random() * 25)))
      : (identifier += Math.floor(Math.random() * 9))
  }
  let expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + 400)

  document.cookie =
    'NationalWorld_PPID=' +
    identifier +
    '; expires=' +
    expirationDate.toUTCString()
  console.log('PPID cookie set')
  googletag.pubads().setPublisherProvidedId(identifier)
  console.log('new ppid set', identifier)
}

/**
 * The function checks if the user has a PPID (Publisher Provided Identifier) cookie and
 * sets it as the publisher provided ID for Google ads if it meets certain length requirements. If the
 * user does not have a PPID cookie or the cookie does not meet the length requirements, it generates
 * a new identifier and sets it as the PPID cookie and the publisher provided ID for Google ads.
 */
export const checkPPID = () => {
  const cookieStr = document.cookie.split(';')
  const ppidCookie = cookieStr.find((cookie) =>
    cookie.trim().startsWith('NationalWorld_PPID='),
  )

  if (ppidCookie) {
    const googletag = window.googletag || {}
    const ppidIdentifier = ppidCookie.split('=')[1]
    if (ppidIdentifier.length >= 32 && ppidIdentifier.length <= 150) {
      googletag.pubads().setPublisherProvidedId(ppidIdentifier)
    } else {
      setIdentifier()
    }
  } else {
    setIdentifier()
  }
}
