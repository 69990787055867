export const getCookie = (name: string) => {
  const cookie = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return cookie ? cookie[2] : null
}

export const setCookie = (
  cname: string,
  cvalue: boolean | string,
  exdays: number,
  isSecure = false,
  isHttpOnly = false,
) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const values = [
    cname + '=' + cvalue,
    'expires=' + d.toUTCString(),
    'path=/',
    isSecure ? 'Secure' : null,
    isHttpOnly ? 'HttpOnly' : null,
  ]
  document.cookie = values.filter(Boolean).join('; ')
}
