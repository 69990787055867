import React from 'react'
import styled from 'styled-components'
import { ArticleData, BlockquoteData, FC } from '~/types'
import quote from '~/icons/quote'
import { ColouredQuote } from '~/section/ArticleTitle'

export type BlockquoteProps = {
  className?: string
  data: BlockquoteData['data']
  hierarchyName?: string
  article?: ArticleData
}

const Blockquote = styled<FC<BlockquoteProps>>(
  ({ className, data: { markup, cite }, hierarchyName, article }) => {
    return (
      <div className={`${className} block-quote`}>
        <ColouredQuote
          className="quote-icon"
          dangerouslySetInnerHTML={{ __html: quote }}
          hierarchyName={hierarchyName}
          article={article}
        />
        <excessremovablewrapper dangerouslySetInnerHTML={{ __html: markup }} />
        <span className="citation">{cite}</span>
      </div>
    )
  },
)`
  position: relative;
  display: inline-block;
  margin: 16px 0;
  border-left: 4px solid ${({ theme }) => theme.greyContrast};
  padding-left: 16px;

  font-family: ${({ theme }) => theme.fontFamily.stagSemiBold};

  .quote-icon svg {
    width: 2.2em;
  }

  blockquote {
    margin: 5px 0 0 0;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 28px;
      font-style: italic;
    }
  }

  .citation {
    font-size: 16px;
    margin-top: 8px;
    float: left;
    color: ${({ theme }) => theme.fontColorMedium};
    font-style: normal;
  }
`

export default Blockquote
