import { Post } from '~/types'

/**
 * The function `fetchData` fetches liveblog posts from an API by making multiple requests with an
 * increasing offset until there are no more posts, and returns an array of all the fetched posts.
 * @param {number} liveBlogId - The `liveBlogId` parameter is a number that represents the ID of a live
 * blog. It is used to fetch live blog posts from the API.
 * @returns The `fetchData` function returns a promise that resolves to an array of `Post` objects.
 */
export const fetchData = async (liveBlogId: number | undefined) => {
  if (liveBlogId === undefined) {
    return []
  }
  const API_LIMIT = 20
  let offset = 0
  let allPosts: Post[] = []
  let fetching = true

  try {
    while (fetching) {
      const response = await fetch(
        `/api/liveblog-posts?id=${liveBlogId}&offset=${offset}`,
      )
      const data = await response.json()
      if (data.length > 0) {
        allPosts = [...allPosts, ...data]
        offset += API_LIMIT // The API limit is fixed at 20 liveblog posts
      } else {
        fetching = false // No more posts, break out of the loop
      }
    }
    return allPosts
  } catch (error) {
    console.error(error)
    return []
  }
}
