import validateNewsletterEmail from '~/util/validateNewsletterEmail'

/*globals hbspt*/
const hubspotNewsletter = () => {
  hbspt.forms.create({
    portalId: window.JSGlobals.hubspot.portalId,
    formId: window.JSGlobals.hubspot.embed.formId,
    target: '#hubspot-newsletter-container',
  })

  const handleNewsletterSubmit = (hasFormResponded) => {
    if (document.querySelector('#hubspot-newsletter-container')) {
      var event = new CustomEvent('custom:newsletterformsubmitted', {
        detail: JSON.stringify({ formId: 'hubspot-newsletter-container' }),
      })
      // Hide static message
      document
        .querySelectorAll('.newsletter-description')
        .forEach((e) => (e.style.display = 'none'))
      document.dispatchEvent(event)
      clearInterval(hasFormResponded)
    }
  }

  // Wait for the form to load
  const isFormReady = setInterval(() => {
    if (
      document
        .querySelector('iframe')
        .contentWindow.document.querySelector('form')
    ) {
      document
        .querySelector('iframe')
        .contentWindow.document.querySelector('form')
        .addEventListener('submit', () => {
          // Wait for successful form response
          const hasFormResponded = setInterval(() => {
            // Check if reCaptcha has appeared
            if (
              document.querySelector(
                'iframe[title="recaptcha challenge expires in two minutes"]',
              )
            ) {
              const reCaptchaIFrame = document.querySelector(
                'iframe[title="recaptcha challenge expires in two minutes"]',
              )
              const reCaptchaContainer = reCaptchaIFrame.closest('div')
              reCaptchaContainer.style.cssText += 'position:fixed;top:40px;'
            }

            handleNewsletterSubmit(hasFormResponded)
            window.scroll(0, 0)
          }, 500)
        })
      clearInterval(isFormReady)
    }
  }, 500)
}
export default hubspotNewsletter

validateNewsletterEmail()
