import React from 'react'
import styled from 'styled-components'

const AxatePaywall = styled.div`
  &.axate-paywall-hidden {
    display: none;
  }
`

const AxateArticleBottom = () => (
  <AxatePaywall className="axate-article-bottom axate-paywall-hidden" />
)

export default AxateArticleBottom
