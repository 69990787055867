const regexCheck = (email: string) => {
  const reg = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/
  return reg.test(String(email).toLowerCase())
}

const validateNewsletterEmail = () => {
  document.querySelectorAll('.input-submit-wrapper').forEach((e) =>
    e.querySelector('input[type=email]')?.addEventListener('input', () => {
      const emailInput = e.firstChild as HTMLInputElement
      const email: string = emailInput?.value

      if (regexCheck(email)) {
        e.querySelector('input[type=submit]')?.removeAttribute('disabled')
      } else {
        e.querySelector('input[type=submit]')?.setAttribute('disabled', '')
      }
    }),
  )
}

export default validateNewsletterEmail
