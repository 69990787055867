import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  ADCONFIG_DESKTOP,
  ADCONFIG_DESKTOP_LITE,
  ADSLOT_VIDEO,
} from '~/constants/ads'
import { DailymotionHeader } from '~/library/Article/Embeds/Dailymotion'
import { getAdConfigSetting } from '~/providers/AdManager/utils/getAdConfigSetting'
import { jsLoader } from '~/util/fileLoaders'
import { getCustomConfig } from '~/util/dailymotion'

const PUBLISHER_CHILD_ID = 3948

const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
`

const Inner = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
`

// @todo should we pass additional info when there are multiple players?
const pushPlayerReadyEvent = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: 'dmPlayerReady' })
}

const DailymotionHydratedPost = ({ data, article, configuration }) => {
  if (!article) return null

  const { id, url = '' } = data

  const { adLiteEnabled } = configuration

  let dmId = id

  // cms will use free text for editor to populate field with url
  if (url) {
    dmId = url?.split('/').pop()
  }

  // classNames
  const classNames = ['dailymotion-cpe']

  // ad config
  // @todo use ad manager context
  const { adConfig } = article
  const hasAdConfig = !!adConfig?.[ADCONFIG_DESKTOP] // @todo remove once hardcoded deprecated

  if (
    hasAdConfig &&
    adLiteEnabled &&
    getAdConfigSetting(adConfig, ADSLOT_VIDEO, ADCONFIG_DESKTOP_LITE)
  ) {
    classNames.push('dailymotion-cpe-ads')
  }

  if (
    hasAdConfig &&
    !adLiteEnabled &&
    getAdConfigSetting(adConfig, ADSLOT_VIDEO, ADCONFIG_DESKTOP)
  ) {
    classNames.push('dailymotion-cpe-ads')
  }

  const networkId = `${PUBLISHER_CHILD_ID}/VideoNetwork/`

  const initialisePlayers = async () => {
    const playerSettings = {
      video: dmId,
      referrerPolicy: 'no-referrer-when-downgrade',
      params: {
        mute: true,
        customConfig: await getCustomConfig(networkId),
      },
    }

    window.dailymotion
      .createPlayer(`dm-post-${dmId}`, playerSettings)
      .then(pushPlayerReadyEvent)
      .catch((error) => {
        console.error('Error creating Dailymotion player:', error)
      })
  }

  const initialiseDailymotion = async () => {
    const dailymotionScript = document.querySelector(
      `script[src="https://geo.dailymotion.com/libs/player/${configuration.videoPlayerId}.js"]`,
    )
    if (!dailymotionScript) {
      jsLoader(
        [
          `https://geo.dailymotion.com/libs/player/${configuration.videoPlayerId}.js`,
        ],
        'dailymotion',
      )
    }
  }

  useEffect(() => {
    const maxRetries = 3
    let retries = 0

    const dmIntervalCheck = setInterval(async () => {
      if (window.dailymotion) {
        clearInterval(dmIntervalCheck)
        await initialisePlayers()
        return
      }
      if (retries >= maxRetries) {
        clearInterval(dmIntervalCheck)
        return
      }
      retries++
    }, 100)

    initialiseDailymotion()
  }, [])

  return (
    <Wrapper>
      <DailymotionHeader {...article.dailymotion} videoId={dmId} />
      <Inner videoPlayerId={configuration?.videoPlayerId}>
        <div
          id={`dm-post-${dmId}`}
          className={classNames}
          referrerPolicy="no-referrer-when-downgrade"
          data-article-dm-post-id={dmId} // to fetch the id for new Dailymotion
          ads-params={''} // client side js will populate this
          data-network-id={networkId}
          {...{
            'no-queue': '',
            'no-pip': '',
          }}
        />
      </Inner>
    </Wrapper>
  )
}

DailymotionHydratedPost.propTypes = {
  article: PropTypes.object,
  configuration: PropTypes.object,
  data: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
}

export default DailymotionHydratedPost
