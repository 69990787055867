import { ArticleData } from '~/types'

const isCommentingEnabled = (article: ArticleData) => {
  if (!article) return false

  const blacklistedSectionTypes = ['Crime', 'Troubles', 'Must Read']
  const blacklistedArticleTypes = ['Commercial']

  const isSectionCommentingHidden =
    article.sections?.some((section) =>
      blacklistedSectionTypes.includes(section.name),
    ) ||
    article.articleTypes?.some((articleType) =>
      blacklistedArticleTypes.includes(articleType.name),
    ) ||
    article.hierarchy?.some((section) => section.extra?.isHideCommenting)

  return (
    !isSectionCommentingHidden &&
    article.commenting &&
    !article.polar &&
    !article.sponsorName &&
    !article.partners
  )
}

export default isCommentingEnabled
