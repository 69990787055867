import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { FC } from '~/types'

const FactBoxContainer = styled.div`
  background: ${({ theme }) => theme.greyContrast};
  padding: 0 20px 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  clear: both;
  @media ${device.maxLaptop} {
    h2 {
      padding-top: 20px;
    }
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.primaryColor};
    margin-top: 20px;
    margin-bottom: 0;
  }
  h2:first-child {
    padding-top: 20px;
  }
  p {
    font-size: 20px;
    margin: 0;
    font-weight: bold;
    line-height: 25px;
    padding-top: 20px;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primaryColor};
    word-break: break-all;
  }
  strong {
    color: ${({ theme }) => theme.primaryColor};
  }
`

type FactBoxProps = {
  data: {
    markup: string
    title: string
  }
  className?: string
}

const FactBox: FC<FactBoxProps> = ({ data: { markup, title }, className }) => {
  return (
    <FactBoxContainer className={className}>
      <excessremovablewrapper dangerouslySetInnerHTML={{ __html: title }} />
      <excessremovablewrapper dangerouslySetInnerHTML={{ __html: markup }} />
    </FactBoxContainer>
  )
}

export default FactBox
