import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { device } from '~/config/theme/JPIMStyles'
import MostPopular from '~/library/Sidebar/MostPopular'

const InlineMostPopular = styled(MostPopular)`
  margin: 20px 0;
  border-top: 2px solid ${(props) => props.theme.greyContrast};
  border-bottom: 2px solid ${(props) => props.theme.greyContrast};
  display: none;
  clear: both;
  ul li div a {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media ${device.laptop} {
    display: block;
  }
`

function MostPopularInline({ configuration, hierarchy }) {
  return (
    <InlineMostPopular
      configuration={configuration}
      hierarchy={hierarchy}
      inline={true}
      className="mostPopularInline"
    />
  )
}

export default MostPopularInline

MostPopularInline.propTypes = {
  configuration: PropTypes.object.isRequired,
  item: PropTypes.object,
  counter: PropTypes.number,
  hierarchy: PropTypes.arrayOf(PropTypes.object),
}
