import { TABLET_BREAKPOINT } from '../config/theme/JPIMStyles'

const evenSlides = (slideCount: number) => slideCount % 2 === 0

/**
 * Calculates the index of the last visible slide based on the total number of slides.
 *
 * For desktop, it subtracts 2 from the total number of slides to account for the layout
 * which has 2 slides per view, ie. the index of the last slide where there are 5 slides
 * would be 3 when sliding one slide at a time. For mobile, it adjusts the calculation
 * based on whether the total number of slides is even or odd.
 *
 * @param slideCount The total number of slides.
 * @returns The index of the last visible slide.
 */
export const getLastVisibleSlideIndex = (slideCount: number) => {
  return (
    slideCount -
    (window.innerWidth >= TABLET_BREAKPOINT
      ? 2 // Desktop
      : Math.round(slideCount / 2) + (evenSlides(slideCount) ? 1 : 0))
  ) // Mobile: slides are stacked
}
