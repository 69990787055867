import { Domain } from '~/types'

export const DAILYMOTION_CONTEXTUAL_PLAYER_ID = 'xxsnk'
export const BLACKLISTED_SECTIONS: string[] = ['Crime', 'Troubles', 'Must Read']

export const BLACKLISTED_ARTICLE_TYPES: string[] = [
  'affiliate article',
  'sponsored article',
]

export const WHITELISTED_DOMAINS: Domain[] = [
  'birminghamworld.uk',
  'blackpoolgazette.co.uk',
  'bristolworld.com',
  'burnleyexpress.net',
  'derbyworld.co.uk',
  'glasgowworld.com',
  'lancasterguardian.co.uk',
  'lep.co.uk',
  'liverpoolworld.uk',
  'londonworld.com',
  'manchesterworld.uk',
  'nationalworld.com',
  'newcastleworld.com',
  'nottinghamworld.com',
  'wigantoday.net',
]
