import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { device } from '~/config/theme/JPIMStyles'
import { chevron } from '~/icons/chevron'
import { chevronEdge } from '~/icons/chevronEdge'

import { LIVEBLOG_POSTS_PER_PAGE } from '~/js/components/LiveblogHydrated/LiveblogHydrated'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  margin: 30px auto;
  font-size: 16px;

  &.dark {
    .button {
      a {
        border: 1px solid ${({ theme }) => theme.lightContrast};
        .label {
          color: ${({ theme }) => theme.lightContrast};
        }
        .chevron {
          fill: ${({ theme }) => theme.lightContrast};
        }
        span.chevron svg g {
          fill: ${({ theme }) => theme.lightContrast};
        }
        span.chevron svg path.container {
          fill: none;
        }
        span.chevron svg path.shape {
          fill: ${({ theme }) => theme.lightContrast};
        }
      }
      &.disabled a {
        border-color: ${({ theme }) => theme.fontColorLight};

        .shape,
        svg g {
          fill: ${({ theme }) => theme.fontColorLight};
        }
      }
    }
  }

  .button {
    display: flex;
    gap: 16px;

    &.disabled a {
      color: ${({ theme }) => theme.fontColorLight};
      border-color: ${({ theme }) => theme.greyContrast};
      pointer-events: none;

      .shape,
      svg g {
        fill: ${({ theme }) => theme.greyContrast};
      }
    }
  }
`

const PageCount = styled.span`
  text-align: center;
  user-select: none;
  font-size: 20px;
`

const Button = styled.a`
  width: 48px;
  height: 50px;
  color: ${({ theme }) => theme.darkestContrast};
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.darkestContrast};
  border-radius: 4px;
  user-select: none;

  @media ${device.tablet} {
    width: 144px;
  }

  span.label {
    display: none;
    font-size: 16px;

    @media ${device.tablet} {
      display: block;
    }
  }

  &.isDark {
    span.chevron {
      color: ${({ theme }) => theme.lightContrast};
    }
    &.right {
      color: ${({ theme }) => theme.lightContrast};
    }
  }
  span.chevron {
    display: flex;
    width: auto;
    height: 18px;
    justify-content: center;

    &.right {
      transform: rotate(-180deg);
    }
  }
`

const ButtonLeft = styled(Button)`
  padding-right: 2px;
`

const ButtonRight = styled(Button)`
  padding-left: 2px;
`

const SquareButton = styled(Button)`
  width: 48px;

  span {
    display: block;
    fill: ${({ theme }) => theme.darkestContrast};
  }
`

const Pagination = styled(
  ({
    className,
    currentPage = 0,
    isDark = false,
    liveblog = false,
    totalPage = 0,
    offset,
    path,
    posts,
  }) => {
    const blogCurrentPage =
      liveblog && posts >= LIVEBLOG_POSTS_PER_PAGE
        ? Math.round(offset / posts) + 1
        : liveblog && posts < LIVEBLOG_POSTS_PER_PAGE
        ? Math.round(offset / LIVEBLOG_POSTS_PER_PAGE) + 1
        : currentPage
    const liveblogHash = liveblog ? '#liveblog' : ''

    const nextPage = blogCurrentPage + 1
    const prevPage = blogCurrentPage - 1

    const baseLink = `${path}?page=`
    const firstPageLink = `${baseLink}1${liveblogHash}`
    const nextPageLink = `${baseLink}${nextPage}${liveblogHash}`
    const prevPageLink = `${baseLink}${prevPage}${liveblogHash}`
    const lastPageLink = `${baseLink}${totalPage}${liveblogHash}`
    return (
      <div className={className} pagination={liveblog ? '' : undefined}>
        <Wrapper className={isDark ? 'dark' : 'light'}>
          {totalPage > 1 && (
            <div
              className={`button${blogCurrentPage === 1 ? ' disabled' : ''}`}
            >
              <SquareButton href={blogCurrentPage === 1 ? '#' : firstPageLink}>
                <span
                  className="chevron"
                  dangerouslySetInnerHTML={{ __html: chevronEdge }}
                />
              </SquareButton>
              <ButtonLeft href={blogCurrentPage === 1 ? '#' : prevPageLink}>
                <span
                  className="chevron"
                  dangerouslySetInnerHTML={{ __html: chevron }}
                />
                <span className="label">Previous</span>
              </ButtonLeft>
            </div>
          )}
          {totalPage > 1 && (
            <PageCount>
              <strong>{blogCurrentPage}</strong> / {totalPage}
            </PageCount>
          )}
          {totalPage > 1 && (
            <div
              className={`button${
                blogCurrentPage === totalPage ? ' disabled' : ''
              }`.trim()}
            >
              <ButtonRight
                href={blogCurrentPage < totalPage ? nextPageLink : '#'}
                id="nextBtn"
              >
                <span className="label">Next Page</span>
                <span
                  className="chevron right"
                  dangerouslySetInnerHTML={{ __html: chevron }}
                />
              </ButtonRight>
              <SquareButton
                href={blogCurrentPage === totalPage ? '#' : lastPageLink}
              >
                <span
                  className="chevron right"
                  dangerouslySetInnerHTML={{ __html: chevronEdge }}
                />
              </SquareButton>
            </div>
          )}
        </Wrapper>
      </div>
    )
  },
)``

Pagination.propTypes = {
  currentPage: PropTypes.number,
  path: PropTypes.string.isRequired,
  totalPage: PropTypes.number,
}

export default Pagination
