export const handleClickTracking = (
  articleId: string | undefined,
  articleType: string | undefined,
  position: string,
  method?: string | null,
) => {
  const data = {
    event: 'share',
    content_id: articleId,
    content_type: articleType,
    position,
    ...(method && { method }),
  }
  window.dataLayer?.push(data)
}
