import striptags from 'striptags'

const DEFAULT_LEAD_LENGTH = 100
const MULTI_HERO_LEAD_LENGTH = 160

const truncateText = (lead: string, maxLength: number): string =>
  lead.length > maxLength ? lead.substring(0, maxLength) + '...' : lead

const generateEditedLead = (
  lead: string,
  includeStandfirst: boolean = false,
  type?: string,
): string => {
  const strippedLead = striptags(lead)

  if (includeStandfirst) {
    return truncateText(strippedLead, DEFAULT_LEAD_LENGTH)
  }

  if (
    type === 'MultiHeroPlus3ArticlesPlusSidebar' ||
    type === 'HeroPlus6ArticlesPlusDMPU' ||
    type === 'HeroPlus8Articles'
  ) {
    return truncateText(strippedLead, MULTI_HERO_LEAD_LENGTH)
  }

  return strippedLead
}

export default generateEditedLead
