import { Post, ArticleData } from '~/types'

export const postHelper = (
  post: Post,
  article: ArticleData,
  containerData: { status?: number },
) => {
  const isPinned = !!post.data.extra.pinWeight

  const articleWithPostAffix = {
    ...article,
    postSocialSharePathAffix: `#post-${post.data.created}`,
  }

  const sortTime =
    parseInt(post.data.scheduleTime) > 0
      ? post.data.scheduleTime
      : post.data.created

  const updateTime =
    parseInt(post.data.scheduleTime) > 0
      ? post.data.scheduleTime
      : post.data.changed

  const sortTimeAd =
    containerData.status === 1 ? parseInt(sortTime) - 1 : parseInt(sortTime) + 1

  const updateTimeAd =
    containerData.status === 1
      ? parseInt(updateTime, 10) - 1
      : parseInt(updateTime, 10) + 1

  return {
    isPinned,
    articleWithPostAffix,
    sortTime,
    updateTime,
    sortTimeAd,
    updateTimeAd,
  }
}
