import { trimImageParam } from '@brightsites/flow-core/lib/utils/cropImage'
import React from 'react'
import striptags from 'striptags'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { Img } from '~/library/Img'
import { buildSizeUpdateParam, capImageWidths } from '~/library/helper'
import { ComponentThemeProvider } from '~/library/providers/ComponentThemeProvider'
import { FC, ImageData, Ratio } from '~/types'
import { CaptionOverlay } from './Image/CaptionOverlay'

type ImageWrapperProps = {
  isProductImage?: boolean
}

const ImageWrapper = styled.figure<ImageWrapperProps>`
  margin: 20px 0;

  & img {
    ${({ isProductImage }) => `
    aspect-ratio: ${isProductImage ? '3 / 2' : 'auto'};
    height: ${isProductImage ? 'auto' : '100%'};
    width: ${isProductImage ? 'fit-content' : '100%'};
    `}
    display: block;
    object-fit: cover;
    background-color: ${({ theme }) => theme.lightGreyContrast};
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  &.hero-img img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &.hero-img > div {
    position: relative;
    padding-top: 66.7%;
  }

  .placeholder {
    object-fit: contain;
  }
`

const InnerImageWrapper = styled.div<ImageWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.maxTablet} {
    margin: 0 -20px;
  }
`

export const Caption = styled.figcaption`
  color: ${({ theme }) => theme.fontColorLight};
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.25;
  padding: 6px 0;
  font-family: ${({ theme }) => theme.fontFamily.openSans};

  @media ${device.maxMobileL} {
    font-size: 0.75rem;
  }
`

const CaptionContrast = styled(Caption)`
  color: ${({ theme }) => theme.lightContrast};
`

export type ImageProps = {
  className: string
  data: ImageData['data']
  hasCaption?: boolean
  isHero?: boolean
  imageDimensions?: { height: number; width: number }
  isHeroVideoImage?: boolean
  isProductImage?: boolean
  isPartnerLogo?: boolean
  srcSet: number[]
  ratio?: Ratio
  theme?: string
  showCaptionOverlay?: boolean
}

const Image: FC<ImageProps> = ({
  className = '',
  data: { url, caption, localCaption, copyright, width, height, extra, crop },
  hasCaption = true,
  isHero = false,
  imageDimensions,
  isHeroVideoImage,
  isPartnerLogo,
  isProductImage,
  srcSet: widths,
  ratio = 'threebytwo',
  theme = 'default',
  showCaptionOverlay = false,
}) => {
  const actualHeight = extra?.imageSize?.height
  const actualWidth = extra?.imageSize?.width
  const CaptionDiv = ComponentThemeProvider(theme, CaptionContrast, Caption)
  const fallbackHeight = 524
  const fallbackWidth = 982
  let imageHeight = height ?? actualHeight ?? fallbackHeight
  let imageWidth = width ?? actualWidth ?? fallbackWidth
  const attrs: Record<string, string> = {}

  if (isHeroVideoImage) {
    imageHeight = imageDimensions?.height || 800
    imageWidth = imageDimensions?.width || 600
  }

  const imageCaption = hasCaption
    ? striptags(caption ?? localCaption ?? '')
    : ''

  if (isHero) {
    attrs['fetchpriority'] = 'high'
  }

  const sizeUpdateParam = !isPartnerLogo
    ? (trimImageParam(crop) ??
      buildSizeUpdateParam({
        ratio,
        sizes: extra?.sizes,
      }))
    : ''

  const urlParams = {
    ...sizeUpdateParam,
    width: '640',
    quality: '65',
    ...(isHero && { enable: 'upscale' }),
  }

  const hasTrim = (urlParams as { trim?: string }).trim

  // Don't cap widths if trim found or for an upscaled image
  // prettier-ignore
  const cappedWidths = hasTrim || urlParams.enable === 'upscale'
    ? widths
    : capImageWidths({ widths, extra })

  return (
    <ImageWrapper
      className={isHero ? `hero-img ${className}` : className}
      {...(isHero && { id: 'hero-slot' })}
      data-testid={isHero ? 'image-wrapper hero' : 'image-wrapper'}
      isProductImage={isProductImage}
    >
      <InnerImageWrapper isProductImage={isProductImage}>
        <Img
          src={url}
          urlParams={urlParams}
          {...(imageCaption && { alt: imageCaption })}
          height={imageHeight}
          width={imageWidth}
          widths={cappedWidths}
          isHeroImage={isHero}
          {...attrs}
        >
          <Img
            className="placeholder"
            src="/img/placeholder.png"
            {...(imageCaption && { alt: imageCaption })}
            width="288"
            height="185"
            isHeroImage={isHero}
          />
        </Img>
        {showCaptionOverlay && (imageCaption || copyright) && (
          <CaptionOverlay>
            <CaptionDiv className="image-caption">
              {imageCaption}
              {imageCaption && copyright && ' | '}
              {copyright}
            </CaptionDiv>
          </CaptionOverlay>
        )}
      </InnerImageWrapper>
      {!showCaptionOverlay && (imageCaption || copyright) && (
        <CaptionDiv className="image-caption" data-testid="image-caption">
          {imageCaption}
          {imageCaption && copyright && ' | '}
          {copyright}
        </CaptionDiv>
      )}
    </ImageWrapper>
  )
}

export default Image
