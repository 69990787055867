import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  .tp-container-inner {
    margin: 20px auto;
  }
`

type PianoArticlePaywall = {
  className?: 'piano-article-paywall' | 'piano-article-paywall-mid'
}

const PianoArticlePaywall = ({
  className = 'piano-article-paywall',
}: PianoArticlePaywall) => <Wrapper className={className} />

export default PianoArticlePaywall
