const upNext = () => {
  const $upNext = document.getElementById('up-next')
  const $closeButton = document.getElementById('up-next-close-button')
  const [$content] = document.getElementsByClassName('article-content')

  if (!$upNext || !$closeButton || !$content) return

  $closeButton.addEventListener('click', (event) => {
    $upNext.classList.add('dismissed')
    event.preventDefault()
  })

  let isHidden = true
  window.addEventListener(
    'scroll',
    () => {
      const { height, y } = $content.getBoundingClientRect()
      const progress = -y / height

      if (isHidden && progress >= 0.65) {
        $upNext.classList.remove('hidden')
        isHidden = false
      }

      if (!isHidden && progress <= 0.5) {
        $upNext.classList.add('hidden')
        isHidden = true
      }
    },
    { passive: true },
  )
}

export default upNext
