import { adConfig } from '~/config/JPIMConfig'
import {
  ADCONFIG_DEFAULT,
  ADCONFIG_LOCALBUYS,
  ADCONFIG_MUSTREAD,
  ADCONFIG_RECOMMENDED,
  ADCONFIG_SPONSORED,
} from './adConfigDeprecated'

export const ampAdData = ({
  slot,
  sections,
  rootSection,
  adTargeting,
  configuration,
}) => {
  const data = Object.assign({}, adConfig.ampAd)
  const formattedPublisherChildId = `${
    configuration.publisherChildId ? `,${configuration.publisherChildId}` : ''
  }`
  data.json = JSON.parse(data.json)
  data.json.targeting = {
    ...data.json.targeting,
    pos: [slot],
    test: adTargeting,
    pagetype: 'article',
    section: sections,
    amp: 'true',
  }
  data[
    'data-slot'
  ] = `/${configuration.networkId}${formattedPublisherChildId}/${configuration.addUnitId}/${rootSection}`

  if (slot === 'mpu1') {
    data['data-multi-size-validation'] = 'false'
    data['data-multi-size'] = '320x480,320x400,300x600'
    data['width'] = 320
    data['height'] = 600
  }

  data.json = JSON.stringify(data.json)
  return data
}

export const TOP_BANNER = 'TopBanner'
export const BOTTOM_BANNER = 'BottomBanner'
export const WALLPAPER = 'WallPaper'
export const NATIVE = 'Native'
export const ADSLOT3X3 = 'AdSlot3X3'
export const ADSLOT2X3 = 'AdSlot2X3'
export const TEADS = 'Teads'
export const ARTICLEBODY = 'ArticleBody'
export const LIVEBLOGPOST = 'LiveblogPost'
export const RIGHTHANDSIDE = 'RightHandSide'
export const TABOOLA = 'Taboola'
export const OUTBRAIN = 'OutBrain'
export const ONSCROLL = 'OnScroll'

export const LITE_TOP_BANNER = 'LiteTopBanner'
export const LITE_BOTTOM_BANNER = 'LiteBottomBanner'
export const LITE_WALLPAPER = 'LiteWallPaper'
export const LITE_NATIVE = 'LiteNative'
export const LITE_TEADS = 'LiteTeads'
export const LITE_ARTICLEBODY = 'LiteArticleBody'
export const LITE_LIVEBLOGPOST = 'LiteLiveblogPost'
export const LITE_RIGHTHANDSIDE = 'LiteRightHandSide'
export const LITE_TABOOLA = 'LiteTaboola'
export const LITE_OUTBRAIN = 'LiteOutBrain'
export const Lite_ADSLOT3X3 = 'LiteAdSlot3X3'
export const LITE_ADSLOT2X3 = 'LiteAdSlot2X3'
export const LITE_ONSCROLL = 'LiteOnScroll'

const getAdConfig = (path) => {
  switch (true) {
    case path.startsWith('/sponsored'):
      return ADCONFIG_SPONSORED
    case path.startsWith('/must-read'):
      return ADCONFIG_MUSTREAD
    case path.startsWith('/local-buys'):
      return ADCONFIG_LOCALBUYS
    case path.startsWith('/recommended'):
      return ADCONFIG_RECOMMENDED
    default:
      return ADCONFIG_DEFAULT
  }
}

export const adConfiguration = ({
  path,
  section = {},
  sponsorURL,
  sponsorName,
}) => {
  const _path = path || section?.path || '/'
  const adConfig =
    sponsorURL || sponsorName ? ADCONFIG_SPONSORED : getAdConfig(_path)
  return (needle) =>
    adConfig.some(({ type, value }) => type === needle && value === true)
}

export const adConfigurationByType = ({
  path,
  section = {},
  sponsorURL,
  sponsorName,
}) => {
  const adConfig =
    sponsorURL || sponsorName
      ? ADCONFIG_SPONSORED
      : getAdConfig(path || section?.path || '/')
  return (needle) => adConfig.find(({ type }) => type === needle) || {}
}
