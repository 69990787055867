import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ParagraphWrapper = styled.div``

const Headline = styled.h2`
  font-size: 40px;
  letter-spacing: -0.6px;
  line-height: 57px;
`

const Title = styled.h1`
  color: ${(props) => props.theme.primaryColor};
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 31px;
`

const Price = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.fontColorMedium};
`

const Description = styled.div`
  font-size: 19px;
  letter-spacing: -0.6px;
  line-height: 32px;
`

const Contact = styled.div`
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 27px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 25px;
`

const Detail = styled.ul`
  font-size: 20px;
  padding-left: 0;
  margin-top: 0;
  li {
    display: flex;
    list-style-type: none;
    border-bottom: 1px solid ${(props) => props.theme.greyContrast};
    padding-bottom: 10px;
    padding-top: 10px;
    span:first-child {
      flex: 1;
    }
  }

  .garage {
    border-top: 1px solid ${(props) => props.theme.greyContrast};
  }
`

const HomeDetail = ({ data }) => {
  return (
    <ParagraphWrapper>
      <Headline>
        {data.numBedrooms} bedrooms, {data.DisplayAddress}
      </Headline>
      <Price>
        &pound;{data.price} {data.RentFrequency}
      </Price>
      <Title>Detail</Title>
      <Detail>
        <li className={'garage'}>
          <span>Garage</span>
          <span>{data.garage}</span>
        </li>
        <li>
          <span>Garden</span>
          <span>{data.garden}</span>
        </li>
        <li>
          <span>Off Street Parking</span>
          <span>{data.OffStreetParking}</span>
        </li>
        <li>
          <span>Children</span>
          <span>{data.Children}</span>
        </li>
        <li>
          <span>Smokers</span>
          <span>{data.Smokers}</span>
        </li>
        <li>
          <span>Let Type</span>
          <span>{data.LettingType}</span>
        </li>
        <li>
          <span>Number of Bedrooms</span>
          <span>{data.numBedrooms}</span>
        </li>
        <li>
          <span>Available</span>
          <span>{data.DateAvailable}</span>
        </li>
        <li>
          <span>Furnished/Unfurnished</span>
          <span>{data.Furnished}</span>
        </li>
        <li>
          <span>Location</span>
          <span>
            {data.DisplayAddress}, {data.locationCounty}
          </span>
        </li>
      </Detail>
      <Title>Description</Title>
      <Description>{data.description}</Description>
      <Contact>Contact Seller: {data.ContactPhone}</Contact>
    </ParagraphWrapper>
  )
}

HomeDetail.propTypes = {
  data: PropTypes.any.isRequired,
  polar: PropTypes.bool,
}

export default HomeDetail
