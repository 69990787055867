export const ADCONFIG_DEFAULT = [
  {
    type: 'TopBanner',
    value: true,
  },
  {
    type: 'WallPaper',
    value: true,
  },
  {
    type: 'Teads',
    value: true,
  },
  {
    type: 'ArticleBody',
    value: true,
    frequency: 4,
  },
  {
    type: 'LiveblogPost',
    value: true,
  },
  {
    type: 'RightHandSide',
    value: true,
  },
  {
    type: 'BottomBanner',
    value: true,
  },
  {
    type: 'Native',
    value: true,
  },
  {
    type: 'VideoRoll',
    value: true,
  },
  {
    type: 'Taboola',
    value: true,
  },
  {
    type: 'OutBrain',
    value: true,
  },
  {
    type: 'OnScroll',
    value: true,
  },
  {
    type: 'AdSlot3X3',
    value: true,
  },
  {
    type: 'AdSlot2X3',
    value: true,
  },
  {
    type: 'LiteTopBanner',
  },
  {
    type: 'LiteWallPaper',
  },
  {
    type: 'LiteTeads',
  },
  {
    type: 'LiteArticleBody',
    value: true,
    frequency: 7,
    maximum: 1,
  },
  {
    type: 'LiteLiveblogPost',
    value: true,
    frequency: 7,
    maximum: 1,
  },
  {
    type: 'LiteRightHandSide',
    value: true,
  },
  {
    type: 'LiteBottomBanner',
    value: false,
  },
  {
    type: 'LiteNative',
    value: true,
  },
  {
    type: 'LiteVideoRoll',
  },
  {
    type: 'LiteTaboola',
    value: true,
  },
  {
    type: 'LiteOnScroll',
  },
  {
    type: 'LiteAdSlot3X3',
  },
]

export const ADCONFIG_SPONSORED = [
  {
    type: 'TopBanner',
    value: false,
  },
  {
    type: 'WallPaper',
    value: false,
  },
  {
    type: 'Teads',
    value: false,
  },
  {
    type: 'ArticleBody',
    value: false,
  },
  {
    type: 'LiveblogPost',
  },
  {
    type: 'RightHandSide',
    value: false,
  },
  {
    type: 'BottomBanner',
    value: false,
  },
  {
    type: 'Native',
    value: false,
  },
  {
    type: 'VideoRoll',
    value: false,
  },
  {
    type: 'Taboola',
    value: false,
  },
  {
    type: 'OnScroll',
    value: false,
  },
  {
    type: 'AdSlot3X3',
    value: false,
  },
  {
    type: 'LiteTopBanner',
    value: false,
  },
  {
    type: 'LiteWallPaper',
    label: 'Wallpaper',
    id: 'vza59fe0',
    value: false,
  },
  {
    type: 'LiteTeads',
    value: false,
  },
  {
    type: 'LiteArticleBody',
    value: false,
  },
  {
    type: 'LiteLiveblogPost',
  },
  {
    type: 'LiteRightHandSide',
    value: false,
  },
  {
    type: 'LiteBottomBanner',
    value: false,
  },
  {
    type: 'LiteNative',
    value: false,
  },
  {
    type: 'LiteVideoRoll',
    value: false,
  },
  {
    type: 'LiteTaboola',
    value: false,
  },
  {
    type: 'LiteOutBrain',
    value: false,
  },
  {
    type: 'LiteOnScroll',
    value: false,
  },
  {
    type: 'LiteAdSlot3X3',
    value: false,
  },
]

export const ADCONFIG_MUSTREAD = [
  {
    type: 'TopBanner',
    value: false,
  },
  {
    type: 'WallPaper',
    value: false,
  },
  {
    type: 'Teads',
    value: false,
  },
  {
    type: 'ArticleBody',
    value: false,
  },
  {
    type: 'LiveblogPost',
  },
  {
    type: 'RightHandSide',
    value: false,
  },
  {
    type: 'BottomBanner',
    value: false,
  },
  {
    type: 'Native',
    value: false,
  },
  {
    type: 'VideoRoll',
    value: false,
  },
  {
    type: 'Taboola',
    value: false,
  },
  {
    type: 'OnScroll',
    value: false,
  },
  {
    type: 'AdSlot3X3',
    value: false,
  },
  {
    type: 'LiteTopBanner',
    value: false,
  },
  {
    type: 'LiteWallPaper',
    value: false,
  },
  {
    type: 'LiteTeads',
    value: false,
  },
  {
    type: 'LiteArticleBody',
    value: false,
  },
  {
    type: 'LiteLiveblogPost',
  },
  {
    type: 'LiteRightHandSide',
    value: false,
  },
  {
    type: 'LiteBottomBanner',
    value: false,
  },
  {
    type: 'LiteNative',
    value: false,
  },
  {
    type: 'LiteVideoRoll',
    value: false,
  },
  {
    type: 'LiteTaboola',
    value: false,
  },
  {
    type: 'LiteOnScroll',
    value: false,
  },
  {
    type: 'LiteAdSlot3X3',
    value: false,
  },
]

export const ADCONFIG_LOCALBUYS = [
  {
    type: 'TopBanner',
    value: true,
  },
  {
    type: 'WallPaper',
    value: false,
  },
  {
    type: 'Teads',
    value: false,
  },
  {
    type: 'ArticleBody',
    value: false,
  },
  {
    type: 'LiveblogPost',
    value: false,
  },
  {
    type: 'RightHandSide',
    value: true,
  },
  {
    type: 'BottomBanner',
    value: true,
  },
  {
    type: 'Native',
    value: true,
  },
  {
    type: 'VideoRoll',
    value: false,
  },
  {
    type: 'Taboola',
    value: false,
  },
  {
    type: 'OnScroll',
    value: true,
  },
  {
    type: 'AdSlot3X3',
    value: false,
  },
  {
    type: 'LiteTopBanner',
    value: false,
  },
  {
    type: 'LiteWallPaper',
    value: false,
  },
  {
    type: 'LiteTeads',
    value: false,
  },
  {
    type: 'LiteArticleBody',
    value: false,
  },
  {
    type: 'LiteLiveblogPost',
    value: false,
  },
  {
    type: 'LiteRightHandSide',
    value: true,
  },
  {
    type: 'LiteBottomBanner',
    value: false,
  },
  {
    type: 'LiteNative',
    value: true,
  },
  {
    type: 'LiteVideoRoll',
    value: false,
  },
  {
    type: 'LiteTaboola',
    value: false,
  },
  {
    type: 'LiteOnScroll',
    value: false,
  },
  {
    type: 'LiteAdSlot3X3',
    value: false,
  },
]

export const ADCONFIG_RECOMMENDED = [
  {
    type: 'TopBanner',
    value: true,
  },
  {
    type: 'WallPaper',
    value: false,
  },
  {
    type: 'Teads',
    value: false,
  },
  {
    type: 'ArticleBody',
    value: false,
  },
  {
    type: 'LiveblogPost',
    value: false,
  },
  {
    type: 'RightHandSide',
    value: true,
  },
  {
    type: 'BottomBanner',
    value: true,
  },
  {
    type: 'Native',
    value: true,
  },
  {
    type: 'VideoRoll',
    value: false,
  },
  {
    type: 'Taboola',
    value: false,
  },
  {
    type: 'OnScroll',
    value: true,
  },
  {
    type: 'AdSlot3X3',
    value: false,
  },
  {
    type: 'AdSlot2X3',
    value: false,
  },
  {
    type: 'LiteTopBanner',
    value: false,
  },
  {
    type: 'LiteWallPaper',
    value: false,
  },
  {
    type: 'LiteTeads',
    value: false,
  },
  {
    type: 'LiteArticleBody',
    value: false,
  },
  {
    type: 'LiteLiveblogPost',
    value: false,
  },
  {
    type: 'LiteRightHandSide',
    value: false,
  },
  {
    type: 'LiteBottomBanner',
    value: false,
  },
  {
    type: 'LiteNative',
    value: false,
  },
  {
    type: 'LiteVideoRoll',
    value: false,
  },
  {
    type: 'LiteTaboola',
    value: false,
  },
  {
    type: 'LiteOnScroll',
    value: false,
  },
  {
    type: 'LiteAdSlot3X3',
    value: false,
  },
  {
    type: 'LiteAdSlot2X3',
    value: false,
  },
]
