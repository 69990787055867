const lastSunday = (month: number, year: number) => {
  const d = new Date()
  const lastDayOfMonth = new Date(
    Date.UTC(year || d.getFullYear(), month + 1, 0),
  )
  const day = lastDayOfMonth.getDay()

  return new Date(
    Date.UTC(
      lastDayOfMonth.getFullYear(),
      lastDayOfMonth.getMonth(),
      lastDayOfMonth.getDate() - day,
    ),
  )
}

const isBST = (date: Date) => {
  const d = date || new Date()
  const starts = lastSunday(2, d.getFullYear())
  starts.setHours(1)
  const ends = lastSunday(9, d.getFullYear())
  ends.setHours(1)

  return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime()
}

export const getTimezone = (date: Date) => (isBST(date) ? 'BST' : 'GMT')
