import React from 'react'
import styled from 'styled-components'
import star from '~/icons/star'
import starHalfFilled from '~/icons/starHalfFilled'
import starEmpty from '~/icons/starEmpty'
import { FC } from '~/types'

const Root = styled.div`
  display: flex;
  margin: 16px 0;
  color: #9b9b9b;
  &.section {
    margin: 8px 0 0;
  }
`

const Star = styled.span`
  display: inline-flex;
  align-items: center;
  &.full svg path {
    fill: ${({ theme }) => theme.darkContrast};
  }
  &.half svg .circle {
    fill: ${({ theme }) => theme.darkContrast};
  }
`

const RatingPercentage = styled.span`
  margin-left: 8px;
  letter-spacing: 0.2px;
`

type StarRatingProps = {
  rating: number // converted to 'number' in Product.tsx
  withoutPercentage?: boolean
  className?: string
}

const StarRating: FC<StarRatingProps> = ({
  rating,
  withoutPercentage,
  className,
}) => {
  const toNearestHalf = (n: number) => Math.round(n * 2) / 2
  let roundedRating = toNearestHalf(rating)
  const stars = []

  for (let i = 0; i < 5; i++) {
    if (roundedRating >= 1) {
      stars.push(
        <Star
          key={i}
          className="full"
          dangerouslySetInnerHTML={{ __html: star }}
        />,
      )
      roundedRating -= 1
    } else if (roundedRating > 0) {
      stars.push(
        <Star
          key={i}
          className="half"
          dangerouslySetInnerHTML={{ __html: starHalfFilled }}
        />,
      )
      roundedRating = 0
    } else {
      stars.push(
        <Star
          key={i}
          className="blank"
          dangerouslySetInnerHTML={{ __html: starEmpty }}
        />,
      )
    }
  }
  return (
    <Root className={['star-rating', className].join(' ')}>
      {stars}
      {!withoutPercentage && <RatingPercentage>{rating}/5</RatingPercentage>}
    </Root>
  )
}

export default StarRating
