import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { getTimezone } from './get-timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export const getFormattedTime = (date: number, shortDate?: boolean) => {
  if (!date) {
    return null
  }
  return `${dayjs
    .unix(date)
    .tz('Europe/London')
    .format(
      `${shortDate ? 'HH:mm' : 'ddd, DD MMM, YYYY, HH:mm'}`,
    )} ${getTimezone(dayjs().toDate())}`
}
