import React from 'react'
import styled from 'styled-components'
import { FC, PartnerExtra } from '~/types'
import emailIcon from '~/icons/author/email'
import twitterIcon from '~/icons/author/twitter'
import facebookIcon from '~/icons/author/facebook'
import instagramIcon from '~/icons/author/instagram'
import linkedInIcon from '~/icons/linkedInIcon'

const Wrapper = styled.div`
  display: flex;
  margin: 10px 0;
`
const SocialLink = styled.a`
  display: flex;
  flex-basis: 24px;
  margin-right: 12px;
  align-items: center;
  text-decoration: none;

  & svg {
    fill: ${({ theme }) => theme.fontColorLight};
    width: 24px;
    height: 24px;
  }

  .linkedin-icon {
    fill: ${({ theme }) => theme.lightContrast};
    border-radius: 50%;
    background: ${({ theme }) => theme.fontColorLight};
  }
`

type SocialLinksProps = {
  extra: PartnerExtra
  className?: string
}

const SocialLinks: FC<SocialLinksProps> = ({ extra, className }) => {
  const { email, linkedIn, instagramUrl, facebook, x } = extra

  return (
    <Wrapper className={className}>
      {email && (
        <SocialLink
          data-partner-social-link="email"
          href={`mailto:${email}`}
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{ __html: emailIcon }}
        />
      )}
      {x && (
        <SocialLink
          data-partner-social-link="twitter"
          href={x}
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{ __html: twitterIcon }}
        />
      )}
      {facebook && (
        <SocialLink
          data-partner-social-link="facebook"
          href={facebook}
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{ __html: facebookIcon }}
        />
      )}
      {instagramUrl && (
        <SocialLink
          data-partner-social-link="instagram"
          href={instagramUrl}
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{ __html: instagramIcon }}
        />
      )}
      {linkedIn && (
        <SocialLink
          data-partner-social-link="linkedIn"
          href={linkedIn}
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{ __html: linkedInIcon }}
        />
      )}
    </Wrapper>
  )
}

export default SocialLinks
