import { Dispatch, SetStateAction } from 'react'
import { Post } from '~/types'
import { InitialData, VisiblePosts } from '../LiveblogHydrated'
import { fetchData } from './fetch-liveblog-posts'

export const getNewPosts = async (
  liveBlogId: number | undefined,
  initialData: InitialData,
  newVisiblePosts: VisiblePosts,
  setNewVisiblePosts: Dispatch<SetStateAction<VisiblePosts>>,
  setButtonVisible: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    // Fetch new posts data
    const dataNewPosts = await fetchData(liveBlogId)

    // Filter out already existing posts
    const newPostsToAdd = dataNewPosts.filter(
      (newPost: Post) =>
        !initialData.some((initialPost) => initialPost.id === newPost.data.id),
    )

    // Early return if no new posts
    if (newPostsToAdd.length === 0 && newVisiblePosts?.length === 0) {
      return
    }

    // Add 'new' key to the new posts
    const newPostsAdded = newPostsToAdd.map((post: Post) => ({
      ...post,
      new: true,
    }))

    // Update the state with new visible posts by concatenating the new posts with the existing ones
    setNewVisiblePosts((prevNewPosts) => [
      ...(prevNewPosts || []),
      ...newPostsAdded,
    ])

    setButtonVisible(true)
  } catch (error) {
    console.error(error)
  }
}
