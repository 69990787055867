import { Dispatch, SetStateAction } from 'react'
import { Post } from '~/types'
import { InitialData, VisiblePosts } from '../LiveblogHydrated'
import { fetchData } from './fetch-liveblog-posts'

export const getUpdatedPosts = async (
  liveBlogId: number | undefined,
  initialData: InitialData,
  visiblePosts: VisiblePosts,
  setInitialData: Dispatch<SetStateAction<InitialData>>,
  setVisiblePosts: Dispatch<SetStateAction<VisiblePosts>>,
) => {
  try {
    const dataUpdatedPosts = await fetchData(liveBlogId)
    const updatedPosts = dataUpdatedPosts.filter((post: Post) => {
      const initialPost = initialData.find((data) => data.id === post.data.id) // find posts that exist already
      return (
        initialPost && String(initialPost.changed) !== String(post.data.changed) // return posts that have changed
      )
    })
    // Create new array of updated visible posts
    // Map through data and find updated posts
    // Return true: updated post | false: original post
    const updatedVisiblePosts = visiblePosts?.map<
      Post & { updated?: boolean; new?: boolean }
    >((post: Post) => {
      const matchingUpdatedPost = updatedPosts.find(
        (updatedPost: Post) => updatedPost.data.id === post.data.id,
      )
      return matchingUpdatedPost
        ? { ...matchingUpdatedPost, updated: true }
        : { ...post, updated: false, new: false }
    })
    setVisiblePosts(updatedVisiblePosts)

    // Update initialData to only include existing posts that have changed
    const updatedInitialData = initialData.map((data) => {
      const matchingUpdatedPost = updatedPosts.find(
        (updatedPost: Post) => updatedPost.data.id === data.id,
      )
      return matchingUpdatedPost
        ? { ...data, changed: matchingUpdatedPost.data.changed }
        : data
    })
    setInitialData(updatedInitialData)
  } catch (error) {
    console.error(error)
  }
}
