export const hubSpotWhiteListedPath = [
  '/newsletter/premium',
  '/newsletter/premium/thank-you',
  '/newsletter/premium/cancel',
  '/newsletter/premium/cancel/confirmation',
  '/newsletter/premium/faq',
  '/newsletter/premium/upgrade',
  '/newsletter/premium/upgrade/confirmation',
  '/subscriptions',
  '/subscriptions/help',
  '/subscriptions/sports',
  '/subscriptions/renewal',
  '/subscription-renewal',
  '/campaign',
  '/subscriptions/campaign',
  '/promotion',
  '/subscriptions/promotion',
  '/sports-promotion',
  '/subscriptions/sports-promotion',
  '/new-year',
  '/subscriptions/new-year',
  '/6-nations',
  '/subscriptions/6-nations',
  '/national-puzzle-day',
  '/subscriptions/national-puzzle-day',
  '/valentines-day',
  '/subscriptions/valentines-day',
  '/mothering-sunday',
  '/subscriptions/mothering-sunday',
  '/fathers-day',
  '/subscriptions/fathers-day',
  '/summer-sports',
  '/subscriptions/summer-sports',
  '/students',
  '/subscriptions/students',
  '/xmas',
  '/subscriptions/xmas',
  '/events',
  '/subscriptions/events',
  '/halloween',
  '/subscriptions/halloween',
  '/easter',
  '/subscriptions/easter',
  '/corporate',
  '/subscriptions/corporate',
  '/group',
  '/subscriptions/group',
  '/about',
  '/how-to-disable-adblocker',
  '/what-is-sso',
  '/subscriptions/gift-redemption',
  '/subscriptions/sports/gift-redemption',
  '/subscriptions/competition',
  '/subscriptions/spring-sale',
  '/subscriptions/summer-sale',
  '/subscriptions/autumn-sale',
  '/subscriptions/winter-sale',
]
