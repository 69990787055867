export const JSON_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

/**
 * Deprecated, please use the one below cacheControls(), more readable
 */
export const cacheControlHeader =
  (
    browserCache = 600,
    cdnCache = 86400,
    staleWhileRevalidate = null,
    staleIfError = null,
  ) =>
  (res) => {
    if (res.headersSent) return
    let cacheControlValue = `public, max-age=${browserCache}, s-maxage=${cdnCache}`
    if (staleWhileRevalidate) {
      cacheControlValue += `, stale-while-revalidate=${staleWhileRevalidate}`
    }
    if (staleIfError) {
      cacheControlValue += `, stale-if-error=${staleIfError}`
    }
    res.set('Cache-Control', cacheControlValue)
  }

export const baseCache = {
  'stale-if-error': 604800,
  public: '',
  'stale-while-revalidate': 3600, // 1 hour
}

export const cacheControls =
  (caches = {}) =>
  (res) => {
    Object.entries(caches).forEach(([key, values]) => {
      if (res.headersSent) return
      res.header(
        key,
        Object.entries(values)
          .map(([k, v]) => {
            if (typeof v === 'string' && v === '') {
              return k
            }
            return `${k}=${v}`
          })
          .join(', '),
      )
    })
  }

export const randomString = (length = 8) =>
  Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
    .toString(36)
    .slice(1)

export const cacheTagHeader =
  (res) =>
  (pageType, pageId = '', cacheKeys = []) => {
    const { domain } = res.locals.configuration
    let keys = res.get('Cache-Tag') // start with the flow-core cache keys

    if (keys === undefined) {
      keys = `${domain} ${pageType}_${pageId}`
    }

    if (Array.isArray(cacheKeys)) {
      keys += ` ${cacheKeys.join(' ')}`
    }

    try {
      if (process.env.CURRENT_RELEASE_TAG) {
        keys += ` ${process.env.CURRENT_RELEASE_TAG}`
        keys += ` ${domain}_${process.env.CURRENT_RELEASE_TAG}`
        keys += ` ${process.env.CURRENT_RELEASE_TAG}_${pageType}`
        keys += ` ${domain}_${process.env.CURRENT_RELEASE_TAG}_${pageType}`
      }
      res.set('Cache-Tag', keys.split(' ').filter(Boolean).join(','))
    } catch (e) {
      // noop
    }
  }
