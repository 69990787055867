import React from 'react'
import styled from 'styled-components'
import flourishIcon from '~/icons/flourishIcon'

const Iframe = ({ url }: { url: string }) => (
  <amp-iframe
    src={url}
    sandbox="allow-scripts allow-same-origin"
    layout="responsive"
  />
)

const StyledIframe = styled(Iframe)`
  height: 600px;
  width: 600px;
  frameborder: 0;
`

const Flourish = styled(({ className, data: { id } }) => {
  if (!/embed/.test(id)) {
    id = id + '/embed'
  }
  const url = 'https://public.flourish.studio/' + id
  return (
    <div className={className}>
      <StyledIframe url={url} />
      <span
        className="logo"
        dangerouslySetInnerHTML={{ __html: flourishIcon }}
      />
    </div>
  )
})`
  clear: both;
  margin-bottom: 20px;
  .logo {
    margin: 20px 0;
  }
`

export default Flourish
