import React from 'react'
import styled from 'styled-components'

import { FC } from '~/types'

type TikTokProps = {
  data: {
    url: string
  }
}

const Wrapper = styled.div`
  clear: both;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
`

const Container = styled.div`
  position: relative;
  min-width: 325px;
  height: 0;
  padding-bottom: 56.25%;
  // for a 16:9 aspect ratio
`

const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
`

const TikTok: FC<TikTokProps> = ({ data: { url } }) => {
  const videoId = url.replace(/.*\/(\d+)\D*.*/, '$1')

  if (!videoId) {
    return null
  }

  return (
    <Wrapper>
      <Container>
        <StyledIframe
          aria-label="TikTok Embed"
          title="TikTok Embed"
          src={`https://www.tiktok.com/embed/v3/${videoId}?lang=en-US`}
          loading="lazy"
        />
      </Container>
    </Wrapper>
  )
}

export default TikTok
