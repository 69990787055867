import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import JPThemeProvider from '~/services/JPThemeProvider'
import { FC } from '~/types'

const ThemeProvider: FC<any> = ({ children }) => {
  return (
    <StyledThemeProvider theme={new JPThemeProvider().get()}>
      {children}
    </StyledThemeProvider>
  )
}

export default ThemeProvider
