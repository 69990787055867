export const imgFallback = () => {
  const hiddenClass = 'responsive-img-hidden'

  const $imgWrappers = document.querySelectorAll('.responsive-img')

  $imgWrappers.forEach(($imgWrapper) => {
    const $img = $imgWrapper.querySelector('img')
    const $fallbackWrapper = $imgWrapper.querySelector(
      '.responsive-img-fallback',
    )

    if (!$fallbackWrapper) return

    const eventOptions = {
      once: true,
      passive: true,
    }

    $img?.addEventListener(
      'error',
      () => {
        $img.classList.add(hiddenClass)
        $fallbackWrapper.classList.remove(hiddenClass)
      },
      eventOptions,
    )

    $img?.addEventListener(
      'load',
      () => {
        $fallbackWrapper.classList.add(hiddenClass)
      },
      eventOptions,
    )
  })
}
