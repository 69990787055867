export const handleClickTracking = (
  event: string,
  titleShort: string,
  url: string,
  index: number,
) => {
  const data = {
    event,
    tickerPosition: index,
    headline: titleShort,
    clickUrl: url,
  }

  window.dataLayer?.push(data)
}
