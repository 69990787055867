import { ArticleData, LiveblogData } from '~/types'

const findPageQueryNumber = (
  liveblogPaging: LiveblogData['paging'],
  postId: number,
) => {
  const index = liveblogPaging?.postsIds.indexOf(postId) || 0

  // Calculate the page number based on the index divided by max posts per page
  const pageNumber = Math.floor(index / 10) + 1
  return pageNumber
}

const injectPageQueryIntoHref = (
  keypoints: string,
  liveblogPaging: LiveblogData['paging'],
  pageNumber: number,
) => {
  // Check for links
  if (!keypoints.includes('<a')) {
    return keypoints
  }
  // Inject '?page='
  const keyPointsWithPageQuery = keypoints.replace(
    /href="#post-(\d+)"/g,
    (match, postId) => {
      const postPageNumber = findPageQueryNumber(liveblogPaging, postId)
      // Don't add query if on current page
      if (postPageNumber !== pageNumber) {
        return `href="?page=${postPageNumber}#post-${postId}"`
      }

      return match
    },
  )

  return keyPointsWithPageQuery
}

/**
 * The function `keypointsDataSources` extracts key points data from an article object, prioritising
 * local level keypoints and falling back to global level keypoints. This is necessary as the backend
 * cannot be changed to provide a single source of truth for keypoints.
 * @param {ArticleData} article
 * @returns The function `keypointsDataSources` returns an object with a property `keypoints`.
 */
const keypointsDataSources = (article: ArticleData) => {
  const keyPointsString = article?.liveblog?.data?.keyPoints
  // 1. Covert API string to object in order to access 'markup' / local level keypoints
  const keyPointsArray = JSON.parse(keyPointsString)
  const { markup } = keyPointsArray?.[0]?.data ?? {}
  // 2. Check for global level keypoints
  const { extra } = article ?? {}
  const { liveblogKeypoints } = extra ?? {}

  const data = {
    keypoints: markup || liveblogKeypoints || '',
  }
  return data
}

/**
 * The function `countKeyPoints` takes an `article` object as input and returns the number of `<li>`
 * elements in the `keypoints` property of the `article` object.
 * @param {ArticleData} article
 * @returns the number of `<li>` elements found in the `keypoints` property of the `article` object. If
 * no `<li>` elements are found, it will return 0.
 */
export const countKeyPoints = (article: ArticleData) => {
  const { keypoints } = keypointsDataSources(article)
  return keypoints?.match(/<li>/g)?.length ?? 0
}

export const getKeypoints = (article: ArticleData, pageNumber: number) => {
  const liveblogPaging = article.liveblog?.paging
  const { keypoints } = keypointsDataSources(article)
  if (countKeyPoints(article) === 0) {
    return null
  }
  return injectPageQueryIntoHref(keypoints, liveblogPaging, pageNumber)
}
