const externalLinkIconWhite = `
  <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="15" height="14" viewBox="0 0 22 22">
    <path d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-7h-2v7H5V5h7V3H5zm9 0v2h3.586l-9.293 9.293 1.414 1.414L19 6.414V10h2V3h-7z"/>
  </svg>
`

const externalLinkIconBlack = `
  <svg xmlns="http://www.w3.org/2000/svg" fill="#000" width="15" height="14" viewBox="0 0 22 22">
    <path d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-7h-2v7H5V5h7V3H5zm9 0v2h3.586l-9.293 9.293 1.414 1.414L19 6.414V10h2V3h-7z"/>
  </svg>
`

export const encodedExternalLinkIconBlack = `data:image/svg+xml;base64,${btoa(
  externalLinkIconBlack,
)}`

export const encodedExternalLinkIconWhite = `data:image/svg+xml;base64,${btoa(
  externalLinkIconWhite,
)}`
