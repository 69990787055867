import React from 'react'
import { ArticleData, FC } from '~/types'
import { ListItem } from './ListItem'
import { TickerListWrapper } from './styles'

type TickerListProps = {
  breakingNews: any
  isAnimating: boolean
  animationDuration: number
  newsTickerRef?: React.RefObject<HTMLDivElement>
  trackingTerm: string
}

export const TickerList: FC<TickerListProps> = ({
  breakingNews,
  isAnimating,
  animationDuration,
  newsTickerRef,
  trackingTerm,
}) => {
  return (
    <TickerListWrapper
      className={`ticker${isAnimating ? ' animate' : ''}`}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{ '--animation-duration': `${animationDuration}s` }}
      {...(newsTickerRef && { ref: newsTickerRef })}
    >
      {breakingNews.map((article: ArticleData, index: number) => {
        const { id, title, titleShort, path } = article
        return (
          <ListItem
            key={id}
            title={title}
            titleShort={titleShort}
            path={path}
            index={index}
            trackingTerm={trackingTerm}
          />
        )
      })}
    </TickerListWrapper>
  )
}
