import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { FC } from '~/types'

const Wrapper = styled.a`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  letter-spacing: -0.15px;

  font-weight: bold;
  height: 30px;
  width: fit-content;
  margin: auto;
  text-decoration: none;

  @media ${device.laptop} {
    font-size: 12px;
    letter-spacing: -0.18px;

    &.weather-widget-header {
      visibility: hidden;
    }
  }

  .scroll & {
    @media ${device.maxLaptop} {
      display: none;
    }
  }
`

const Hour = styled.span`
  color: #919090;
`

const WeatherIcon = styled.span`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 30px;
  width: 30px;
  background-image: url('/img/weather/sunny.svg');

  &.icon-01 {
    background-image: url('/img/weather/sunny.svg');
  }
  &.icon-02 {
    background-image: url('/img/weather/cloudy.svg');
  }
  &.icon-04,
  &.icon-03 {
    background-image: url('/img/weather/scatteredCloudy.svg');
  }
  &.icon-09 {
    background-image: url('/img/weather/showerRain.svg');
  }
  &.icon-10 {
    background-image: url('/img/weather/rain.svg');
  }
  &.icon-11 {
    background-image: url('/img/weather/thunderstorm.svg');
  }
  &.icon-13 {
    background-image: url('/img/weather/snow.svg');
  }
  &.icon-50 {
    background-image: url('/img/weather/mist.svg');
  }
`

const Temperature = styled.span`
  color: ${({ theme }) => theme.darkestContrast};
`

type WeatherWidgetProps = {
  className: string
}

type WeatherData = {
  items: { hour: string; weatherIcon: string; temperature: string }[]
}

export const WeatherWidget: FC<WeatherWidgetProps> = ({ className }) => {
  const [weatherData, setWeatherData] = useState<WeatherData>({ items: [] })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getWeatherData = async () => {
      try {
        const response = await fetch('/api/weather')
        const data = await response.json()
        setWeatherData(data)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getWeatherData()
  }, [])

  const { items } = weatherData || { items: [] }
  const { hour, weatherIcon, temperature } = (items && items[0]) || {
    hour: '',
    weatherIcon: '',
    temperature: '',
  }

  return (
    <Wrapper className={className} href="/news/weather">
      {!isLoading && (
        <>
          <Hour className="hour">{hour}</Hour>
          <WeatherIcon className={weatherIcon} />
          <Temperature>{temperature}°C</Temperature>
        </>
      )}
    </Wrapper>
  )
}
