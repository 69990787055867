/*global*/
import curryN from 'ramda/src/curryN'
import { isLogin } from '~/js/piano'
import { METER_EXPIRED } from './constant'

/**
 * Returns true if the URL contains valid Google Article Access (GAA) params.
 * TODO: Link to a public document describing GAA params.
 * @return {boolean}
 */
function isGaa() {
  // Validate GAA params.
  const params = getQueryParams()
  if (!params.gaa_at) {
    return false
  }
  if (!params.gaa_n) {
    console.error('SwG Entitlements: The `gaa_n` URL param is missing.')
    return false
  }
  if (!params.gaa_sig) {
    console.error('SwG Entitlements: The `gaa_sig` URL param is missing.')
    return false
  }
  if (!params.gaa_ts) {
    console.error('SwG Entitlements: The `gaa_ts` URL param is missing.')
    return false
  }
  if (parseInt(params.gaa_ts, 16) < Date.now() / 1000) {
    console.error(
      'SwG Entitlements: The `gaa_ts` URL param should contain a hex string timestamp which points to the future.',
    )
    return false
  }

  // Validate referrer.
  // NOTE: This regex was copied from SwG's AMP extension. https://github.com/ampproject/amphtml/blob/c23bf281f817a2ee5df73f6fd45e9f4b71bb68b6/extensions/amp-subscriptions-google/0.1/amp-subscriptions-google.js#L56
  const GOOGLE_DOMAIN_RE = /(^|\.)google\.(com?|[a-z]{2}|com?\.[a-z]{2}|cat)$/
  const referrer = getAnchorFromUrl(document.referrer)
  if (
    referrer.protocol !== 'https:' ||
    !GOOGLE_DOMAIN_RE.test(referrer.hostname)
  ) {
    // Real publications should bail if this referrer check fails.
    // This script is only logging a warning for metering demo purposes.
    console.warn(
      `SwG Entitlements: This page's referrer ("${referrer.origin}") can't grant Google Article Access. Real publications should bail if this referrer check fails.`,
    )
  }

  return true
}

/**
 * Returns anchor element from a given URL.
 * @return {HTMLAnchorElement}
 */
function getAnchorFromUrl(url) {
  const a = document.createElement('a')
  a.href = url
  return a
}

/**
 * Returns query params from URL.
 * @return {!Object<string, string>}
 */
function getQueryParams() {
  const queryParams = {}
  location.search
    .substring(1)
    .split('&')
    .forEach((pair) => {
      const parts = pair.split('=')
      queryParams[parts[0]] = parts[1]
    })
  return queryParams
}

const formatUserState = (userData) => ({
  metering: {
    state: {
      id: userData.ppid,
      standardAttributes: {
        registered_user: {
          timestamp: userData.timestamp,
        },
      },
    },
  },
})

const anonymousUserHasAccess = (meter) => meter !== METER_EXPIRED

const isUserRegistered = () => isLogin()

const logger = curryN(2, (type = 'log', ...rest) => {
  if (!console || !console[type]) {
    return
  }
  console[type].apply(console, ['g-e-a >> ', ...rest])
})

const log = logger('log')

export {
  getQueryParams,
  isGaa,
  logger,
  formatUserState,
  isUserRegistered,
  anonymousUserHasAccess,
  log,
}
