export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const getCookie = (cname) => {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const gotoHomepage = () =>
  (location.href = `/?r=${Math.floor(Math.random() * 1000)}`)

const feeds = document.querySelectorAll('.feed-list li')
const pubs = document.querySelectorAll('.pub-list li')
const clear = document.getElementById('clear-setting')
const pianoToggle = document.getElementById('piano')

if (clear) {
  clear.addEventListener('click', () => {
    setCookie('feed', '')
    setCookie('publication', '')
    setCookie('pianoDisabled', '')
  })
}

if (feeds) {
  feeds.forEach((element) => {
    element.addEventListener('click', (e) => {
      setCookie('feed', e.target.getAttribute('data-value'))
      gotoHomepage()
    })
  })
}

if (pubs) {
  pubs.forEach((element) => {
    element.addEventListener('click', (e) => {
      setCookie('publication', e.target.getAttribute('data-value'))
      gotoHomepage()
    })
  })
}

if (pianoToggle) {
  if (getCookie('pianoDisabled') === 'true') {
    pianoToggle.setAttribute('checked', '')
  }
  pianoToggle.addEventListener('click', (e) => {
    setCookie('pianoDisabled', e.target.checked)
  })
}
