import React from 'react'
import styled from 'styled-components'
import { encodedExternalLinkIconBlack } from '~/icons/externalLinkIcon'
import { modifyMarkupContent } from './Markup.utils'

// TODO - get API passing the title markup into this component

const ListBulleted = styled(
  ({ className, data: { title, markup }, isSponsored, currentDomain }) => {
    return (
      <div className={className}>
        <excessremovablewrapper dangerouslySetInnerHTML={{ __html: title }} />
        <excessremovablewrapper
          dangerouslySetInnerHTML={{
            __html: modifyMarkupContent(markup, isSponsored, currentDomain),
          }}
        />
      </div>
    )
  },
)`
  ul {
    padding-left: 0;
    padding-bottom: 10px;
    list-style: none;
    line-height: 28px;
  }
  li,
  p {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    display: list-item;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 8px;

    // Diamond bullet list item
    ${({ theme }) => {
      if (theme.name === 'The Scotsman') {
        return `
          position: relative;
          padding-left: 20px;
          &:after {
            content: '';
            background: ${theme.primaryColor};
            height: 8px;
            width: 8px;
            display: block;
            position: absolute;
            transform: rotate(45deg);
            top: 9px;
            left: 2px;
          };
        `
      }

      return `
        margin-left: 20px;
        list-style-type: disc;
      `
    }}

    &:last-child {
      margin-bottom: 0;
    }
    &::marker {
      font-size: 20px;
      color: ${({ theme }) => theme.primaryColor};
    }
  }
  h2 {
    font-size: 20px;
  }

  a {
    color: ${({ theme }) => theme.fontColor};
    font-weight: 700;
    text-decoration: none;
    position: relative;
  }

  a::before {
    content: ''; /* pseudo-element for the underline */
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #333333;
  }

  a[rel*='nofollow']:after {
    display: none;
  }

  .external-link[rel*='nofollow']:after,
  .external-link[rel*='sponsored']:after,
  .external-link[rel*='ugc']:after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 14px;
    background: url(${encodedExternalLinkIconBlack}) 50% 50% no-repeat;
    background-size: 100%;
    margin-left: 2px;
  }
`

export default ListBulleted
