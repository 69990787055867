import styled from 'styled-components'
import { increasedWidthSites } from '~/config/JPIMConfig'
import { device } from '~/config/theme/JPIMStyles'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.lightContrast};

  @media ${device.tablet} {
    background-color: ${({ theme }) => theme.breakingNewsYellow};
    color: ${({ theme }) => theme.lightContrast};
  }
`

export const Inner = styled.div<{
  domain: string
}>`
  opacity: 1 !important;
  display: flex !important;
  margin: auto;
  border-bottom: 1px solid ${({ theme }) => theme.adContainerBottomBorder};
  gap: 8px;
  padding-left: 8px;
  flex-direction: row;
  height: 48px;
  width: 100%;
  max-width: 1000px;

  @keyframes tickerAnimation {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .animate {
    .ticker:nth-child(2) {
      display: flex;
    }
  }

  @media ${device.laptopM} {
    max-width: ${({ domain }) =>
      increasedWidthSites.includes(domain) ? '1300px' : '1000px'};
  }
`

export const Breaking = styled.span`
  font-size: 12px;
  padding: 4px 8px;
  align-self: center;
  font-family: ${({ theme }) => theme.fontFamily.openSansSemiBold};
  color: ${({ theme }) => theme.darkestContrast};
  background-color: ${({ theme }) => theme.breakingNewsYellow};
  position: relative;
  z-index: 1;

  @media ${device.tablet} {
    color: ${({ theme }) => theme.lightContrast};
    background-color: ${({ theme }) => theme.darkestContrast};
  }
`

export const TickerWrapper = styled.div<{
  domain: string
}>`
  display: flex;
  margin-left: 80px;
  position: absolute;
  overflow: hidden;
  width: 910px;
  max-width: 100%;

  .ticker {
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;
    line-height: 48px;
    animation: ticker linear infinite both;
    will-change: transform;
    overflow: initial;
    flex-shrink: 0;

    &.animate {
      animation: tickerAnimation var(--animation-duration) linear infinite;

      @keyframes tickerAnimation {
        0% {
          transform: translate3d(0, 0, 0);
        }
        100% {
          transform: translate3d(-100%, 0, 0);
        }
      }

      &:nth-child(2) {
        display: flex;
      }
    }

    &:nth-child(2) {
      display: none;
    }
  }

  @media ${device.laptop} {
    margin-left: 90px;
  }

  @media ${device.laptopM} {
    width: ${({ domain }) =>
      increasedWidthSites.includes(domain) ? '1210px' : '910px'};
  }
`

export const TickerListWrapper = styled.ul`
  margin: 0;
  padding: 0;
`

export const SlideText = styled.li`
  display: flex;
  align-items: center;

  a {
    font-size: 14px;
    color: ${({ theme }) => theme.fontColor};
    font-family: ${({ theme }) => theme.fontFamily.openSansSemiBold};
    text-decoration: none;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 7.5px;
    background-color: ${({ theme }) => theme.darkestContrast};
    margin: 0 12px;
  }
`
