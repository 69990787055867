import React from 'react'
import styled from 'styled-components'

const StyledImage = styled.img<{
  width: string
  height: string
  layout?: string
  className?: string
  srcset?: string
}>`
  display: block;
  margin: auto;
  object-fit: cover;

  width: ${({ width }) => (width === '1' ? '100%' : `${width}px`)};
  height: ${({ height, layout }) =>
    layout === 'responsive' ? 'auto' : height === '1' ? '100%' : `${height}px`};
`

type ImageProps = {
  id?: string
  src?: string
  alt?: string
  width?: string
  height?: string
  layout?: string
  className?: string
  srcset?: string
}

const Image = ({
  id,
  src,
  alt = 'Image',
  width = '1',
  height = '1',
  layout,
  className = '',
  srcset = '',
}: ImageProps) => {
  if (!src) return null

  return (
    <StyledImage
      id={id}
      src={src}
      alt={alt}
      width={width}
      height={height}
      layout={layout}
      className={className}
      srcset={srcset}
    />
  )
}

export default Image
