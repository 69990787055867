const starHalfFilled = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 width="16px" height="15.2px" viewBox="0 0 16 15.2" enable-background="new 0 0 16 15.2" xml:space="preserve">
<path fill="#222222" d="M16,5.8L10.4,5L8,0L5.6,5L0,5.8l4,3.9l-1,5.5L8,12.6l4.9,2.6l-1-5.5L16,5.8z M8,11.1v-8l1.6,3.2l3.5,0.5
l-2.6,2.5l0.6,3.5L8,11.1z"/>
</svg>

`

export default starHalfFilled
