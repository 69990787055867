import { log } from './log'

type Timers = {
  [key: string]: number
}

const timers: Timers = {}

export const start = (name: string) => {
  const startTime = performance.now()
  timers[name] = startTime
  log(`starting ${name} load @ ${startTime.toFixed(2)}`)
}

export const end = (name: string) => {
  const endTime = performance.now()
  const startTime = timers[name]
  if (startTime !== undefined) {
    log(
      `finished ${name} load @ ${endTime.toFixed(2)}, duration ${(
        endTime - startTime
      ).toFixed(2)}`,
    )
  }
  delete timers[name]
}

/**
 * Math.round
 */

// import { log } from './log'

// const timers = {}

// export const start = (name) => {
//   const startTime = performance.now()
//   timers[name] = startTime
//   log(`starting ${name} load @ ${Math.round(startTime)}`)
// }

// export const end = (name) => {
//   const endTime = performance.now()
//   log(
//     `finished ${name} load @ ${Math.round(endTime)}, duration ${Math.round(
//       endTime - timers[name],
//     )}`,
//   )
//   delete timers[name]
// }
