import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { refreshIcon } from '~/icons/refresh'
import { fetchData } from '~/js/components/LiveblogHydrated/util/fetch-liveblog-posts'
import {
  countKeyPoints,
  getKeypoints,
} from '~/js/components/LiveblogHydrated/util/get-keypoints'
import { getNewPosts } from '~/js/components/LiveblogHydrated/util/get-new-posts'
import { getUpdatedPosts } from '~/js/components/LiveblogHydrated/util/get-updated-posts'
import { showNewPosts } from '~/js/components/LiveblogHydrated/util/show-new-posts'
import { updateVisiblePosts } from '~/js/components/LiveblogHydrated/util/update-visible-posts'
import Pagination from '~/library/Article/Pagination'
import JPThemeProvider from '~/services/JPThemeProvider'
import { AdConfigData, ArticleData, FC, LiveblogData, Post } from '~/types'
import {
  Button,
  KeyEventsOuterWrapper,
  LiveblogAnchor,
  RefreshIcon,
  Title,
  Wrapper,
} from './styles'
import { LiveblogRows } from './LiveblogRows'

export const LIVEBLOG_POSTS_PER_PAGE = 18
const DEFAULT_VISIBLE_HEIGHT: number = 100
const BUTTON_OFFSET: number = -184
const POSTS_REFRESH_TIMEOUT: number = 30000

export type InitialData = { id: number; changed: string }[]
export type VisiblePosts =
  | (Post & { updated?: boolean; new?: boolean })[]
  | undefined

type LiveblogHydratedProps = {
  className?: string
  adConfig: AdConfigData
  adTargeting: boolean
  article: ArticleData
  configuration: {
    adLiteEnabled?: boolean
    domain: string
    facebookAppId: string
    isAdFree: boolean
    isSubscriber: boolean
  }
  liveBlogId?: number
  liveblogs: LiveblogData
  url?: string
}

export const LiveblogHydrated: FC<LiveblogHydratedProps> = ({
  className,
  adConfig,
  adTargeting,
  article,
  configuration,
  liveBlogId,
  liveblogs: { data: containerData, posts, paging },
  url,
}) => {
  const [initialData, setInitialData] = useState<InitialData>([])
  const [visiblePosts, setVisiblePosts] = useState<VisiblePosts>(posts)
  const [newVisiblePosts, setNewVisiblePosts] = useState<VisiblePosts>([])
  const liveBlogRef = useRef<HTMLDivElement>(null)
  const [shouldUpdateVisiblePosts, setShouldUpdateVisiblePosts] =
    useState(false)
  const [buttonVisible, setButtonVisible] = useState(false)

  // Keypoints
  const [showText, setShowText] = useState(false)
  const moreLessRef = useRef<HTMLDivElement>(null)
  const [keypointsHeight, setKeypointsHeight] = useState<number | undefined>(0)
  const [visibleKeypointsHeight, setVisibleKeypointsHeight] = useState(
    DEFAULT_VISIBLE_HEIGHT,
  )
  const hasKeypoints = countKeyPoints(article) > 0
  const hasMoreThanFiveKeypoints = countKeyPoints(article) > 5

  const [pageNumber, setPageNumber] = useState(1)

  // Get page number for Key Points
  useEffect(() => {
    const queryString = window.location.search
    const capturePageNumber = queryString.match(/\?page=(\d+)/)

    if (capturePageNumber && capturePageNumber[1]) {
      const pageNumber = parseInt(capturePageNumber[1], 10)
      setPageNumber(pageNumber)
    }
  }, [])

  const calculateHeight = (): { height: string } => {
    return hasMoreThanFiveKeypoints
      ? showText
        ? { height: `${keypointsHeight}px` }
        : { height: `${visibleKeypointsHeight}px` }
      : { height: 'auto' }
  }

  const toggleTextVisibility = useCallback((): void => {
    setShowText((prevShowText) => !prevShowText)
  }, [showText])
  // Memorised to prevent a new instance being created on every render

  useEffect(() => {
    const getVisibleKeypointsHeight = () => {
      if (moreLessRef.current) {
        const ul = moreLessRef.current.querySelector('ul')
        const firstFiveItems = ul?.querySelectorAll('li:nth-child(-n+5)')
        // Get the offsetHeight of the first five <li>'s
        let elementsOffsetHeight = 0
        firstFiveItems?.forEach((li) => {
          const elementWithOffsetHeight = li as HTMLElement
          elementsOffsetHeight += elementWithOffsetHeight.offsetHeight + 8 // Add 8px for bottom margin
        })

        setKeypointsHeight(ul?.offsetHeight)
        setVisibleKeypointsHeight(elementsOffsetHeight)
      }
    }

    // Get initial height
    getVisibleKeypointsHeight()
    // Create a ResizeObserver to watch for changes in the container's dimensions
    const resizeObserver = new ResizeObserver(getVisibleKeypointsHeight)

    if (moreLessRef.current) {
      resizeObserver.observe(moreLessRef.current)
    }

    // Cleanup up observer
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  /* 1. Fetch some initial data from API.
  Map over to extract the `id` and `changed` properties which are later used
  to check for new /or updated posts */
  useEffect(() => {
    ;(async () => {
      try {
        const data = await fetchData(liveBlogId)
        const initialData = data.map((post: Post) => ({
          id: post.data.id,
          changed: post.data.changed,
        }))
        setInitialData(initialData)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [liveBlogId, visiblePosts])

  // 2. Check for new posts and updated posts every 10 seconds
  useEffect(() => {
    let interval: NodeJS.Timeout
    if (initialData.length !== 0) {
      // Need to wait until we have initial data
      interval = setInterval(() => {
        getNewPosts(
          // 3. Check for new posts and show update button
          liveBlogId,
          initialData,
          newVisiblePosts,
          setNewVisiblePosts,
          setButtonVisible,
        )
        getUpdatedPosts(
          // 4. Automatically update existing content
          liveBlogId,
          initialData,
          visiblePosts,
          setInitialData,
          setVisiblePosts,
        )
      }, POSTS_REFRESH_TIMEOUT)
    }
    return () => clearInterval(interval)
  }, [initialData])

  useEffect(() => {
    // first run only if true
    if (shouldUpdateVisiblePosts) {
      updateVisiblePosts(
        setVisiblePosts,
        newVisiblePosts,
        setNewVisiblePosts,
        setButtonVisible,
      )
    }
  }, [shouldUpdateVisiblePosts])

  const { domain } = configuration
  const theme = new JPThemeProvider(domain).get()

  const liveblogTitle = article?.liveblog?.data.name

  return (
    <ThemeProvider theme={{ ...theme, ...configuration }}>
      <Wrapper className={className} id="liveblog">
        {liveblogTitle && <Title>{liveblogTitle}</Title>}
        {hasKeypoints && (
          <KeyEventsOuterWrapper>
            <h3>Key Events</h3>
            <div
              ref={moreLessRef}
              style={calculateHeight()}
              className={[
                `events-container${showText ? ' show-text' : ''}`,
                !hasMoreThanFiveKeypoints ? 'auto' : '',
              ]
                .join(' ')
                .trimEnd()}
              dangerouslySetInnerHTML={{
                __html: getKeypoints(article, pageNumber) as
                  | string
                  | TrustedHTML,
              }}
            />
            {hasMoreThanFiveKeypoints && (
              <button onClick={toggleTextVisibility} className="more-button">
                Load {showText ? 'Less' : 'More'}
              </button>
            )}
          </KeyEventsOuterWrapper>
        )}

        {buttonVisible && (
          <Button
            onClick={() =>
              showNewPosts(
                pageNumber,
                setVisiblePosts,
                newVisiblePosts,
                setNewVisiblePosts,
                setButtonVisible,
                liveBlogRef,
                BUTTON_OFFSET,
                setShouldUpdateVisiblePosts,
              )
            }
          >
            Show new updates
            <RefreshIcon
              className="rotate"
              dangerouslySetInnerHTML={{ __html: refreshIcon }}
            />
          </Button>
        )}

        <div ref={liveBlogRef}>
          <LiveblogAnchor id="liveblog-anchor" />
          <LiveblogRows
            adConfig={adConfig}
            article={article}
            visiblePosts={visiblePosts}
            adTargeting={adTargeting}
            containerData={containerData}
            configuration={configuration}
            url={url}
          />
        </div>
        <Pagination
          path={article.path}
          offset={paging.offset}
          posts={paging.posts}
          totalPage={paging.totalPages}
          liveblog={true}
        />
      </Wrapper>
    </ThemeProvider>
  )
}
