import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { RelatedLink } from '~/types'

const ArticleRelated = styled(({ className, relatedLinks }) => (
  <ul className={className}>
    {relatedLinks &&
      relatedLinks
        .filter(
          (item: RelatedLink) =>
            item !== null && item.headline !== 'undefined' && item.url,
        )
        .map(
          ({ headline, url }: RelatedLink, index: number) =>
            index < 4 && (
              <li key={`related-${index}`}>
                <a href={url}>{headline}</a>
              </li>
            ),
        )}
  </ul>
))`
  border-left: none;
  margin: 20px 0 0;
  padding-left: 0;
  list-style: disc;
  position: relative;
  @media ${device.laptop} {
    margin: 30px 0 0;
  }
  li {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.fontColor};
    margin-left: 15px;
    @media ${device.laptop} {
      font-size: 15px;
      line-height: 19px;
    }
    span {
      color: ${({ theme }) => theme.fontColor};
      text-decoration-skip-ink: auto;
      text-decoration-offset: 3px;
      margin-right: 5px;
    }
  }
  a {
    color: ${({ theme }) => theme.fontColor};
  }
`

export default ArticleRelated
