const disqusIcon = `
  <svg class="article-comments-cta__icon fill-primary" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30.028px" height="30.022px" viewBox="0 0 30.028 30.022" enable-background="new 0 0 30.028 30.022" xml:space="preserve">
      <path d="M15.018,0C6.729,0,0,6.719,0,15.012c0,8.291,6.727,15.011,15.018,15.011s15.011-6.72,15.011-15.011
      C30.025,6.719,23.309,0,15.018,0z M22.086,19.032c0,0.359-0.289,0.646-0.645,0.646h-7.07l-1.844,2.104
      c-0.232,0.269-0.613,0.271-0.849,0l-1.812-2.104H8.581c-0.356,0-0.645-0.289-0.645-0.646v-8.348c0-0.359,0.288-0.648,0.646-0.648
      h12.855c0.358,0,0.645,0.291,0.645,0.648L22.086,19.032L22.086,19.032z"></path>
  </svg>
`

export default disqusIcon
