import { Dispatch, RefObject, SetStateAction } from 'react'
import { updateVisiblePosts } from '~/js/components/LiveblogHydrated/util/update-visible-posts'
import { VisiblePosts } from '../LiveblogHydrated'

export const scrollToFirstPost = (
  liveBlogRef: RefObject<HTMLDivElement>,
  BUTTON_OFFSET: number,
) => {
  if (liveBlogRef.current) {
    const element = liveBlogRef.current
    const position = element.getBoundingClientRect().top + window.pageYOffset
    window.scrollTo({
      top: position + BUTTON_OFFSET,
      behavior: 'smooth',
    })
  }
}

export const goToPageOne = () => {
  const urlWithQuery =
    window.location.origin +
    window.location.pathname +
    '?page=1#liveblog-anchor'
  window.location.href = urlWithQuery
}

export const showNewPosts = (
  pageNumber: number,
  setVisiblePosts: Dispatch<SetStateAction<VisiblePosts>>,
  newVisiblePosts: VisiblePosts,
  setNewVisiblePosts: Dispatch<SetStateAction<VisiblePosts>>,
  setButtonVisible: Dispatch<SetStateAction<boolean>>,
  liveBlogRef: RefObject<HTMLDivElement>,
  BUTTON_OFFSET: number,
  setShouldUpdateVisiblePosts: Dispatch<SetStateAction<boolean>>,
) => {
  if (pageNumber === 1) {
    scrollToFirstPost(liveBlogRef, BUTTON_OFFSET)
    updateVisiblePosts(
      setVisiblePosts,
      newVisiblePosts,
      setNewVisiblePosts,
      setButtonVisible,
    )
  } else {
    // if on page 2, 3, 4 etc.
    goToPageOne()

    // Listen for active history entry change / page navigation before updating posts
    window.addEventListener('popstate', () => setShouldUpdateVisiblePosts(true))

    return () => {
      window.removeEventListener('popstate', () =>
        setShouldUpdateVisiblePosts(true),
      )
    }
  }
}
