import React, { useEffect } from 'react'
import { FC } from '~/types'

type TaboolaProps = {
  mode: string
  containerId: string
  placement: string
}

const Taboola: FC<TaboolaProps> = ({ mode, containerId, placement }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window._taboola = window._taboola || []
      window._taboola.push({
        mode: mode,
        container: containerId,
        placement: placement,
        target_type: 'mix',
      })
    }
  }, [mode, containerId, placement])

  return <div id={containerId}></div>
}

export default Taboola
