/* globals JSGlobals */
import { getCookie } from './cookie'
import { axateSub } from './axate'
import { isLogin } from '~/js/piano'
import { isGaa } from './google/helper'
import { getSiteSections } from './adsHelper'
import { getAdSlotIncrementAppendix } from '~/providers/AdManager/utils/getAdSlotIncrement'
import {
  DESKTOP_ADS_BREAKPOINT,
  INSKIN_BREAKPOINT,
  LAPTOP_MEDIUM_BREAKPOINT,
  MAX_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '~/config/theme/JPIMStyles'
import {
  ADSLOT_PHOTO_BOTTOM,
  ADSLOT_PHOTO_MOBILE,
  ADSLOT_PHOTO_MPU1,
  ADSLOT_PHOTO_MPU2,
  ADSLOT_PHOTO_SIDEBAR,
  ADSLOT_PHOTO_TOP,
  getSlots,
  MULTI_AD_SIZES_DESKTOP,
} from '~/constants/ads'
import { getAdSlot, checkPPID } from './gpt.util'
import {
  widerArticleSites,
  longPagePhotoArticleSites,
  premiumArticleLayoutSites,
} from '~/config/JPIMConfig'
import { getPageId } from './getAdData'

export const valueTargettingConverter = (val) => {
  switch (typeof val) {
    case 'boolean':
      return val.toString()
    case 'undefined':
      return 'false'
    default:
      return val
  }
}

export const isTextArticle = (JSGlobals) => {
  if (
    JSGlobals &&
    JSGlobals.article &&
    JSGlobals.pageType !== 'photo article'
  ) {
    return true
  }
  return null
}

export const getCustomParams = (JSGlobals, options) =>
  Object.entries({
    test: JSGlobals && JSGlobals.adTargeting.toString(),
    sensitive: JSGlobals.sensitive,
    premium: JSGlobals.premium,
    siteSection:
      JSGlobals &&
      JSGlobals.article &&
      getSiteSections(JSGlobals.article.siteSections),
    topics: JSGlobals.article?.topics?.map((topic) => topic.name),
    infiniteScrollArticle: JSGlobals.isInfiniteScrollArticle,
    paragraphnum: isTextArticle(JSGlobals) && JSGlobals.paragraphCount, // need to remain lowercase for Google Analytics
    affiliateArticle: JSGlobals && JSGlobals.article?.isAffiliated,
    pageid: JSGlobals && JSGlobals.pageId,
    polarpreview: /polarpreview=preview/.test(document.location.search),
    video: JSGlobals && JSGlobals.article && JSGlobals.article.isVideoArticle,
    login: isLogin(),
    googleExtendedAccess: isGaa(),
    mantis: JSGlobals?.mantis?.ratings,
    mantis_context: JSGlobals?.mantis?.mantis_context,
    iab_context: JSGlobals?.mantis?.iab_context,
    ...options,
  }).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: valueTargettingConverter(value),
    }
  }, {})

export const setCustomTargetParam = (JSGlobals, options) => {
  const googletag = window.googletag || {}
  Object.entries(getCustomParams(JSGlobals, options)).forEach(
    ([key, value]) => googletag.pubads().setTargeting(key, value), // @todo - possibly to go later
  )
}

const gpt = (article, secondLevelDomainName) => {
  const {
    GPT_ADROW,
    GPT_ADSECTION,
    GPT_ARTICLEADSLOT_LITE,
    GPT_ARTICLEMPU,
    GPT_ARTICLEMPU_LITE,
    GPT_BILLBOARD,
    GPT_BLOG,
    GPT_BLOGMPU,
    GPT_BOTTOM,
    GPT_BOTTOMBANNER,
    GPT_BOTTOMBANNER_LITE,
    GPT_EXTRALB,
    GPT_LEADERBOARD,
    GPT_MPU,
    GPT_NATIVE,
    GPT_NATIVE_LITE,
    GPT_SEEDTAG,
    GPT_SEEDTAG_LITE,
    GPT_SIDEBARMPU,
    GPT_SIDEBARMPU_LITE,
    GPT_SIDEBARONSCROLL,
    GPT_TEADSIB1,
    GPT_TEADSIB2,
    GPT_TEADSIB_LITE,
    GPT_TOP,
    GPT_TOPBANNER,
    GPT_TOPBANNER_LITE,
    GPT_WALLPAPER,
    GPT_WALLPAPER_LITE,
  } = getSlots()

  return new Promise((resolve) => {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    const isMobile = windowWidth < TABLET_BREAKPOINT

    let subscriber = getCookie('ev_su', 'false')
    let subscriberResource = '/'
    let isAdFree = false
    if (JSGlobals.piano && JSGlobals.piano.subscription) {
      subscriber = getCookie('subscriber', 'false')
      subscriberResource = getCookie('sub_resource', '')
      isAdFree = subscriberResource === 'premium'
    }
    if (JSGlobals.domain === 'yorkshirepost.co.uk' && !subscriber) {
      subscriber = getCookie(axateSub, 'false')
      if (subscriber) {
        // axate has no resource white list
        subscriberResource = '/'
      }
    }
    let isSubscriber = subscriber === 'true'

    const isLoggedIn = getCookie('lgd_n', '')
    if (isLoggedIn && !isSubscriber) {
      subscriberResource = 'registered'
    }

    var googletag = window.googletag || {}
    googletag.cmd = googletag.cmd || []

    const { pathname } = window.location
    const path = pathname.split('/').filter((item) => item)
    const articleType = JSGlobals.articleType
    const pageType = JSGlobals.pageType
    const slot = getAdSlot()

    let topLevelSection = 'home'
    if (pathname.length > 1 && path.length > 0) {
      if (pageType !== 'category') path.pop()
      topLevelSection = path[0]
    }

    let wallpaper
    let seedtag
    let liteSeedtag
    let liteWallpaper
    let teadsib1
    let teadsib2
    let liteTeadsib
    let native
    let liteNative

    const sidebarPriorityAllocation = [1, 2, 5, 3, 4]
    const sidebarMPU = []

    const slotIncrementSelector = (() => {
      const appendix = getAdSlotIncrementAppendix()

      if (!appendix) {
        return ''
      }

      return `[id*=${appendix}]`
    })()

    document
      .querySelectorAll(`[id^=${GPT_SIDEBARMPU}]${slotIncrementSelector}`)
      .forEach((el) => sidebarMPU.push(el.id))

    const onscroll$ = []
    document
      .querySelectorAll(`[id^=${GPT_SIDEBARONSCROLL}]`)
      .forEach((el) => onscroll$.push(el.id))

    const liteSidebarMPU = []
    document
      .querySelectorAll(`[id^=${GPT_SIDEBARMPU_LITE}]${slotIncrementSelector}`)
      .forEach((el) => liteSidebarMPU.push(el.id))

    const articleMPU = []
    document
      .querySelectorAll(
        `[id^=${GPT_ARTICLEMPU}]${slotIncrementSelector}${
          isMobile ? '[id*=-mobile]' : ':not([id*=-mobile])'
        }`,
      )
      .forEach((el) => articleMPU.push(el.id))

    const articleLiveblogMPU = []
    document
      .querySelectorAll(
        `[id^=${GPT_BLOGMPU}]${slotIncrementSelector}${
          isMobile ? '[id*=-mobile]' : ':not([id*=-mobile])'
        }`,
      )
      .forEach((el) => articleLiveblogMPU.push(el.id))

    const liteArticleMPU = []
    document
      .querySelectorAll(
        `[id^=${GPT_ARTICLEMPU_LITE}]${slotIncrementSelector}${
          isMobile ? '[id*=-mobile]' : ':not([id*=-mobile])'
        }`,
      )
      .forEach((el) => liteArticleMPU.push(el.id))

    const sectionMPU = []
    document
      .querySelectorAll(`[id^=${GPT_MPU}]${slotIncrementSelector}`)
      .forEach((el) => sectionMPU.push(el.id))

    const leaderboard$ = []
    document
      .querySelectorAll(`[id^=${GPT_LEADERBOARD}]`)
      .forEach((el) => leaderboard$.push(el.id))

    const liteArticleAdSlot$ = []
    document
      .querySelectorAll(`[id^=${GPT_ARTICLEADSLOT_LITE}]`)
      .forEach((el) => liteArticleAdSlot$.push(el.id))

    const adRowSection$ = []
    document
      .querySelectorAll(`[id^=${GPT_ADROW}]`)
      .forEach((el) => adRowSection$.push(el.id))

    let mpus = []
    let mpuBlog = []
    let sectionMpus = []
    let leaderboards = []
    let liteArticleAdSlots = []
    let adRows = []
    let topBanner
    let liteTopBanner
    let bottomBanner
    let liteBottomBanner
    let onscroll = []
    let billboards = []
    let photoAds = []

    let topBannerSize
    let bottomBannerSize
    if (windowWidth >= DESKTOP_ADS_BREAKPOINT) {
      topBannerSize = [970, 250]
    } else if (windowWidth >= TABLET_BREAKPOINT) {
      topBannerSize = [728, 90]
    } else {
      topBannerSize = [320, 50]
    }

    if (windowWidth >= TABLET_BREAKPOINT) {
      bottomBannerSize = [728, 90]
    } else {
      bottomBannerSize = [320, 50]
    }

    const isLongPagePhotoArticleSite = longPagePhotoArticleSites.includes(
      JSGlobals.domain,
    )

    let leaderboardMultiMobileSize

    if (isLongPagePhotoArticleSite) {
      leaderboardMultiMobileSize =
        windowWidth > MAX_LAPTOP_BREAKPOINT
          ? MULTI_AD_SIZES_DESKTOP
          : windowWidth > TABLET_BREAKPOINT
            ? [
                [728, 90],
                [300, 250],
              ]
            : [
                [300, 250],
                [320, 50],
              ]
    } else {
      leaderboardMultiMobileSize =
        windowWidth >= TABLET_BREAKPOINT ? MULTI_AD_SIZES_DESKTOP : [320, 50]
    }

    const leaderboardSingleMobileSize =
      windowWidth >= TABLET_BREAKPOINT ? MULTI_AD_SIZES_DESKTOP : [320, 50]

    // polar can only target one id, so we set it based on device size
    if (document.querySelector('.polar-placeholder')) {
      if (windowWidth >= TABLET_BREAKPOINT) {
        document
          .querySelectorAll('.polar-placeholder.polar-desktop')
          .forEach((el) => {
            el.id = 'sponsored-teaser'
          })
      } else {
        document
          .querySelectorAll('.polar-placeholder.polar-mobile')
          .forEach((el) => {
            el.id = 'sponsored-teaser'
          })
      }
    }

    const teadSibSize = [2, 1]
    const wallPaperSize = [2, 2]
    const seedTagSize = [2, 3]
    const nativeSize = [1, 2]
    const adSlot3x3Size = [3, 3]
    const onscrollSize = [4, 4]

    const isPremiumArticleLayout =
      JSGlobals.premium && premiumArticleLayoutSites.includes(JSGlobals.domain)

    const billboardSize =
      isPremiumArticleLayout && windowWidth > TABLET_BREAKPOINT
        ? [
            [970, 250],
            [728, 90],
            [300, 250],
          ]
        : windowWidth > TABLET_BREAKPOINT
          ? [
              [970, 250],
              [728, 90],
            ]
          : [300, 250]

    const mpuSize = (i, isSidebar = false) =>
      i > 0 && isSidebar
        ? [
            [300, 600],
            [300, 250],
          ]
        : [300, 250]

    const articleBodyMpuSize = (i) => {
      if (windowWidth < TABLET_BREAKPOINT) {
        return [[300, 250]]
      }
      if (windowWidth <= MAX_LAPTOP_BREAKPOINT) {
        return [
          [728, 90],
          [300, 250],
        ]
      }
      if (windowWidth >= LAPTOP_MEDIUM_BREAKPOINT) {
        return [
          [970, 250],
          [728, 90],
          [300, 250],
        ]
      }
      return mpuSize(i)
    }

    const isInskin = windowWidth >= INSKIN_BREAKPOINT ? 'true' : 'false'

    const defineSlot = (
      id,
      size,
      pos,
      isInskin = 'false',
      customSlot = false,
    ) => {
      // push slot and pos into JSGlobals for assertive yield to pick up
      JSGlobals.slotTargeting = JSGlobals.slotTargeting || {}
      JSGlobals.slotTargeting[id] = { pos }

      return googletag
        .defineSlot(customSlot || slot, size, id) // this will eventually need to be revisited
        ?.setCollapseEmptyDiv(!0) // ?. guards against defineSlot returning null upon failing to create a slot
        .setTargeting('tile', id)
        .setTargeting('pos', pos)
        .setTargeting('rfs', 0)
        .setTargeting('fn_pageskin', isInskin)
    }

    googletag.cmd.push(function () {
      const deviceClass =
        windowWidth <= TABLET_BREAKPOINT
          ? ':not(.desktop-only)'
          : ':not(.mobile-only)'
      if (isSubscriber) {
        if (
          document.querySelector(`[id="${GPT_WALLPAPER_LITE}"]${deviceClass}`)
        ) {
          liteWallpaper = defineSlot(
            GPT_WALLPAPER_LITE,
            wallPaperSize,
            GPT_WALLPAPER,
            isInskin,
          )
        }
        if (
          document.querySelector(`[id="${GPT_SEEDTAG_LITE}"]${deviceClass}`)
        ) {
          liteSeedtag = defineSlot(
            GPT_SEEDTAG_LITE,
            seedTagSize,
            GPT_SEEDTAG_LITE,
          )
        }
        if (
          !isAdFree &&
          document.querySelector(`[id="${GPT_NATIVE_LITE}"]${deviceClass}`)
        ) {
          liteNative = defineSlot(GPT_NATIVE_LITE, nativeSize, GPT_NATIVE)
        }
        if (
          document.querySelector(`[id="${GPT_TEADSIB_LITE}"]${deviceClass}`)
        ) {
          liteTeadsib = defineSlot(GPT_TEADSIB_LITE, teadSibSize, GPT_TEADSIB1)
        }
        if (
          document.getElementById(GPT_BOTTOMBANNER_LITE) &&
          windowWidth <= TABLET_BREAKPOINT
        ) {
          liteBottomBanner = defineSlot(
            GPT_BOTTOMBANNER_LITE,
            bottomBannerSize,
            GPT_BOTTOM,
          )
        }
        if (
          document.querySelector(`[id="${GPT_TOPBANNER_LITE}"]${deviceClass}`)
        ) {
          liteTopBanner = defineSlot(
            GPT_TOPBANNER_LITE,
            topBannerSize,
            GPT_TOP,
            isInskin,
          )
        }
      } else {
        if (document.querySelector(`[id="${GPT_WALLPAPER}"]${deviceClass}`)) {
          wallpaper = defineSlot(
            GPT_WALLPAPER,
            wallPaperSize,
            GPT_WALLPAPER,
            isInskin,
          )
        }
        if (document.querySelector(`[id="${GPT_SEEDTAG}"]${deviceClass}`)) {
          seedtag = defineSlot(GPT_SEEDTAG, seedTagSize, GPT_SEEDTAG)
        }
        if (document.querySelector(`[id="${GPT_NATIVE}"]${deviceClass}`)) {
          native = defineSlot(GPT_NATIVE, nativeSize, GPT_NATIVE)
        }
        if (document.querySelector(`[id="${GPT_TEADSIB1}"]${deviceClass}`)) {
          teadsib1 = defineSlot(GPT_TEADSIB1, teadSibSize, GPT_TEADSIB1)
        }
        if (document.querySelector(`[id="${GPT_TEADSIB2}"]${deviceClass}`)) {
          teadsib2 = defineSlot(GPT_TEADSIB2, teadSibSize, GPT_TEADSIB2)
        }
        if (
          document.getElementById(GPT_BOTTOMBANNER) &&
          windowWidth <= TABLET_BREAKPOINT
        ) {
          bottomBanner = defineSlot(
            GPT_BOTTOMBANNER,
            bottomBannerSize,
            GPT_BOTTOM,
          )
        }
        if (document.querySelector(`[id="${GPT_TOPBANNER}"]${deviceClass}`)) {
          topBanner = defineSlot(
            GPT_TOPBANNER,
            topBannerSize,
            GPT_TOP,
            isInskin,
          )
        }
      }

      if (document.querySelector(`[id="${ADSLOT_PHOTO_TOP}"]`)) {
        photoAds.push(
          defineSlot(ADSLOT_PHOTO_TOP, topBannerSize, ADSLOT_PHOTO_TOP),
        )
      }
      if (document.querySelector(`[id="${ADSLOT_PHOTO_BOTTOM}"]`)) {
        photoAds.push(
          defineSlot(
            ADSLOT_PHOTO_BOTTOM,
            bottomBannerSize,
            ADSLOT_PHOTO_BOTTOM,
          ),
        )
      }
      if (document.querySelector(`[id="${ADSLOT_PHOTO_MPU1}"]${deviceClass}`)) {
        photoAds.push(
          defineSlot(ADSLOT_PHOTO_MPU1, [300, 250], ADSLOT_PHOTO_MPU1),
        )
      }
      if (document.querySelector(`[id="${ADSLOT_PHOTO_MPU2}"]${deviceClass}`)) {
        photoAds.push(
          defineSlot(ADSLOT_PHOTO_MPU2, [300, 250], ADSLOT_PHOTO_MPU2),
        )
      }
      if (
        document.querySelector(`[id="${ADSLOT_PHOTO_MOBILE}"]${deviceClass}`)
      ) {
        photoAds.push(
          defineSlot(ADSLOT_PHOTO_MOBILE, [300, 250], ADSLOT_PHOTO_MOBILE),
        )
      }
      if (
        document.querySelector(`[id="${ADSLOT_PHOTO_SIDEBAR}"]${deviceClass}`)
      ) {
        photoAds.push(
          defineSlot(
            ADSLOT_PHOTO_SIDEBAR,
            [
              [300, 600],
              [300, 250],
            ],
            ADSLOT_PHOTO_SIDEBAR,
          ),
        )
      }

      document
        .querySelectorAll(`[id^="${GPT_BILLBOARD}"]`)
        .forEach((el, index) => {
          billboards.push(
            defineSlot(el.id, billboardSize, `${GPT_ADSECTION}${index + 1}`),
          )
        })

      if (isPremiumArticleLayout && windowWidth > MAX_LAPTOP_BREAKPOINT) {
        liteArticleAdSlots = liteArticleAdSlot$.reduce((a, id, i) => {
          const position = i + 1
          a.push(
            defineSlot(id, billboardSize, `${GPT_ADSECTION}${position + 1}`),
          )
          return a
        }, [])
      } else if (
        isPremiumArticleLayout &&
        windowWidth <= MAX_LAPTOP_BREAKPOINT
      ) {
        liteArticleAdSlots = liteArticleAdSlot$.reduce((a, id, i) => {
          const position = i + 1
          a.push(defineSlot(id, [300, 250], `${GPT_ADSECTION}${position + 1}`))
          return a
        }, [])
      } else if (
        !JSGlobals.domain.includes(widerArticleSites) &&
        windowWidth <= MAX_LAPTOP_BREAKPOINT
      ) {
        const slots = isSubscriber ? [liteArticleMPU] : [articleMPU]
        mpus = [].concat(...slots).reduce((a, id, i) => {
          const position = i + 1
          a.push(defineSlot(id, mpuSize(position), `${GPT_MPU}${position}`))
          return a
        }, [])
      } else {
        const sidebarSize = isSubscriber
          ? liteSidebarMPU.length
          : sidebarMPU.length
        const slots = isSubscriber
          ? [liteSidebarMPU, liteArticleMPU]
          : [sidebarMPU, articleMPU]
        mpus = [].concat(...slots).reduce((a, id, i) => {
          let position = i + 1
          if (sidebarSize === 3 && sidebarPriorityAllocation.length > 0) {
            position = sidebarPriorityAllocation.shift()
          }
          const isSidebar = id.toLowerCase().includes('sidebar')
          const size =
            id.includes('article') &&
            JSGlobals.domain.includes(widerArticleSites)
              ? articleBodyMpuSize(position, id)
              : mpuSize(position, isSidebar)
          a.push(defineSlot(id, size, `${GPT_MPU}${position}`))
          return a
        }, [])
      }

      mpuBlog = articleLiveblogMPU.reduce((a, id, i) => {
        const position = i + 1
        a.push(defineSlot(id, [300, 250], `${GPT_BLOG}${position}`))
        return a
      }, [])

      sectionMpus = sectionMPU.reduce((a, id, i) => {
        const position = i + 1
        const size =
          /^mpuD/.test(id) && windowWidth > TABLET_BREAKPOINT
            ? [300, 600]
            : [300, 250]
        a.push(defineSlot(id, size, `${GPT_MPU}${position}`))
        return a
      }, [])

      leaderboards = leaderboard$.reduce((a, id, i) => {
        const position = i + 1
        let leaderboardSize
        if (isLongPagePhotoArticleSite && (position === 2 || position === 5)) {
          leaderboardSize = leaderboardSingleMobileSize
        } else {
          leaderboardSize = leaderboardMultiMobileSize
        }
        a.push(defineSlot(id, leaderboardSize, `${GPT_EXTRALB}${position}`))
        return a
      }, [])

      adRows = adRowSection$.reduce((a, id) => {
        a.push(defineSlot(id, adSlot3x3Size, id))
        return a
      }, [])

      if (windowWidth > MAX_LAPTOP_BREAKPOINT) {
        onscroll = onscroll$.reduce((a, id) => {
          a.push(defineSlot(id, onscrollSize, id))
          return a
        }, [])
      }

      checkPPID()
    })

    const customTargetingParams = {
      articletype: articleType,
      deviceType: windowWidth >= DESKTOP_ADS_BREAKPOINT ? 'desktop' : 'mobile',
      pagetype: pageType,
      section: topLevelSection,
      subscriber: subscriberResource,
    }

    if (article) {
      customTargetingParams.topics = article.topics?.map((topic) => topic.name)
      customTargetingParams.sensitive = article.sensitive
      customTargetingParams.premium = article.premium
      customTargetingParams.affiliateArticle = article.isAffiliated
      customTargetingParams.pageId = getPageId(article)
    }

    googletag.cmd.push(function () {
      // set targeting here instead of the slot to optimise the ad's url size
      setCustomTargetParam(JSGlobals, customTargetingParams)

      if (window.apDataKeyValues) {
        for (var key in window.apDataKeyValues) {
          if (
            Object.prototype.hasOwnProperty.call(window.apDataKeyValues, key)
          ) {
            googletag.pubads().setTargeting(key, window.apDataKeyValues[key])
          }
        }
        // Capture Audience Project Values in dataLayer
        const audienceProjectValues = {
          audienceProjectValues: { ...window.apDataKeyValues },
        }
        // Ensure the Audience Project Values are always in the third position
        window.dataLayer.splice(3, 0, audienceProjectValues)
      }

      const filteredSlots = (() => {
        if (isSubscriber) {
          return [
            liteWallpaper,
            liteSeedtag,
            liteNative,
            liteTeadsib,
            liteTopBanner,
            ...mpus,
            ...mpuBlog,
            liteBottomBanner,
            ...sectionMpus,
            ...liteArticleAdSlots,
            ...leaderboards,
            ...adRows,
            ...billboards,
            ...photoAds,
          ]
        }

        return [
          wallpaper,
          seedtag,
          native,
          teadsib1,
          teadsib2,
          ...mpus,
          ...mpuBlog,
          topBanner,
          bottomBanner,
          ...sectionMpus,
          ...onscroll,
          ...liteArticleAdSlots,
          ...leaderboards,
          ...adRows,
          ...billboards,
          ...photoAds,
        ]
      })().filter(Boolean)

      // Define Opt Out script ad slots if user has rejected consent
      if (typeof secondLevelDomainName === 'string') {
        const ootag = window.ootag || {}
        ootag.queue = ootag.queue || []

        const slotIds = filteredSlots.map((slot) => slot.getSlotElementId())

        slotIds.forEach((slotId) =>
          ootag.queue.push(() => {
            ootag.defineSlot({
              adSlot: `${secondLevelDomainName}_${slotId}`,
              targetId: `${slotId}`,
            })
          }),
        )
        ootag.makeRequests()

        // Otherwise run GT as normal
      } else {
        filteredSlots.forEach((slot) => googletag.display(slot))

        const slotIds = filteredSlots.map((slot) => slot.getSlotElementId())
        console.log({ slotIds }) // @todo - temporarily added for testing

        resolve([filteredSlots, windowWidth, topLevelSection])
      }
    })
  })
}

export default gpt
