export type Configuration = {
  facebookAppId?: string | undefined
  baseUrl?: string
}

export type Article = {
  titleSocial: string
  titleShort: string
  title: string
  path: string
  isLiveBlog: boolean
  postSocialSharePathAffix?: string | undefined
}

const getTitle = (article?: Article) => {
  return article?.titleSocial || article?.titleShort || article?.title
}

export const facebookShareUrl = (
  configuration: Configuration,
  article?: Article,
) =>
  `https://www.facebook.com/dialog/share?app_id=${
    configuration?.facebookAppId
  }&href=${encodeURIComponent(
    `${configuration.baseUrl}${article?.path || ''}`,
  )}`

export const twitterShareUrl = (
  configuration: Configuration,
  article?: Article,
) =>
  `https://twitter.com/intent/tweet?text=${getTitle(
    article,
  )}&url=${encodeURIComponent(`${configuration.baseUrl}${article?.path}`)}`

export const pinterestShareUrl = (
  configuration: Configuration,
  article: Article,
) =>
  `https://pinterest.com/pin/create/button?description=${getTitle(
    article,
  )}&url=${encodeURIComponent(`${configuration.baseUrl}${article.path}`)}`

const getWhatsappSharePathAffix = (article?: Article): string =>
  article?.isLiveBlog ? article.postSocialSharePathAffix || '' : ''

export const whatsappShareUrl = (
  configuration: Configuration,
  article?: Article,
) => {
  const articleUrl = `${configuration.baseUrl}${article?.path}`
  const whatsappSharePathAffix = getWhatsappSharePathAffix(article)
  const encodedArticleUrl = encodeURIComponent(articleUrl)
  const encodedArticleUrlWithAffix = encodeURIComponent(
    `${articleUrl}${whatsappSharePathAffix}`,
  )
  const title = getTitle(article)

  return `https://api.whatsapp.com/send?text=${title} ${encodedArticleUrlWithAffix}&url=${encodedArticleUrl}`
}
export const emailShareUrl = (
  configuration: Configuration,
  article?: Article,
) =>
  `mailto:?subject=${getTitle(article)}&body=${encodeURIComponent(
    `${configuration.baseUrl}${article?.path}`,
  )}`

export const linkedinShareUrl = (
  configuration: Configuration,
  article: Article,
) =>
  `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    `${configuration.baseUrl}${article.path}`,
  )}&title=${getTitle(article)}&source=${configuration.baseUrl}`
