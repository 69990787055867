export const theYorkshirePost = [
  {
    image: '/assets/static/YP-Today.png',
    section: 'The Yorkshire Post Today',
    text: 'The day’s headlines and highlights emailed direct to you every morning from around Yorkshire',
    opt_in: 'ypos_opt_in',
    frequency: 'Daily',
  },
  {
    image: '/assets/static/Politics.png',
    section: 'Politics',
    text: 'Westminster correspondent Ralph Blackburn brings all the latest politics news and gossip that affects Yorkshire',
    opt_in: 'ypos_politics_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/YP-Sports.jpg',
    section: "Sports Editor's Team Talk",
    text: 'Get all your Yorkshire sports news and opinion in one place',
    opt_in: 'ypos_sportstalk_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/yorkshire-post-from-the-editor.png',
    section: 'From the Editor',
    text: 'Yorkshire’s biggest talking points, unwrapped and undiluted by our editor, James Mitchinson',
    opt_in: 'ypos_lfte_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/Insider.png',
    section: 'The Insider',
    text: 'Exclusive content for registered users – including regular features from the newsroom, promotions, competitions, surveys, giveaways and event invitations',
    opt_in: 'ypos_marketing_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/yp-newsletter-crime.png',
    section: 'Court Report',
    text: "Get the latest news and updates from across Yorkshire's courtrooms",
    opt_in: 'ypos_crime_opt_in',
    frequency: 'Daily',
  },
  {
    image: '/assets/static/Heritage.png',
    section: 'Yorkshire Heritage',
    text: 'Read all about Yorkshire’s incredible heritage every week',
    opt_in: 'ypos_heritage_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/Business.png',
    section: 'Business',
    text: 'A roundup of the essential Business news from The Yorkshire Post',
    opt_in: 'ypos_business_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/Lifestyle.png',
    section: 'Yorkshire Lifestyle',
    text: 'The very best culture and features delivered to your inbox each week',
    opt_in: 'ypos_yorkshirelifestyle_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/YP-Country-and-Farming.jpg',
    section: 'Country Post',
    text: 'Country Post - Rural life and farming across Yorkshire',
    opt_in: 'ypos_countryandfarming_opt_in',
    frequency: 'Weekly',
  },
]
