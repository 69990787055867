import React from 'react'
import styled from 'styled-components'
import {
  device,
  getPrimaryColorBasedOffAllSections,
} from '~/config/theme/JPIMStyles'
import commentDisqusIcon from '~/icons/commentDisqusIcon'
import { ArticleData, FC } from '~/types'
import { formatString } from '../utils/formatString'

const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 0 0 8px;
`

const CommentIcon = styled.span<{
  article: ArticleData
}>`
  width: 100%;
  display: flex;
  fill: ${({ theme }) => theme.lightContrast};
`

const Link = styled.a<{ article: ArticleData; hierarchyName?: string }>`
  max-width: 200px;
  display: flex;
  gap: 4px;
  align-items: flex-end;
  border: 2px solid
    ${({ theme, hierarchyName, article }) => {
      if (theme.domain === 'farminglife.com') {
        return getPrimaryColorBasedOffAllSections(theme, article?.sections)
      }
      const section = formatString(hierarchyName)
      return `var(--section-${section}-500, var(--primary-500))`
    }};
  background: ${({ theme, hierarchyName, article }) => {
    if (theme.domain === 'farminglife.com') {
      return getPrimaryColorBasedOffAllSections(theme, article?.sections)
    }
    const section = formatString(hierarchyName)
    return `var(--section-${section}-500, var(--primary-500))`
  }};
  border-radius: 25px;
  color: ${({ theme }) => theme.lightContrast};
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontFamily?.openSans};
  text-decoration: none;
  scroll-behavior: smooth;
  scroll-margin: 5rem;

  @media ${device.tablet} {
    padding: 4px 14px;
  }
`

export type CommentButtonProps = {
  className?: string
  pageId: string
  position: string
  article: ArticleData
  hierarchy?: { name: string }[]
}

const CommentButton: FC<CommentButtonProps> = ({
  pageId,
  className,
  position,
  article,
  hierarchy,
}) => {
  const hierarchyName =
    hierarchy && hierarchy.length > 0 ? hierarchy[0]?.name : undefined

  return (
    <Wrapper data-testid="article-btm-cmnt-wp">
      <Link
        href="#disqus-comment-section"
        id="commentButtonDisqus"
        className={`${className} ${position}`}
        article={article}
        hierarchyName={hierarchyName}
        data-testid="article-btm-cmnt-link"
      >
        <CommentIcon
          dangerouslySetInnerHTML={{ __html: commentDisqusIcon }}
          article={article}
        />
        <span
          id="comment-counter"
          data-testid="article-btm-cmnt-counter"
          className="disqus-comment-count"
          data-disqus-identifier={pageId}
          style={{
            fontSize: '14px',
            fontFamily: 'OpenSans,sans-serif',
            fontWeight: 700,
          }}
        />
        <span id="comment-counter-wording">Comment</span>
      </Link>
    </Wrapper>
  )
}

export default CommentButton
