import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Embed = styled.div`
  clear: both;
  width: 100%;
  max-width: 100%;

  > * {
    margin: auto;
  }
`

const HTMLEmbed = ({ className, data }) => {
  if (!data?.markup) return null
  const { markup } = data
  const html = markup.trim()

  return html.length > 0 ? (
    <Embed
      className={className}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  ) : null
}

HTMLEmbed.propTypes = {
  data: PropTypes.shape({
    markup: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
}

export default HTMLEmbed
