import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import SocialShare from '~/library/Article/SocialShare'

import { ArticleData, Configuration, FC } from '~/types'

type PolarSponsorBannerProps = {
  article: ArticleData
  configuration: Configuration
  timezone: string
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  min-height: 68px;
  align-content: center;
  flex-direction: column;

  @media ${device.mobileL} {
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: none;
  }
`

const InnerWrapper = styled.div`
  justify-content: center;
  flex-direction: column;
  flex-grow: 0;
  display: flex;
  margin: 0;
  min-height: 50px;
  width: 100%;

  @media ${device.mobileL} {
    width: auto;
  }
`

const PromotedBy = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.fontColor};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-align: left;
  line-height: 1.2;
  font-weight: bold;
  flex-wrap: nowrap;
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;

  & a,
  & span {
    color: ${({ theme }) => theme.fontColor};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    line-height: 1.2;
    text-decoration: none;
  }
`

const SponsorLogo = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 11px 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.fontColorLight};
  & img {
    max-width: 140px;
    max-height: 140px;
    height: auto;
    width: 100%;
    object-fit: contain;
    display: block;
  }

  @media ${device.mobileL} {
    margin: 0 20px;
    padding: 0;
    border: none;
    width: auto;
  }
`

const DateContainer = styled.div`
  display: contents;
  color: ${({ theme }) => theme.fontColorLight};
  text-align: left;
  line-height: 19px;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: 12px;
`

const PolarSponsorBanner: FC<PolarSponsorBannerProps> = ({
  article,
  configuration,
  timezone,
}) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <PromotedBy>
          Ad Feature by&nbsp;
          <div id="native-content-sponsor-link">
            <span id="native-content-sponsor-name" />
          </div>
        </PromotedBy>
        <DateContainer>Published </DateContainer>
        <DateContainer id="native-content-pub-date" />
        <DateContainer>{' ' + timezone}</DateContainer>
      </InnerWrapper>
      <SponsorLogo id="native-content-sponsor-logo" />
      <SocialShare
        position="in article - top"
        article={article}
        configuration={configuration}
      />
    </Wrapper>
  )
}

export default PolarSponsorBanner
