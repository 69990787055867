export default () => {
  const btn = document.getElementById('mobileBtnBio')
  const bio = document.getElementById('peopleBio')

  const showBio = () => {
    if (bio && btn) {
      bio.style.display = 'flex'
      bio.style.opacity = '1'
      btn.innerHTML = 'Hide Bio'
    }
  }

  const hideBio = () => {
    if (bio && btn) {
      bio.style.display = 'none'
      bio.style.opacity = '0'
      btn.innerHTML = 'View Bio'
    }
  }

  if (bio) {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        showBio()
      } else {
        hideBio()
      }
    })
  }

  if (btn) {
    btn.addEventListener('click', () => {
      if (bio?.style.display === 'none' || bio?.style.display === '') {
        showBio()
      } else {
        hideBio()
      }
    })
  }
}
