import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

import disqusIcon from '~/icons/disqusIcon.js'

const Wrapper = styled.div`
  border-top: 4px solid ${(props) => props.theme.fontColor};
  margin: 100px 0 50px 0;
  scroll-behavior: smooth;
  scroll-margin-top: 5rem;

  @media ${device.laptop} {
    margin: 180px 0 0 0;
  }

  #disqus-logged-out {
    font-family: 'open-sans', sans-serif;
    border: 1px solid ${(props) => props.theme.greyContrast};
    border-radius: 4px;
    background-color: ${(props) => props.theme.backgroundColor};
    margin: 20px 0;
    line-height: 1.4;
    padding: 20px;
    text-align: center;

    @media ${device.laptop} {
      margin: 40px 0;
      padding: 30px;
    }
  }

  #disqus-comment-counter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .disqus-comments-icon {
    display: inline-block;
  }

  button {
    color: ${(props) => props.theme.primaryColor};
  }

  p {
    margin: 0;
  }

  #disqus_thread {
    display: none;
  }
`

const disqusComments = ({ pageId }) => {
  return (
    <Wrapper id="disqus-comment-section" data-testid="article-comment-section">
      <h2 data-testid="article-comment-header">Comments</h2>
      <div id="disqus-logged-out" data-testid="article-comment-logout">
        <div id="disqus-comment-counter" data-testid="article-comment-count">
          <div
            className="disqus-comments-icon"
            dangerouslySetInnerHTML={{ __html: disqusIcon }}
          />
          <span>&nbsp;</span>
          <span
            className="disqus-comment-count"
            data-disqus-identifier={pageId}
          >
            0
          </span>
          <span>&nbsp;comments</span>
        </div>
        <p id="join_in" data-testid="article-join-in">
          Want to join the conversation? Please{' '}
          <button
            className="register-button"
            data-testid="article-comment-register"
          >
            register
          </button>{' '}
          or{' '}
          <button className="login-button" data-testid="article-comment-login">
            log in
          </button>{' '}
          to comment on this article.
        </p>
      </div>
      <div id="disqus_thread"></div>
    </Wrapper>
  )
}

disqusComments.propTypes = {
  pageId: PropTypes.string.isRequired,
}

export default disqusComments
