import { TABLET_BREAKPOINT } from '../config/theme/JPIMStyles'
import { debounce } from './debounce'
import { getLastVisibleSlideIndex } from './get-last-visible-slide-index'
import { viewportHasChanged } from './viewport-has-changed'

const CAROUSEL_DEBOUNCE_DELAY = 250

export default () => {
  const carousels = document.getElementsByClassName('flow-carousel')

  if (carousels) {
    Array.from(carousels).forEach((carousel) => {
      let slideCount
      let sliderPos = 0
      let Slider
      let Dots
      // Get 'SLIDES_PER_VIEW' in RelatedArticles OR '1' for the standard Carousel component
      const slidesPerView = carousel.getAttribute('data-slides-per-view') || 1
      const hasPagination =
        !!carousel.getElementsByClassName('flow-carousel-dots').length

      let autoSlideInterval

      const sliderPosChanged = () => {
        if (window.innerWidth >= TABLET_BREAKPOINT) {
          // Desktop
          Slider.style = `transform: translateX(-${
            (100 / slideCount) * sliderPos
          }%)`
        } else {
          // Mobile
          Slider.style = `transform: translateX(-${
            (100 / Math.round(slideCount / slidesPerView)) * sliderPos
          }%)`
        }

        if (hasPagination) {
          Array.from(Dots.children).forEach((dot) => {
            if (dot.className === `dot-${sliderPos}`) {
              dot.setAttribute('active', 'true')
              return
            }

            dot.removeAttribute('active')
          })
        }
      }

      // Reset sliderPos if mobile layout / translateX is different than desktop
      if (slidesPerView >= 2) {
        const resizeHandler = debounce(() => {
          const hasSizeChanged = viewportHasChanged()
          if (hasSizeChanged) {
            sliderPos = 0
            sliderPosChanged()
          }
        }, CAROUSEL_DEBOUNCE_DELAY)

        window.addEventListener('resize', resizeHandler)
      }

      const dotClicked = (index) => {
        startCarouselInterval()

        sliderPos = index
        sliderPosChanged()
      }

      const prevSlide = () => {
        startCarouselInterval()

        // Return if the current slide is the first visible slide
        if (sliderPos <= 0) {
          return
        }

        if (window.innerWidth >= TABLET_BREAKPOINT && sliderPos <= 0) {
          // Desktop 1st slide
          sliderPos = slideCount - 1
          sliderPosChanged()
        } else if (window.innerWidth <= TABLET_BREAKPOINT && sliderPos <= 0) {
          // Mobile 1st slide
          sliderPos = Math.round(slideCount / slidesPerView) - 1
          sliderPosChanged()
        } else {
          // All other slides
          sliderPos = sliderPos - 1
          sliderPosChanged()
        }
      }

      const nextSlide = (intervalTriggered) => {
        if (!intervalTriggered) {
          startCarouselInterval()
        }

        const isLastSlideVisible = () => {
          // Carousel with 1 slide per view:
          if (slidesPerView === 1) {
            return sliderPos >= slideCount - 1
          } else {
            // Carousel with multiple slides per view:
            return sliderPos >= getLastVisibleSlideIndex(slideCount)
          }
        }

        if (isLastSlideVisible()) {
          // If the last slide is visible, go back to the first slide
          sliderPos = 0
        } else {
          // For all other cases, go to next slide
          sliderPos = sliderPos + 1
        }

        sliderPosChanged()
      }

      const startCarouselInterval = () => {
        clearInterval(autoSlideInterval)

        autoSlideInterval = setInterval(() => {
          nextSlide(true)
        }, 5000)
      }

      const setupSwipeListeners = () => {
        let startX = 0
        let endX = 0
        let threshold = 50

        const checkDirection = (e) => {
          if (endX < startX - threshold) {
            if (e.cancelable) {
              e.preventDefault()
            }

            nextSlide()
          }

          if (endX > startX + threshold) {
            if (e.cancelable) {
              e.preventDefault()
            }

            prevSlide()
          }
        }

        Slider.addEventListener(
          'touchstart',
          (e) => {
            startX = e.changedTouches[0].screenX
          },
          { passive: false },
        )

        Slider.addEventListener(
          'touchend',
          (e) => {
            endX = e.changedTouches[0].screenX
            checkDirection(e)
          },
          { passive: false },
        )
      }

      Array.from(carousel.children).forEach((child) => {
        if (child.className.indexOf('flow-carousel-arrow-left') > -1) {
          child.addEventListener(
            'click',
            () => {
              prevSlide()
            },
            { passive: true },
          )
        }

        if (child.className.indexOf('flow-carousel-arrow-right') > -1) {
          child.addEventListener(
            'click',
            () => {
              nextSlide()
            },
            { passive: true },
          )
        }

        if (child.className.indexOf('flow-carousel-inner') > -1) {
          Array.from(child.children).forEach((innerChild) => {
            if (innerChild.className.indexOf('flow-carousel-arrow-left') > -1) {
              innerChild.addEventListener(
                'click',
                () => {
                  prevSlide()
                },
                { passive: true },
              )

              return
            }

            if (
              innerChild.className.indexOf('flow-carousel-arrow-right') > -1
            ) {
              innerChild.addEventListener(
                'click',
                () => {
                  nextSlide()
                },
                { passive: true },
              )

              return
            }

            if (innerChild.className.indexOf('flow-carousel-slider') > -1) {
              Slider = innerChild
              slideCount = innerChild.children.length

              if (slideCount > 1) {
                startCarouselInterval()
                setupSwipeListeners()
              }

              return
            }

            if (innerChild.className.indexOf('flow-carousel-dots') > -1) {
              Dots = innerChild

              innerChild.addEventListener(
                'click',
                (e) => {
                  if (e.target === innerChild) {
                    return
                  }

                  dotClicked(+e.target.className.split('-')[1])
                },
                { passive: true },
              )

              return
            }
          })
        }
      })
    })
  }
}
