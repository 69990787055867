import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import SocialShare from './SocialShare'
import dayjs from 'dayjs'

import { getTimezone } from '~/util/get-timezone'
import { ComponentThemeProvider } from '~/library/providers/ComponentThemeProvider'
import SocialShareMobile from './SocialShareMobile'
import { domainsWithNativeShare } from '~/config/JPIMConfig'
import Image from '~/component/library/Section/Image'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  flex-direction: column;
  @media ${device.mobileL} {
    flex-direction: row;
    align-items: center;
  }
`

const InnerWrapper = styled.div`
  justify-content: center;
  flex-direction: column;
  flex-grow: 0;
  display: flex;
  margin: 0;
  min-height: 50px;
  width: 100%;
  @media ${device.mobileL} {
    width: auto;
  }
`

const SponsorLink = styled.a`
  text-decoration: none;
`

const SponsorName = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-align: left;
  line-height: 1.2;
  font-weight: bold;
  text-decoration: none;
`

const SponsorNameContrast = styled(SponsorName)`
  color: ${({ theme }) => theme.lightContrast};
`

const SponsorLogoWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  @media ${device.mobileL} {
    width: auto;
  }
`

const SponsorLogo = styled.a`
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.fontColorLight};
  width: 100%;
  padding: 11px 0;
  margin: 0 0 20px 0;
  & #sponsor-image {
    max-width: 140px;
    max-height: 140px;
    width: 100%;
    object-fit: contain;
    display: block;
    margin: 0;
    padding: 0;
    img {
      object-fit: contain;
    }
  }
  @media ${device.mobileL} {
    margin: 0 20px;
    padding: 0;
    border: none;
    width: auto;
  }
`

const PublishedDate = styled.div`
  color: ${({ theme }) => theme.fontColorLight};
  text-align: left;
  line-height: 19px;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: 12px;
`

function SponsorBanner({ article, configuration, date, theme, updatedDate }) {
  const { sponsorName, sponsorURL, sponsorLogo, sponsorImage } = article
  const logoSrc = sponsorImage ? sponsorImage?.data?.url : sponsorLogo
  const SponsorBrand = ComponentThemeProvider(
    theme,
    SponsorNameContrast,
    SponsorName,
  )
  return (
    <Wrapper data-testid="sponsor-outer-wrapper">
      <InnerWrapper data-testid="sponsor-inner-wrapper">
        <SponsorLink
          href={sponsorURL}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <SponsorBrand data-testid="sponsor-brand-name">
            Ad Feature by {sponsorName}
          </SponsorBrand>
        </SponsorLink>
        <PublishedDate data-testid="sponsor-publish-date">
          Published {date}
          {' ' + getTimezone(dayjs().toDate())}
        </PublishedDate>
        {updatedDate && (
          <PublishedDate data-testid="sponsor-update-date">
            Updated {updatedDate}
            {' ' + getTimezone(dayjs().toDate())}
          </PublishedDate>
        )}
      </InnerWrapper>
      <SponsorLogoWrapper>
        {(sponsorImage || sponsorLogo) && (
          <SponsorLogo
            href={sponsorURL}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <Image
              id="sponsor-image"
              src={logoSrc}
              alt={sponsorName}
              height="100"
              width="200"
            />
          </SponsorLogo>
        )}
      </SponsorLogoWrapper>
      <SocialShare
        position="in article - top"
        article={article}
        configuration={configuration}
        theme={theme}
        data-testid="sponsor-social-share"
      />
      {domainsWithNativeShare.includes(configuration.domain) && (
        <SocialShareMobile
          position="in article - top"
          article={article}
          shareClass="sponsor-share-btn"
          data-testid="sponsor-mb-social-share"
        />
      )}
    </Wrapper>
  )
}

SponsorBanner.propTypes = {
  article: PropTypes.object,
  configuration: PropTypes.object.isRequired,
  date: PropTypes.string,
  theme: PropTypes.string,
  updatedDate: PropTypes.string,
}

export default SponsorBanner
