import { setCookie } from './cookie'

const switchGeoLocation = () => {
  const locationSwitchButton = document.getElementsByClassName(
    'location-switch-btn',
  )[0]
  if (!locationSwitchButton) {
    return
  }

  locationSwitchButton.addEventListener('click', () => {
    const geoLocationValue = document.cookie.match(
      new RegExp('(^| )' + 'geo_location' + '=([^;]+)'),
    )?.[2]
    const oppositeLocation = geoLocationValue === 'US' ? 'UK' : 'US'

    setCookie('geo_location', oppositeLocation, 3650) // 10 years = 3650 days

    window.location.replace(oppositeLocation === 'UK' ? '/' : '/us')
  })
}

export default switchGeoLocation
