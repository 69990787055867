const menu = (() => {
  const delay = 1000
  const delayHover = 'delay-hover'
  let lastElement: HTMLElement | null = null
  let timer: ReturnType<typeof setTimeout>

  return {
    watchMouseState($el: HTMLElement) {
      $el.addEventListener('mouseenter', () => {
        if (lastElement && $el.querySelector('ul')) {
          lastElement.classList.remove(delayHover)
        }
      })
      $el.addEventListener('mouseleave', () => {
        if (!$el.querySelector('ul')) return
        lastElement = $el
        $el.classList.add(delayHover)
        clearTimeout(timer)
        timer = setTimeout(
          () => lastElement?.classList.remove(delayHover),
          delay,
        )
      })
    },
  }
})()

export default () => {
  document.querySelectorAll('.menu-navbar-item').forEach(menu.watchMouseState)
}
