import React from 'react'
import styled, { keyframes } from 'styled-components'

type DotProps = {
  delay?: number
}

const fadeInLeft = keyframes`
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.darkContrast};
  animation: ${fadeInLeft} 0.8s infinite ${({ delay }) => delay || 0}s;
  margin: 0 4px;
`

export const Loading = () => (
  <LoadingContainer data-testid="loading-container">
    <Dot />
    <Dot delay={0.1} />
    <Dot delay={0.2} />
  </LoadingContainer>
)
