import React, { createContext } from 'react'
import { getAdManagerArticleConfig } from './utils/getAdManagerArticleConfig'
import { AdManagerConfig, AdConfigData, FC } from '~/types'

export const AdManagerContext = createContext(
  {} as AdConfigData | AdManagerConfig,
)

type AdManagerProviderProps = {
  adConfig: AdConfigData
  articleLength?: number
  domain?: string
  isSection?: boolean
  children: JSX.Element
}

export const AdManagerProvider: FC<AdManagerProviderProps> = ({
  adConfig,
  articleLength = 0,
  children,
  domain = '',
  isSection = false,
}) => {
  try {
    if (!adConfig) {
      return children || null
    }

    const adManagerConfig = isSection
      ? adConfig
      : getAdManagerArticleConfig(adConfig, domain, articleLength)

    return (
      <AdManagerContext.Provider value={adManagerConfig}>
        {children}
      </AdManagerContext.Provider>
    )
  } catch (error) {
    console.error('Error in AdManagerProvider:', error)
    return children || null
  }
}
