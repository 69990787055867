import React from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 100%;
  margin: 30px 0 0;
`

export const SidebarHeading = styled.div`
  margin: 12px 0;
  padding: 0;
  color: ${({ theme }) => theme.primaryColor};
  font-family: ${({ theme }) => theme.fontFamily.titleFont};
  font-size: 20px;
  line-height: 20px;
  display: inline-flex;
`

export const TrendingAsHTML = ({
  className,
  target,
}: {
  className: string
  target: string
}) => {
  return (
    <div
      data-target={target}
      className={className}
      dangerouslySetInnerHTML={{
        __html: `<esi:include src="${target}" />`,
      }}
    />
  )
}

export const TrendingListTemplate = styled(TrendingAsHTML)`
  ul {
    padding: 0;
    margin: 0;
    justify-content: space-between;
    display: flex;

    li {
      padding: 12px 0;
      background-color: ${({ theme }) => theme.lightContrast};
      display: flex;
      width: 49%;
      position: relative;

      &:after {
        content: '';
        border-bottom: 1px solid ${({ theme }) => theme.greyContrast};
        position: absolute;
        bottom: 0;
        width: calc(100% - 24px);
      }

      &:nth-last-child(-n + 2):after {
        border-bottom: none;
      }

      a {
        display: flex;
        color: ${({ theme }) => theme.fontColor};
        text-decoration: none;
        line-height: 17px;
        letter-spacing: 0;
        font-family: ${({ theme }) => theme.fontFamily.titleFont};
        font-size: 14px;
      }

      span {
        display: none;
      }

      div {
        margin-left: 16px;
        display: flex;
        flex-direction: column;

        a:last-child {
          font-size: 14px;
          font-weight: 700;
          text-decoration: none;
          color: ${({ theme }) => theme.primaryColor};
          position: absolute;
          bottom: 12px;
          opacity: ${({ theme }) =>
            theme.name === 'Yorkshire Post' ? 0.5 : 1};
        }
      }
    }
  }
`
