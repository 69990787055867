import { device } from '~/config/theme/JPIMStyles'

const MEDIA_QUERY = device.maxMobileL

// max length of article in vh before truncation
export const ARTICLE_MAX_LENGTH = 800

// an arbitrary offset to account for the max recored heightInVH not quite reaching ARTICLE_MAX_LENGTH
const PIXELS_OFFSET = 5

// domains to exclude from truncation and continue reading button
export const excludedDomains = [
  'scotsman.com',
  'yorkshirepost.co.uk',
  'newsletter.co.uk',
]

export const continueReading = () => {
  const TARGET_DIV = '.article-content'

  const articleContentDiv = document.querySelector(TARGET_DIV) as HTMLDivElement

  const btn = document.querySelector(
    '#continue-reading-btn',
  ) as HTMLButtonElement

  const overlay = document.querySelector(
    '#continue-reading-overlay',
  ) as HTMLDivElement

  const newsletterPaywall = document.querySelector(
    '.piano-article-paywall > .tp-container-inner',
  )

  const hideBtnOverlay = () => {
    overlay && overlay.remove()
  }

  const removeArticleShorteningClass = () => {
    articleContentDiv && articleContentDiv.classList.remove('read-more')
  }

  const continueReadingClick = () => {
    hideBtnOverlay()
    removeArticleShorteningClass()
  }

  if (btn) {
    btn.addEventListener('click', () => continueReadingClick())
  }

  const resizeObserver = new ResizeObserver((divSize) => {
    const vh = window.innerHeight / 100
    for (const size of divSize) {
      const heightInVH = size.contentRect.height / vh
      if (
        heightInVH > ARTICLE_MAX_LENGTH - PIXELS_OFFSET &&
        window.matchMedia(MEDIA_QUERY).matches &&
        overlay
      ) {
        overlay.style.display = 'block'
      }
    }
  })

  const paywallObserver = new MutationObserver(() => {
    if (newsletterPaywall) {
      continueReadingClick()
      paywallObserver.disconnect()
    }
  })

  if (articleContentDiv) {
    resizeObserver.observe(articleContentDiv)
    paywallObserver.observe(document, { childList: true, subtree: true })
  }
}
