/*globals JSGlobals, dataLayer*/
import { jsLoader } from './fileLoaders'
import { isLogin, isPianoSubscriber, reloadWithRandomParam } from '~/js/piano'
import { getCookie, setCookie } from './cookie'
import { pauseAllDailymotionPlayers } from './dailymotion'

export const axateSub = 'axateSub'
export const pianoAxate = '_pc_axate'
export const axateFree = 'free'
export const axateLoggedIn = 'logged-in'

export const openAxateLogin = () => {
  window.axateSignOn()
}
export const openAxateDayPass = () => {
  window.axateDayPass()
}

export const axateSubscriber = () => Boolean(getCookie(axateSub))

const tracking = () => {
  window.dataLayer = window.dataLayer || []
  return (eventAction) =>
    dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'axate',
      eventAction,
      eventLabel: undefined,
      eventValue: 0,
      eventNI: 0,
    })
}

const postMessageLogin = () => {
  window.addEventListener(
    'message',
    (event) => {
      const whiteListed = [
        'axate.io',
        'page-notices.yorkshirepost.co.uk',
        'tinypass.com',
        'https://sandbox.tinypass.com',
      ]

      if (whiteListed.find((domain) => event.origin.includes(domain))) {
        try {
          const { type, value } = JSON.parse(event.data)
          if (type === 'DIALOG_OPEN') {
            switch (value) {
              case 'LOGIN':
              case 'LOGIN_PIANO': {
                const tp = window.tp || []
                tp.push([
                  'init',
                  () => {
                    tp.pianoId.show()
                  },
                ])
                break
              }
            }
          }
        } catch (ex) {
          // noop
        }
      }
    },
    false,
  )
}

export const initAxate = () => {
  const pushTracking = tracking()
  window.agateInit = function () {
    pushTracking('loaded')
    pauseAllDailymotionPlayers()
  }

  window.agateUserLoggedIn = function () {
    pushTracking('log in')
    setCookie(pianoAxate, axateLoggedIn, 1)

    if (!sessionStorage.getItem('hasReloadedAfterAxateGranted')) {
      sessionStorage.setItem('hasReloadedAfterAxateGranted', 'true')
      reloadWithRandomParam()
    }

    const container = document.querySelector('.article-content.premium')
    const hiddenSelector = 'no-entitlement'

    if (container && container.classList.contains(hiddenSelector)) {
      container.classList.remove(hiddenSelector)
    }
  }
  window.agateUserLoggedOut = function () {
    pushTracking('log out')
    setCookie(axateSub, '', -1)
    setCookie(pianoAxate, '', -1)
    sessionStorage.removeItem('hasReloadedAfterAxateGranted')
  }
  window.agatePremiumContentRendered = function () {
    pushTracking('article purchased')
  }

  if (
    isLogin() &&
    !isPianoSubscriber() &&
    ![axateFree, axateLoggedIn].includes(getCookie(pianoAxate))
  ) {
    const axateWallet = document.getElementById('axate-wallet')
    if (axateWallet) {
      axateWallet
        .getAttributeNames()
        .filter((x) => !/id|class/.test(x))
        .forEach((attr) => {
          axateWallet.removeAttribute(attr)
        })
    }
  }

  if (!isPianoSubscriber()) {
    window.axateUserHasAccessToContent = function () {
      const container = document.querySelector('.article-content.premium')
      const hiddenSelector = 'no-entitlement'

      if (container && container.classList.contains(hiddenSelector)) {
        container.classList.remove(hiddenSelector)
      }
    }
    window.axateUserLoggedInWithSubscription = function () {
      setCookie(pianoAxate, axateLoggedIn, 1)
      pushTracking('log in with subscription')

      //show axate paywall to allow users to buy article
      const axatePaywall = document.querySelector('.axate-article-bottom')
      const hiddenPaywall = 'axate-paywall-hidden'

      if (axatePaywall && axatePaywall.classList.contains(hiddenPaywall)) {
        axatePaywall.classList.remove(hiddenPaywall)
      }
    }
    window.axateUserOnFreePeriod = function () {
      pushTracking('free period')
      if (!getCookie(axateSub)) {
        setCookie(axateSub, 'true', 1)
        setCookie(pianoAxate, axateFree, 1)
        reloadWithRandomParam()
      }
    }
    jsLoader(
      [
        `https://wallet${
          JSGlobals.mode === 'production' ? '' : '-staging'
        }.axate.io/bundle.js`,
      ],
      'axate',
    )

    // // override blocker content height for photo articles with 1 photo
    if (
      document.querySelectorAll('.no-entitlement .gallery-image').length === 1
    ) {
      const removeSingleGallery = setInterval(() => {
        if (document.querySelector('.axate-standfirst')) {
          document.querySelector('.axate-standfirst').style.maxHeight = 0
          clearInterval(removeSingleGallery)
        }
      }, 300)
    }

    postMessageLogin()
  }
}
