const whatsappIcon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 40 40" style="stroke: none" xml:space="preserve">
<g id="Ellipse_8" transform="translate(1012 499)">
\t<circle cx="-991.2" cy="-479.8" r="19.2" style="fill: none"/>
\t<path style="fill: none; stroke-width: 2;" d="M-1000.1-463.9c2.6,1.5,5.6,2.3,8.9,2.3c10.1,0,18.2-8.2,18.2-18.2s-8.2-18.2-18.2-18.2s-18.2,8.2-18.2,18.2   c0,3.8,1.2,7.3,3.1,10.2l-3.1,9L-1000.1-463.9z"/>
</g>
<path d="M29.9,23.1c-0.5-0.2-2.9-1.4-3.4-1.6c-0.5-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.3,1.6-1.6,1.9  c-0.3,0.3-0.6,0.4-1.1,0.1c-2.9-1.5-4.8-2.6-6.7-5.9c-0.5-0.9,0.5-0.8,1.5-2.7c0.2-0.3,0.1-0.6,0-0.9c-0.1-0.3-1.1-2.7-1.5-3.7  c-0.4-1-0.8-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0c-0.3,0-0.9,0.1-1.3,0.6c-0.5,0.5-1.7,1.7-1.7,4.1s1.8,4.8,2,5.1  c0.3,0.3,3.5,5.3,8.5,7.5c3.1,1.4,4.4,1.5,5.9,1.2c1-0.1,2.9-1.2,3.3-2.4c0.4-1.2,0.4-2.2,0.3-2.4C30.7,23.5,30.4,23.4,29.9,23.1z"/>
</svg>
`
export default whatsappIcon
