export const belfastNewsletter = [
  {
    image: '/assets/static/Belfast-News-Letter-Today.png',
    section: 'Belfast News Letter Today',
    text: 'The day’s headlines and highlights emailed direct to you every morning from around Northern Ireland',
    opt_in: 'belf_opt_in',
    frequency: 'Daily',
  },
  {
    image: '/assets/static/belfast-from-the-editor.png',
    section: 'From the Editor',
    text: 'Northern Ireland’s biggest talking points, unwrapped and undiluted by our editor, Ben Lowry.',
    opt_in: 'belf_lfte_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/Belfast-Insider.png',
    section: 'The Insider',
    text: 'Get emails from our editor on the biggest issues affecting Northern Ireland, as well as the latest competitions, promotions, giveaways, surveys, event invitations and news from Belfast News Letter newsroom.',
    opt_in: 'belf_marketing_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/belfast-business.png',
    section: 'Business Briefing',
    text: 'Stay ahead with the latest business trends and corporate news from Northern Ireland.',
    opt_in: 'belf_businessbriefing_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/Motorcycling.png',
    section: 'Motorcycling',
    text: 'The latest motorcycling news, results, analysis and comment sent direct to your inbox.',
    opt_in: 'belf_motorcycling_opt_in',
    frequency: 'Weekly',
  },
]
