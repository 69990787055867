import { ArticleSnippetData, ImageData } from '~/types'

export const getHeroSlots = (
  useImageCarousel: boolean,
  hero?: ArticleSnippetData[] | null,
  heroSlotTwo?: ArticleSnippetData[] | null,
  heroSlotThree?: ArticleSnippetData[] | null,
) => {
  const heroSlots = useImageCarousel
    ? [hero, heroSlotTwo, heroSlotThree]
        .flatMap((slot) => slot ?? [])
        .filter(Boolean)
        .reverse()
    : hero // otherwise just the hero

  const heroImageCount = heroSlots?.filter(
    (snippet): snippet is ImageData => snippet.type === 'image',
  ).length

  return { heroSlots, heroImageCount }
}

export const getClassName = (heroImageCount: number) => {
  switch (heroImageCount) {
    case 2:
      return 'image-wrap carousel two'
    case 3:
      return 'image-wrap carousel three'
    default:
      return 'image-wrap'
  }
}
