import { IslandForRender } from '@brightsites/island-hydration/lib/components'
import React from 'react'
import Dailymotion from './Embeds/Dailymotion'
import Facebook from './Embeds/Facebook'
import HTMLEmbed from './Embeds/HTML'
import { Instagram } from './Embeds/Instagram'
import { Twitter } from '~/js/components/Twitter'
import Youtube from './Embeds/Youtube'
import FactBox from './FactBox'
import Flourish from './Flourish'
import HomeDetail from './HomeDetail'
import IFrame from './IFrame'
import Image from './Image'
import { Markup } from './Markup'
import { ReadMore } from './ReadMore'
import RelatedArticles from './RelatedArticles'

export const readmoreOnce = () => {
  let foundOne = null
  return (comp) => {
    if (comp.type === 'readMore') {
      if (foundOne) {
        return false
      }
      foundOne = true
      return true
    }
    return true
  }
}

// @todo rework components to remove dependencies can be added to components with withRequest provider
export const renderBody = (
  components,
  // remove below dependencies
  article,
  configuration,
  domain,
) =>
  components
    .filter(({ type }) => !!type)
    .filter(readmoreOnce())
    .map((snippet, index) => {
      switch (snippet.type) {
        case 'H2':
        case 'H3':
        case 'H4':
        case 'H5':
        case 'H6':
        case 'listnumbered':
        case 'listbulleted':
        case 'markup':
          return (
            <Markup
              key={snippet.type + index}
              article={article}
              data={snippet.data}
              polar={article.polar}
              hierarchy={article.hierarchy}
              isSponsored={!!article.sponsorName || !!article.partners}
              className="markup"
            />
          )
        case 'video-dm':
        case 'dailymotion':
          return (
            <Dailymotion
              key={snippet.type + index}
              configuration={configuration}
              article={article}
              data={snippet.data}
            />
          )
        case 'brightcove':
          return null
        case 'video-youtube':
        case 'youtube':
          return <Youtube key={snippet.type + index} data={snippet.data} />
        case 'image':
          return (
            <Image
              key={snippet.type + index}
              srcSet={[320, 640]}
              data={snippet.data}
            />
          )
        case 'twitter':
          return (
            <IslandForRender
              key="twitter"
              name="Twitter"
              props={{
                data: snippet.data,
              }}
            >
              <Twitter key={snippet.type + index} data={snippet.data} />
            </IslandForRender>
          )
        case 'readMore':
          return <ReadMore key={snippet.type + index} data={snippet.data} />
        case 'related':
          return (
            <RelatedArticles
              key={snippet.type + index}
              data={snippet.data}
              hierarchy={article.hierarchy}
            />
          )
        case 'homedetail':
          return <HomeDetail key={snippet.type + index} data={snippet.data} />
        case 'facebook':
          return <Facebook key={snippet.type + index} data={snippet.data} />
        case 'instagram':
          return <Instagram key={snippet.type + index} data={snippet.data} />
        case 'factbox':
          return (
            <FactBox
              className={'last-factbox'}
              key={snippet.type + index}
              data={snippet.data}
            />
          )
        case 'flourish':
          return (
            <Flourish
              key={snippet.type + index}
              data={snippet.data}
              domain={domain}
            />
          )
        case 'iframe':
          return <IFrame key={snippet.type + index} data={snippet.data} />
        case 'html':
          return <HTMLEmbed key={snippet.type + index} data={snippet.data} />
        default:
          // temporarily warn the client if missing
          console.error('TODO: define component type', snippet.type)
      }
    })
