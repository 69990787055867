import { getLastVisibleSlideIndex } from '~/util/get-last-visible-slide-index'

export const isLastSlideVisible = (sliderPos: number, slideCount: number) => {
  return sliderPos >= getLastVisibleSlideIndex(slideCount)
}

export const changeSlide = (
  direction: 'left' | 'right',
  sliderPos: number,
  setSliderPos: (prevPos: number | ((prevPos: number) => number)) => void,
  slideCount: number,
) => {
  if (direction === 'left') {
    // Navigate left:
    // Prevent sliderPos from becoming a negative number.
    // Math.max ensures that the result is at least 0
    setSliderPos((prevPos) => Math.max(prevPos - 1, 0))
  } else {
    // Decide whether to navigate back to start if last
    // slide is visible, otherwise navigate right
    if (isLastSlideVisible(sliderPos, slideCount)) {
      setSliderPos(0)
    } else {
      setSliderPos((prevPos) => prevPos + 1)
    }
  }
}
