import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { customColorLogicSites } from '~/config/JPIMConfig'
import {
  getPrimaryColorBasedOffAllSections,
  getSectionPrimaryColor,
} from '~/config/theme/JPIMStyles'
import { formatString } from './utils/formatString'

const ExplainerLabel = styled.a`
  text-decoration: none;
`

export const HeadlineLabelContainer = styled(({ className, label }) =>
  label !== 'Explainer' ? (
    <span className={className} data-testid="article-label">
      {label}
    </span>
  ) : (
    <ExplainerLabel href="/explainer" className={className}>
      {label}
    </ExplainerLabel>
  ),
)`
  position: relative;
  font-size: 15px;
  letter-spacing: 0.38px;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  background: var(--nw-breaking-news);
  padding: 4px 8px;
  margin-right: 8px;
  color: ${({ theme }) => theme.darkestContrast};
  align-self: center;
  text-transform: uppercase;
`

export const LiveBlogLabelContainer = styled.div`
  position: relative;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;

  .live-blog-span {
    margin-left: 8px;
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    color: ${({ theme }) => theme.redContrast};
  }

  .blink-indicator {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: ${({ theme }) => theme.redContrast};
    animation: blink 1.4s infinite alternate;
  }

  @keyframes blink {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const HeadlineLabelContainerPrimary = styled(HeadlineLabelContainer)`
  background: ${({ theme, hierarchyColor, article }) => {
    if (theme.domain === 'farminglife.com') {
      return getPrimaryColorBasedOffAllSections(theme, article?.sections)
    }
    if (customColorLogicSites.includes(theme.domain)) {
      const formattedHierarchyColor = formatString(hierarchyColor)
      return getSectionPrimaryColor(theme, formattedHierarchyColor)
    }
    return theme.primaryColor
  }};
  color: ${({ theme }) => theme.lightContrast};
  font-weight: 700;
`

export const HeadlineLabel = ({ article, className }) => {
  // for some reason the article.articleTypes array isn't defined right away
  const articleTypes = article?.articleTypes?.length ? article.articleTypes : []
  const hierarchyColor = article?.hierarchy?.[0]?.name

  const isAnalysis = articleTypes[0]?.name?.includes('Analysis')
  const isExplainer = articleTypes[0]?.name?.includes('Explainer')
  const isBreakingNews = article?.extra?.isBreakingNews
  const timeDifference = Date.now() / 1000 - article?.updated
  const isLive = article?.liveblog && article?.liveblog?.data?.status === 1
  const breakingCondition =
    !article?.isLiveBlog && isBreakingNews && timeDifference < 7200

  if (isLive || (isLive && isBreakingNews)) {
    return (
      <LiveBlogLabelContainer>
        <div className="blink-indicator" />
        <span className="live-blog-span">Live</span>
      </LiveBlogLabelContainer>
    )
  }
  if (breakingCondition) {
    return (
      <HeadlineLabelContainer
        hierarchyColor={hierarchyColor}
        className={className}
        label="Breaking"
      />
    )
  }
  if (isAnalysis) {
    return (
      <HeadlineLabelContainerPrimary
        hierarchyColor={hierarchyColor}
        className={className}
        label="Analysis"
        article={article}
      />
    )
  }
  if (isExplainer) {
    return (
      <HeadlineLabelContainerPrimary
        hierarchyColor={hierarchyColor}
        className={className}
        label="Explainer"
      />
    )
  }
  return null
}

HeadlineLabel.propTypes = {
  article: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default HeadlineLabel
