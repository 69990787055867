/*globals JSGlobals, tp */
import { pianoActiveChurnDomains, subscriptionPages } from '~/config/JPIMConfig'
import { buildSubscriptionRedirectUrl } from '~/section/utils/buildSubscriptionRedirect'
import { openAxateDayPass, openAxateLogin } from '~/util/axate'
import { getCookie, setCookie } from '~/util/cookie'
import {
  initDisqus,
  setDisqusLogin,
  setDisqusLogout,
  showLoggedInComments,
} from '~/util/disqus'
import { jsLoader } from '~/util/fileLoaders'
import { after_login_page } from '~/util/google/constant'
import { sendUserTags } from '~/util/oneSignal'
import {
  pianoHideArticleContent,
  pianoHideArticleContentMid,
  pianoHideCustomNewsletter,
  pianoHideHubspotNewsletter,
  pianoHideHubspotNewsletterRHR,
  pianoHideNewsletterByIds,
  pianoHidePuzzles,
  pianoRemoveArticleContent,
  pianoRemoveDisqusComments,
} from '~/util/pianoUtils'
import { serialize } from '~/util/serialize'
import { stripEmojis } from '~/util/strip-emojis'
import { setViafouraLogout } from '~/util/viafoura'

const MAX_CONTAINER_WIDTH = 984
const NEWSLETTER_MAX_WIDTH = 968
const DEFAULT_CONTAINER_WIDTH = 700
const DEFAULT_NEWSLETTER_WIDTH = 500
const PADDING = 20

export const isLogin = () => Boolean(getCookie('lgd_n'))
export const isPianoSubscriber = () => Boolean(getCookie('subscriber'))
export const login = () => {
  setCookie(after_login_page, location.href, 1)
  window.location = '/sign-in-popup'
}
export const openSubscriptionPage = () => {
  window.localStorage.setItem('page_after_subscription', window.location.href)
  window.open('/subscriptions', '_blank')
}

export const reloadWithRandomParam = (win = window) => {
  if ('URLSearchParams' in win) {
    var searchParams = new URLSearchParams(win.location.search)
    searchParams.set('r', Math.floor(Math.random() * 10000))
    win.location.search = searchParams.toString()
  } else {
    location.reload()
  }
}

const closeTemplateRedirect = (path) => {
  window.onmessage = (event) => {
    let event_data = JSON.parse(event.data)
    if (
      event_data.sender.startsWith('piano-id') &&
      event_data.event === 'closed'
    ) {
      window.location.href = '/'
      if (path === '/register') window.location.href = '/newsletter'

      // A user canceled the registration process
      window._cbq = window._cbq || []
      window._cbq.push(['trackRegistrationCancel'])
    }
  }
}

const pianoTestAdblockerEnabled = async () => {
  try {
    await jsLoader(['//www.npttech.com/advertising.js'], 'piano')
  } catch (e) {
    document.cookie = '__adblocker=true;path=/'
  }
}

/**
 * Sets the user identity for Permutive using the provided payload from Piano.
 *
 * This function checks if Permutive is initialized and the `identify` function is available.
 * It then validates the `user_id` from the payload and sends it to Permutive if valid.
 *
 * @param {Object} payload - The user payload containing the user ID.
 * @param {string} payload.user_id - The unique identifier for the user.
 *
 * @example
 * const payload = { user_id: '12345' }
 * setPermutiveUserIdentity(payload)
 *
 * @throws Will log an error if Permutive is not initialized, the `identify` function is missing,
 * or the user ID is invalid (null, undefined, or an empty string).
 */
const setPermutiveUserIdentity = (payload) => {
  if (
    window.permutive?.identify &&
    typeof window.permutive.identify === 'function'
  ) {
    if (!payload || typeof payload.user_id === 'undefined') {
      console.error('Payload or user_id is missing')
      return
    }

    const userId = payload.user_id

    // Check if user_id is not null, undefined, or an empty string as requested by Permutive
    if (userId !== null && userId !== undefined && userId !== '') {
      window.permutive.identify([
        {
          id: userId,
          tag: 'piano_user', // Needs to match the tag identifier added in the workspace settings by NW
          priority: 0,
        },
      ])
    } else {
      console.error(
        'User ID is null, undefined, or empty. Identity not passed.',
      )
    }
  } else {
    console.error('Permutive not initialized or identify function is missing')
  }
}

const loadPianoExtendedUserData = (initialState) =>
  new Promise((resolve) => {
    try {
      window.tp.pianoId.loadExtendedUser({
        extendedUserLoaded: (data) => {
          const userData = {}
          const postcode = data.custom_field_values?.find(
            ({ field_name }) => field_name === 'postcode',
          )
          if (postcode && postcode.value) {
            userData.postcode = postcode.value.slice(0, 4).toUpperCase()
          }
          resolve(Object.assign({}, initialState, userData))
        },
        formName: 'RegistrationFields',
      })
    } catch (ex) {
      console.error(ex)
      resolve(initialState)
    }
  })

export const getPianoUserData = async () => {
  const user = await tp.pianoId.getUser()
  if (user) {
    const payload = {}
    payload.user_id = user.uid
    payload.site = JSGlobals.domain
    payload.subscriber = 'false'
    payload.__amp_source_origin = window.location.origin

    return loadPianoExtendedUserData(payload).then((userPayload) => {
      return fetch(
        `/internal-api/subscription/post-checkout?${serialize(userPayload)}`,
      )
        .then((response) => response.json())
        .then(({ data, accesses }) => {
          if (data && data.length > 0) {
            // Find the index of the item with the desired rid format and remove it if needed
            const indexToRemove = data.findIndex(({ rid }) => {
              const parts = rid.split('_')
              return parts.length > 1 && parts[1] === 'registration'
            })

            // If indexToRemove is found, remove the item from the array
            if (indexToRemove !== -1) {
              data.splice(indexToRemove, 1)
            }

            const activeTerm = data.find(
              ({ access_expired }) => !access_expired,
            )
            if (activeTerm) {
              const monthShortNames = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ]
              const date = new Date(activeTerm.next_bill_date)
              const activeResource = activeTerm.rid
                .split(/_(.+)/)
                .filter((x) => x)
              const term = {
                term_id: activeTerm.subscription_id,
                custom_billing_plan: activeTerm.billing_plan,
                subscriber: 'true',
                resource: activeResource[1] || '',
                access_end_date: `${
                  monthShortNames[date.getMonth()]
                } ${date.getFullYear()}`,
              }
              return Promise.resolve(Object.assign({}, userPayload, term))
            }
          }
          if (accesses && accesses.length > 0) {
            // Find the index of the item with the desired rid format and remove it if needed
            const indexToRemove = accesses.findIndex(({ resource }) => {
              const { rid } = resource
              const parts = rid.split('_')
              return parts.length > 1 && parts[1] === 'registration'
            })

            // If indexToRemove is found, remove the item from the array
            if (indexToRemove !== -1) {
              accesses.splice(indexToRemove, 1)
            }

            const access = accesses.find(({ granted }) => granted)
            if (access) {
              const { resource } = access
              const activeResource = resource.rid
                .split(/_(.+)/)
                .filter((x) => x)
              const term = {
                term_id: '',
                custom_billing_plan: '',
                subscriber: 'true',
                resource: activeResource[1] || '',
                access_end_date: '',
              }
              return Promise.resolve(Object.assign({}, userPayload, term))
            }
          }
          return Promise.resolve(userPayload) // simplify object
        })
        .catch(() => Promise.resolve(userPayload))
    })
  }
  return Promise.reject()
}

export const setLoginCookie = (eventData = {}) => {
  if (!getCookie('lgd_n')) {
    setCookie('lgd_n', true, 30)
  }
  if (
    location.pathname.includes('/subscriptions') ||
    eventData.source === 'OFFER'
  ) {
    sessionStorage.removeItem('eventData')
    return
  }
  // prevents an infinite loop of reloads if a user has been redirected back successfully from
  // an id.piano.io domain
  if (!getCookie('sso_redirect_reloaded')) {
    setCookie('sso_redirect_reloaded', true, 30)
    // Commented out to prevent reloading so that new users can see the new email validation message.
    // NB. Cannot recreate the infinite loop of reloads after commenting this line.
    // Tested sign-up and sign-in via /profile, SSO, checkout flow, and Viafoura.
    // reloadWithRandomParam()
  }
}

const setUserLoginCookie = (user) => {
  if (getCookie('euid')) {
    return
  }

  const body = user
    ? JSON.stringify({
        id: user.uid,
        email: user.email,
        username: user.firstName + ' ' + user.lastName?.slice(0, 1),
      })
    : null
  fetch(
    `/internal-api/set-user-login?__amp_source_origin=${window.location.origin}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'same-origin',
      cache: 'no-cache',
      credentials: 'same-origin',
      body,
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error('Network response was not ok.')
    })
    .then((response) => {
      console.log(response)
      sendUserTags(user)
    })
    .catch((error) => {
      console.log('Fetch failed: ', error.message)
    })
}

export const userLogout = () => {
  const logout = setInterval(() => {
    if (window.tp && window.tp.pianoId) {
      setDisqusLogout()
      setViafouraLogout()
      tp.push([
        'init',
        () => {
          tp.pianoId.logout(() => {
            setCookie('lgd_n', '', -1)
            setCookie('subscriber', '', -1)
            setCookie('sub_resource', '', -1)
            setCookie('euid', '', -1)
            setCookie('__utp', '', -1)
            setCookie('sso_redirect_reloaded', '', -1)
            reloadWithRandomParam()
          })
        },
      ])
      clearInterval(logout)
    }
  }, 500)
}

const setLoggedInItems = (loggedIn, data) => {
  const loggedInItems = document.querySelectorAll('.menu-logged-in')
  const loggedOutItems = document.querySelectorAll('.menu-logged-out')
  const accountName = document.querySelectorAll('.piano-account-name')
  let userName = localStorage.getItem('username')
  const setHideClass = (items, hide = true) => {
    if (hide) {
      items.forEach((i) => {
        i.classList.add('hide')
      })
    } else {
      items.forEach((i) => {
        i.classList.remove('hide')
      })
    }
  }

  if (!isPianoSubscriber()) {
    const hiddenElements = document.querySelectorAll('.subscriber-hidden')
    hiddenElements.forEach((elem) => elem.classList.remove('subscriber-hidden'))
  }
  if (loggedIn) {
    if (data?.user?.firstName || data?.user?.lastName) {
      userName = `${data?.user?.firstName} ${data?.user?.lastName}`
      localStorage.setItem('username', userName)
    }
    if (userName) {
      const hasFirstName = userName.split(' ')[0] !== 'undefined'
      const userFirstName = stripEmojis(userName.split(' ')[0])
      const truncateFirstName =
        userFirstName.length > 21
          ? userFirstName.slice(0, 21) + '...'
          : userFirstName
      accountName.forEach((elem) => [
        (elem.innerText = !hasFirstName ? 'Welcome back' : truncateFirstName),
        hasFirstName && (elem.title = userFirstName),
      ])
    }

    setHideClass(loggedInItems, false)
    setHideClass(loggedOutItems)
    if (window.JSGlobals.disqus) {
      showLoggedInComments()
    }
    // make sure cookie is set
    if (!getCookie('lgd_n')) {
      setCookie('lgd_n', true, 30)
    }
  } else {
    setHideClass(loggedInItems)
    setHideClass(loggedOutItems, false)
    localStorage.removeItem('username')
  }
}

const isSubscriber = (term) => {
  if (term.subscriber === 'true') {
    setCookie('subscriber', true, 30)
    setCookie('sub_resource', term.resource || '', 30)
  } else {
    setCookie('subscriber', '', -1)
    setCookie('sub_resource', '', -1)
  }
}

const listenForPianoMessages = () => {
  window.addEventListener(
    'message',
    (event) => {
      try {
        const eventData = JSON.parse(event.data)
        const { type, value } = eventData
        const eventName = eventData?.params?.eventName

        // @todo - add whitelisting: e,g,
        // const whiteListed = [
        //   'tinypass.com',
        //   'https://sandbox.tinypass.com',
        // ]

        // if (whiteListed.find((domain) => event.origin.includes(domain))) {

        // @todo - replace this if same issue occurring SSO modals
        if (type === 'DIALOG_OPEN') {
          switch (value) {
            case 'CLOSE_VERIFICATION_MESSAGE': // 'Almost finished!' verification message
            case 'CONTINUE_SSO':
              reloadWithRandomParam()
              break
          }
        }
        if (eventName) {
          switch (eventName) {
            case 'axate-day-pass':
              openAxateDayPass()
              break
            case 'sign-in-axate':
              openAxateLogin()
              break
          }
        }
        if (eventData?.event === 'confirmationEmailSent') {
          // Reload page if user clicks 'RESEND'
          // after 1 second delay to read pop up message
          setTimeout(() => {
            reloadWithRandomParam()
          }, 1000)
        }
      } catch (ex) {
        // noop
      }
    },
    false,
  )
}

export const initPiano = (tag) => {
  const isMyNewslettersPage =
    window.location.pathname === '/profile/my-newsletters'

  const calculateWidth = () => {
    return isMyNewslettersPage
      ? // Full width needed on /profile/my-newsletters
        window.innerWidth > MAX_CONTAINER_WIDTH
        ? NEWSLETTER_MAX_WIDTH
        : window.innerWidth - PADDING
      : // Default width 500px elsewhere
        window.innerWidth > DEFAULT_CONTAINER_WIDTH
        ? DEFAULT_NEWSLETTER_WIDTH
        : window.innerWidth - PADDING
  }

  const gmssoConfig = {
    authType: 'redirect',
    confirmation: 'after',
  }

  const initConfig = {
    loggedIn: (data) => {
      const eventData = JSON.parse(sessionStorage.getItem('eventData') || '{}')
      setLoggedInItems(true, data)
      if (eventData.state !== 'checkoutCompleted') {
        setLoginCookie(eventData)
      } else {
        sessionStorage.removeItem('eventData')
      }
    },
    loggedOut: () => {
      setLoggedInItems(false)
    },
    width: calculateWidth(),
    gmsso: gmssoConfig,
    displayMode: 'redirect',
    confirmation: 'after',
  }

  tp.push(['setUsePianoIdUserProvider', true])
  // these functions are attached to window so they can
  // be accessed from piano experiences
  window.pianoHideArticleContent = pianoHideArticleContent
  window.pianoHideArticleContentMid = pianoHideArticleContentMid
  window.pianoRemoveArticleContent = pianoRemoveArticleContent
  window.pianoHideHubspotNewsletter = pianoHideHubspotNewsletter
  window.pianoHideHubspotNewsletterRHR = pianoHideHubspotNewsletterRHR
  window.pianoHideCustomNewsletter = pianoHideCustomNewsletter
  window.pianoHidePuzzles = pianoHidePuzzles
  window.pianoHideNewsletterByIds = pianoHideNewsletterByIds

  if (window.JSGlobals.disqus) {
    window.pianoRemoveDisqusComments = pianoRemoveDisqusComments
  }

  let tags = []
  if (tag) {
    tags.push(tag)
  }
  if (JSGlobals.article && JSGlobals.article.sections) {
    tags.push(JSGlobals.article.sections)
    if (JSGlobals.pageType === 'sponsored article') {
      tags.push('advertorial')
    }
    if (JSGlobals.sensitive) {
      tags.push('sensitive')
    }
    if (JSGlobals.premium) {
      tags.push('premium')
    }
  } else {
    if (JSGlobals.pageType === 'category' || JSGlobals.pageType === 'home') {
      tags.push('hub page')
    }
  }

  switch (location.pathname) {
    case '/newsletter':
      tags.push('newsletter')
      break
    case '/contact-us':
      tags.push('contact')
      break
    case '/about':
      tags.push('about')
      break
    case '/archive':
      tags.push('archive')
      break
    case '/profile':
      tags.push('profile')
      break
  }
  if (location.pathname.includes('subscriptions')) {
    tags.push('subscription')
  }
  tags.push(JSGlobals.pageType)

  // only reset password modal can run on reset page
  if (location.pathname.includes('/reset-password')) {
    tp.push(['setTags', ['reset password']])
  } else {
    tp.push(['setTags', tags])
  }

  // // @TODO Turn off debug
  // tp.push(['setDebug', true])

  tp.push([
    'init',
    () => {
      // ping GTM, piano object is loaded.
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: 'tpLoad' })
      window.dataLayer.push({
        event: 'subscriber_status',
        subscriber: isPianoSubscriber(),
      })

      tp.pianoId.init(initConfig)

      if (tp.pianoId.isUserValid()) {
        const user = tp.pianoId.getUser()
        if (user) {
          getPianoUserData().then((payload) => {
            isSubscriber(payload)
            setLoggedInItems(true, payload)
            setUserLoginCookie(user)
          })
          if (
            window.JSGlobals.disqus &&
            document.querySelector('#disqus_thread')
          ) {
            setDisqusLogin()
          }
        }
      } else {
        setLoggedInItems(false)
        setCookie('lgd_n', '', -1)
        setCookie('subscriber', '', -1)
        setCookie('sub_resource', '', -1)
        setCookie('euid', '', -1)
      }

      const modalParams = {}
      const isActiveChurn = pianoActiveChurnDomains.includes(JSGlobals.domain)

      if (location.pathname === '/profile') {
        modalParams.displayMode = 'inline'
        modalParams.containerSelector = '.piano-account-wrapper'

        if (!tp.user.isUserValid()) {
          tp.push([
            'init',
            () => {
              // Show Piano sign-in/up form
              tp.pianoId.show({
                displayMode: 'inline',
                containerSelector: '.piano-account-wrapper',
              })
            },
          ])

          closeTemplateRedirect(location.pathname)
        } else {
          return tp.myaccount.show({
            displayMode: 'inline',
            containerSelector: '.piano-account-wrapper',
            activateTemplateInteraction: isActiveChurn,
          })
        }
      }

      if (location.pathname === '/forgotten-password') {
        if (!tp.user.isUserValid()) {
          tp.push([
            'init',
            () => {
              tp.pianoId.show({
                displayMode: 'inline',
                containerSelector: '.piano-login-div',
                screen: 'restore',
              })
            },
          ])
        } else {
          // send user to My Profile page
          window.location.href = '/profile'
        }
      }

      if (isLogin() && subscriptionPages.includes(window.location.pathname)) {
        const path = window.location.pathname
        const pianoConfig =
          JSGlobals.mode === 'production'
            ? (JSGlobals?.piano?.prod ?? {})
            : (JSGlobals?.piano?.sandbox ?? {})

        const urlParams = new URLSearchParams(window.location.search)

        const {
          close,
          complete,
          containerSelector,
          customEvent,
          displayMode,
          loginRequired,
          showCloseButton,
          templateId,
          templateVariantId,
          termIds,
        } = pianoConfig

        // Extract URL parameters
        const checkoutFlowId = urlParams.get('checkoutFlowId')
        const offerId = urlParams.get('offerId')
        const promoCode = urlParams.get('promoCode')
        const termId = urlParams.get('termId')
        const targetedTermId = urlParams.get('targetedTermId')

        if (offerId) {
          // Check if the user has been redirected already during this session
          if (!sessionStorage.getItem('redirected')) {
            // Mark that the user has been redirected
            sessionStorage.setItem('redirected', 'true')

            const redirectUrl = buildSubscriptionRedirectUrl({
              checkoutFlowId,
              offerId,
              path,
              promoCode,
              termId,
              targetedTermId,
            })

            // Redirect the user to the subscription URL then return
            window.location.href = redirectUrl
            return
          } else {
            // If the user was already redirected, remove the marker then continue down the file
            sessionStorage.removeItem('redirected')
          }

          tp.push([
            'init',
            () => {
              try {
                tp.offer.show({
                  checkoutFlowId,
                  close,
                  complete,
                  containerSelector,
                  customEvent,
                  displayMode,
                  loginRequired,
                  offerId,
                  promoCode,
                  showCloseButton,
                  targetedTermId,
                  templateId,
                  templateVariantId,
                  termId,
                  termIds,
                })
              } catch (e) {
                console.error('Failed to show offer', e)
                window.location.href = '/subscriptions'
              }
            },
          ])
        }
      }

      if (location.pathname === '/profile/my-newsletters') {
        if (!tp.user.isUserValid()) {
          tp.push([
            'init',
            () => {
              tp.pianoId.show({
                displayMode: 'inline',
                containerSelector: '.piano-account-wrapper',
                loggedIn: () => {
                  window.location.pathname = '/profile/my-newsletters'
                  location.reload() // Refresh the page
                },
              })
            },
          ])
        }
      }

      if (
        location.pathname === '/register' ||
        location.pathname === '/sign-in' ||
        location.pathname === '/reset-password'
      ) {
        modalParams.displayMode = 'inline'
        modalParams.containerSelector = '.piano-login-div'
        if (location.pathname === '/register') {
          modalParams.screen = 'register'
        }
        if (location.pathname === '/reset-password') {
          modalParams.screen = 'reset password'
        }
        closeTemplateRedirect(location.pathname)
        if (!tp.user.isUserValid()) {
          tp.push([
            'init',
            () => {
              tp.pianoId.show({
                ...modalParams,
              })
            },
          ])
        } else {
          window.location.href = '/profile'
        }
      }

      if (location.search.includes('loginform=true')) {
        modalParams.screen = 'sign-in'
        tp.push([
          'init',
          () => {
            tp.pianoId.show({ ...modalParams })
          },
        ])
      }
      if (location.search.includes('forgotpassword=true')) {
        modalParams.screen = 'restore'
        tp.push([
          'init',
          () => {
            tp.pianoId.show({ ...modalParams })
          },
        ])
      }
      if (location.search.includes('registration=true')) {
        modalParams.screen = 'register'
        tp.push([
          'init',
          () => {
            tp.pianoId.show({ ...modalParams })

            // A user started the registration process
            window._cbq = window._cbq || []
            window._cbq.push(['trackRegistrationStart'])
          },
        ])
      }
      if (location.search.includes('logout=true')) {
        userLogout()
      }
    },
    listenForPianoMessages(),
  ])

  tp.push([
    'addHandler',
    'onShow',
    () => {
      // Listen for modal to be closed and reload with param
      const tpModal = document.querySelector('.tp-modal')
      if (tpModal) {
        const closeButton = tpModal.querySelector('.tp-close')
        if (closeButton) {
          closeButton.addEventListener('click', () => {
            reloadWithRandomParam()
          })
        }
      }

      let iframes = document.querySelectorAll('iframe')
      iframes.forEach((i) =>
        i.name.includes('piano-id')
          ? i.setAttribute('data-cy', 'signin-form')
          : {},
      )

      // A user viewed a registration screen
      window._cbq = window._cbq || []
      window._cbq.push(['trackRegistrationShown'])
    },
  ])

  tp.push([
    'addHandler',
    'checkoutClose',
    (event) => {
      if (event?.state === 'close') {
        return
      }
      /* Default behavior is to refresh the page on successful checkout */
      if (event?.state == 'checkoutCompleted') {
        sessionStorage.setItem('eventData', JSON.stringify(event))
        // use location target if available on localStorage, google extended access use this..
        var targetLocation = 'page_after_subscription'
        var target = localStorage.getItem(targetLocation)

        if (target) {
          localStorage.setItem(targetLocation, '')
          location.href = target
          return false
        }

        // default checkout handler
        if (location.pathname.includes('/subscriptions')) {
          var url_params = new URLSearchParams(location.search)
          var return_url = url_params.get('redirect')
          if (return_url) {
            location.href = return_url
          } else {
            location.reload()
          }
        }

        reloadWithRandomParam()
      } else {
        location.reload()
      }
    },
  ])

  tp.push([
    'addHandler',
    'checkoutComplete',
    () => {
      getPianoUserData().then((payload) => {
        isSubscriber(payload)
        window.PARSELY.conversions.trackSubscription(
          'Subscription is successful',
        )
      })

      // A user completed a subscription transaction
      window._cbq = window._cbq || []
      window._cbq.push(['trackPaywallComplete'])
    },
  ])

  tp.push([
    'addHandler',
    'registrationSuccess',
    () => {
      window.PARSELY.conversions.trackLeadCapture('Registration is successful')

      // A user completed the registration process
      window._cbq = window._cbq || []
      window._cbq.push(['trackRegistrationComplete'])
    },
  ])

  tp.push([
    'addHandler',
    'loginSuccess',
    (eventData) => {
      const isEmailConfirmationRequired =
        eventData?.params?.email_confirmation_required
      sessionStorage.setItem('eventData', JSON.stringify(eventData))
      getPianoUserData()
        .then((payload) => {
          setPermutiveUserIdentity(payload)
          isSubscriber(payload)
          setLoggedInItems(true, payload)
          setLoginCookie(eventData)
          if (isEmailConfirmationRequired) {
            listenForPianoMessages()
          }
          if (eventData.source !== 'OFFER' && !isEmailConfirmationRequired) {
            reloadWithRandomParam()
          }
        })
        .catch(() => {
          setLoginCookie(eventData)
        })
    },
  ])

  pianoTestAdblockerEnabled()
}

export const loadPiano = async () => {
  const tinypass = (() => {
    try {
      const appId =
        JSGlobals.mode === 'production'
          ? JSGlobals.piano.prod.appId
          : JSGlobals.piano.sandbox.appId

      if (!appId) {
        throw new Error('AppId not found at time of loadPiano execution')
      }

      const baseUrl =
        JSGlobals.mode === 'production'
          ? '//experience.tinypass.com/xbuilder/experience/load'
          : '//sandbox.tinypass.com/xbuilder/experience/load'

      return [`${baseUrl}?aid=${appId}`]
    } catch (error) {
      console.error(error.message)
      return null
    }
  })()

  window.tp = window.tp || []
  window.cX = window.cX || { options: { tcf20: true } }

  setLoggedInItems(getCookie('lgd_n'))

  if (window.JSGlobals.disqus && document.querySelector('#disqus_thread')) {
    initDisqus()
  }

  document.querySelectorAll('.login-button').forEach((btn) =>
    btn.addEventListener('click', () => {
      const initAndShowPianoId = (attempt = 1) => {
        if (typeof tp !== 'undefined' && typeof tp.pianoId !== 'undefined') {
          tp.pianoId.init({
            width:
              window.innerWidth > DEFAULT_CONTAINER_WIDTH
                ? DEFAULT_NEWSLETTER_WIDTH
                : window.innerWidth - PADDING,
          })
          tp.pianoId.show({ flow: 'gmsso' })
          document.querySelector('.close-menu').click()
        } else if (attempt <= 5) {
          setTimeout(() => initAndShowPianoId(attempt + 1), 100)
        } else {
          console.error('tp or tp.pianoId is not defined after 5 attempts.')
        }
      }
      initAndShowPianoId()
    }),
  )

  // Hide double scroll bar when Piano modal is open
  const bodyElement = document.body
  const htmlElementClassList = document.querySelector('html').classList
  const observerConfig = { attributes: true }
  const callback = (mutationList) => {
    for (const mutation of mutationList) {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class'
      ) {
        bodyElement.classList.contains('tp-modal-open')
          ? htmlElementClassList.add('overflow-hidden')
          : htmlElementClassList.remove('overflow-hidden')
      }
    }
  }
  const observer = new MutationObserver(callback)
  observer.observe(bodyElement, observerConfig)

  document.querySelectorAll('.register-button').forEach((btn) =>
    btn.addEventListener('click', () => {
      tp.pianoId.show({ screen: 'register' })
      document.querySelector('.close-menu').click()
    }),
  )

  document.querySelectorAll('.logout-button').forEach((btn) =>
    btn.addEventListener('click', () => {
      userLogout()
    }),
  )

  if (/^\/sign-in-popup$/.test(location.pathname)) {
    tp.push([
      'init',
      () => {
        tp.pianoId.show({
          displayMode: 'inline',
          containerSelector: '.piano-login-div',
          loggedIn: () => {
            const afterLogin = document.getElementById('piano-logged-in')
            if (afterLogin) {
              afterLogin.classList.add('show')
            }
            const target = getCookie(after_login_page)
            if (target) {
              setCookie(after_login_page, '', -1)
              location = `${target}${
                /\?/.test(target) ? '&' : '?'
              }r=${Math.floor(Math.random() * 10000)}`
            } else {
              location = '/'
            }
          },
        })
      },
    ])
  }

  if (location.pathname.includes('reset-password')) {
    // reset password from piano
    tp.push([
      'init',
      () => {
        // Password can be reset only if user is anonymous
        if (!tp.user.isUserValid()) {
          // If URL has reset_token parameter
          var tokenMatch = location.search.match(/reset_token=([A-Za-z0-9]+)/)
          if (tokenMatch) {
            // Get value of the token
            var token = tokenMatch[1]
            // Present password reset form with the found token
            tp.pianoId.show({
              resetPasswordToken: token,
              loggedIn: () => {
                // Once user logs in - refresh the page
                location.reload()
              },
            })
          }
        } else {
          // send user back to homepage
          window.location.href = '/'
        }
      },
    ])
  }

  return jsLoader(tinypass, 'piano')
}
