import React from 'react'
import styled from 'styled-components'

const ImageWrapper = styled.figure`
  margin: 0 0 20px 0;

  & img {
    max-width: 100%;
    height: auto;
  }
`

const Caption = styled.figcaption`
  color: ${({ theme }) => theme.fontColorLight};
  text-align: left;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  line-height: 20px;
  padding: 6px 0;
`

const PolarImage = () => {
  return (
    <ImageWrapper>
      <img id="native-content-media" src="" alt="" />
      <Caption>
        <h4 id="native-content-media-caption" />
        <h4 id="native-content-media-credits" />
      </Caption>
    </ImageWrapper>
  )
}

export default PolarImage
