import { ArticleAPI } from '~/types'
import gpt from '~/util/gpt'

export const initAds = (
  article?: ArticleAPI | null,
  secondLevelDomainName?: string,
) =>
  new Promise((resolve) => {
    const init = () => {
      gpt(article, secondLevelDomainName).then(resolve)
    }

    if (typeof window.loadAudienceProjectPredictions === 'function') {
      window.loadAudienceProjectPredictions('sakjohnston', init, {
        consentString: window.JSGlobals && window.JSGlobals.consentString,
        gdprApplies: window.JSGlobals && window.JSGlobals.gdprApplies,
      })
    } else {
      setTimeout(init)
    }
  })
