import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
// @todo - rebuild with Taboola if this feature is worked on again
import Outbrain from '~/library/ThirdParty/Outbrain'
import { EndlessScrollListener } from '~/js/util/endless-scroll-listener'
import { extractSubstring } from '~/util/extract-substring'
import {
  AdConfigData,
  AdManagerConfig,
  ArticleAPI,
  Domain,
  ParsleyRelatedArticleData,
} from '~/types'
import { initAds } from '../initAds'
import {
  dailymotionArticle,
  destroyAllDailymotionPlayers,
} from '~/util/dailymotion'
import { pageTitle } from '~/util/pageTitle'
import { getDataLayer } from '~/util/dataLayer'
import ArticleContent from '~/library/Article/Content'
import { getAdManagerArticleConfig } from '~/providers/AdManager/utils/getAdManagerArticleConfig'
import { setAdSlotIncrement } from '~/providers/AdManager/utils/getAdSlotIncrement'

const ArticleContainer = styled.div`
  padding-top: 20px;
`

type InfiniteScrollProps = {
  domain: Domain
  configuration: any
  article: ArticleAPI
  adTargeting: any
  jsGlobals: any
  articleTitle: string
  isPremiumArticleLayout?: boolean
}

enum InfiniteScrollState {
  Initialising,
  Idle,
  LoadingNextArticle,
  Error,
  End,
}

const endlessScrollListenerBottomOptions = {
  root: null,
  rootMargin: '0px 0px 50% 0px',
  threshold: 0,
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  domain,
  configuration,
  article,
  adTargeting,
  jsGlobals,
  isPremiumArticleLayout,
}) => {
  const [initialUrl] = useState<string>(window.location.href)
  const [articleLimit, setArticleLimit] = useState<number>(10)
  const articleRef = useRef<IntersectionObserver | null>(null)
  const [infiniteScrollState, setInfiniteScrollState] =
    useState<InfiniteScrollState>(InfiniteScrollState.Initialising)
  const [relatedArticlesData, setRelatedArticlesData] = useState<
    Array<ParsleyRelatedArticleData>
  >([])
  const [articlePosition, setArticlePosition] = useState(-1)
  const [articlesToDisplay, setArticlesToDisplay] = useState<Array<ArticleAPI>>(
    [],
  )

  const getNextArticleData = (index: number) => {
    setInfiniteScrollState(InfiniteScrollState.LoadingNextArticle)
    const nextArticle = relatedArticlesData[index]

    if (!nextArticle) {
      setInfiniteScrollState(InfiniteScrollState.End)
      return Promise.reject(null)
    }

    const articleSlug = extractSubstring(nextArticle.url, domain)

    return fetch(`/api/article?domain=${domain}&path=${articleSlug}`)
      .then((res) => {
        return res.json()
      })
      .catch(() => {
        setInfiniteScrollState(InfiniteScrollState.Error)
      })
  }

  const isValidInfiniteScrollArticle = (nextArticle: ArticleAPI) => {
    return (
      typeof nextArticle !== 'undefined' &&
      !!nextArticle.body?.length &&
      nextArticle.status === true &&
      nextArticle.articleTypes[0]?.name === 'News'
    )
  }

  const reinitialiseDailymotion = () => {
    destroyAllDailymotionPlayers()
    setTimeout(dailymotionArticle, 800)
  }

  const handleTopNotVisible = () => {
    window.history.pushState(null, '', `${article.path}?layout=is`)
  }

  const handleBottomVisible = () => {
    if (infiniteScrollState !== InfiniteScrollState.Idle) {
      return
    }

    setArticlePosition(articlePosition + 1)
    // @todo could this use the logic in DailymotionHydratedPost and extract?
    const maxRetries = 3
    let retries = 0

    const fetchNextArticle = (articlePos: number) => {
      getNextArticleData(articlePos).then((nextArticle: ArticleAPI) => {
        // @todo - remove when infinite scroll feature goes live
        console.log('ArticleId', nextArticle.id)

        if (!isValidInfiniteScrollArticle(nextArticle)) {
          if (retries < maxRetries && articlePos < articleLimit) {
            retries++
            setArticlePosition(articlePos + 1)
            fetchNextArticle(articlePos + 1) // retry with a different article
          } else {
            setInfiniteScrollState(InfiniteScrollState.End)
          }
          return
        }

        // the article is valid, proceed as before
        setArticlesToDisplay((articlesToDisplay) => [
          ...articlesToDisplay,
          nextArticle,
        ])

        setAdSlotIncrement(articlePosition + 1)

        window.dataLayer?.push({
          event: 'infinite scroll pageview',
          ...getDataLayer(jsGlobals || window?.JSGlobals, nextArticle),
          infiniteScroll: 1,
          articlePosition: articlePosition + 1,
          documentTitle: pageTitle(
            { article: nextArticle },
            (jsGlobals || window?.JSGlobals)?.title,
          ),
          documentPath: nextArticle.path,
          documentLocation: `https://${domain}${nextArticle.path}`,
          contributor:
            nextArticle.contentSource?.some(({ name }) => name === 'UGC') ??
            false,
          initialUrl,
        })

        initAds(nextArticle)

        // @todo - rebuild with Taboola if this feature is worked on again
        // make sure the Outbrain widgets are available before calling the OBR function
        const recursiveCheckForOutbrainWidgets = (counter: number) => {
          if (window.OBR) {
            window.OBR.extern.researchWidget()
          } else if (counter > 0) {
            setTimeout(recursiveCheckForOutbrainWidgets, 100, counter - 1)
          }
        }
        recursiveCheckForOutbrainWidgets(100)

        setInfiniteScrollState(() => {
          if (articlePosition >= articleLimit) {
            return InfiniteScrollState.End
          }

          return InfiniteScrollState.Idle
        })
      })
    }

    fetchNextArticle(articlePosition + 1)
  }

  useEffect(() => {
    fetch(`/api/related?${new URLSearchParams({ url: article.path })}`)
      .then((res) => res.json())
      .then((data) => {
        setInfiniteScrollState(InfiniteScrollState.Idle)

        // check that there are no duplicates of initial article
        const uniqueArticlesArray = data.items.filter(
          (retrievedArticle: { url: string }) =>
            extractSubstring(retrievedArticle.url, domain, '?itm') !==
            article.path,
        )

        // check that there are no duplicates within the data itself
        const filteredArticlesArray: ParsleyRelatedArticleData[] = []
        const articleTitles = new Set()

        for (const article of uniqueArticlesArray) {
          // we have to check the article title here as sometimes the same article has a different ID in the URL
          if (!articleTitles.has(article.title)) {
            filteredArticlesArray.push(article)
            articleTitles.add(article.title)
          }
        }

        // @todo - remove when infinite scroll feature goes live
        console.log({ filteredArticlesArray })

        setRelatedArticlesData(filteredArticlesArray)
        setArticleLimit(filteredArticlesArray.length)
      })
      .catch((err) => {
        setInfiniteScrollState(InfiniteScrollState.Error)
        console.error(err)
      })
  }, [])

  useEffect(() => {
    articleRef.current = new IntersectionObserver(
      (entries) => {
        const newArticle = entries.filter(
          ({ isIntersecting }) => isIntersecting,
        )[0]

        if (!newArticle?.target) {
          // if there is no article, ignore
          return
        }

        window.history.pushState(
          null,
          '',
          `${newArticle.target.getAttribute('data-path')}?layout=is`,
        )
      },
      {
        rootMargin: '-50% 0px -50% 0px',
        threshold: 0,
      },
    )

    return () => {
      if (articleRef.current) {
        articleRef.current.disconnect()
      }
    }
  }, [])

  const observeDiv = (div: HTMLDivElement | null) => {
    if (div && articleRef.current) {
      articleRef.current.observe(div)
    }
  }
  const [isLastInfiniteScrollArticle, setIsLastInfiniteScrollArticle] =
    useState<boolean>(false)

  useEffect(() => {
    const isLast = articlePosition === articleLimit - 1
    setIsLastInfiniteScrollArticle(isLast)
  }, [articlePosition, articleLimit])

  return (
    <>
      <EndlessScrollListener
        bottomISOptions={endlessScrollListenerBottomOptions}
        onTopNotVisible={handleTopNotVisible}
        onBottomVisible={handleBottomVisible}
      >
        {articlesToDisplay.map((nextArticle: ArticleAPI, index: number) => {
          const articleTitle = pageTitle(
            { article: nextArticle },
            configuration.title,
          )

          if (!nextArticle.body?.length) {
            return <></>
          }

          const dailymotionVideo = nextArticle.hero?.find(
            (dm) => dm.type === 'dailymotion',
          )

          if (dailymotionVideo) {
            reinitialiseDailymotion()
          }

          const articleAdConfig: AdManagerConfig = getAdManagerArticleConfig(
            article.adConfig as AdConfigData,
            domain,
            nextArticle.body?.length,
          )

          return (
            <ArticleContainer
              key={index}
              ref={observeDiv}
              data-path={nextArticle.path}
            >
              <ArticleContent
                domain={domain}
                configuration={configuration}
                article={nextArticle}
                adConfig={articleAdConfig}
                adTargeting={adTargeting}
                articleTitle={articleTitle}
                isPremiumArticleLayout={isPremiumArticleLayout}
                isInfiniteScrollEnabled
                isLastInfiniteScrollArticle={isLastInfiniteScrollArticle}
              />
            </ArticleContainer>
          )
        })}
      </EndlessScrollListener>
      <Outbrain
        src={`${configuration.baseUrl}${article.path}`}
        id="AR_1"
        subscriberId="AR_20"
        isSubscriber={configuration.isSubscriber}
      />
    </>
  )
}

export default InfiniteScroll
