/**
 * The `debounce` function helps limit the frequency of function calls by delaying execution
 * until a specified time has passed without additional calls.
 * @param callback - The `callback` parameter is a function that will be executed after a certain delay
 * when the debounced function is called.
 * @param {number} delay - The `delay` parameter specifies the time in milliseconds for which the
 * execution of the `callback` function should be delayed after the debounce function is called.
 * @returns A function that, when called, will execute the provided callback function after a specified
 * delay, ensuring that the callback is not called multiple times within that delay period.
 */
export const debounce = (callback: () => void, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      callback()
    }, delay)
  }
}
