import { isNode } from 'browser-or-node'
import { getIdFromUrl } from '~/library/helper'
import { HTMLElement, parse } from 'node-html-parser'
import { nationalWorldDomain } from '~/config/JPIMConfig'

export const modifyMarkupContent = (
  markup: string,
  isSponsored?: boolean,
  currentDomain?: string,
) => {
  const dom: HTMLElement | Document | null = (() => {
    if (!markup) {
      return null
    }

    if (isNode) {
      return parse(markup)
    }

    const DomParser = new DOMParser()
    return DomParser.parseFromString(markup, 'text/html')
  })()

  dom?.querySelectorAll('a').forEach((element: any) => {
    const id = getIdFromUrl(element.getAttribute('href'))
    const baseAttrs: { rel?: string; target?: string; class?: string } = {}

    const href = element?.getAttribute('href')
    const domainMatch = href.includes(currentDomain)
    const isExternalLink =
      href?.startsWith('http') ||
      href?.startsWith('www') ||
      href?.startsWith('//')
    const originalRel = element.getAttribute('rel')
    const doesNWDomainIncludeHref = nationalWorldDomain.some((domain) =>
      href?.includes(domain),
    )

    if (doesNWDomainIncludeHref && originalRel === 'nofollow') {
      return element.removeAttribute('rel')
    }

    let getValue = element.getAttribute('rel')

    getValue = getValue
      ?.split(' ')
      .filter((value: string) => value !== 'nofollow')
      .join(' ')

    if (isExternalLink && domainMatch) {
      element.setAttribute('rel', getValue)
    } else if (isExternalLink && !doesNWDomainIncludeHref) {
      baseAttrs.target = '_blank'
    }

    // Set the REL attribute based on the originalRel value
    if (originalRel !== undefined) {
      baseAttrs.rel = originalRel
    }

    // Remove REL attribute if it's undefinied
    if (baseAttrs.rel === undefined) {
      element.removeAttribute('rel')
    } else {
      element.setAttribute('rel', baseAttrs.rel)
    }

    // Set the CLASS for external links
    if (isExternalLink && !domainMatch && !doesNWDomainIncludeHref) {
      baseAttrs.class = 'external-link'
    }

    const attributes: Record<string, string | null | undefined> = (() => {
      if (id) {
        return {
          ...baseAttrs,
          'data-vars-event': 'gaEvent',
          'data-vars-ec': 'navigation',
          'data-vars-ea': 'in article',
          'data-vars-el': 'plain links',
          'data-vars-aidclick': id,
          'data-vars-titleclick': element.textContent,
          'data-vars-urlclick': element.getAttribute('href'),
        }
      }

      return {
        ...baseAttrs,
        'data-vars-event': 'gaEvent',
        'data-vars-ec': 'navigation',
        'data-vars-ea': 'in article - outbound',
        'data-vars-el': element.getAttribute('href'),
      }
    })()

    Object.keys(attributes).forEach((key) => {
      if (attributes[key]) {
        element.setAttribute(key, attributes[key] || '')
      }
    })
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // unfortunately, the type definition of node-html-parser doesn't include the outerHTML property
  return dom?.firstChild?.outerHTML || dom?.firstChild?.toString() || ''
}
