import React from 'react'
import styled from 'styled-components'

const Entale = styled(({ url, className }) => (
  <div className={className}>
    {/* @todo remove AMP
     eslint-disable-next-line @typescript-eslint/ban-ts-comment
     @ts-ignore */}
    <amp-iframe
      src={url}
      height={600}
      width={600}
      sandbox="allow-scripts allow-same-origin"
      frameBorder="0"
      layout="responsive"
    />
  </div>
))`
  clear: both;
  margin: 20px 0;
`

export default Entale
