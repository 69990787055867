import React from 'react'
import styled from 'styled-components'
import { renderBody } from '~/library/Article/helper'
import { ArticleData, Configuration, Domain } from '~/types'

type ProductVerdictProps = {
  article: ArticleData
  newTemplate: boolean
  configuration: Configuration
  domain: Domain
}

const Root = styled.div``

const ProductVerdict = ({
  article,
  newTemplate,
  // @todo remove dependencies once render function refactored
  configuration,
  domain,
}: ProductVerdictProps) => {
  if (!newTemplate || !article?.extra?.verdict) {
    return null
  }
  return (
    <Root>
      {renderBody(article.extra.verdict, article, configuration, domain)}
    </Root>
  )
}

export default ProductVerdict
