import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { encodedExternalLinkIconBlack } from '~/icons/externalLinkIcon'
import { modifyMarkupContent } from './Markup.utils'

const TableWrapper = styled.div`
  table {
    border-collapse: collapse;
    margin-bottom: 16px;
    line-height: 1.2em;
    display: inline-block;
    overflow-x: auto;
    position: relative;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.greyContrast};
  }

  table tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.greyContrast};
  }

  table tr:nth-child(even) {
    background-color: ${({ theme }) => theme.lightContrast};
  }

  tr:first-child td:last-child {
    border-top-right-radius: 4px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  td {
    padding: 10px 8px 8px;
    border: 1px solid ${({ theme }) => theme.greyContrast};
  }

  a {
    color: ${({ theme }) => theme.fontColor};
    font-weight: 700;
    text-decoration: none;
    position: relative;
  }

  a::before {
    content: ''; /* pseudo-element for the underline */
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #333333;
  }

  a[rel*='nofollow']:after {
    display: none;
  }

  .external-link[rel*='nofollow']:after,
  .external-link[rel*='sponsored']:after,
  .external-link[rel*='ugc']:after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 14px;
    background: url(${encodedExternalLinkIconBlack}) 50% 50% no-repeat;
    background-size: 100%;
    margin-left: 2px;
  }
`

const Table = ({ data: { markup }, isSponsored = false, currentDomain }) => {
  const strippedMarkup = markup?.replaceAll('<p>', '').replaceAll('</p>', '')
  const modifiedMarkup = modifyMarkupContent(
    strippedMarkup,
    isSponsored,
    currentDomain,
  )

  return <TableWrapper dangerouslySetInnerHTML={{ __html: modifiedMarkup }} />
}

Table.propTypes = {
  data: PropTypes.object,
  isSponsored: PropTypes.bool,
  currentDomain: PropTypes.string,
}

export default Table
