import React from 'react'
import styled from 'styled-components'
import { Button } from '~/embeds/Button'
import shoppingCart from '~/icons/shoppingCart'

const ShoppingCart = styled.span`
  height: 20px;
  width: 20px;
`

const BuyButton = ({ href }: { href: string }) => {
  return (
    <Button
      className="article-buy-now"
      data-vars-event="gaEvent"
      data-vars-ec="navigation"
      data-vars-ea="affiliate buy button"
      data-vars-el="plain links"
      data-vars-urlclick={href}
      href={href}
      target="_blank"
      bold
      textL
      fullWidthMobile
      backgroundColour="orangeContrast"
    >
      <ShoppingCart dangerouslySetInnerHTML={{ __html: shoppingCart }} />
      Buy now
    </Button>
  )
}

export default BuyButton
