export const nationalWorld = [
  {
    image: '/assets/static/National-World-Today.png',
    section: 'NationalWorld Today',
    text: 'The day’s headlines and highlights emailed direct to you every morning',
    opt_in: 'nwld_opt_in',
    frequency: 'Daily',
  },
  {
    image: '/assets/static/nw-from-the-editor.png',
    section: 'From the Editor',
    text: 'An expert perspective on the UK’s biggest talking points.',
    opt_in: 'nwld_lfte_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/National-World-Insider.png',
    section: 'The Insider',
    text: 'Exclusive content for registered users - including regular features from the newsroom, promotions, competitions, surveys, giveaways and event invitations.',
    opt_in: 'nwld_marketing_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/National-World-Sport.png',
    section: 'World of Sport',
    text: 'Sharp analysis and humorous distractions from our sports writers',
    opt_in: 'nwld_sport_opt_in',
    frequency: 'Daily',
  },
]
