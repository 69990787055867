import curry from 'ramda/src/curry'
import { getAdSlotIncrementAppendix } from '~/providers/AdManager/utils/getAdSlotIncrement'

/** Ad sizes */
export const ALLOWED_REFRESH_SIZE = [
  [970, 250],
  [728, 90],
  [300, 600],
  [300, 250],
  [320, 50],
]

export const MULTI_AD_SIZES_DESKTOP = [
  [970, 250],
  [728, 90],
  [300, 250],
]

/** Ad slot variables */
export const getSlots = () => {
  const slotIdAppendix = getAdSlotIncrementAppendix()

  return {
    GPT_ADROW: 'adRow' + slotIdAppendix,
    GPT_ADSECTION: 'adSection' + slotIdAppendix,
    GPT_ARTICLEADSLOT_LITE: 'liteArticleAdSlot' + slotIdAppendix,
    GPT_ARTICLEMPU: 'articleMPU' + slotIdAppendix,
    GPT_ARTICLEMPU_LITE: 'liteArticleMPU' + slotIdAppendix,
    GPT_BILLBOARD: 'billboard' + slotIdAppendix,
    GPT_BLOG: 'blog' + slotIdAppendix,
    GPT_BLOGMPU: 'blogMpu' + slotIdAppendix,
    GPT_BOTTOM: 'bottom' + slotIdAppendix,
    GPT_BOTTOMBANNER: 'bottomBanner' + slotIdAppendix,
    GPT_BOTTOMBANNER_LITE: 'liteBottomBanner' + slotIdAppendix,
    GPT_EXTRALB: 'extralb' + slotIdAppendix,
    GPT_LEADERBOARD: 'leaderboard' + slotIdAppendix,
    GPT_MPU: 'mpu' + slotIdAppendix,
    GPT_NATIVE: 'nativeBanner' + slotIdAppendix,
    GPT_NATIVE_LITE: 'liteNativeBanner' + slotIdAppendix,
    GPT_SEEDTAG: 'adSlotSeedTag' + slotIdAppendix,
    GPT_SEEDTAG_LITE: 'liteAdSlotSeedTag' + slotIdAppendix,
    GPT_SIDEBARMPU: 'sidebarMPU' + slotIdAppendix,
    GPT_SIDEBARMPU_LITE: 'liteSidebarMPU' + slotIdAppendix,
    GPT_SIDEBARONSCROLL: 'sidebar-onscroll' + slotIdAppendix,
    GPT_MANTISIB1: 'mantis_adslot1' + slotIdAppendix,
    GPT_MANTISIB2: 'mantis_adslot2' + slotIdAppendix,
    GPT_MANTISIB_LITE: 'liteAdSlotMantis' + slotIdAppendix,
    GPT_TOP: 'top' + slotIdAppendix,
    GPT_TOPBANNER: 'topBanner' + slotIdAppendix,
    GPT_TOPBANNER_LITE: 'liteTopBanner' + slotIdAppendix,
    GPT_WALLPAPER: 'adSlotWallpaper' + slotIdAppendix,
    GPT_WALLPAPER_LITE: 'liteAdSlotWallpaper' + slotIdAppendix,

    ARTICLEMPU1: 'articleMPU1' + slotIdAppendix,
    ARTICLEMPU2: 'articleMPU2' + slotIdAppendix,
    ARTICLEMPU3: 'articleMPU3' + slotIdAppendix,
    ARTICLEMPU4: 'articleMPU4' + slotIdAppendix,
    ARTICLEMPU5: 'articleMPU5' + slotIdAppendix,
    MPUD: 'mpuD' + slotIdAppendix,
    MPUD0: 'mpuD0' + slotIdAppendix,
    MPUD1: 'mpuD1' + slotIdAppendix,
    MPUS: 'mpuS' + slotIdAppendix,
    MPUS2: 'mpuS2' + slotIdAppendix,
    MPUS3: 'mpuS3' + slotIdAppendix,
    MPUS4: 'mpuS4' + slotIdAppendix,
    MPUS5: 'mpuS5' + slotIdAppendix,
    MPUS6: 'mpuS6' + slotIdAppendix,
    MPUS7: 'mpuS7' + slotIdAppendix,
    MPUS8: 'mpuS8' + slotIdAppendix,
    MPUS9: 'mpuS9' + slotIdAppendix,
    MPUS10: 'mpuS10' + slotIdAppendix,
    MPUS11: 'mpuS11' + slotIdAppendix,
    SIDEBARMPU1: 'sidebarMPU1' + slotIdAppendix,
    SIDEBARMPU2: 'sidebarMPU2' + slotIdAppendix,
    SIDEBARMPU3: 'sidebarMPU3' + slotIdAppendix,
    SIDEBARMPU4: 'sidebarMPU4' + slotIdAppendix,

    // New slot naming conventions for Project Unify
    GPT_MIDBANNER: 'midbanner' + slotIdAppendix,
    GPT_MULTISIZE: 'multisize' + slotIdAppendix,
    MPUO1: 'mpuo1' + slotIdAppendix,
    MPUO2: 'mpuo2' + slotIdAppendix,
    MPUO3: 'mpuo3' + slotIdAppendix,
    MPUO4: 'mpuo4' + slotIdAppendix,
    MPUO5: 'mpuo5' + slotIdAppendix,
    PHOTOMPU: 'photoMPU' + slotIdAppendix,
  }
}

/** FLOW ad config values */
export const ADCONFIG_DESKTOP = 'desktop'
export const ADCONFIG_DESKTOP_LITE = 'desktopAdlite'
export const ADCONFIG_MOBILE = 'mobile'
export const ADCONFIG_MOBILE_LITE = 'mobileAdlite'
export const ADCONFIG_AMP = 'amp'
export const ADCONFIG_AMP_LITE = 'ampLite'

export const ADSLOT_TOPBANNER = 'top_banner'
export const ADSLOT_WALLPAPER = 'wallpaper'
export const ADSLOT_SEEDTAG = 'seedtag'
export const ADSLOT_NATIVE = 'native'
export const ADSLOT_NATIVE_SECTION = 'native_section'
export const ADSLOT_BOTTOM = 'bottom_banner'
export const ADSLOT_MANTIS_1 = 'teads_adslot_1'
export const ADSLOT_MANTIS_2 = 'teads_adslot_2'
export const ADSLOT_SIDEBAR = 'sidebar'
export const ADSLOT_VIDEO = 'video'
export const ADSLOT_3X3 = 'ad_slot'
export const ADSLOT_ROW_BILLBOARD = 'ad_row_billboard'
export const ADSLOT_ON_SCROLL = 'on_scroll'
// @todo - rename when FLOW is updated with Taboola ad config
export const ADSLOT_OUTBRAIN_MID = 'outbrain_mid'
export const ADSLOT_OUTBRAIN_FEED = 'outbrain_feed'
export const ADSLOT_OUTBRAIN_SIDEBAR = 'outbrain_sidebar'

export const ADSLOT_PHOTO_TOP = 'photo_top'
export const ADSLOT_PHOTO_BOTTOM = 'photo_bottom'
export const ADSLOT_PHOTO_SIDEBAR = 'photo_sidebar'
export const ADSLOT_PHOTO_MPU1 = 'photo_mpu1'
export const ADSLOT_PHOTO_MPU2 = 'photo_mpu2'
export const ADSLOT_PHOTO_MOBILE = 'mobile_photo_mpu1'

export const ADSETTING_PARAGRAPH_FREQ = 'paragraph_freq'
export const ADSETTING_PARAGRAPH_SIZE = 'paragraph_size'
export const ADSETTING_MPU1_POSITION = 'mpu1_pos'
export const ADSETTING_LIVEBLOG_FREQ = 'liveblog_post_freq'
export const ADSETTING_LIVEBLOG_SIZE = 'liveblog_post_size'

export const DAILYMOTION_TYPE = 'dailymotion'

/** Third party ad vendor default values */
export const DEFAULT_POSITIONS = {
  FIRST_TEADS: 4,
  SECOND_TEADS: 7,
  LONG_ARTICLE_EXTENDED: 7,
  LONG_ARTICLE_OTHER: 5,
  SHORT_ARTICLE: 3,
}

// @todo - leaving this here as it will soon be deprecated
/**
 * Gets the amp ad param object from the config data
 * @type {*}
 */
export const getAMPAdParams = curry(
  (adTargeting, configuration, article, slot) => ({
    slot,
    adTargeting,
    configuration,
    sections: article.sections[0].path.split('/'),
    rootSection: article.sections[0].name.toLowerCase().replace(/\s/g, '-'),
    ...article,
  }),
)
