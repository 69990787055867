import { ArticleData } from '~/types'
import dayjs from 'dayjs'
import {
  getAuthorSlug,
  getAuthorsName,
  getPageCategory,
} from '~/services/authors'
import { ArticleSnippetData, ArticleType, Author, Section } from '~/types'
import { isDmPlayerPresent } from './dailymotion'

export const getDataLayer = (jsGlobals: any, article?: ArticleData) => {
  interface BaseArticle {
    sectionCategory?: Array<{ siteSection: string }>
    title: string
    publish?: any
    authors?: Author[]
    isAffiliated: boolean
    isUserGenerated?: boolean
    hierarchy?: Section[]
    topics?: ArticleType[]
    wordCount?: number
    imageCount?: number
    remoteId?: null | string
    id: string
    sponsorName: string | null
    premium: boolean
    hero?: ArticleSnippetData[] | null
    tags?: Array<string> | null
  }

  const baseArticle: BaseArticle = article ? article : jsGlobals.article

  const base = {
    trackingId: jsGlobals.ga,
    pageType: jsGlobals.pageType,
    headline: undefined,
    headlineWordCount: undefined,
    publishedDate: undefined,
    sponsorName: undefined,
    siteKey: jsGlobals.siteKey,
    articleId: article ? article.id : jsGlobals.pageId,
  }

  if (typeof article === 'undefined') {
    return base
  }

  const siteSectionNodes = baseArticle.sectionCategory?.map(
    ({ siteSection }) => {
      const hierarchy = siteSection.split('/')
      return hierarchy[hierarchy.length - 1]
    },
  )

  return {
    ...base,
    headline: baseArticle.title.toLowerCase(),
    headlineWordCount: baseArticle.title.split(' ').length,
    publishedDate: article
      ? dayjs.unix(baseArticle.publish).format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : baseArticle.publish,
    authorName: getAuthorsName(baseArticle.authors).toLowerCase(),
    authorSlug: getAuthorSlug(baseArticle.authors),
    contributor: baseArticle.isUserGenerated,
    articleType: article
      ? article.articleTypes && article.articleTypes[0]?.name.toLowerCase()
      : jsGlobals.article.articleType,
    pageCategories: getPageCategory(baseArticle.hierarchy).toLowerCase(),
    additionalKeywords:
      (baseArticle.topics &&
        baseArticle.topics
          .map(({ name }) => name)
          .join(',')
          .toLowerCase()) ||
      null,
    articleWordCount: baseArticle.wordCount,
    imageCount: baseArticle.imageCount,
    centralId: baseArticle.remoteId ?? baseArticle.id,
    siteSectionNodes: siteSectionNodes?.join('|'),
    sponsorName: baseArticle.sponsorName?.toLowerCase(),
    premiumContent: baseArticle.premium,
    countOfNodes: siteSectionNodes?.length,
    dmPlayerPresent: isDmPlayerPresent(),
    affiliateArticle: baseArticle.isAffiliated,
  }
}
