import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { NewsletterSignupIcon } from '~/icons/NewsletterSignupIcon'

const Container = styled.div<{ section: boolean }>`
  display: flex;
  width: ${({ section }) => (section ? 'auto' : '100%')};
  justify-content: space-between;
`

const IconWrapper = styled.div<{ section: boolean }>`
  box-sizing: border-box;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: var(--primary-500);

  ${({ section }) =>
    section &&
    `
    @media ${device.laptop} {
    width: 80px;
    height: 80px;
    padding: 10px;
    }
  `}
`

export const NewsletterSignupHeader = ({ section }: { section: boolean }) => {
  return (
    <Container className="newsletter-signup-header" section={section}>
      <IconWrapper section={section}>
        <NewsletterSignupIcon />
      </IconWrapper>
      {/* label will go here, hence the Container - client has asked this be done in a later sprint */}
      {/* 2024-09-30 */}
    </Container>
  )
}
