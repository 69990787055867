import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { FC } from '~/types'

type InstagramProps = {
  data: {
    url?: string
    align: string
    shortcode?: string
    id?: string
  }
}

const Wrapper = styled.div`
  margin: auto;

  @media ${device.tabletM} {
    max-width: 550px;
  }
`

const StyledIframe = styled.iframe`
  border: none;
`

export const Instagram: FC<InstagramProps> = ({ data }) => {
  const { url, shortcode, id: idAsUrl } = data

  // The API has 'url' and 'align' but not 'shortcode' nor 'id'. Possibly needed for
  // where the component is used elsewhere and so keeping both for now.
  let id
  if (shortcode) {
    id = shortcode
  }

  let matched

  if (url) {
    matched = url.match(/\/p\/([a-zA-Z0-9_-]+)\/?/)
  } else if (idAsUrl) {
    matched = idAsUrl.match(/\/p\/([a-zA-Z0-9_-]+)\/?/)
  }

  if (!id && matched && matched[1]) {
    id = matched[1]
  } else {
    return null
  }

  return (
    <Wrapper className="instagram-media">
      <StyledIframe
        src={`https://www.instagram.com/p/${id}/embed/captioned`}
        width="100%"
        title="Instagram Post"
        scrolling="no"
      ></StyledIframe>
    </Wrapper>
  )
}
