/*globals JSGlobals googletag */
import compose from 'ramda/src/compose'
import { jsLoader } from './fileLoaders'
import { shouldGetContextualVideo } from './get-contextual-video/get-contextual-video'
import { getSkyPlayer, shouldGetSkyPlayer } from './get-sky-player'
import { getPlayerId } from './get-video-player-id'
import { serialize } from './serialize'
import { DAILYMOTION_CONTEXTUAL_PLAYER_ID } from './get-contextual-video/config'
import { insertHeroImageAfterFirstParagraph } from './get-contextual-video/insert-hero-image-after-first-paragraph'
import { isDigitalPlusSubscriber } from './isDigitalPlusSubscriber'

const NETWORK_ID = 3948
const NETWORK_PATH = `${NETWORK_ID}/VideoNetwork/`
const HERO_IMAGE_ID = 'hero-slot'
export const DAILYMOTION_ID_PREFIX = 'dm-article-'

export const isDailymotionVideoInDom = () => {
  return !!document.querySelector(`[id^=${DAILYMOTION_ID_PREFIX}]`)
}

export const isDmPlayerPresent = () => {
  if (isDailymotionVideoInDom()) return 'TRUE'

  const {
    domain,
    articleType,
    sections,
    sensitive: isSensitive,
    article: { isAffiliated } = {},
  } = JSGlobals

  const isSportsArticle = window.location.pathname.startsWith('/sport')

  const willInjectContextualVideo = () => {
    return (
      !isSportsArticle &&
      shouldGetContextualVideo(
        domain,
        articleType,
        sections,
        isAffiliated,
        isSensitive,
      )
    )
  }

  return willInjectContextualVideo() ? 'contextual' : 'FALSE'
}

export const hasDailymotionVideo = (array) => {
  return array.some(
    (item) =>
      typeof item.id === 'string' && item.id.startsWith(DAILYMOTION_ID_PREFIX),
  )
}

const getGoogleAdsParam = () => {
  const selectorAttr = 'data-section-dm-id'
  const dmElement = document.querySelector(`[${selectorAttr}]`)
  const videoId = dmElement?.getAttribute(selectorAttr) ?? ''

  const pubads = googletag?.pubads()
  const targetingKeys = pubads?.getTargetingKeys()

  const googleAdsParam = targetingKeys?.reduce(
    (acc, key) => ({
      ...acc,
      [key]: pubads.getTargeting(key),
    }),
    {},
  )

  return {
    ...googleAdsParam,
    videoSite: JSGlobals.title,
    videoID: videoId || JSGlobals.article?.DMVideoId,
    topics: (JSGlobals.article?.topics ?? []).map((topic) => topic.name),
  }
}

export const setTargeting = compose(
  encodeURIComponent,
  serialize,
  getGoogleAdsParam,
)

/**
 * Every time dailymotion library updates z-index to max possible value
 * (when PiP shown), change it down to a safe value.
 * When PiP is hidden, library code changes z-index to 'auto' - leave that as is
 */
const setPIPzIndex = () => {
  const dmPlayerWrappers = document.querySelectorAll(
    '.dailymotion-player-wrapper',
  )

  dmPlayerWrappers.forEach((wrapper) => {
    const callback = () => {
      if (wrapper.style['z-index'] !== 'auto') wrapper.style['z-index'] = '2000'
    }

    const observer = new MutationObserver(callback)
    observer.observe(wrapper, { attributeFilter: ['style'] })
  })
}

const pushContextualVideoEvent = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: 'contextual video' })
}

const pushPlayerReadyEvent = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: 'dmPlayerReady' })
}

export const destroyAllDailymotionPlayers = async () => {
  await Promise.all(window.dailymotion.getAllPlayers())
    .then((players) => {
      players.forEach((player) => player.destroy())
    })
    .catch((e) => console.error(e))
}

export const waitForDailymotion = (interval = 50, timeout = 10000) => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now()
    const checkInterval = setInterval(() => {
      if (window.dailymotion) {
        clearInterval(checkInterval)
        resolve()
      } else if (Date.now() - startTime >= timeout) {
        clearInterval(checkInterval)
        reject(new Error('Timed out waiting for Dailymotion SDK'))
      }
    }, interval)
  })
}

export const pauseAllDailymotionPlayers = async () => {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  try {
    if (!window.dailymotion) {
      await waitForDailymotion() // Ensure the Dailymotion SDK is available
    }

    await delay(5000) // Delay for 5 seconds

    // Get the array of Promises for all players
    const playerPromises = await window.dailymotion.getAllPlayers()

    // Await the resolution of all player Promises
    const players = await Promise.all(playerPromises)

    // Map each player to a new Promise that resolves when the player is in the 'playing' state
    const playPromises = players.map((player) => {
      return player
        .getState()
        .then((state) => {
          if (state.playerIsPlaying) {
            return player
          } else {
            return null // Player is not playing
          }
        })
        .catch((error) => {
          console.error('Error getting player state:', error)
          return null
        })
    })

    // Filter out null values (players that are not playing)
    const playingPlayers = (await Promise.all(playPromises)).filter(
      (player) => player !== null,
    )

    // Pause all players that are in the 'playing' state
    const pausePromises = playingPlayers.map((player) => {
      return player.pause().catch((error) => {
        console.error('Error pausing player:', error)
      })
    })
    // Wait for all players to be paused
    await Promise.all(pausePromises)
  } catch (e) {
    //no op
  }
}

const delayedPIPClose = () => {
  if (!['lep.co.uk', 'peterboroughtoday.co.uk'].includes(JSGlobals.domain)) {
    return
  }
  if (!window.matchMedia('(max-width: 767px)').matches) {
    return
  }

  const checkScrollDepth = () => {
    if (window.scrollY > 678) {
      setTimeout(() => {
        window.dailymotion.pipClose()
      }, 10000)
      window.removeEventListener('scroll', checkScrollDepth)
    }
  }

  window.addEventListener('scroll', checkScrollDepth)
}
export const getCustomConfig = (networkId, isPremiumArticle) => {
  const MAX_RETRIES = 150
  let retryCount = 0

  const t0 = performance.now()
  console.log(`getCustomConfig called at: * ${t0}ms *`)

  const googletag = window.googletag || {}

  return new Promise((resolve) => {
    const checkGoogletag = () => {
      if (googletag.pubadsReady || retryCount >= MAX_RETRIES) {
        if (retryCount >= MAX_RETRIES) {
          console.log('timed out waiting for googletag')
        } else {
          const t1 = performance.now()
          console.log(`googletag ready after: * ${t1 - t0}ms *`)
        }
        const params = JSGlobals.showAds ? setTargeting() : 'NoAds'
        resolve({
          dynamiciu: `/${networkId}`, // '/' added at request from Dailymotion
          keyvalues: params,
          plcmt: '1', // all players '1'
          ...((isPremiumArticle || isDigitalPlusSubscriber()) && {
            premium: 'true',
          }),
        })
      } else {
        console.log(
          `googletag not ready, calling again. retries: ${retryCount++}`,
        )
        setTimeout(checkGoogletag, 100)
      }
    }

    checkGoogletag()
  })
}

let retryCount = 0
const MAX_RETRIES = 5

/**
 * Initializes the Dailymotion player with the given settings and handles heavy ads intervention.
 * @param {string} playerContainerId - The ID of the container where the player will be created.
 * @param {Object} playerSettings - Configuration settings for the Dailymotion player.
 * @returns {Promise} - Resolves with the player instance if successful, otherwise rejects with an error.
 */
const initializeDailymotionPlayer = (
  playerContainerId,
  playerSettings,
  isContextualEmbed = false,
) => {
  return new Promise((resolve, reject) => {
    if (window.dailymotion) {
      window.dailymotion
        .createPlayer(playerContainerId, playerSettings)
        .then((player) => {
          // Reset the retry count upon successful player initialization
          retryCount = 0

          // Add an event listener for the heavy ads intervention event
          player.on(
            window.dailymotion.events.PLAYER_HEAVYADSINTERVENTION,
            ({ playerHeavyAdsInterventionReports }) => {
              console.log(
                'PLAYER_HEAVYADSINTERVENTION',
                playerHeavyAdsInterventionReports,
              )

              // Check if the retry count has reached its maximum limit
              if (retryCount < MAX_RETRIES) {
                retryCount++ // Increment the retry count

                // Destroy the current player to prepare for reinitialization
                player
                  .destroy()
                  .then(() => {
                    // Modify player settings to disable autoplay to prevent further heavy ads intervention
                    const newPlayerSettings = {
                      ...playerSettings,
                      params: {
                        ...playerSettings.params,
                        autoplay: false,
                      },
                    }

                    // Recursively reinitialize the player with the updated settings
                    initializeDailymotionPlayer(
                      playerContainerId,
                      newPlayerSettings,
                    ).catch((error) =>
                      console.error('Error reinitializing player:', error),
                    )
                  })
                  .catch((destroyError) => {
                    console.error('Error destroying player:', destroyError)
                  })
              } else {
                // Log an error message if the maximum number of retries has been reached
                console.error(
                  'Maximum retries reached. Player will not be reinitialized.',
                )
              }
            },
          )

          setPIPzIndex(player)

          if (isContextualEmbed) pushContextualVideoEvent()
          pushPlayerReadyEvent(player)

          delayedPIPClose(player)

          resolve(player)
        })
        .catch((error) => {
          console.error('Error with Dailymotion Player:', error)
          reject(error)
        })
    } else {
      reject(new Error('Dailymotion SDK not loaded'))
    }
  })
}

export const dailymotionArticle = () => {
  const {
    domain,
    articleType,
    sections,
    sensitive: isSensitive,
    article: { isAffiliated } = {},
    pageType,
  } = JSGlobals
  const selectorAttr = 'data-article-dm-id'
  const dmElements = Array.from(document.querySelectorAll(`[${selectorAttr}]`))
  const heroImage = document.getElementById(HERO_IMAGE_ID)
  const isArticleTypeVideo = JSGlobals.article.isArticleTypeVideo
  const isSportsArticle = window.location.pathname.startsWith('/sport')
  const isProductArticle = pageType === 'affiliate article'
  const isPremiumArticle =
    pageType === 'sponsored article' || articleType === 'sponsored article'

  if (heroImage) {
    dmElements.unshift(heroImage) // Add hero image to the beginning of the array
  }

  // Hero SkyPlayer integration, on Sports articles
  if (shouldGetSkyPlayer(JSGlobals.article, JSGlobals.domain)) {
    getSkyPlayer()
  }

  const isContextualVideoArticle = () => {
    // Hero Dailymotion contextual-video integration to replace hero image, NOT on Sports articles
    return (
      !isSportsArticle &&
      shouldGetContextualVideo(
        domain,
        articleType,
        sections,
        isAffiliated,
        isSensitive,
        isProductArticle,
      )
    )
  }

  // Early return if article does not have a hero video, SkyPlayer, or Contextual Videos
  if (!hasDailymotionVideo(dmElements) && !isContextualVideoArticle()) {
    return
  }

  // Insert hero image in article body for contextual embeds
  if (isContextualVideoArticle() && heroImage) {
    insertHeroImageAfterFirstParagraph(dmElements[0])
  }

  jsLoader(
    [
      `https://geo.dailymotion.com/libs/player/${getPlayerId(
        !!heroImage && isContextualVideoArticle(), // Different Player ID for contextual-video integration to replace hero image
        isArticleTypeVideo,
      )}.js`,
    ],
    'dailymotion',
  ).then(() => {
    dmElements.forEach(async (el) => {
      const networkId = NETWORK_PATH
      const dmId = el.getAttribute(selectorAttr)
      const isContextualEmbed =
        el.id === HERO_IMAGE_ID && isContextualVideoArticle()

      const playerSettings = {
        video: isContextualEmbed ? '' : dmId,
        player:
          // Pass Player ID as a runtime parameter for subsequent players
          // https://developers.dailymotion.com/guides/getting-started-with-web-sdk/#player-library-script-multiple-players
          isContextualEmbed
            ? DAILYMOTION_CONTEXTUAL_PLAYER_ID
            : getPlayerId(false, isArticleTypeVideo),
        referrerPolicy: 'no-referrer-when-downgrade',
        params: {
          customConfig: await getCustomConfig(networkId, isPremiumArticle),
        },
      }

      if (domain === 'northantstelegraph.co.uk') {
        delete playerSettings.params.mute
      } else {
        playerSettings.params.mute = !isArticleTypeVideo // turns sound off if article type is not video
      }

      const playerContainerId =
        el.id === HERO_IMAGE_ID && isContextualVideoArticle()
          ? HERO_IMAGE_ID
          : `${DAILYMOTION_ID_PREFIX}${dmId}`

      initializeDailymotionPlayer(
        playerContainerId,
        playerSettings,
        isContextualEmbed,
      ).catch((error) => {
        console.error('Error with Dailymotion Player:', error)
      })
    })
  })
}

export const dailymotionSection = () => {
  const dmVideoPreviewElements = document.querySelectorAll(
    '[data-type="dm-video-preview"]',
  )
  if (dmVideoPreviewElements.length) {
    return jsLoader(['https://api.dmcdn.net/widget.js'], 'dailymotion')
  }

  const selectorAttr = 'data-section-dm-id'
  const dmElements = document.querySelectorAll(`[${selectorAttr}]`)
  if (dmElements.length) {
    return jsLoader(
      ['https://geo.dailymotion.com/libs/player/x4uxe.js'],
      'dailymotion',
    ).then(() => {
      dmElements.forEach((el) => {
        const video = el.getAttribute(selectorAttr)
        const playerSettings = {
          referrerPolicy: 'no-referrer-when-downgrade',
          params: {
            mute: false, // Home pages
            customConfig: getCustomConfig(NETWORK_PATH),
          },
        }

        const playerType = el.getAttribute('data-video-type')

        if (playerType === 'video') {
          playerSettings.video = video
        } else {
          playerSettings.playlist = video
        }

        initializeDailymotionPlayer(
          `dm-section-${video}`,
          playerSettings,
        ).catch((error) => {
          console.error('Error with Dailymotion Player:', error)
        })
      })
    })
  }
}

export const initDailyMotion = () => {
  if (JSGlobals.article) {
    return dailymotionArticle()
  }
  return dailymotionSection()
}
