import React from 'react'
import styled from 'styled-components'
import pin from '~/icons/pin'

export const Pin = styled(({ className }) => (
  <span className={className} dangerouslySetInnerHTML={{ __html: pin }}></span>
))`
  color: ${({ theme }) => theme.fontColor};
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
`
