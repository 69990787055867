//@TODO MOVE AD-DATA FUNCTIONALITY TO HERE
import { isProd } from '~/services/serverUtil'
import { ArticleData } from '~/types'

export const getTestTargeting = (req: {
  cookies: { feed: string }
  hostname: string
}) => {
  if (isProd()) {
    return 'beta'
  }
  // non prod here,
  const feed = req.cookies?.feed
  switch (true) {
    case feed === 'prod-jpi':
      return 'beta'
    case feed === 'uat-jpi':
    case /web-uat/.test(req.hostname):
      return 'uat'
    default:
      return true
  }
}

export const getPageId = (article: ArticleData) => {
  const { type, id, section } = article

  switch (type) {
    case 'article':
      return id
    case 'topic':
      return `topic-${section?.id}`
    case 'author':
      return `author-${section?.id}`
    case 'partner':
      return `partner-${section?.id}`
    case 'section':
      return `hub-${section?.id}`
    case 'video':
      return 'video'
    default:
      return ''
  }
}
