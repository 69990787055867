import React from 'react'
import { FC } from '~/types'
import { SlideText } from './styles'
import { handleClickTracking } from './util/handle-click-tracking'

type LinkItemProps = {
  title: string
  titleShort: string
  path: string
  index: number
  trackingTerm: string
}

export const ListItem: FC<LinkItemProps> = ({
  title,
  titleShort,
  path,
  index,
  trackingTerm,
}) => {
  return (
    <SlideText data-position={index}>
      <a
        href={`https://www.nationalworld.com${path}?itm_source=Internal&itm_channel=${trackingTerm}_banner&itm_campaign=breaking-news-ticker&itm_content=${index}`}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => {
          const href = e.currentTarget.getAttribute('href')!
          handleClickTracking('breaking news carousel', titleShort, href, index)
        }}
      >
        {titleShort || title}
      </a>
    </SlideText>
  )
}
