const commentDisqusIcon = `
<svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    >
        <path
        d="M15 0.674894V11.1237C15 11.4965 14.7419 11.7986 14.4236 11.7986H5.09293C5.04454 11.7961 4.99876 11.8245 4.97342 11.8727L3.36096 14.6982C3.25371 14.8863 3.07355 14.9994 2.88049 15C2.83144 14.9996 2.78256 14.9922 2.73515 14.978C2.49662 14.903 2.32463 14.66 2.30616 14.3718L2.15853 11.955C2.15019 11.8654 2.08545 11.7981 2.00861 11.7986H0.576458C0.423599 11.7986 0.276952 11.7275 0.168884 11.601C0.0606572 11.4743 0 11.3027 0 11.1237V0.674888C0 0.30213 0.258147 0 0.576437 0H14.4234C14.7417 0 14.9998 0.302149 14.9998 0.674888L15 0.674894Z"
        />
</svg> 
`
export default commentDisqusIcon
