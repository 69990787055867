import React from 'react'
import styled from 'styled-components'
import { FC } from '~/types'
import { device } from '~/config/theme/JPIMStyles'

type ButtonProps = {
  children?: React.ReactNode
  className?: string
  id?: string
  textS?: boolean
  textL?: boolean
  textXL?: boolean
  paddingS?: boolean
  paddingL?: boolean
  backgroundColour?: string
  textColour?: string
  orangeContrast?: boolean
  hoverOpacity?: boolean
  hoverReverse?: boolean
  border?: string
  rounded?: boolean
  bold?: boolean
  iconRight?: boolean
  href?: string
  target?: string
  rel?: string
  tabindex?: string
  fullWidthMobile?: boolean
  font?: string
}

const Wrapper = styled.a<ButtonProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  justify-content: center;
  width: fit-content;

  ${({
    theme,
    textColour,
    backgroundColour,
    textS,
    textL,
    textXL,
    bold,
    paddingL,
    paddingS,
    border,
    rounded,
    hoverOpacity,
    hoverReverse,
    iconRight,
    fullWidthMobile,
    font,
  }) => `
    color: ${theme[textColour ?? 'lightContrast']} !important;
    background-color: ${theme[backgroundColour ?? 'primaryColor']};
    font-size: ${textS ? '12px' : textL ? '16px' : textXL ? '22px' : '14px'};
    font-family: ${theme.fontFamily[font ?? 'primaryFont']};
    font-weight: ${
      bold && !/^(The Scotsman|Yorkshire Post)$/.test(theme.name)
        ? '600'
        : 'normal'
    };
    padding: ${paddingS ? '2px 4px' : paddingL ? '16px 24px' : '6px 16px'};
    border: ${border ? `1px solid ${theme[border]}` : 'none'};
    border-radius: ${rounded ? ' 8px' : ''};
    ${
      hoverReverse
        ? 'transition: background-color 0.2s, color 0.2s, border-color 0.2s'
        : 'transition: opacity 0.6s ease-in'
    };
    flex-direction: ${iconRight ? 'row-reverse' : 'initial'};

    &:hover {
      ${hoverOpacity && 'opacity: 0.8;'}
      ${
        hoverReverse
          ? `
        color: ${
          textColour === 'lightContrast'
            ? theme.photoArticleBackground
            : theme.lightContrast
        };
        background-color: ${
          textColour === 'lightContrast'
            ? theme.lightContrast
            : theme.primaryColor
        };
        border-color: ${
          textColour === 'lightContrast'
            ? theme.lightContrast
            : theme.primaryColor
        };
      `
          : ''
      }
    }

    @media ${device.maxTablet} {
      width: ${fullWidthMobile && 'calc(100% - 32px)'};
    }
  `}
`

export const Button: FC<ButtonProps> = (props) => (
  <Wrapper {...props}>{props.children}</Wrapper>
)
