import React from 'react'
import striptags from 'striptags'
import { pianoWallWithWordLimit } from '~/config/JPIMConfig'
import PianoArticlePaywall from '~/library/ThirdParty/PianoArticlePaywall'
import { ArticleData } from '~/types'
import { Markup } from '../Markup'
import isAxateEnabled from '~/util/isAxateEnabled'

const WORD_LIMIT = 25

type RenderPianoPaywallProps = {
  article: ArticleData
  content: Array<JSX.Element>
  contentArray: {
    type: string
    data: {
      markup: string
    }
  }[]
  domain: string
  isPremium: boolean
  piano: boolean
}

const renderPianoPaywall = ({
  article,
  content,
  contentArray,
  domain,
  isPremium,
  piano,
}: RenderPianoPaywallProps) => {
  const clonedContent = [...content]

  if (!piano || isAxateEnabled(domain)) return clonedContent
  // axate deals with the paywall separately in Content.tsx

  const isPianoWallWithWordLimit = pianoWallWithWordLimit.includes(domain)

  if (isPianoWallWithWordLimit && clonedContent[0]) {
    // Get first paragraph
    const firstParagraph = clonedContent[0]?.props.data.markup
    const strippedFirstParagraph = striptags(firstParagraph)

    // Split into two paragraphs based on word limit
    const words = strippedFirstParagraph.split(' ')
    const firstPart: string = words.slice(0, WORD_LIMIT).join(' ')
    const secondPart: string = words.slice(WORD_LIMIT).join(' ')

    // 1. Update the first paragraph with the first part
    clonedContent[0].props.data.markup = `<p>${firstPart}</p>`
    // 2. Inject Paywall component after the first paragraph
    clonedContent.splice(
      1,
      0,
      <PianoArticlePaywall key="piano-article-paywall" />,
    )
    // 3. Inject new paragraph with the second part
    clonedContent.splice(
      2,
      0,
      <Markup
        key="markup-2"
        article={article}
        data={{ markup: `<p>${secondPart}</p>` }}
        polar={article.polar}
        hierarchy={article.hierarchy}
        isSponsored={!!article.sponsorName}
        className="markup"
        isPremiumArticleLayout={isPremium}
      />,
    )
    // Returns the updated content
    return clonedContent
  } else {
    // Inject original Paywall
    clonedContent.splice(
      contentArray.length >= 5 ? 3 : 1,
      0,
      <PianoArticlePaywall key="piano-article-paywall" />,
    )

    // Inject Mid Piano paywall after Taboola
    const taboolaIndex = clonedContent.findIndex(
      (content) => content.props.id === 'taboola-mid-0',
    )

    const doesTaboolaExist = taboolaIndex !== -1

    if (!doesTaboolaExist) {
      return clonedContent
    }

    clonedContent.splice(
      taboolaIndex + 1,
      0,
      <PianoArticlePaywall
        key="piano-article-paywall-mid"
        className="piano-article-paywall-mid"
      />,
    )

    return clonedContent
  }
}

export default renderPianoPaywall
