import { trimImageParam } from '@brightsites/flow-core/lib/utils/cropImage'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { TABLET_BREAKPOINT, device } from '~/config/theme/JPIMStyles'
import { arrow } from '~/icons/arrow'
import { Img } from '~/library/Img'
import { FC, Link } from '~/types'
import { changeSlide } from '~/util/change-slide'
import { debounce } from '~/util/debounce'
import { formatString } from './utils/formatString'

const CAROUSEL_DEBOUNCE_DELAY = 250
const SLIDES_PER_VIEW: number = 2

const Wrapper = styled.aside<{ hierarchyName?: string }>`
  position: relative;
  padding: 16px;
  margin: 32px 0;
  background: ${({ theme, hierarchyName }) => {
    if (theme.domain === 'nationalworld.com') {
      const section = formatString(hierarchyName)
      return `var(--section-${section}-100, var(--primary-100))`
    }
    return theme.lightGreyContrast
  }};
  border-top: 4px solid
    ${({ theme, hierarchyName }) => {
      if (theme.domain === 'nationalworld.com') {
        const section = formatString(hierarchyName)
        return `var(--section-${section}-500, var(--primary-500))`
      }
      return theme.primaryColor
    }};

  .flow-carousel-arrow-left,
  .flow-carousel-arrow-right {
    position: absolute;
    z-index: 1;
    top: 50%;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.greyContrast};
    background-color: ${({ theme }) => theme.lightContrast};
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s;
    user-select: all;

    svg {
      transform: scale(0.7);
      path {
        transition: fill 0.2s;
        fill: ${({ theme }) => theme.darkestContrast};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.primaryColor};
      border-color: ${({ theme }) => theme.primaryColor};
      svg path {
        fill: ${({ theme }) => theme.lightContrast};
      }
    }
  }

  .flow-carousel-arrow-left {
    left: -10px;
    transform: scaleX(-1) translateY(-50%);
  }

  .flow-carousel-arrow-right {
    right: -10px;
    transform: translateY(-50%);
  }
`

const Title = styled.h2`
  font-size: 22px;
  line-height: 22px;
  font-family: ${({ theme }) => theme.fontFamily.openSansSemiBold};
  margin: 0 0 16px 0;
  pointer-events: none;
`

const Inner = styled.div`
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
`

const Slider = styled.ul<{ count: number }>`
  display: grid;
  row-gap: 16px;
  padding: 0;
  margin: 0;
  transform: translateX(0);
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  overflow: hidden;
  transition: transform 0.35s ease-in-out;
  width: ${({ count }) => `calc(${count}00% / 2)`};

  @media ${device.maxTablet} {
    width: ${({ count }) => `calc(100% * ${Math.round(count / 2)})`};
  }
`

const ListItem = styled.li`
  list-style-type: none;
  padding: 0 20px 0 0;

  @media ${device.maxTablet} {
    &:nth-child(even) {
      grid-row-start: 2;
    }
  }
`

const ArticleLink = styled.a`
  display: flex;
  gap: 16px;
  letter-spacing: 0;
  text-decoration: none;
  line-height: 20px;
  font-size: 14px;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-bottom: auto;
  }

  img {
    display: block;
    width: 84px;
    min-width: 84px;
    object-fit: cover;
    height: 64px;
  }

  .placeholder {
    object-fit: contain;
  }
`

const ArticleTitle = styled.span`
  color: ${({ theme }) => theme.darkContrast};
`

const PlaceholderImage = () => (
  <img
    src="/img/placeholder.png"
    className="placeholder"
    height="470"
    width="630"
  />
)

type RelatedArticleProps = {
  data: { links: Array<Link> }
  hierarchy?: { name: string }[]
}

const RelatedArticle: FC<RelatedArticleProps> = ({
  data: { links },
  hierarchy,
}) => {
  const slides = links
  const sliderRef = useRef<HTMLUListElement>(null)
  const [sliderPos, setSliderPos] = useState(0)

  const slideCount = slides?.length
  const CONTAINER_WIDTH_PERCENT = 100

  const hierarchyName =
    hierarchy && hierarchy.length > 0 ? hierarchy[0]?.name : undefined

  // useEffect only for RelatedArticles within a hydrated component, ie. Liveblog
  useEffect(() => {
    const slider = sliderRef.current
    if (!slider) return

    // Initial width of the slider container
    let initialWidth = slider.offsetWidth

    const getSlideWidth = () => {
      if (window.innerWidth >= TABLET_BREAKPOINT) {
        // Desktop
        return CONTAINER_WIDTH_PERCENT / slideCount
      } else {
        // Mobile
        return (
          CONTAINER_WIDTH_PERCENT / Math.round(slideCount / SLIDES_PER_VIEW)
        )
      }
    }

    slider.style.transform = `translateX(-${getSlideWidth() * sliderPos}%)`

    // Debounced version of the ResizeObserver callback
    const debouncedResizeCallback = debounce(() => {
      const currentWidth = slider.offsetWidth
      if (currentWidth !== initialWidth) {
        // Reset slider position
        setSliderPos(0)
        slider.style.transform = 'translateX(-0%)'
        // Update the initial width
        initialWidth = currentWidth
      }
    }, CAROUSEL_DEBOUNCE_DELAY)

    // Watch for changes in the Slider's width
    const sliderResizeObserver = new ResizeObserver(debouncedResizeCallback)

    sliderResizeObserver.observe(slider)
    return () => sliderResizeObserver.disconnect()
  }, [sliderPos, slideCount])

  const Arrow = ({ direction }: { direction: 'left' | 'right' }) => {
    if (slideCount > SLIDES_PER_VIEW) {
      return (
        <div
          // onClick only for RelatedArticles within a hydrated component, ie. Liveblog
          onClick={() =>
            changeSlide(direction, sliderPos, setSliderPos, slideCount)
          }
          className={`flow-carousel-arrow-${direction}`}
          dangerouslySetInnerHTML={{ __html: arrow }}
        />
      )
    }
    return null // should not display arrows if all slides are already visible
  }

  return (
    <Wrapper
      className="related-articles flow-carousel"
      {...(slides?.length < 2 ? { singleSlide: 'true' } : {})}
      hierarchyName={hierarchyName}
      data-slides-per-view={SLIDES_PER_VIEW}
    >
      <Arrow direction="left" />
      <Title>Read More</Title>
      <Inner className="flow-carousel-inner">
        <Slider
          id="flow-carousel-slider"
          className="flow-carousel-slider"
          count={slides?.length}
          ref={sliderRef}
        >
          {links?.map(({ href, title, image, trimData }) => {
            const urlParams = {
              ...(trimImageParam({ trimData }) ?? { crop: '4:3,smart' }),
              auto: 'webp',
              quality: '75',
              width: '100',
            }
            return (
              <ListItem key={href}>
                <ArticleLink {...(href && { href: href })}>
                  {image && (
                    <Img
                      src={image}
                      urlParams={urlParams}
                      {...(title && { alt: title })}
                      height={1}
                      width={1.5}
                    >
                      <PlaceholderImage />
                    </Img>
                  )}
                  {!image && <PlaceholderImage />}
                  <ArticleTitle>{title}</ArticleTitle>
                </ArticleLink>
              </ListItem>
            )
          })}
        </Slider>
      </Inner>
      <Arrow direction="right" />
    </Wrapper>
  )
}

export default RelatedArticle
