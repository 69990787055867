import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  padding-top: 56.25%;
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`

export const DynamicVideoPreview = ({
  dataId,
  domain = '', // domain from MultiArticleX3PlusNumberedListX5 where this component is used
  path,
}: {
  dataId: string
  domain?: string
  path: string
}) => {
  return (
    <Wrapper>
      <Inner
        data-type="dm-video-preview"
        data-id={dataId}
        data-options={`title=none&duration=none&mode=video&trigger=auto&click=https://www.${domain}${path}%40top`}
      />
    </Wrapper>
  )
}
