import { DEFAULT_POSITIONS } from '~/constants/ads'
import { extendedMidTaboolaLongArticleDefaultPosition } from '~/config/JPIMConfig'

/**
 * Gets default position for mid Taboola depending on publication and article length
 * @domain
 * @articleLength
 * @return {number|*}
 * */
export const getMidTaboolaDefaultPosition = (
  domain: string,
  articleLength: number,
) => {
  const longArticleDefaultPosition =
    extendedMidTaboolaLongArticleDefaultPosition.includes(domain)
      ? DEFAULT_POSITIONS.LONG_ARTICLE_EXTENDED
      : DEFAULT_POSITIONS.LONG_ARTICLE_OTHER

  return articleLength >= 10
    ? longArticleDefaultPosition
    : DEFAULT_POSITIONS.SHORT_ARTICLE
}
