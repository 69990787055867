import React from 'react'
import { Configuration, FC, SectionReference } from '~/types'
import { SidebarHeading, TrendingListTemplate, Wrapper } from './components'

type MostPopularProps = {
  className: string
  configuration: Configuration
  counter: number
  hierarchy: SectionReference[]
  inline: boolean
  item: {
    section: {
      name: string
    }
    labelText: string
  }
  isSameSection: boolean
}

const MostPopular: FC<MostPopularProps> = ({
  className,
  configuration,
  item,
  counter = 0,
  hierarchy,
  isSameSection,
}) => {
  const { domain } = configuration
  const truncateTitleSize = 60
  const limit = 2
  const start = isSameSection ? counter * limit : 0
  const hierarchyName = hierarchy?.[0]?.name ?? 'News'
  const section = encodeURIComponent(item?.section?.name || hierarchyName)
  const title = item?.labelText || 'Most Popular'

  const params = new URLSearchParams({
    section,
    start: start.toString(),
    domain,
    requestingDomain: domain,
    truncateTitleSize: truncateTitleSize.toString(),
    limit: limit.toString(),
  }).toString()

  const target = `/internal-api/html/trending?${params}`

  return (
    <Wrapper className={`sidebar-most-popular ${className}`}>
      <SidebarHeading>{title}</SidebarHeading>
      <TrendingListTemplate className={className} target={target} />
    </Wrapper>
  )
}

export default MostPopular
