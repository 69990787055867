import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from '~/library/Article/Image'

const Wrapper = styled.div``
const Title = styled.h3`
  line-height: 27px;
  font-size: 1.17em;
  margin: 0.8em 0 16px;
`
const Link = styled.a``
const Description = styled.p``

const ArticleLink = (props) => {
  const {
    className,
    data: { url, image, title, description },
  } = props
  if (!image) return null
  return (
    <Wrapper className={className}>
      <Image data={image} srcSet={[640]} />
      <Title>{title && <Link href={url}>{title}</Link>}</Title>
      {description && <Description>{description}</Description>}
    </Wrapper>
  )
}

ArticleLink.propTypes = {
  data: PropTypes.any.isRequired,
  className: PropTypes.string,
}

export default ArticleLink
