export const toggleDetailedMenu = () => {
  const sidebar = document.getElementById('detailed-menu') as HTMLElement
  const overlay = document.getElementById('menu-overlay') as HTMLElement
  const hamburgerButton = document.getElementById('burger-menu') as HTMLElement
  const closeButton = document.querySelector('.close-menu') as HTMLElement

  if (!sidebar || !hamburgerButton || !closeButton || !overlay) return

  const closeMenu = () => {
    sidebar.classList.remove('open')
    overlay.classList.remove('open')
    // Re-enable body scrolling when the sidebar is closed
    document.body.style.removeProperty('position') // Remove fixed positioning
    document.body.style.removeProperty('top') // Remove top
    document.body.style.removeProperty('width') // Remove width
  }

  // Open the sidebar and show the overlay when the burger menu is clicked
  hamburgerButton.addEventListener('click', () => {
    sidebar.classList.add('open')
    overlay.classList.add('open')

    // Store the current scroll position
    const scrollY = window.scrollY

    // Disable body scrolling by setting position fixed with important to overwrite amp CSS rules
    document.body.style.setProperty('position', 'fixed', 'important')
    document.body.style.setProperty('top', `-${scrollY}px`, 'important')
    document.body.style.setProperty('width', '100%', 'important')

    // Focus on the close button when the menu is opened
    closeButton.focus()
    // Add a focus style to the close button
    closeButton.classList.add('focus')
  })

  // Close the sidebar and hide the overlay when the close button is clicked
  closeButton.addEventListener('click', () => {
    closeMenu()
    // Remove the focus style from the close button
    closeButton.classList.remove('focus')
  })

  // Also close the menu if the overlay is clicked
  overlay.addEventListener('click', closeMenu)

  // Close the menu if the Escape key is pressed
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      closeMenu()
      // Remove the focus style from the close button
      closeButton.classList.remove('focus')
    }
  })
}
