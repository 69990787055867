export const trackArticleContentScroll = () => {
  let hasArticleScrollingUpBeenLogged = false
  let hasBottomOfArticleInViewBeenLogged = false
  let isScrolling = false

  const checkScrollState = () => {
    if (!isScrolling) {
      isScrolling = true
      window.dataLayer = window.dataLayer || []
      const contentRootWrapper = document.getElementById('content-root-wrapper')

      if (contentRootWrapper) {
        const rect = contentRootWrapper.getBoundingClientRect()
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight

        // check if the article has been scrolled up
        if (rect.top < 400 && !hasArticleScrollingUpBeenLogged) {
          console.log('Article is scrolling up the page') // temp console log

          window.dataLayer.push({
            event: 'content_scroll',
            contentProgression: 'start',
          })
          hasArticleScrollingUpBeenLogged = true
        }

        // check if the bottom of the article is in view
        if (
          rect.bottom <= windowHeight &&
          !hasBottomOfArticleInViewBeenLogged
        ) {
          console.log('End of article is scrolled into view') // temp console log
          window.dataLayer.push({
            event: 'content_scroll',
            contentProgression: 'end',
          })
          hasBottomOfArticleInViewBeenLogged = true
        }
      }

      setTimeout(() => {
        isScrolling = false
      }, 100)
    }
  }

  window.addEventListener('scroll', checkScrollState)

  checkScrollState()
}
