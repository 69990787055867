import React from 'react'
import styled, { keyframes } from 'styled-components'

const baseColor = '#ddd'
const shineColor = '#e8e8e8'

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`

const ArticleLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const LoaderShape = styled.div`
  width: 100%;
  padding-top: 66.66%;
  background: linear-gradient(
    90deg,
    ${baseColor} 0%,
    ${shineColor} 50%,
    ${baseColor} 100%
  );
  background-size: 200% 100%;
  animation: ${loadingAnimation} 2s infinite;
`

const LoaderLine = styled.div`
  height: 24px;
  background: linear-gradient(
    90deg,
    ${baseColor} 0%,
    ${shineColor} 50%,
    ${baseColor} 100%
  );
  background-size: 200% 100%;
  animation: ${loadingAnimation} 2s infinite;

  &:nth-child(2) {
    width: 90%;
  }

  &:nth-child(3) {
    width: 70%;
  }
`

export const ArticleLoader = () => {
  return (
    <ArticleLoaderContainer>
      <LoaderShape />
      <LoaderLine />
      <LoaderLine />
    </ArticleLoaderContainer>
  )
}
