import React, { useEffect, useState } from 'react'
import { StyledTodaysDate } from '~/component/library/Header/styles'
import { FC } from '~/types'

type TrustedCopyProps = {
  isHome: boolean
  isSimplifiedHeaderSite: boolean
}

export const TodaysDate: FC<TrustedCopyProps> = ({
  isHome,
  isSimplifiedHeaderSite,
}) => {
  const [todaysDate, setTodaysDate] = useState('')

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }

  useEffect(() => {
    const currentDate = new Date()
    const formattedDate = currentDate.toLocaleDateString('en-GB', options)
    setTodaysDate(formattedDate)
  }, [])

  return (
    <StyledTodaysDate
      isSimplifiedHeaderSite={isSimplifiedHeaderSite}
      className={isSimplifiedHeaderSite && !isHome ? 'hide' : ''}
    >
      {todaysDate}
    </StyledTodaysDate>
  )
}
