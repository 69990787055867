import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { device } from '~/config/theme/JPIMStyles'
import MPU from '~/library/Article/Ads/MPU'

export const Container = styled.div`
  position: relative;
  margin: auto;
  z-index: 2;
  height: inherit;
  display: flex;
  flex-direction: column;

  > div > div > div {
    @media ${device.maxTablet} {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 300px;
      overflow: hidden;
    }
  }
`

const Header = styled.div`
  height: 25px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 10px 16px;
  z-index: 10;

  p,
  a {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  }

  p {
    margin: 0;
    padding: 0;
    color: #9b9b9b;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.fontColor};
  }
`

const AdContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
`

const InlineContainer = styled(Container)`
  > div > div > div {
    &:before {
      content: 'Advertisement';
      position: absolute;
      left: 10px;
      top: 0;
      line-height: 25px;
      color: #9b9b9b;
      font-size: 12px;
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    }
  }

  @media ${device.tablet} {
    height: unset;
  }
`

export const AdsContainer = ({ showNoAdsLink, children }) => (
  <Container>
    <Header id="ad-header-container">
      <p id="advertisement-text">Advertisement</p>
      {showNoAdsLink && (
        <a id="hide-ad-link" href="/subscriptions">
          Hide Ad
        </a>
      )}
    </Header>
    <AdContainer>{children}</AdContainer>
  </Container>
)

export const InlineAdsContainer = ({ children }) => (
  <InlineContainer>{children}</InlineContainer>
)

const MarkupAds = (props) => <MPU {...props} />

export default MarkupAds

AdsContainer.propTypes = {
  children: PropTypes.any,
  showNoAdsLink: PropTypes.bool,
}

InlineAdsContainer.propTypes = {
  children: PropTypes.any,
}
