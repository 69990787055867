import { THIRD_PARTY_MODE_PARAM } from './speed-tool'

const queryParams = new URLSearchParams(location.search)
const isDebug = queryParams.has('__DEBUG__')

console.log(
  `========================== ${
    isDebug ? 'DEBUG MODE' : 'PRODUCTION MODE'
  } ==========================`,
)

// Console log the 3rd-party-mode variants that are enabled
if (queryParams.has(THIRD_PARTY_MODE_PARAM)) {
  const paramValue = queryParams.get(THIRD_PARTY_MODE_PARAM)
  console.log(
    `==================== 3RD PARTY${
      paramValue ? ` ${paramValue.toUpperCase()}` : ''
    } MODE ====================`,
  )
  queryParams.forEach((value, key) => {
    if (value === 'enabled') {
      console.log(`* ${key.toUpperCase()} ✅`)
    }
  })
}

// debug shows function times when ?__DEBUG__ query param exists
// functions can be wrapped with debug in the format
// debug(function, 'function name')(function arguments)
export const debug =
  <T extends (...args: any[]) => any>(_function: T, name: string) =>
  (...args: any[]): ReturnType<T> => {
    // const queryParams = new URLSearchParams(location.search)
    // const isDebug = queryParams.has('__DEBUG__')

    if (isDebug) {
      console.log(`Timer: ${name} started at ${performance.now().toFixed(0)}ms`)
    }

    return _function(...args)
  }
