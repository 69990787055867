import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { FC } from '~/types'

const Root = styled.div`
  #axate-wallet-wrapper {
    top: 74px;
    @media ${device.laptop} {
      top: 135px;
    }
  }
`

type AxateProps = {
  domain: string
}

export const Axate: FC<AxateProps> = ({ domain }) => {
  const siteKey = domain === 'newsletter.co.uk' ? 'BELF' : 'YPOS'

  return (
    <Root
      id="axate-wallet"
      data-selector-environment="staging"
      data-selector-premium-content=".premium"
      data-selector-in-page-notice=".axate-article-bottom"
      data-selector-site-key={siteKey}
      data-testid="axate-wallet"
    />
  )
}
