export const insertHeroImageAfterFirstParagraph = (
  heroImgElement?: HTMLElement,
  // optional because the file calling is .js
  // we can put the guard here for now
  // then refactor when dailymotion.js is converted to .ts
) => {
  if (!heroImgElement) return

  const firstParagraph = document.querySelector(
    '[class*="Markup__ParagraphWrapper"]',
  )

  if (!firstParagraph) return

  const clonedHero = heroImgElement.cloneNode(true) as HTMLElement
  // clone the element and use the clone
  // so Dailymotion doesn't try to replace it
  // then adjust attributes as appropriate
  const elementToInsert = prepareHeroImageForInsertion(clonedHero)

  firstParagraph.after(elementToInsert)
}

const prepareHeroImageForInsertion = (
  heroImgElement: HTMLElement,
): HTMLElement => {
  // change ID to avoid duplicates in the DOM
  // set loading and fetchpriority attributes as this image no longer functions as a hero
  heroImgElement.id = 'hero-image-reinserted-after-first-paragraph'
  const imgContainers = heroImgElement.querySelectorAll('.responsive-img')

  imgContainers.forEach((container) => {
    for (const img of container.children) {
      img.setAttribute('loading', 'lazy')
      img.setAttribute('fetchpriority', 'low')
    }
  })

  return heroImgElement
}
