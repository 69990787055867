import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { FC } from '~/types'

const StyledOutbrain = styled.div`
  &.mid-outbrain {
    position: relative;
    overflow: hidden;
    width: 100%;
    /* Fit largest items which have 'Learn More' buttons */
    padding-top: calc((0.85 * 100%) + 160px);
    @media ${device.mobileS} {
      padding-top: calc((0.75 * 100%) + 160px);
    }
    @media ${device.mobileL} {
      padding-top: calc((0.65 * 100%) + 160px);
    }
    @media ${device.tabletM} {
      padding-top: calc((0.6 * 100%) + 160px);
    }

    @media ${device.tablet} {
      padding-top: calc((0.55 * 100%) + 160px);
    }
  }
`

const OutbrainInner = styled.div`
  .mid-outbrain & {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
`

type OutbrainProps = {
  id: string
  isSubscriber: boolean
  isMidOutbrain?: boolean
  src: string
  subscriberId?: string
}

const Outbrain: FC<OutbrainProps> = ({
  id,
  isSubscriber = false,
  isMidOutbrain = false,
  src,
  subscriberId,
}) => {
  if (src && id) {
    return (
      <StyledOutbrain
        className={isMidOutbrain ? 'mid-outbrain' : ''}
        data-testid={
          isMidOutbrain
            ? 'article-content article-mid-outbrain'
            : 'article-bottom-outbrain'
        }
      >
        <OutbrainInner>
          <div
            className="OUTBRAIN"
            data-src={src}
            data-widget-id={isSubscriber ? subscriberId : id}
          />
        </OutbrainInner>
      </StyledOutbrain>
    )
  }

  return null
}

export default Outbrain
