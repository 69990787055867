import React from 'react'
import styled from 'styled-components'

const getBackgroundColor = ({
  theme,
  isBreakingNews,
  isActiveLiveBlog,
  isExclusiveArticle,
}) => {
  if (
    isActiveLiveBlog ||
    isExclusiveArticle ||
    (isActiveLiveBlog && isBreakingNews)
  )
    return theme.redContrast
  if (isBreakingNews) return theme.breakingNewsYellow

  return 'transparent'
}

const getColor = ({ isBreakingNews, theme, isExclusiveArticle }) => {
  if (isExclusiveArticle) return theme.lightContrast
  if (isBreakingNews) return theme.darkestContrast

  return theme.lightContrast
}

export const Capsule = styled(
  ({
    className,
    hierarchy,
    isActiveLiveBlog,
    isAnalysis,
    isBreakingNews,
    isExclusiveArticle,
    isExplainer,
    path,
    sponsorName,
    updated,
  }) => {
    const timeDifference = Date.now() / 1000 - updated
    const isBreakingNewsCondition = isBreakingNews && timeDifference < 7200
    const isSponsored = !!sponsorName

    const renderSectionLabel = () => {
      if (
        !isActiveLiveBlog &&
        !isAnalysis &&
        !isBreakingNewsCondition &&
        !isExclusiveArticle &&
        !isExplainer &&
        !isSponsored
      ) {
        return null
      }
      if (isSponsored) {
        return <span className="ad-feature-label">Ad Feature</span>
      }
      if (isAnalysis) {
        return <span className="section-label-span">Analysis</span>
      }
      if (isExplainer) {
        return <span className="section-label-span">Explainer</span>
      }
      if (isExclusiveArticle) {
        return <span className="section-label-span">Exclusive</span>
      }
      if (isActiveLiveBlog) {
        return (
          <>
            <div className="blink-indicator" />
            <span className="live-blog-span">Live</span>
          </>
        )
      }
      if (isBreakingNewsCondition) {
        return <span className="section-label-span">Breaking</span>
      }
    }

    return (
      <span className={className}>
        {renderSectionLabel() ? (
          <a
            className={[
              className,
              'capsules',
              isActiveLiveBlog && 'sectionLabelText',
              isBreakingNewsCondition && 'sectionLabelText',
              isAnalysis && 'sectionLabelText',
              isExplainer && 'sectionLabelText',
              isExclusiveArticle && 'sectionLabelText',
            ].join(' ')} // join automatically filters out falsy values
            href={!isSponsored ? hierarchy.at(-1).path : path}
          >
            {renderSectionLabel()}
          </a>
        ) : null}
      </span>
    )
  },
)`
  @keyframes blink {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: ${({ isActiveLiveBlog, is4xSmall }) =>
    isActiveLiveBlog && is4xSmall ? '3px' : null};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primaryColor};
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    line-height: inherit;
    margin-bottom: 5px;
  }

  a.sectionLabelText {
    display: flex;
    color: ${({ theme }) => theme.lightContrast};
    font-size: 12px;
    line-height: 17px;
    padding: 4px 8px;
    background-color: ${getBackgroundColor};
  }

  .blink-indicator {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: ${({ theme }) => theme.lightContrast};
    animation: blink 1.4s infinite;
  }

  .live-blog-span {
    margin-left: 8px;
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    letter-spacing: 1px;
    color: ${({ theme }) => theme.lightContrast} !important;
  }

  .ad-feature-label {
    background-color: #fff;
    font-size: 12px;
  }

  .section-label-span {
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    letter-spacing: 1px;
    color: ${getColor};
  }

  .explainer-analysis-span {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
  }
`
