import { isThirdPartyModeEnabled } from '~/util/speed-tool'

const loadSingleJS = (src, attrs) =>
  new Promise((resolve, reject) => {
    const element = document.createElement('script')
    const parent = 'head'
    element.onload = resolve
    element.onerror = reject
    const { async, ...rest } = attrs
    if (async !== false) {
      element.async = 'async'
    }
    Object.entries(rest || {}).forEach(([k, v]) => {
      element.setAttribute(k, v)
    })
    element.src = src
    document[parent].appendChild(element)
  })

/**
 * The function can accept an array of string (src value) or object that will be passed to `script`
 * attribute. Async attribute will be enabled by default
 * @param urls
 * @returns {Promise<unknown[]>}
 */
const jsLoader = (urls, alias) => {
  const { isEnabled, enabledValues } = isThirdPartyModeEnabled()
  const isScriptEnabled = enabledValues.includes(alias)
  // If 3rd-party-mode and 'alias' is not in 'enabledValues' then early return
  if (isEnabled && !isScriptEnabled) {
    return
  }

  if (typeof Promise === 'undefined') return
  var promises = urls.map((item) => {
    const defaultAttr = { async: '' }
    switch (typeof item) {
      case 'string':
        return loadSingleJS(item, defaultAttr)
      case 'object': {
        const { src, ...rest } = item
        return loadSingleJS(src, { ...defaultAttr, ...rest })
      }
      default:
        return Promise.reject('Invalid value for jsLoader')
    }
  })
  return Promise.all(promises)
}

const cssLoader = (urls, resolve, reject, id) => {
  if (typeof Promise === 'undefined') return
  var promises = urls.map((url) => {
    return new Promise((ok, error) => {
      var element = document.createElement('link')
      var parent = 'head'
      var attr = 'href'
      element.onload = ok
      element.onerror = error
      if (id && id !== 'undefined') {
        element.id = id
      }
      element.rel = 'stylesheet'
      element[attr] = url
      // insert at top of head to maintain css specifity
      document[parent].insertAdjacentElement('afterbegin', element)
    })
  })
  return Promise.all(promises).then(resolve, reject)
}

export { jsLoader, cssLoader }
