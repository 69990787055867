import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { HeroCaptionInfoIcon } from '~/icons/HeroCaptionInfoIcon'
import { FC } from '~/types'

type CaptionInfoButtonProps = {
  size: number
  positions: { sm: number; lg: number }
}

const StyledButton = styled.button<CaptionInfoButtonProps>`
  position: absolute;
  z-index: 1;
  height: ${({ size }) => size}px;
  bottom: ${({ positions }) => positions.sm}px;
  right: calc(10px + ${({ positions }) => positions.sm}px);
  // 10px accounts for negative margin in parent

  @media ${device.laptop} {
    bottom: ${({ positions }) => positions.lg}px;
    right: ${({ positions }) => positions.lg}px;
  }

  @media (hover: none) {
    &:hover svg {
      opacity: 1;
    }
  }
`

export const CaptionInfoButton: FC<CaptionInfoButtonProps> = ({
  size,
  positions,
}: CaptionInfoButtonProps) => {
  return (
    <StyledButton
      className="caption-info-button"
      positions={positions}
      size={size}
    >
      <HeroCaptionInfoIcon size={size} />
    </StyledButton>
  )
}
