import React from 'react'

export const HeroCaptionInfoIcon = ({ size = 32 }: { size: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="black" />
      <path d="M16 12.3333V24.3333" stroke="white" strokeWidth="2" />
      <circle cx="16.0003" cy="9.00002" r="1.33333" fill="white" />
    </svg>
  )
}
