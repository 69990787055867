import React from 'react'

export const NewsletterSignupIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 52 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#fff"
        d="M1.588 54.541 20.277 38.42l3.766-3.256c1.089-.942 2.826-.942 3.916 0l3.766 3.256 18.689 16.122M20.267 38.419.705 21.534M51.296 21.534 31.725 38.42"
      />
      <path
        stroke="#fff"
        d="M51.287 21.534V52.48c0 .8-.329 1.534-.873 2.061a2.832 2.832 0 0 1-1.907.753H3.485c-.733 0-1.4-.291-1.907-.753a2.861 2.861 0 0 1-.873-2.06V21.543"
      />
      <path
        stroke="#fff"
        d="M.705 21.534v.01h.01L7.1 16.028M51.286 21.544h-.01l-6.385-5.516M18.145 6.494l5.888-5.082c1.08-.941 2.836-.941 3.925 0l5.889 5.082"
      />
      <path
        fill="#fff"
        d="M6.35 6.494a.75.75 0 0 1 .75-.75H44.89a.75.75 0 0 1 .75.75v20.564a.75.75 0 1 1-1.5 0V7.245H7.85v19.814a.75.75 0 1 1-1.5 0V6.495Zm10.518 6.41a.75.75 0 0 1 .75-.75h16.764a.75.75 0 0 1 0 1.5H17.618a.75.75 0 0 1-.75-.75Zm-3.702 4.125a.75.75 0 0 0 0 1.5h25.667a.75.75 0 1 0 0-1.5H13.166Zm-.75 5.625a.75.75 0 0 1 .75-.75h25.667a.75.75 0 0 1 0 1.5H13.166a.75.75 0 0 1-.75-.75Zm.75 4.125a.75.75 0 0 0 0 1.5h25.667a.75.75 0 0 0 0-1.5H13.166Z"
      />
    </svg>
  )
}
