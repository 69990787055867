import { belfastNewsletter } from '~/pages/SimplifiedNewsletterPage/NewsletterData/BelfastNewsletter'
import { theYorkshirePost } from '~/pages/SimplifiedNewsletterPage/NewsletterData/TheYorkshirePost'
import { nationalWorld } from '~/pages/SimplifiedNewsletterPage/NewsletterData/NationalWorld'
import { theScotsman } from '~/pages/SimplifiedNewsletterPage/NewsletterData/TheScotsman'

export const chooseNewsletterPagePublication = (domain: string) => {
  switch (domain) {
    case 'scotsman.com':
      return {
        localTitle: 'The Scotsman Newsletters',
        subHeading: 'Get the best of The Scotsman, straight to your inbox',
        footerText: 'The Scotsman',
        newsletterData: theScotsman,
        recaptchaPublicKey: '6LfNwLEZAAAAAFqzcWG5kKAj-NQ6p3RqSiZyUNzQ',
        form_id: 'b5a2a401-e810-4ef8-bb22-0b002a519412',
      }
    case 'nationalworld.com':
      return {
        localTitle: 'NationalWorld.com Newsletters',
        subHeading: 'Get the best of NationalWorld.com, straight to your inbox',
        footerText: 'NationalWorld',
        newsletterData: nationalWorld,
        recaptchaPublicKey: '6LcZrLQZAAAAAP6YIP_Jm-5SceqbhMS_pdOGgnFq',
        form_id: '077f3ac7-7188-4974-8ecd-6f81a1ec7640',
      }
    case 'newsletter.co.uk':
      return {
        localTitle: 'Sign up to our newsletters',
        subHeading:
          'Get the best of the Belfast News Letter straight to your inbox',
        footerText: ' the Belfast News Letter',
        newsletterData: belfastNewsletter,
        recaptchaPublicKey: '6Lc_La4ZAAAAAI6vSbGZHkLbOnmxVTFsYKT-IdM0',
        form_id: '24dca940-dbb0-4386-9074-864124eeb2e5',
      }
    case 'yorkshirepost.co.uk':
      return {
        localTitle: 'The Yorkshire Post',
        subHeading:
          'Get the best of The Yorkshire Post, straight to your inbox',
        footerText: ' The Yorkshire Post',
        newsletterData: theYorkshirePost,
        recaptchaPublicKey: '6LeBMLQZAAAAAMYWudAgK0CfEkHuZWJ8H03kuses',
        form_id: 'dc74066d-5350-4cfa-82e0-f18325a09372',
      }
  }
}
