import { Configuration } from '~/types'

const newsletterSuccessText = (configuration: Configuration) => {
  const piano = configuration.piano

  if (!piano) return null

  const { subscription, adLite, premium } = piano
  const { isSubscriber } = configuration

  const brandName = configuration.name
  const isNewsletter = !isSubscriber && !subscription && !adLite && !premium

  const profileText = `Did you know you can manage your profile, and explore all of the available newsletters from ${brandName} within your account.`

  if (isSubscriber) {
    return profileText
  } else {
    switch (true) {
      case adLite:
        return `Did you know with an ad-lite subscription to ${brandName}, you get 70% fewer ads while viewing the news that matters to you.`
      case premium:
        return `Did you know with a Digital subscription to ${brandName}, you can get access to all of our premium content, as well as benefiting from fewer ads, loyalty rewards and much more.`
      case subscription:
        return `Did you know with a Digital Subscription to ${brandName}, you can get unlimited access to the website including our premium content, as well as benefiting from fewer ads, loyalty rewards and much more.`
      case isNewsletter:
        return profileText
      default:
        return null
    }
  }
}

export default newsletterSuccessText
