/**
 * Adds click event listeners to elements with the `.mobile-submenu-toggle` class to toggle
 * the visibility of their respective submenu sections on mobile devices.
 *
 * When an element with the `.mobile-submenu-toggle` class is clicked, this function finds
 * the closest parent element with the `.hover-section` class and toggles the `open-section`
 * class on it, showing or hiding the submenu.
 */
export const mobileSubmenuListener = () => {
  const expandElements = document.querySelectorAll('.mobile-submenu-toggle')

  for (const expandBtn of expandElements) {
    expandBtn.addEventListener('click', (event) => {
      const target = event.currentTarget as HTMLElement
      const section = target.closest('.hover-section')

      if (section) {
        section.classList.toggle('open-section')
      }
    })
  }
}
