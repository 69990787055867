import React from 'react'
import styled from 'styled-components'
import Iframe from '@brightsites/flow-core/lib/component/amp/Iframe'

const Root = styled.div`
  clear: both;
  margin: 20px 0;
`

const iFrame = (props) => (
  <Root>
    <Iframe {...props} />
  </Root>
)

export default iFrame
