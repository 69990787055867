/** This function takes two or three parameters:
 *  @originalString (the original string),
 *  @firstValue (the starting delimiter),
 *  @secondValue (the ending delimiter, which is optional).
 *  It will return the substring in between the firstValue and secondValue strings, or substring after the first value
 *  if no secondValue is set.
 */

export const extractSubstring = (
  originalString: string,
  firstValue: string,
  secondValue?: string,
): string | null => {
  const startIndex = originalString.indexOf(firstValue)
  if (startIndex === -1) {
    return null
  }

  const endIndex = secondValue
    ? originalString.indexOf(secondValue, startIndex + firstValue.length)
    : originalString.length
  if (endIndex === -1) {
    return null
  }

  return originalString.substring(startIndex + firstValue.length, endIndex)
}
