/**
 * This function is designed to retrieve a specific setting from a group of configurations (adConfiguration):
 * @param adConfiguration - The configuration object to search for the setting.
 * @param setting - The name of the setting to retrieve.
 * @param group - The group or groups of configurations to search within.
 * @return {boolean|*} - The value of the setting if found; otherwise, false.
 */

export const getAdConfigSetting = (
  adConfiguration: any,
  setting: string,
  group: string,
) => {
  // Convert the group parameter to an array if it's not already.
  const groups = Array.isArray(group) ? group : [group]

  // Iterate through each group and check if the setting exists in the configuration.
  for (const groupName of groups) {
    if (adConfiguration?.[groupName]?.[setting]) {
      // If the setting is found in the current group, return its value.
      return adConfiguration[groupName][setting]
    }
  }

  // If the setting is not found in any group, return false.
  return false
}
