import React from 'react'
import Helmet from 'react-helmet'
import { FC, ProductData } from '~/types'
import Product from './Product'

type ProductsProps = {
  articleTitle: string
  products: ProductData[]
}

const ProductSchema: FC<ProductsProps> = ({ articleTitle, products }) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: products.map((product, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'Product',
          name: product?.data?.title,
        },
      }
    }),
    itemListOrder: 'https://schema.org/ItemListOrderDescending',
    name: articleTitle,
  }

  return (
    <Helmet>
      <script className="structured-data-list" type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  )
}

const Products: FC<ProductsProps> = ({ products, articleTitle }) => (
  <>
    {products && (
      <ProductSchema articleTitle={articleTitle} products={products} />
    )}
    {products &&
      products.map((product, index) => (
        <Product
          key={index}
          index={index}
          product={product}
          rating={product.data.rating}
        />
      ))}
  </>
)

export default Products
