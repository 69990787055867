import { isThirdPartyModeEnabled } from './speed-tool'

const AUDIENCE_PROJECT_ALIAS = 'audienceproject'
const { isEnabled, enabledValues } = isThirdPartyModeEnabled()
const isScriptEnabled = enabledValues.includes(AUDIENCE_PROJECT_ALIAS)

export const initAudienceProject = () => {
  if (isEnabled && !isScriptEnabled) {
    return
  }

  if (typeof loadAudienceProjectPredictions === 'function') return
  // minified version of audience project prediction #JP-965
  /*eslint-disable */
  !((e) => {
    e.loadAudienceProjectPredictions = (o, n, t) => {
      var r = (t = t || {}).timeout || 1e3,
        c = '',
        a = document.referrer
      if (a) {
        var i = e.location.origin || e.document.origin
        if (i && -1 === a.indexOf(i)) {
          c = a
          try {
            e.localStorage.setItem('apr_sref', c)
          } catch (e) {}
        }
      }
      var d = ''
      try {
        ;(d = e.localStorage.getItem('apr_dsu')),
          (c = e.localStorage.getItem('apr_sref'))
      } catch (e) {}
      var p = e.document.createElement('script')
      ;(p.src =
        'https://pdw-usr.userreport.com/js/v2/partner/' +
        encodeURIComponent(o) +
        '/uid?med=' +
        encodeURIComponent(window.location.toString()) +
        '&dsu=' +
        encodeURIComponent(d) +
        '&ref=' +
        encodeURIComponent(a || '') +
        '&sref=' +
        encodeURIComponent(c || '') +
        '&gdpr_applies=' +
        encodeURIComponent(t.gdprApplies || '') +
        '&iab_consent=' +
        encodeURIComponent(t.consentString || '')),
        (p.async = !0)
      var m = setTimeout(() => {
        n && n([])
      }, r)
      if (
        ((p.onload = () => {
          clearTimeout(m), n && n(e.apDataKeyValues)
        }),
        (p.onerror = () => {
          clearTimeout(m), n && n({})
        }),
        e.document.head)
      )
        e.document.head.appendChild(p)
      else {
        var s = e.document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(p, s)
      }
    }
  })(window)
  /*eslint-enable */
}
