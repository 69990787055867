import styled, { css } from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

const adContainerStyles = css`
  height: 250px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.adContainerGrey};
  border-top: 2px solid ${({ theme }) => theme.adContainerBottomBorder};
  border-bottom: 2px solid ${({ theme }) => theme.adContainerBottomBorder};
  padding: 16px 0;
`

export const Wrapper = styled.div`
  max-width: 100%;
  margin-bottom: 20px;
  margin-top: 40px;
  border-top: 4px solid ${({ theme }) => theme.fontColor};

  .ad-container {
    ${adContainerStyles};
  }

  .ad-container-mobile {
    display: none;
  }

  @media ${device.maxTablet} {
    .ad-container-mobile {
      ${adContainerStyles};
      display: block;
    }

    .ad-container {
      display: none;
    }
  }

  .social-icons {
    margin-top: 20px;
  }
`

export const StyledPost = styled.div`
  margin: 20px 0;
  border: 1px solid ${({ theme }) => theme.greyContrast};
  border-top: 2px solid ${({ theme }) => theme.fontColorLight};
  transition: all 1s ease-out;

  &.pinned {
    background: ${({ theme }) => theme.lightContrast};
    border: 1px solid ${({ theme }) => theme.greyContrast};
    border-top: 2px solid ${({ theme }) => theme.fontColor};
  }

  @keyframes borderColourChange {
    0%,
    100% {
      border: 1px solid ${({ theme }) => theme.greyContrast};
      border-top: 2px solid ${({ theme }) => theme.fontColorLight};
    }
    50% {
      border-color: #396bf3;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &.updated {
    animation: borderColourChange 4s;
  }
  &.new {
    animation: borderColourChange 4s;
    animation: fadeIn 2s;
  }
`

export const PostHeader = styled.div`
  padding: 0 20px 12px;
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
`

export const PostContent = styled.div`
  padding: 0 20px 20px;

  .list-bulleted > ul {
    border: none;
    margin: 0;
    padding: 0;
  }

  .markup {
    p {
      &:first-child {
        margin-top: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .image {
    margin-top: 0;
  }

  .social-icons {
    justify-content: flex-start;
  }
`

export const PostTitle = styled.h4`
  margin: 8px 0 0;
  font-family: ${({ theme }) => theme.fontFamily.stagSemiBold};
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.darkContrast};
`

export const KeyEventsOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 16px;
  overflow-y: hidden;
  --accent-color: ${({ theme }) => theme.redContrast};
  --background-color: ${({ theme }) => theme.pinkContrast};
  background-color: var(--background-color);
  border: 1px solid ${({ theme }) => theme.fontColorLight};
  border-top: 2px solid var(--accent-color);
  transition: max-height 0.25s ease-in;
  margin-top: 20px;

  h3 {
    margin: 16px 0px 16px 16px;
    font-family: ${({ theme }) => theme.fontFamily.openSans};
  }

  .more-button {
    z-index: 2; // Stay above the fade-overlay
    color: ${({ theme }) => theme.lightContrast};
    background: var(--accent-color);
    border-radius: 100px;
    padding: 6px 24px 8px 16px;
    margin: 12px auto 0 auto;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fontFamily.openSans};

    ::after {
      // Up Arrowhead
      content: '';
      display: inline-block;
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      height: 0.25em;
      width: 0.25em;
      vertical-align: text-bottom;
      position: relative;
      left: 0.5em;
      bottom: 0.95ex;
      transform: rotate(135deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  .events-container {
    transition: height 0.5s;
    font-family: ${({ theme }) => theme.fontFamily.stagSemiBold};
    font-size: 16px;
    --line-height: 28px;
    line-height: var(--line-height);

    ul {
      display: list-item;
      position: relative;
      list-style: none;
      --bullet-diameter: 8px;
      --side-padding: 16px;
      --button-tolerance: 12px;
      padding: 0 var(--side-padding);
      margin-top: 0;

      // Vertical dotted line going trough bullets
      &::before {
        content: '';
        position: absolute;
        top: 0;
        height: calc(
          100% + var(--button-tolerance) * 0.9
        ); /* Make sure to reach the last bullet when faded (0 height)
              but don't exceed the button show/hide logic tolerance
            */
        left: calc(var(--side-padding) + var(--bullet-diameter) / 2);
        border-left: 1px dotted var(--accent-color);
      }

      li {
        color: ${({ theme }) => theme.darkContrast};
        list-style-type: none;
        position: relative;
        padding-inline: 8px;
        --left-margin: 20px;
        margin: 0 0 8px var(--left-margin);

        // Bullet point
        &::before {
          content: '';
          position: absolute;
          top: calc(var(--line-height) / 2 - var(--bullet-diameter) / 2);
          left: calc(-1 * var(--left-margin) + 0.5px);
          background-color: var(--accent-color);
          display: inline-block;
          width: var(--bullet-diameter);
          height: var(--bullet-diameter);

          ${({ theme }) =>
            theme.name === 'The Scotsman'
              ? `
                // Diamond bullet symbol
                transform: rotate(45deg);
              `
              : `
                // Custom disc symbol
                border-radius: 100px;
              `}
        }

        a {
          color: ${({ theme }) => theme.fontColor};
          text-decoration: none;
          font-family: ${({ theme }) => theme.fontFamily.componentFont};

          :hover {
            color: var(--accent-color);
          }
        }
      }
    }

    &.auto::after {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to top,
        var(--background-color) 40px,
        transparent 90px
      );
      pointer-events: none; // Don't block clicks
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }

    &.show-text {
      &::after {
        opacity: 0;
      }

      ~ .more-button::after {
        // Down Arrowhead
        transform: rotate(-45deg);
        bottom: 0.75ex;
      }
    }
  }
`

export const Title = styled.h3`
  clear: both;
  font-size: 24px;
  font-family: ${({ theme }) => theme.fontFamily.stagSemiBold};
`

export const TimeWrapper = styled.a`
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  text-decoration: none;
  color: ${({ theme }) => theme.darkContrast};
  margin-right: 8px;
`
export const PostHeaderTop = styled.div`
  display: flex;

  .updated {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    color: ${({ theme }) => theme.fontColorMedium};
    margin-left: 8px;

    &::before {
      content: '';
      border-left: 1px solid ${({ theme }) => theme.greyContrast};
      top: 4px;
      height: 60%;
      padding-right: 8px;
    }
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.lightContrast};
  background-color: ${({ theme }) => theme.primaryColor};
  padding: 12px 24px;
  position: fixed;
  top: 280px;
  left: 50%;
  width: max-content;
  transform: translateX(-50%);
  border-radius: 30px;
  z-index: 9999;

  @media ${device.tablet} {
    top: 200px;
  }

  a {
    text-decoration: none;
    display: flex;
    color: ${({ theme }) => theme.lightContrast};
  }
`

export const LiveblogAnchor = styled.a`
  position: relative;
  z-index: -1;
  top: -200px; /* Offset */
`
export const PostAnchor = styled.a`
  position: relative;
  z-index: -1;
  padding: 90px;
  top: -100px; /* Offset */
  @media ${device.maxTablet} {
    top: -40px; /* Offset */
  }
`

export const RefreshIcon = styled.div`
  display: flex;
  margin-left: 10px;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    animation: rotation 2s infinite linear;
    height: 16px;
    width: 16px;
    path {
      fill: ${({ theme }) => theme.lightContrast};
    }
  }
`
