import React from 'react'
import styled from 'styled-components'

const Live = styled(({ className }) => <span className={className}>LIVE</span>)`
  color: ${({ theme }) => theme.lightContrast};
  align-items: center;
  padding: 5px 7px;
  font-size: 8px;
  font-weight: bold;
`

export default Live
