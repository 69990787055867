import { Author, Section } from '~/types'

export const getAuthorsName = (authors: Author[] = []) => {
  return authors && authors.length > 0
    ? authors
        .map((author) => {
          if (author.name) {
            return author.name
          }
          const firstName = author.firstName ? author.firstName.trim() : ''
          const lastName = author.lastName ? author.lastName.trim() : ''
          return `${firstName} ${lastName}`.trim()
        })
        .filter((author) => author.replace(/\s/g, '').length > 0)
        .join(', ') || ''
    : ''
}

export const getAuthorSlug = (authors: Author[] = []) => {
  return authors && authors.length > 0
    ? authors
        .map((author) => author.name)
        .map((name) => name?.replace(/^By\s/, ''))
        .join(', ')
        .toString() || ''
    : ''
}

export const getPageCategory = (hierarchy: Section[] = []) => {
  return hierarchy
    ? hierarchy
        .map((section) => section.name)
        .join(',')
        .toString()
    : ''
}
