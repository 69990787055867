import striptags from 'striptags'
import { Post } from '~/types'

const shareLiveBlog = (post: Post) => {
  const title = post?.data?.title || ''
  const text = striptags(
    (post.postJson as { data: { markup: string } }[])
      .map((item) => item.data.markup)
      .toString(),
  )
  const url = window?.location?.href || ''

  type ShareDataProps = {
    title: string
    text: string
    url: string
  }

  const shareData: ShareDataProps = {
    title,
    text,
    url,
  }

  if (navigator.share) {
    navigator
      .share(shareData)
      .then(() => {
        console.log('Shared')
      })
      .catch((error) => console.error('Error sharing', error))
  } else {
    console.error('Web Share API not supported')
  }
}

export default shareLiveBlog
