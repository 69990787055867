import React, { useContext } from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import ArticleMeta from '~/library/Article/ArticleMeta'
import Hero from '~/library/Article/Hero'
import { ArticleContentContext } from '~/providers/ArticleContent'
import { Configuration, FC } from '~/types'

const HeroWrapper = styled.div<{ isPremium: boolean }>`
  margin: ${({ isPremium }) => (isPremium ? '0' : '20px 0 0')};

  &.hero-img,
  .hero-media {
    margin: ${({ isPremium }) => isPremium && '0'};
  }

  @media ${device.maxTablet} {
    margin: 0 -20px;

    &.hero-img,
    .hero-media {
      margin: 0;
    }

    .image-caption {
      padding: 4px 16px;
    }
  }
`

const PreAxateContent: FC<{ configuration: Configuration }> = ({
  configuration,
}) => {
  const { article, isPremium, isInfiniteScroll } = useContext(
    ArticleContentContext,
  )

  const content: Array<JSX.Element> = []

  if (article.hero?.length) {
    content.push(
      <HeroWrapper key="hero" isPremium={isPremium}>
        <Hero
          configuration={configuration}
          className="hero-media"
          article={article}
        />
      </HeroWrapper>,
    )
  }

  if (isPremium) {
    content.push(
      <ArticleMeta
        className="article-meta"
        article={article}
        configuration={configuration}
        isPremiumArticleLayout={isPremium}
        isInfiniteScrollEnabled={isInfiniteScroll}
        key="article-meta-preaxate"
      />,
    )
  }

  return <>{content}</>
}

export default PreAxateContent
