import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import { format } from 'timeago.js'
import { ArticleData } from '~/types'
import { shouldUseTimeAgo } from '~/util/date-ago'
import { getTimezone } from '~/util/get-timezone'

type Props = {
  article: ArticleData
  date: Dayjs
  isUs: boolean
  isUpdated?: boolean
  publishDate?: string
}

export const DateInfo = ({
  article,
  date,
  isUs,
  isUpdated,
  publishDate,
}: Props) => {
  const publishedTimeAgo = shouldUseTimeAgo({ date: article.publish })
    ? format(article.publish * 1000)
    : publishDate

  return (
    <span
      className="published-date-ago"
      data-testid={`article-${isUpdated ? 'update' : 'publish'}-date`}
      data-date={article[isUpdated ? 'updated' : 'publish']}
    >
      {!isUs && (
        <span className="semibold-label">
          {isUpdated ? 'Updated ' : 'Published '}
        </span>
      )}
      <span>
        {isUs ? publishedTimeAgo : `${date} ${getTimezone(dayjs().toDate())}`}
      </span>
    </span>
  )
}
