export default () => {
  const articleNextMenu = document.getElementById('article-next-menu')
  if (articleNextMenu) {
    var doesSupportPassive = false
    try {
      var opts = Object.defineProperty({}, 'passive', {
        get: function () {
          // eslint-disable-line
          doesSupportPassive = true
        },
      })
      window.addEventListener('testPassive', null, opts)
      window.removeEventListener('testPassive', null, opts)
    } catch (e) {
      // noop
    }

    let lastScrollTop = 0
    const $header = document.getElementById('header')
    const downCls = 'scroll-direction-down'
    const upCls = 'scroll-direction-up'
    window.addEventListener(
      'scroll',
      function () {
        var st = window.pageYOffset || document.documentElement.scrollTop
        if (st < 900) return
        if (st > lastScrollTop) {
          $header.classList.remove(upCls)
          $header.classList.add(downCls)
        } else {
          $header.classList.remove(downCls)
          $header.classList.add(upCls)
        }
        lastScrollTop = st <= 0 ? 0 : st
      },
      doesSupportPassive ? { passive: true } : false,
    )
  }
}
