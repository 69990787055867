import { format } from 'timeago.js'
import { getCookie } from './cookie'

export const shouldUseTimeAgo = ({ date, cutoff = 86400 }) => {
  const timelapsed = Date.now() / 1000 - date
  if (timelapsed < cutoff) {
    return true
  }
  return false
}

// run after pageload to update cached value
export const updatePublishedDateAgo = () => {
  const datesToUpdate = document.querySelectorAll('.published-date-ago')
  const location = getCookie('geo_location')

  if (location !== 'US') {
    return
  }

  datesToUpdate.forEach((date) => {
    if (date?.dataset?.date) {
      if (shouldUseTimeAgo({ date: date.dataset.date })) {
        date.innerText = format(date.dataset.date * 1000)
      }
    }
  })
}
