const disqusCommentCountWording = () => {
  const commentCounters = document.querySelectorAll('#comment-counter')
  const counterWordings = document.querySelectorAll('#comment-counter-wording')

  const updateCounterWording = (counter: Element) => {
    const count = parseInt(counter.innerHTML, 10)
    const wordingText = count === 1 ? 'Comment' : 'Comments'
    counterWordings.forEach((wording) => {
      wording.innerHTML = wordingText
    })
  }

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        updateCounterWording(mutation.target as Element)
      }
    })
  })

  commentCounters.forEach((counter) => {
    updateCounterWording(counter)
    observer.observe(counter, { childList: true })
  })
}

export default disqusCommentCountWording
