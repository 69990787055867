const vfCommentCountWording = () => {
  const counterNode = document.querySelectorAll('.comment-counter-wrapper')
  const counterWordings = document.querySelectorAll(
    '#viafoura-comment-counter-wording',
  )
  const config = {
    attributes: true,
    childList: true,
    subtree: true,
    attributeOldValue: true,
  }

  const updateCounterWording = (node) => {
    const count = parseInt(node.innerText, 10)
    const wordingText = count < 2 || isNaN(count) ? 'Comment' : 'Comments'
    counterWordings.forEach((wording) => {
      wording.innerHTML = wordingText
    })
  }

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      updateCounterWording(mutation.target)
    })
  })

  counterNode.forEach((node) => {
    updateCounterWording(node)
    observer.observe(node, config)
  })
}

export default vfCommentCountWording
