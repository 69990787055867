const comments = `
<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <circle stroke-width="2" fill="none" cx="20" cy="20" r="19"></circle>
    <g id="Group_93" transform="translate(10.000000, 13.000000)">
        <polygon id="Path_9" points="10 7.937 0.013 0.32 19.985 0.32"></polygon>
        <polygon id="Path_10" points="10 10.083 0 2.368 0 13.653 20 13.653 20 2.368"></polygon>
    </g>
</svg>
`

export default comments
