import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { GlobalStyle } from '~/library/ThirdParty/ViafouraComments'

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  margin: 24px 0;

  &:before {
    content: '';
    display: table;
    clear: both;
  }

  h2.vf-trending-articles__header.vf-subheading-text {
    font-size: 25px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  }

  @media ${device.mobileL} {
    h2.vf-trending-articles__header.vf-subheading-text {
      font-size: 32px;
    }
  }

  .vf-primary-text p,
  .viafoura p {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  }

  .viafoura p.vf-content__icon {
    font-family: ${({ theme }) => theme.fontFamily.openSansSemiBold} !important;
  }

  .viafoura .vf-nav-slide:focus-visible,
  .viafoura .vf-nav-slide:active {
    background-color: ${({ theme }) => theme.primaryColor} !important;
  }

  .viafoura .vf-trending-article-list__item-section:hover {
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }
`

const ViafouraCarousel = ({ pageId, isCommentingEnabled }) => {
  if (!isCommentingEnabled) {
    return null
  }

  return (
    <Wrapper className="viafoura">
      <vf-content-recirculation
        title="Most comments"
        limit="6"
        days-published="10"
        trend-window="1"
        sort="comments"
        vf-container-id={pageId}
      />
      <GlobalStyle />
    </Wrapper>
  )
}

export default ViafouraCarousel
