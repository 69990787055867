export const throttle = (callback: () => void, ms: number) => {
  let waiting = false
  let trailingCall = false

  return () => {
    if (!waiting) {
      callback()
      waiting = true

      setTimeout(() => {
        waiting = false

        if (trailingCall) {
          callback()
          trailingCall = false
        }
      }, ms)
    } else {
      trailingCall = true
    }
  }
}
