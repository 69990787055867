import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
`

const ResponsiveIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Youtube = ({ data: { id } }: { data: { id: string } }) => {
  let videoId = id

  if (/^http/.test(id)) {
    const url = new URL(id)
    videoId = url.searchParams.get('v') ?? id
  }

  const youtubeUrl = `https://www.youtube.com/embed/${videoId}`

  return (
    <Wrapper>
      <ResponsiveIframe
        width="560"
        height="315"
        src={youtubeUrl}
        frameBorder="0"
        allowFullScreen
        data-testid="youtube-iframe"
      ></ResponsiveIframe>
    </Wrapper>
  )
}

export default Youtube
