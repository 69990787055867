import { chooseNewsletterPagePublication } from '~/util/chooseNewsletterPagePublication'

/* globals grecaptcha */
/* globals JSGlobals */

const standaloneNewsletter = () => {
  const $newsletterSelectors = document.querySelectorAll('.newsletter-select')
  const $selectedCounter = document.querySelectorAll('.newsletter-count')
  const $selectedCounterPlural = document.querySelectorAll(
    '.newsletter-count-plural',
  )

  const $banner = document.querySelector('.newsletter-banner')
  const $newsletterForm = document.querySelector('.newsletter-form')
  const $successMessage = document.querySelector('.newsletter-success')
  const $formSubmit = $newsletterForm?.querySelector('.form-submit')

  const emailInputField = 'input[name="loginEmail"]'

  const validateEmail = (emailInput) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return emailRegex.test(emailInput)
  }

  if (
    !$newsletterSelectors ||
    !$selectedCounter ||
    !$selectedCounterPlural ||
    !$banner ||
    !$newsletterForm ||
    !$successMessage ||
    !$formSubmit
  ) {
    return
  }

  const getCheckedValues = () => {
    return Array.from(document.querySelectorAll('input[type="checkbox"]'))
      .filter(($checkbox) => $checkbox.checked)
      .map(($checkbox) => $checkbox.value)
  }

  $newsletterSelectors.forEach(($checkbox) => {
    $checkbox.addEventListener('change', () => {
      // count selected newsletters
      let count = 0

      $newsletterSelectors.forEach((select) => {
        const $cardDiv = select.closest('.newsletter-card-container')
        const $parentDiv = select.closest('div')
        const $tickIcon = $parentDiv.querySelector('#tick-icon')
        const $circlePlusIcon = $parentDiv.querySelector('#circle-plus-icon')
        const $selectText = $parentDiv.querySelector('.select-text')
        const hiddenIconClassName = 'hidden-icon'
        if (select.checked) {
          $cardDiv.classList.add('selected')
          $parentDiv.classList.add('selected')
          $circlePlusIcon.classList.add(hiddenIconClassName)
          $tickIcon.classList.remove(hiddenIconClassName)
          $selectText.innerText = 'Selected'
          count++
        } else {
          $cardDiv.classList.remove('selected')
          $parentDiv.classList.remove('selected')
          $tickIcon.classList.add(hiddenIconClassName)
          $circlePlusIcon.classList.remove(hiddenIconClassName)
          $selectText.innerText = 'Select'
        }
      })

      // update selected count on page
      $selectedCounter.forEach((counter) => {
        counter.innerText = count
      })

      // pluralise copy
      if (count === 1) {
        $selectedCounterPlural.forEach((plural) => {
          plural.innerText = 'newsletter'
        })
      } else {
        $selectedCounterPlural.forEach((plural) => {
          plural.innerText = 'newsletters'
        })
      }

      // hide / show newsletter signup form
      if (count > 0) {
        $banner.classList.remove('hidden')
        $banner.classList.add('visible')
        $newsletterForm.classList.remove('hidden')
        $successMessage.classList.add('hidden')
      } else {
        $banner.classList.remove('visible')
      }
    })
  })

  const $newsletterBannerForm = document.querySelector(
    '.newsletter-bottom-banner',
  )
  const $prodHCheckValue = $newsletterBannerForm.querySelector(
    'input[name="prod_hcheck"]',
  ).value

  // disable submit button by default
  $formSubmit.disabled = true

  $newsletterBannerForm.addEventListener('submit', (e) => {
    e.preventDefault()
    if ($formSubmit.disabled) {
      return
    }

    // validate email
    const emailInput =
      $newsletterBannerForm.querySelector(emailInputField).value
    if (!validateEmail(emailInput)) {
      return
    }

    $formSubmit.disabled = true
    const recaptchaPublicKey = chooseNewsletterPagePublication(
      JSGlobals.domain,
    ).recaptchaPublicKey
    grecaptcha.ready(() => {
      grecaptcha
        .execute(recaptchaPublicKey, { action: 'submit' })
        .then((token) => {
          const postData = async (url) => {
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'User-Agent': 'flow-web-request',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email:
                  $newsletterBannerForm.querySelector(emailInputField).value,
                prod_hcheck: $prodHCheckValue,
                opt_in: getCheckedValues(),
                source: $newsletterBannerForm.querySelector(
                  'input[name="regSourceMethod"]',
                ).value,
                source_url: `https://www.${JSGlobals.domain}/newsletter`,
                source_website_category: 'Newsletter Page',
                site_id: '3835120',
                form_id: chooseNewsletterPagePublication(JSGlobals.domain)
                  .form_id,
                recaptcha_token: token,
              }),
            })
            return response.json()
          }
          postData(
            `/internal-api/newsletter?__amp_source_origin=${window.location.origin}`,
          )
            .then((response) => {
              $formSubmit.disabled = false
              if (
                response.email ===
                $newsletterBannerForm.querySelector(emailInputField).value
              ) {
                $newsletterForm.classList.add('hidden')
                $successMessage.classList.remove('hidden')
                setTimeout(() => {
                  $successMessage.classList.add('hidden')
                  $banner.classList.remove('visible')
                }, 5000)
              }
            })
            .catch((error) => {
              $formSubmit.disabled = false
              console.error('Fetch failed: ', error.message)
            })
        })
    })
  })

  // listen on input change and check email validation
  $newsletterBannerForm
    .querySelector(emailInputField)
    .addEventListener('input', () => {
      const emailInput =
        $newsletterBannerForm.querySelector(emailInputField).value

      // enable / disable button based on email validation
      $formSubmit.disabled = !validateEmail(emailInput)
    })
}
export default standaloneNewsletter
