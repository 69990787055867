import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

const MOBILE_HEIGHT = 471
const DESKTOP_HEIGHT = 455

const Wrapper = styled.div`
  height: ${MOBILE_HEIGHT}px;

  mantis-ui-widget {
    height: 100%;
  }

  @media ${device.laptop} {
    height: ${DESKTOP_HEIGHT}px;

    mantis-ui-widget {
      height: 100%;
    }
  }
`

type DataConfigProps = {
  offset?: number
  theme?: string
  title: string
}

type MantisAdsProps = {
  index: number
  dataConfig: DataConfigProps
}

const MantisAds = ({ index, dataConfig }: MantisAdsProps) => (
  <Wrapper id={`mantis-carousel-wrapper${index > 0 ? `-${index}` : ''}`}>
    <mantis-ui-widget
      id={`mantis-ui-widget${index > 0 ? `-${index}` : ''}`}
      data-config={dataConfig}
    />
  </Wrapper>
)

export default MantisAds
