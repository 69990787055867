import { TABLET_BREAKPOINT } from '~/config/theme/JPIMStyles'

// Get window viewport
/**
 * The function `getWindowViewport` determines whether the viewport is in desktop or tablet mode based
 * on the window width.
 * @returns The function `getWindowViewport` returns either 'desktop' or 'tablet' based on the
 * comparison of the window's inner width with the constant TABLET_BREAKPOINT.
 */
const getWindowViewport = () => {
  if (window.innerWidth >= TABLET_BREAKPOINT) {
    return 'desktop'
  } else {
    return 'tablet'
  }
}

// Initialise current viewport
let currentViewport = getWindowViewport()

// Check if viewport has changed
/**
 * This function checks if the viewport has changed and returns true if it has.
 * @returns The function `viewportHasChanged` returns a boolean value - `true` if the viewport has
 * changed and `false` if it has not changed.
 */
export const viewportHasChanged = () => {
  const newViewport = getWindowViewport()
  if (newViewport !== currentViewport) {
    currentViewport = newViewport
    return true
  }
  return false
}
