import React from 'react'
import { isMoreThan24Hours } from '~/library/TimeAgo'
import { Post } from '~/types'
import { getFormattedTime } from '~/util/get-formatted-time'
import { TimeWrapper } from './styles'

export const LiveblogTime = ({
  updateTime,
  post,
}: {
  updateTime: string
  post: Post
}) => {
  return (
    <TimeWrapper href={`#post-${updateTime}`}>
      {isMoreThan24Hours(post.data.created)
        ? getFormattedTime(parseInt(post.data.created))
        : getFormattedTime(parseInt(post.data.created), true)}

      {post.data.created !== post.data.changed && (
        <span className="updated">
          Updated {getFormattedTime(parseInt(post.data.changed), true)}
        </span>
      )}
    </TimeWrapper>
  )
}
