import axios from 'axios'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { serialize } from 'tinyduration'
import { weatherWidgetSites } from '~/config/JPIMConfig'
import {
  ADCONFIG_DESKTOP,
  ADCONFIG_DESKTOP_LITE,
  ADSLOT_VIDEO,
} from '~/constants/ads'
import { getAdConfigSetting } from '~/providers/AdManager/utils/getAdConfigSetting'

const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
`

const Inner = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;

  #dailymotion-pip-large-viewport {
    --position-bottom: 40;
  }

  #dailymotion-pip-small-viewport {
    ${(props) =>
      props.videoPlayerId === 'xa5cn'
        ? ' --position-bottom: 0px;'
        : `--position-top: ${
            weatherWidgetSites.includes(props.theme.domain) ? 74 : 60
          }`};
  }
`

export const fetchDailymotionApi = async (data) => {
  const id = data?.hero?.find(({ type }) => type === 'dailymotion')?.data?.id

  if (!id) {
    return {}
  }

  const url = `https://api.dailymotion.com/video/${id}?fields=title,description,duration,created_time,thumbnail_url,embed_url`

  try {
    const result = await axios(url)
    if (!result.data) {
      return {}
    }
    return { id, ...result.data }
  } catch (error) {
    console.log(error)
    return {}
  }
}

export const DailymotionHeader = ({
  title,
  description,
  duration,
  created_time,
  thumbnail_url,
  embed_url,
  videoId,
}) => {
  if (!title) {
    return null
  }

  const schema = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: title,
    description: description,
    duration: serialize({ seconds: duration }),
    uploadDate: new Date(created_time * 1000).toISOString(),
    thumbnailUrl: thumbnail_url,
    embedUrl: embed_url,
    contentUrl: `https://www.dailymotion.com/crawler/video/${videoId}`,
  })
  return (
    <Helmet>
      <script className="structured-data-list" type="application/ld+json">
        {schema}
      </script>
    </Helmet>
  )
}

DailymotionHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.number,
  created_time: PropTypes.number,
  thumbnail_url: PropTypes.string,
  embed_url: PropTypes.string,
  videoId: PropTypes.string,
}

const Dailymotion = ({
  data: { id, url = '' },
  article,
  configuration,
  liveblogPost = false,
  heroPosition = false,
}) => {
  if (!article) return null

  const { adLiteEnabled } = configuration
  let adUnitId = 'VideoNetwork'
  const formattedPublisherChildId = `${
    configuration.publisherChildId ? `,${configuration.publisherChildId}` : ''
  }`
  let dmId = id

  // cms will use free text for editor to populate field with url
  if (url) {
    const { pathname } = new URL(url)
    const paths = pathname.split('/')
    dmId = paths[paths.length - 1]
  }

  const networkId = `${configuration.networkId}${formattedPublisherChildId}/${adUnitId}/`
  const { adConfig } = article
  const hasAdConfig = !!adConfig?.[ADCONFIG_DESKTOP] // @todo remove once hardcoded deprecated
  // add no-stp attribute on body videos to prevent autoplay
  let params = {}
  if (
    (!heroPosition && !liveblogPost) ||
    (article.premium && !configuration.isSubscriber)
  ) {
    params['no-stp'] = '' // no autoplay
  }

  if (liveblogPost) {
    params['no-queue'] = ''
    params['no-pip'] = ''
  }

  const classNames = ['dailymotion-cpe']

  if (
    hasAdConfig &&
    adLiteEnabled &&
    getAdConfigSetting(adConfig, ADSLOT_VIDEO, ADCONFIG_DESKTOP_LITE)
  ) {
    classNames.push('dailymotion-cpe-ads')
  }

  if (
    hasAdConfig &&
    !adLiteEnabled &&
    getAdConfigSetting(adConfig, ADSLOT_VIDEO, ADCONFIG_DESKTOP)
  ) {
    classNames.push('dailymotion-cpe-ads')
  }

  return (
    <Wrapper>
      <DailymotionHeader {...article.dailymotion} videoId={dmId} />
      <Inner videoPlayerId={configuration?.videoPlayerId}>
        <div
          id={`dm-article-${dmId}`}
          className={classNames}
          referrerPolicy="no-referrer-when-downgrade"
          data-article-dm-id={dmId} // to fetch the id for new daily motion
          ads-params={''} // client side js will populate this
          data-network-id={networkId}
          {...params}
        />
      </Inner>
    </Wrapper>
  )
}

Dailymotion.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  article: PropTypes.object,
  configuration: PropTypes.object,
  heroPosition: PropTypes.bool,
  liveblogPost: PropTypes.bool,
}

export default Dailymotion
