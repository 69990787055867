const visibleClass = 'visible'
const submittedClass = 'submitted'

const setUpDependentInputs = (formInputs: NodeListOf<HTMLInputElement>) => {
  formInputs.forEach((input) => {
    const { dependsOnInput, dependsOnValue } = input.dataset

    if (!dependsOnInput) return

    const dependency = document.querySelector<HTMLInputElement>(dependsOnInput)

    dependency?.addEventListener('change', function () {
      const isDependencyMet = dependency.value === dependsOnValue

      if (isDependencyMet && input.hidden) {
        input.hidden = false
      } else if (!isDependencyMet && !input.hidden) {
        input.hidden = true
      }
    })
  })
}

const updateErrorMessage = (fieldId: string, errorMessage: string) => {
  const errorElement = document.getElementById(`${fieldId}-error`)

  if (!errorElement) return

  if (errorMessage) {
    errorElement.classList.add(visibleClass)
    errorElement.textContent = errorMessage
  } else {
    errorElement.classList.remove(visibleClass)
    errorElement.textContent = ''
  }
}

const displaySuccessMessage = (formId: string) => {
  const successElement = document.getElementById(`${formId}-success-message`)

  if (!successElement) return

  successElement.classList.add(visibleClass)
}

const validateInput = (input: HTMLInputElement) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const phoneRegex = /^\d{3,15}$/

  const fieldId = input.id
  const fieldType = input.type
  const fieldLabel = document.querySelector(
    `label[for=${fieldId}]`,
  )?.textContent
  const fieldValue = input.value.trim()

  let errorMessage = ''

  if (fieldValue === '' && input.hasAttribute('required')) {
    errorMessage = `${fieldLabel} cannot be blank`
  } else if (
    fieldType === 'email' &&
    fieldValue != '' &&
    !emailRegex.test(fieldValue)
  ) {
    errorMessage = 'Invalid email address'
  } else if (
    fieldType === 'tel' &&
    fieldValue != '' &&
    !phoneRegex.test(fieldValue)
  ) {
    errorMessage = 'Invalid phone number'
  }

  updateErrorMessage(fieldId, errorMessage)

  return errorMessage === ''
}

const submitForm = async (form: HTMLFormElement, formData: FormData) => {
  const formAction = form.dataset.action

  if (!formAction) return

  const res = await fetch(formAction, {
    method: 'POST',
    body: formData,
  })

  const { error } = await res.json()

  if (error) {
    console.error(error)
    return
  }

  if (res.status === 200) {
    form.reset()
    form.classList.add(submittedClass)

    displaySuccessMessage(form.id)
  }
}

export const hubspotForm = () => {
  const forms = document.querySelectorAll<HTMLFormElement>('.hubspot-form')

  forms.forEach((form) => {
    const formInputs = form.querySelectorAll<HTMLInputElement>(
      'input, textarea, select',
    )

    setUpDependentInputs(formInputs)

    formInputs.forEach((input) => {
      input.addEventListener('blur', () => {
        validateInput(input)
      })
    })

    form.addEventListener('submit', async (e) => {
      e.preventDefault()

      const isFormValid = Array.from(formInputs).reduce(
        (isFormValid, input) => {
          return validateInput(input) && isFormValid
        },
        true,
      )

      if (!isFormValid) return

      const formData = new FormData(form)

      if (window.grecaptcha) {
        window.grecaptcha.ready(async () => {
          const { recaptchaSiteKey } = window.JSGlobals ?? {}

          const token = await window.grecaptcha.execute(recaptchaSiteKey, {
            action: 'submit',
          })

          formData.set('recaptcha_token', token)

          submitForm(form, formData)
        })
      } else {
        submitForm(form, formData)
      }
    })
  })
}
