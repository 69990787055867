import { hubSpotWhiteListedPath } from '~/constants/hubSpot'
import { chooseNewsletterPagePublication } from '~/util/chooseNewsletterPagePublication'
import { debug } from './debug'
import { jsLoader } from './fileLoaders'
import { hubspotForm } from './hubspot-form'
import hubspotNewsletter from './hubspotNewsletter'
import removeSkimlinks from './removeSkimlinks'
import standaloneNewsletter from './standaloneNewsletter'

export const skimlinksScript = () => {
  if (
    window.JSGlobals?.article &&
    !location.pathname.startsWith('/read-this') &&
    window.JSGlobals.domainId
  ) {
    debug(
      jsLoader,
      'skimlinks',
    )([
      {
        src: `/api/script?url=https://s.skimresources.com/js/125352X${window.JSGlobals.domainId}.skimlinks.js`,
      },
    ])
  } else {
    debug(removeSkimlinks, 'remove skimlinks')()
  }
}

export const newPolarNativeScript = () => {
  if (location.pathname === '/sponsored') {
    debug(
      jsLoader,
      'polar native',
    )([
      {
        src: '/api/script?url=https://polarcdn-terrax.com/nativeads/script/jpress/newPolarNative.js',
      },
    ])
  }
}

export const hubspotScript = () => {
  if (window.JSGlobals?.hubspot) {
    debug(jsLoader, 'hubspot script tag')(
      ['//js.hs-scripts.com/3835120.js'],
      'hubspot',
    )
  }
}

export const hubspotFormsScript = async () => {
  if (hubSpotWhiteListedPath.some((path) => location.pathname === path)) {
    const { recaptchaSiteKey } = window.JSGlobals ?? {}

    await debug(jsLoader, 'hubspot form script tag')(
      ['https://js.hsforms.net/forms/v2.js'],
      'hubspot',
    )

    if (recaptchaSiteKey) {
      await debug(jsLoader, 'recaptcha script tag')(
        [`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`],
        'recaptcha',
      )
    }

    debug(hubspotForm, 'hubspotForm')
  }
}

export const newsletterPage = () => {
  if (location.pathname.includes('/newsletter')) {
    if (window.JSGlobals?.customNewsletterPage) {
      const domain = window.JSGlobals?.domain
      debug(jsLoader, 'recaptcha - custom newsletter page')(
        [
          `https://www.google.com/recaptcha/api.js?render=${
            chooseNewsletterPagePublication(domain)?.recaptchaPublicKey
          }`,
        ],
        'recaptcha',
      ).then(debug(standaloneNewsletter, 'standaloneNewsletter'))
    } else {
      debug(jsLoader, 'hubspot form script tag')(
        ['https://js.hsforms.net/forms/v2.js'],
        'hubspot',
      ).then(debug(hubspotNewsletter, 'hubspotNewsletter (standalone)'))
    }
  }
}

export const fontawesomeScript = () => {
  if (location.pathname == '/jobs') {
    debug(jsLoader, 'fontawesome')(
      ['https://use.fontawesome.com/f6e91d2e5c.js'],
      'fontawesome',
    )
  }
}

export const instagramEmbed = () => {
  const embeds = document.getElementsByClassName('instagram-media')
  if (embeds.length > 0) {
    // listen for instagram event and resize iframe
    window.addEventListener('message', (event) => {
      if (event.origin !== 'https://www.instagram.com') return

      const data = JSON.parse(event.data)
      if (data?.type === 'MEASURE' && data?.details) {
        const height = data.details.height
        if (height) {
          const instaFrames = document.querySelectorAll<HTMLIFrameElement>(
            '.instagram-media iframe',
          )
          instaFrames.forEach((frame) => {
            if (frame.contentWindow === event.source)
              frame.style.height = `${height}px`
          })
        }
      }
    })
  }
}

export const initMantis = async () => {
  if (window.JSGlobals?.mantis) {
    await debug(jsLoader, 'mantis script tag')(
      ['https://loader.mantis-intelligence.com/nationalworld2/loader.js'],
      'mantis',
    )
  }
}
