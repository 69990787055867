import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import emailIcon from '~/icons/emailIcon.js'
import facebookIcon from '~/icons/facebookIcon.js'
import twitterIcon from '~/icons/twitterIcon.js'
import whatsappIcon from '~/icons/whatsappIcon'
import { handleClickTracking } from '~/js/components/LiveblogHydrated/util/handle-click-tracking'
import { ComponentThemeProvider } from '~/library/providers/ComponentThemeProvider'
import { ArticleData, Configuration, FC } from '~/types'
import {
  emailShareUrl,
  facebookShareUrl,
  twitterShareUrl,
  whatsappShareUrl,
} from '~/util/get-social-sharer-urls'
import { openModal } from '~/util/social-share-modal'

export const Wrapper = styled.div<{
  iconWidth?: number
  iconHeight?: number
  article?: ArticleData
}>`
  display: none;
  align-items: center;
  gap: 8px;

  @media ${device.tablet} {
    display: none;
    justify-content: left;
    margin: 0;
  }

  & svg {
    display: flex;
    fill: ${({ theme }) => theme.darkContrast};
    stroke: ${({ theme }) => theme.lightGrey};
    transition: 0.2s all ease-in;
    width: ${({ iconWidth }) => iconWidth}px;
    height: ${({ iconHeight }) => iconHeight}px;
  }

  .social-link {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-size: 60%;
    background-image: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }

  .whatsapp {
    display: flex;
    justify-content: center;
    svg g path {
      stroke: ${({ theme }) => theme.lightGrey};
    }
    @media ${device.tablet} {
      display: none;
    }
  }
`

const WrapperContrast = styled(Wrapper)`
  .whatsapp {
    background-image: none;
  }
  & svg {
    fill: ${({ theme }) => theme.lightContrast};
    stroke: ${({ theme }) => theme.lightContrast};
  }
  .whatsapp svg g path {
    stroke: ${({ theme }) => theme.lightContrast};
  }
`

type SocialsShareProps = {
  article?: ArticleData
  className?: string
  configuration: Configuration
  iconWidth?: number
  iconHeight?: number
  position: string
  theme?: string
  url?: string
}

const SocialShare: FC<SocialsShareProps> = ({
  article,
  className = '',
  configuration,
  iconWidth = 32,
  iconHeight = 32,
  position,
  theme = 'default',
}) => {
  const Div = ComponentThemeProvider(theme, WrapperContrast, Wrapper)

  return (
    <Div
      id="social-share"
      className={[className, 'social-share'].join(' ')}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
      article={article}
      data-testid="article-social-group"
    >
      {configuration.facebookAppId && (
        <a
          onClick={(e) => {
            openModal(e)
            handleClickTracking(
              article?.id,
              article?.type,
              position,
              'facebook',
            )
          }}
          type="facebook"
          className="social-link"
          data-href={facebookShareUrl(configuration, article)}
          data-position={position}
          data-vars-event="gaEvent"
          data-vars-ec="social"
          data-vars-ea="facebook share"
          data-vars-aidclick={article?.id}
          data-vars-titleclick={article?.title}
          data-vars-urlclick={article?.path}
          data-vars-el={position}
          rel="noopener noreferrer"
          aria-label="Share to Facebook"
          dangerouslySetInnerHTML={{ __html: facebookIcon }}
        />
      )}
      <a
        onClick={(e) => {
          openModal(e)
          handleClickTracking(article?.id, article?.type, position, 'twitter')
        }}
        type="twitter"
        className="social-link"
        data-href={twitterShareUrl(configuration, article)}
        data-position={position}
        data-vars-event="gaEvent"
        data-vars-ec="social"
        data-vars-ea="twitter share"
        data-vars-aidclick={article?.id}
        data-vars-titleclick={article?.title}
        data-vars-urlclick={article?.path}
        data-vars-el={position}
        rel="noopener noreferrer"
        aria-label="Share to Twitter"
        dangerouslySetInnerHTML={{ __html: twitterIcon }}
      />
      <a
        onClick={(e) => {
          openModal(e)
          handleClickTracking(article?.id, article?.type, position, 'whatsapp')
        }}
        type="whatsapp"
        className="social-link whatsapp"
        data-href={whatsappShareUrl(configuration, article)}
        data-position={position}
        data-vars-event="gaEvent"
        data-vars-ec="social"
        data-vars-ea="whatsapp share"
        data-vars-aidclick={article?.id}
        data-vars-titleclick={article?.title}
        data-vars-urlclick={article?.path}
        data-vars-el={position}
        rel="noopener noreferrer"
        aria-label="Share to Whatsapp"
        dangerouslySetInnerHTML={{ __html: whatsappIcon }}
      />
      <a
        onClick={(e) => {
          openModal(e)
          handleClickTracking(article?.id, article?.type, position, 'email')
        }}
        type="email"
        className="social-link"
        data-href={emailShareUrl(configuration, article)}
        data-position={position}
        data-vars-event="gaEvent"
        data-vars-ec="social"
        data-vars-ea="email share"
        data-vars-aidclick={article?.id}
        data-vars-titleclick={article?.title}
        data-vars-urlclick={article?.path}
        data-vars-el={position}
        rel="noopener noreferrer"
        aria-label="Share by email"
        dangerouslySetInnerHTML={{ __html: emailIcon }}
      />
    </Div>
  )
}

export default SocialShare
