import React from 'react'
import styled from 'styled-components'
import { FC } from '~/types'

const ContributorWrapper = styled.span`
  && > a.contributor-badge {
    text-decoration: none;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 0 5px;
    color: ${(props) => props.theme.lightContrast};
    background-color: ${(props) => props.theme.contributorLight};
  }
`

export type ContributorBadgeProps = {
  contentSource?: { name?: string }[]
}

export const ContributorBadge: FC<ContributorBadgeProps> = ({
  contentSource,
}) => {
  if (contentSource?.[0]?.name === 'UGC') {
    return (
      <ContributorWrapper>
        <a
          className="contributor-badge"
          href="https://submit.nationalworld.com"
          target="_blank"
          rel="noreferrer"
        >
          Contributor
        </a>
      </ContributorWrapper>
    )
  }

  return null
}
