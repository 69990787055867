import { domainsWithNativeShare } from '~/config/JPIMConfig'
import isMobileDevice from './check-for-mobile-device'

const SHARE_BUTTONS_SELECTOR =
  '.live-blog-share-btn, .sponsor-share-btn, .gallery-share-btn, .bottom-share-btn, .top-share-btn'

// 1. Handle the case when the Web Share API is not supported
const handleWebShareNotSupported = () => {
  const socialShare = document.querySelectorAll('#social-share')
  const socialShareMobile = document.querySelectorAll(SHARE_BUTTONS_SELECTOR)

  if (socialShare) {
    socialShare.forEach(
      (element) => ((element as HTMLElement).style.display = 'flex'),
    )
  }

  if (socialShareMobile) {
    socialShareMobile.forEach(
      (element) => ((element as HTMLElement).style.display = 'none'),
    )
  }
}

// 2. Handle the share button click event
const handleShareButtonClick = (event: Event) => {
  const clickedButton = event.target as Element
  const wrapper = clickedButton.closest('.caption-gallery')

  // Get the title and text of the article, but initialize them as null in case they are not found
  let headerTitle: HTMLElement | null = null
  let headerText: HTMLElement | null = null

  // Get the title and text of the article based on the type of share button clicked
  if (
    clickedButton.classList.contains('top-share-btn') ||
    clickedButton.classList.contains('bottom-share-btn') ||
    clickedButton.classList.contains('sponsor-share-btn')
  ) {
    headerTitle = document.querySelector('.article-title')
    headerText = document.querySelector(
      '.article-standfirst, .article-standfirst p',
    )
  } else if (clickedButton.classList.contains('gallery-share-btn')) {
    headerTitle = wrapper && wrapper.querySelector('.caption-gallery-title')
    headerText = wrapper && wrapper.querySelector('.caption-gallery-text')
  }

  // Get the data to share
  const title = headerTitle?.textContent ?? 'Title not found'
  const text = headerText?.textContent ?? 'Text not found'
  const url = window.location.href

  type ShareDataProps = {
    title: string
    text: string
    url: string
  }

  const shareData: ShareDataProps = {
    title,
    text,
    url,
  }

  navigator
    .share(shareData)
    .catch((error) => console.error('Error sharing', error))
}

// 3. Export the shareArticle function
export const shareArticle = () => {
  // Check if the current domain is in the list of domains that support native share
  const currentDomain = window.JSGlobals?.domain
  const isDomainWithNativeShare = domainsWithNativeShare.includes(currentDomain)
  if (!isDomainWithNativeShare) {
    handleWebShareNotSupported()
  }

  // Check if the browser supports the Web Share API
  const isNavigatorShareSupported = typeof navigator.share !== 'undefined'

  // Get all share buttons
  const shareButtons = document.querySelectorAll(SHARE_BUTTONS_SELECTOR)

  if (isMobileDevice() && isNavigatorShareSupported) {
    shareButtons.forEach((shareButton) => {
      shareButton.addEventListener('click', handleShareButtonClick)
    })
  } else {
    handleWebShareNotSupported()
  }
}
