import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { domainsWithNativeShare } from '~/config/JPIMConfig'
import {
  device,
  getPrimaryColorBasedOffAllSections,
  getSectionPrimaryColor,
} from '~/config/theme/JPIMStyles'
import ViafouraCommentButton, { Button } from '~/embeds/ViafouraCommentButton'
import { Img } from '~/library/Img'
import PolarSponsorBanner from '~/library/ThirdParty/PolarSponsorBanner'
import { ComponentThemeProvider } from '~/library/providers/ComponentThemeProvider'
import PersonalityProfileTags from '~/section/PersonalityProfileTags'
import isCommentingEnabled from '~/util/commenting'
import { getTimezone } from '~/util/get-timezone'
import { getPageId } from '~/util/getAdData'
import { AffiliateMessage } from './AffiliateMessage'
import { ContributorBadge } from './ContributorBadge'
import CommentButton from './Embeds/CommentButton'
import PartnerBanner from './PartnerBanner'
import SocialShare from './SocialShare'
import SocialShareMobile from './SocialShareMobile'
import SponsorBanner from './SponsorBanner'
import { containsAffiliateLinks } from './utils/article-utils'
import { DateInfo } from './DateInfo'
import { opacityToAlpha } from '~/util/opacityToAlpha'
import { Authors } from './Authors'
import { formatString } from './utils/formatString'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const Wrapper = styled.div`
  padding: 11px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 20px;

  &.sponsored-capsule {
    border-top: 1px solid ${({ theme }) => theme.greyContrast};
    border-bottom: 1px solid ${({ theme }) => theme.greyContrast};
  }

  @media ${device.tablet} {
    margin: 20px 0;
  }

  @media ${device.maxTablet} {
    display: block;
    border-bottom: 0px;
  }
`

const SocialShareAndCommentWrapperMeta = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${({ isPhotoArticle }) => isPhotoArticle && '10px 0'};

  @media ${device.tablet} {
    width: auto;
    padding: 10px 0;
  }

  #comment-counter-wording {
    @media ${device.maxMobileM} {
      display: none;
    }
  }
`

const SocialShareAndCommentWrapperMetaContrast = styled(
  SocialShareAndCommentWrapperMeta,
)`
  // for an unknown reason if using theme.lightContrast it doesn't work
  ${Button} {
    border: 1.5px solid white;
  }
  .viafoura-comment-icon {
    fill: white;
  }
  .viafoura {
    color: white;

    #viafoura-comment-counter-wording {
      color: white;
    }
  }
`

const WrapperContrast = styled(Wrapper)`
  border-top: 1px solid ${({ theme }) => theme.lightContrast};
  flex-direction: column;
  border-bottom: none;

  .viafoura-comments {
    background: transparent;
  }

  @media ${device.tablet} {
    flex-direction: row;
    border-bottom: 1px solid ${({ theme }) => theme.lightContrast};
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  padding-bottom: 20px;
  flex-direction: row;
  margin-bottom: 20px;

  @media ${device.maxTablet} {
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    padding-bottom: 0;
    margin: auto 0;
  }

  @media ${device.maxTablet} {
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
  }
`

const InnerWrapperContrast = styled(InnerWrapper)`
  border-bottom: 1px solid ${({ theme }) => theme.lightContrast};

  @media ${device.tablet} {
    border-bottom: 0;
  }
`

const PublishedDate = styled.div`
  color: var(--grey-600);
  text-align: left;
  line-height: 19px;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  display: flex;
  white-space: pre-wrap;

  .normal-label {
    font-weight: normal;
  }

  .semibold-label {
    font-weight: 600;
  }
`

const PublishedDateContrast = styled(PublishedDate)`
  color: ${({ theme }) => theme.lightContrast};
`

const Author = styled.div`
  text-align: left;
  line-height: 23px;
  font-weight: bold;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  display: flex;

  .article-author-job-title {
    display: ${({ article }) => (article.photoArticle ? 'none' : 'inline')};
  }

  span {
    color: ${({ theme, article }) => {
      if (!article.photoArticle) return theme.darkContrast
      if (theme.domain === 'farminglife.com') {
        return getPrimaryColorBasedOffAllSections(theme, article.sections)
      }
      return getSectionPrimaryColor(theme, article.hierarchy[0]?.name)
    }};
  }

  a {
    text-decoration: none;
    text-transform: capitalize;
  }
`
const AuthorContrast = styled(Author)`
  a {
    color: ${({ theme }) => theme.lightContrast};
  }

  span {
    color: ${({ theme }) => theme.lightContrast};
  }
`
const AuthorAvatar = styled.a`
  margin-right: 10px;
  min-height: 48px;
  min-width: 48px;

  img {
    display: block;
    background-color: ${({ theme }) =>
      `${theme.lightContrast}${opacityToAlpha(0.8)}`};
    border-radius: 50%;
  }
`

const AuthorDate = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`

export const StyledArticleMeta = styled(ArticleMeta)`
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  flex-direction: row;
  align-items: center;

  @media ${device.tablet} {
    margin-top: ${({ isPremiumArticleLayout }) =>
      isPremiumArticleLayout ? '0' : '24px'};
    margin-bottom: 30px;
  }

  ${InnerWrapper} {
    padding-bottom: 0;
    @media ${device.maxTablet} {
      flex-direction: row;
    }
  }

  ${AuthorAvatar} {
    display: flex;

    img {
      background-color: ${({ article }) => {
        const section = formatString(article.hierarchy[0]?.name)
        return `var(--section-${section}-300, var(--primary-300))`
      }};
      border-radius: 50%;
    }
  }

  ${Author} {
    font-size: 14px;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fontFamily.openSans};

    a {
      color: ${({ theme }) => theme.darkContrast}
  }

  ${InnerWrapper} {
    border-bottom: none;
  }

  @media ${device.maxTablet} {
    ${InnerWrapper} {
      margin-bottom: 0;
    }
  }

  ${PublishedDate} {
    font-size: 12px;
    line-height: 18px;
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    color: #9b9b9b;
    @media ${device.mobileL} {
      font-size: 14px;
      line-height: 20px;
    }
  }
`

const AuthorSocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    flex-direction: row;
  }

  ${({ isPhotoArticle }) =>
    !isPhotoArticle &&
    `
      margin-bottom: 24px;
      gap: 24px;
  `};
`

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;


      @media ${device.laptopS} {
      flex-direction: row;
      & > :not(:last-child)::after {
        content: '|';
        margin: 0 0.25rem;
      }
    }
  }
`

function ArticleMeta({
  article,
  className,
  configuration,
  iconHeight = undefined,
  iconWidth = undefined,
  isPremiumArticleLayout,
  isInfiniteScrollEnabled,
  theme = 'default',
}) {
  const isPhotoArticle = article.photoArticle === true

  const { geolocation } = configuration
  const isUs = geolocation === 'US'
  const publishDate = dayjs
    .unix(article.firstPublished ? article.firstPublished : article.publish)
    .tz('Europe/London')
    .format(isUs ? 'Do MMM YYYY, h:mma' : 'Do MMM YYYY, HH:mm')

  const displayUpdatedDate = () => {
    if (isUs) {
      if (
        typeof article.updated === 'undefined' ||
        article.updated <= article.publish
      )
        return null

      return dayjs
        .unix(article.updated)
        .tz('Europe/London')
        .format('Do MMM YYYY, h:mma')
    } else {
      const articlePublishedDate = article.firstPublished
        ? article.firstPublished // sometimes null on Prod but needed in some cases
        : article.publish

      if (articlePublishedDate < article.updated) {
        return dayjs
          .unix(article.updated)
          .tz('Europe/London')
          .format('Do MMM YYYY, HH:mm')
      }
    }
  }

  const updatedDate = displayUpdatedDate()

  const getAuthorName = (author) => {
    const authorName =
      author.firstName && author.lastName
        ? `${author.firstName} ${author.lastName}`
        : author.name

    return authorName.replace(/^by/i, '').replace(/, contributor$/i, '')
  }

  const renderAvatar = () => {
    const hasMultipleAuthors = article.authors
      ? article.authors.length > 1
      : false
    if (hasMultipleAuthors) {
      return null
    }

    const authorWithAvatar = article.authors
      .filter((a) => a.imageUrl)
      .filter(Boolean)?.[0]

    const isSvgAvatar = authorWithAvatar?.imageUrl.includes('.svg')

    if (isSvgAvatar) {
      return null
    }

    if (authorWithAvatar) {
      const authorName = getAuthorName(authorWithAvatar)
      return (
        <AuthorAvatar
          aria-label={`Latest from ${authorName}`}
          href={authorWithAvatar?.path}
          data-testid="article-author-avatar"
        >
          <Img
            alt={authorName}
            src={authorWithAvatar?.imageUrl}
            urlParams={{ width: 96, crop: '1:1,smart' }}
            height="48"
            width="48"
          />
        </AuthorAvatar>
      )
    }
    return null
  }

  const renderDateInfo = () => (
    <DateWrapper isPhotoArticle={isPhotoArticle}>
      <DateDiv>
        <DateInfo
          article={article}
          date={publishDate}
          isUs={isUs}
          publishDate={publishDate}
        />
      </DateDiv>
      {updatedDate && !isUs && (
        <DateDiv>
          <DateInfo
            isUpdated
            article={article}
            date={updatedDate}
            isUs={isUs}
          />
        </DateDiv>
      )}
    </DateWrapper>
  )

  const InnerDiv = ComponentThemeProvider(
    theme,
    InnerWrapperContrast,
    InnerWrapper,
  )
  const OuterDiv = ComponentThemeProvider(theme, WrapperContrast, Wrapper)
  const DateDiv = ComponentThemeProvider(
    theme,
    PublishedDateContrast,
    PublishedDate,
  )
  const AuthorDiv = ComponentThemeProvider(theme, AuthorContrast, Author)

  const SocialShareWrapper = ComponentThemeProvider(
    theme,
    SocialShareAndCommentWrapperMetaContrast,
    SocialShareAndCommentWrapperMeta,
  )

  return (
    <div className="outerWrapper" data-testid="article-meta-wrapper">
      <OuterDiv
        className={`${
          (article.sponsorName || article.partners || article.polar) &&
          'sponsored-capsule'
        } ${className}`}
      >
        {article.sponsorName && (
          <SponsorBanner
            article={article}
            date={publishDate}
            updatedDate={updatedDate}
            configuration={configuration}
            theme={theme}
          />
        )}
        {article.partners && (
          <PartnerBanner
            article={article}
            date={publishDate}
            updatedDate={updatedDate}
            configuration={configuration}
          />
        )}
        {article.polar && (
          <PolarSponsorBanner
            article={article}
            configuration={configuration}
            timezone={getTimezone(dayjs().toDate())}
          />
        )}
        {!article.sponsorName && !article.partners && !article.polar && (
          <>
            <AuthorSocialContainer isPhotoArticle={isPhotoArticle}>
              <InnerDiv>
                {renderAvatar()}
                <AuthorDate>
                  <AuthorDiv article={article}>
                    <Authors authors={article.authors} />
                    <ContributorBadge contentSource={article.contentSource} />
                  </AuthorDiv>
                  {isPhotoArticle && renderDateInfo()}
                </AuthorDate>
              </InnerDiv>
              <SocialShareWrapper
                isPremiumArticleLayout={isPremiumArticleLayout}
                isPhotoArticle={isPhotoArticle}
              >
                {domainsWithNativeShare.includes(configuration.domain) && (
                  <SocialShareMobile
                    position="in article - top"
                    article={article}
                    shareClass="top-share-btn"
                  />
                )}
                <SocialShare
                  position="in article - top"
                  article={article}
                  theme={theme}
                  configuration={configuration}
                  iconWidth={iconWidth}
                  iconHeight={iconHeight}
                />

                {isCommentingEnabled(article) &&
                configuration.disqus &&
                !article.photoArticle ? (
                  <CommentButton
                    pageId={getPageId(article)}
                    className="commentButtonDisqusCounter"
                    position="top"
                    article={article}
                    hierarchy={article.hierarchy}
                  />
                ) : configuration.viafoura && !isInfiniteScrollEnabled ? (
                  <>
                    <ViafouraCommentButton
                      article={article}
                      isCommentingEnabled={isCommentingEnabled(article)}
                      className="commentButtonViafouraCounter viafoura-comments"
                      position="top"
                      hierarchy={article.hierarchy}
                    />
                  </>
                ) : null}
              </SocialShareWrapper>
            </AuthorSocialContainer>
            {!article.photoArticle && renderDateInfo()}
          </>
        )}
      </OuterDiv>
      {((article.people && article.people.length > 0) ||
        (article.player && article.player.length > 0)) && (
        <PersonalityProfileTags
          people={article.people ? article.people : article.player}
          isPlayer={article.player}
        />
      )}
      {isPremiumArticleLayout && containsAffiliateLinks(article) && (
        <AffiliateMessage />
      )}
    </div>
  )
}

ArticleMeta.propTypes = {
  article: PropTypes.any.isRequired,
  className: PropTypes.string,
  configuration: PropTypes.object.isRequired,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  isPremiumArticleLayout: PropTypes.bool,
  theme: PropTypes.string,
}

export default ArticleMeta
