import React from 'react'
import styled from 'styled-components'

const StyledViafouraConversationStarter = styled.div`
  .vf-conversation-starter__ad-container-wrapper {
    display: none;
  }
  .vf-conversation-starter {
    svg {
      g {
        path {
          stroke: ${({ theme }) => theme.primaryColor} !important;
        }
      }
    }
  }
  .vf-conversation-starter__header-title {
    color: ${({ theme }) => theme.primaryColor};
  }

  .vf-conversation-starter_link {
    background-color: ${({ theme }) => theme.primaryColor} !important;
  }
`

const ViafouraConversationStarter = ({ isCommentingEnabled }) => {
  if (!isCommentingEnabled) {
    return null
  }
  return (
    <StyledViafouraConversationStarter>
      <div className="viafoura">
        <vf-conversation-starter target="commentsWidget"></vf-conversation-starter>
      </div>
    </StyledViafouraConversationStarter>
  )
}

export default ViafouraConversationStarter
