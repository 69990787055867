import { jsLoader } from './fileLoaders'

/* globals JSGlobals, OneSignal */

const excludedPages = [
  '/contact-us',
  '/profile',
  '/profile/my-newsletters',
  '/sign-in',
  '/register',
  '/forgotten-password',
  '/reset-password',
  '/newsletters',
  '/newsletter/premium',
  '/newsletter/premium/thank-you',
  '/newsletter/premium/cancel',
  '/newsletter/premium/cancel/confirmation',
  '/newsletter/premium/faq',
  '/newsletter/premium/upgrade',
  '/newsletter/premium/upgrade/confirmation',
  '/subscriptions',
  '/subscriptions/consent-subs',
  '/subscriptions/sports',
  '/subscriptions/renewal',
  '/subscription-renewal',
  '/campaign',
  '/subscriptions/campaign',
  '/promotion',
  '/subscriptions/promotion',
  '/sports-promotion',
  '/subscriptions/sports-promotion',
  '/new-year',
  '/subscriptions/new-year',
  '/6-nations',
  '/subscriptions/6-nations',
  '/national-puzzle-day',
  '/subscriptions/national-puzzle-day',
  '/valentines-day',
  '/subscriptions/valentines-day',
  '/mothering-sunday',
  '/subscriptions/mothering-sunday',
  '/fathers-day',
  '/subscriptions/fathers-day',
  '/summer-sports',
  '/subscriptions/summer-sports',
  '/students',
  '/subscriptions/students',
  '/xmas',
  '/subscriptions/xmas',
  '/events',
  '/subscriptions/events',
  '/halloween',
  '/subscriptions/halloween',
  '/easter',
  '/subscriptions/easter',
  '/corporate',
  '/subscriptions/corporate',
  '/group',
  '/subscriptions/group',
  '/about',
  '/subscriptions/competition',
  '/competition',
  '/how-to-disable-adblocker',
  '/what-is-sso',
  '/members',
  '/subscribers',
  '/subscriptions/spring-sale',
  '/subscriptions/summer-sale',
  '/subscriptions/autumn-sale',
  '/subscriptions/winter-sale',
  '/subscriptions/gift-redemption',
  '/subscriptions/sports/gift-redemption',
  '/subscriptions/terms-and-conditions',
  '/subscriptions/upgrade',
  '/subscriptions/upgrade-offer',
  '/subscriptions/upgrade-promotion',
  '/subscriptions/upgrade-renewal',
  '/subscriptions/upgrade-students',
  '/subscriptions/upgrade-winback',
  '/subscriptions/why-register',
  '/subscriptions/frequently-asked-questions',
  '/upgrade',
  '/upgrade-offer',
  '/upgrade-promotion',
  '/upgrade-renewal',
  '/upgrade-students',
  '/upgrade-winback',
]

export const initOneSignal = () => {
  jsLoader(
    ['https://cdn.onesignal.com/sdks/OneSignalSDK.js'],
    'onesignal',
  ).then(oneSignal)
}
const oneSignal = () => {
  const path = window.location.pathname
  if (excludedPages.some((exclude) => path.includes(exclude))) return

  const pathArray = path.split('/')
  const topic = pathArray[pathArray.length - 1]
  const numVisitsTrigger = 1
  window.OneSignal = window.OneSignal || []
  OneSignal.push(function () {
    OneSignal.init({
      // use static id for development
      // Branch ID ( https://jpi-web-feature-adp-31.brightsites.co.uk )
      // appId:'68a2606c-e137-4b00-87d9-92e346e8e031',
      // localhost ID
      // appId: '487fc05b-7678-4f84-8459-744725102bcd',
      appId: JSGlobals.oneSignalId,
    })
  })

  function tagUserWithPageTopic(key, value) {
    if (path === '/') return null
    OneSignal.push(function () {
      OneSignal.isPushNotificationsEnabled(function (isEnabled) {
        if (isEnabled) {
          OneSignal.sendTag(key, value, function (tagsSent) {
            // Callback called when tags have finished sending
            console.log('tags sent: ', tagsSent)
          })
        }
      })
    })
  }

  if (typeof localStorage !== 'undefined') {
    let topicVisits = parseInt(localStorage.getItem(topic), 10)
    if (!isNaN(topicVisits)) {
      topicVisits += 1
    } else {
      topicVisits = 1
    }
    localStorage.setItem(topic, topicVisits)

    if (topicVisits >= numVisitsTrigger) {
      tagUserWithPageTopic(topic, topicVisits)
    }
  }
}

export const sendUserTags = (user) => {
  const tags = {
    firstName: user.firstName,
    lastName: user.lastName,
  }

  OneSignal.push(() => {
    // 1. Identify the user with their UID
    OneSignal.login(user.uid)

    // 2. Add or update aditional tags
    OneSignal.sendTags(tags)
  })
}
