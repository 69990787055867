import React, { useEffect } from 'react'
import styled from 'styled-components'
import { getTweetId } from '../util/get-tweet-id'

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 320px;
  clear: both;
`

export const Twitter = ({
  data,
}: {
  data: { url?: string; id?: string; align?: string }
}) => {
  const tweetId = getTweetId(data)
  const className = `tweet-${tweetId}`

  useEffect(() => {
    const renderTweet = () => {
      window.twttr?.widgets.createTweet(
        tweetId,
        document.querySelector(`.${className}`),
        {
          align: data.align || 'center',
        },
      )
    }

    const twttrInterval = setInterval(() => {
      if (window.twttr && window.twttr.widgets) {
        clearInterval(twttrInterval)
        renderTweet()
      }
    }, 500)

    return () => {
      clearInterval(twttrInterval)
    }
  }, [className, tweetId])

  return (
    <Wrapper className="twitter-embed">
      <div className={className} />
    </Wrapper>
  )
}
