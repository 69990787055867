import React, { useEffect, useRef, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import Audio from '~/library/Article/Audio'
import Camera from '~/library/Article/Camera'
import Live from '~/library/Article/Live'
import Video from '~/library/Article/Video'
import ArticleHeadline from '~/section/ArticleHeadline'
import ArticleItem from '~/section/ArticleItem'
import JPThemeProvider from '~/services/JPThemeProvider'
import { Configuration } from '~/types'
import { ArticleLoader } from './ArticleLoader'

const VISIBLE_PERCENTAGE_THRESHOLD = 5

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 16px;

  .article-item {
    display: grid;
    gap: 12px;
    grid-auto-rows: max-content;

    ${ArticleHeadline} {
      font-size: 16px;
      margin: 0;
    }

    @media ${device.tablet} {
      gap: 16px;
      ${ArticleHeadline} {
        font-size: 18px;
        margin: 0;
      }
    }
  }

  .article-item-image {
    padding-top: 66.67%;

    ${Camera}, ${Video}, ${Audio} {
      width: 50px;
      height: 50px;
      bottom: 18px;
      border-radius: 50%;
      left: 18px;
    }

    ${Live} {
      width: 26px;
      height: 20px;
      font-size: 14px;
      padding: 5px 10px;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const NoVideosMessage = styled.p`
  font-size: 24px;
  column-span: all;
  grid-column: span 2 / span 2;

  @media ${device.tablet} {
    grid-column: span 3 / span 3;
  }
`

export const HeroGrid = ({
  configuration,
  watchThisId,
}: {
  configuration: Configuration
  watchThisId: number
}) => {
  const { domain } = configuration
  const theme = new JPThemeProvider(domain).get()

  const tabRef = useRef<HTMLDivElement>(null)
  const [watchThisArticles, setWatchThisArticles] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const hasFetchedContent = useRef(false) // Flag to track if content has been fetched once
  const ARTICLE_COUNT_LIMIT = 12

  useEffect(() => {
    const getWatchThisArticles = async () => {
      try {
        const response = await fetch(
          `/api/watch-this-articles?domain=${domain}&limit=${ARTICLE_COUNT_LIMIT}&tids=${watchThisId}`,
        )
        const data = await response.json()
        const { items } = data
        setWatchThisArticles(items)
        setIsLoading(false)
        hasFetchedContent.current = true
      } catch (error) {
        console.error(error)
      }
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting && !hasFetchedContent.current) {
          getWatchThisArticles()
        }
      },
      { threshold: VISIBLE_PERCENTAGE_THRESHOLD / 100 },
    )

    if (tabRef.current) {
      observer.observe(tabRef.current)
    }

    return () => {
      if (tabRef.current) {
        observer.unobserve(tabRef.current)
      }
    }
  }, [])

  return (
    <ThemeProvider theme={{ ...theme, ...configuration }}>
      <Wrapper className="section-body no-card" ref={tabRef}>
        {isLoading ? (
          Array.from({ length: 6 }, (_, index) => <ArticleLoader key={index} />)
        ) : !watchThisArticles ? ( // is undefined if no items returned from API
          <NoVideosMessage>
            There are no videos available at the moment, please try again later…
          </NoVideosMessage>
        ) : (
          watchThisArticles?.map((article, id) => {
            return (
              <ArticleItem
                key={id}
                srcset={[471, 349, 280]}
                ratio="threebytwo"
                data={article}
                isHeroRelated={false}
              />
            )
          })
        )}
      </Wrapper>
    </ThemeProvider>
  )
}

// Add displayName to component to deal with the component name
// being minified or altered during build and causing a mismatch
// between the server- and client-side component names when used
// within the hydrated TabbedContent component
HeroGrid.displayName = 'HeroGrid'
