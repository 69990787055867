import React from 'react'
import styled from 'styled-components'
import { FC } from '~/types'

export type HorizontalRuleProps = {
  className: string
}

const HorizontalRule = styled<FC<HorizontalRuleProps>>(({ className }) => {
  return <div className={className}></div>
})`
  border: 4px solid #ececec;
  width: 100%;
`

export default HorizontalRule
