import { applyUrlParams } from '@brightsites/flow-core/lib/services/helper/url'
import { trimImageParam } from '@brightsites/flow-core/lib/utils/cropImage'
import { ImageExtra, Ratio, SizeUpdateMap } from '~/types'

export const getIdFromUrl = (str?: string | null) => {
  str ??= ''
  return str.split('?')[0]?.match(/\d+$/)?.[0]
}

/**
 * Builds an `<img>` srcset. If `width` is present in `urlParams`, it gets overriden
 * by subsequent values of `widths`
 * @param src - Base url. May contain ?url=parameters
 * @param widths - An array of widths to create srcset links for
 * @param urlParams - Additional url parameters to apply to `src`
 * @returns `<img>` srcset
 */
export const buildSrcSet = (
  src: string,
  widths: number[] = [],
  urlParams?: Record<string, string>,
) =>
  widths
    .map(String)
    .map((width) => {
      const srcWithParams = applyUrlParams({
        url: src,
        urlParams: { ...urlParams, width },
        unique: true,
      })
      return `${srcWithParams} ${width}w`
    })
    .join(',\n')

const cropMap: { [key in Ratio]: `${number}:${number}` } = {
  Freeform: '4:3', // default for when Freeform trim data isn't set for an image
  onebyone: '1:1',
  fivebyfour: '5:4',
  fourbythree: '4:3',
  threebytwo: '3:2',
  eightbyfive: '8:5',
  ninebyfive: '9:5',
  twobyone: '2:1',
  ninebyfour: '9:4',
  twelvebyfive: '12:5',
}

/**
 * Builds a `trim` param for an image URL from the contents of `sizes[ratio]`.
 * If that fails, it generates a `crop` param for the same ratio, unless
 * a fallback `crop` param is supplied, in which case that is used as is.
 *
 * @param param0.ratio - requested image ratio. Defaults to `'fourbythree'`
 * @param param0.sizes - if `sizes[ratio]` exists, used to create the `trim` param
 * @param param0.crop - a backup `crop` param to use if `sizes[ratio]` not found
 * @returns an object with either a `trim` or `crop` URL param
 * @example
 *    buildSizeUpdateParam({
 *      ratio: 'twobyone',
 *      sizes: {
 *        'twobyone': {
 *          trimData: { top: 0, right: 239, bottom: 266, left: 0 },
 *        },
 *      },
 *      crop: '2:1,smart'
 *    })
 */
export const buildSizeUpdateParam = ({
  ratio = 'fourbythree',
  sizes,
  crop = cropMap[ratio] + ',smart',
}: {
  ratio?: Ratio
  sizes?: SizeUpdateMap
  crop?: string
}) => {
  const sizeUpdate = sizes?.[ratio]

  const trimObj = trimImageParam(sizeUpdate)

  if (trimObj?.trim) return { ...trimObj, crop: '' }

  return { crop, trim: '' }
}

/**
 * Caps the width at the original image width to avoid issues when cropping.
 *
 * Rationale:
 * As in JPI we're translating the crop param to CloudFlare's `fit: 'crop'`, requesting a `crop`
 * with a `width` greater than the original distorts the resulting crop ratio.
 * Requesting a `trim` with a `width` greater than the original doesn't make the image bigger
 * unless `enable=upscale` param is also provided, so in most cases, it's a safe choice.
 *
 * @see {@link https://developers.cloudflare.com/images/image-resizing/resize-with-workers/#fit}
 * @see {@link https://github.com/brightsitesconsulting/jpi-cloudflare-worker/blob/a1091b8a7da13dbec9968063d8238165fc7d34ae/src/external-image-routing.js#L37}
 *
 * @param param0.extra          - image.extra data containing original image width
 * @param param0.suggestedWidth - Optional. Used if less or equal to original image width.
 *                                Defaults to original width.
 * @param param0.defaultWidth   - Optional, in case original image width info missing
 * @returns the smaller of `suggestedWidth` or original image width obtained from `extra`
 */
export const cropSafeImageWidth = ({
  extra,
  suggestedWidth,
  defaultWidth = 640,
}: {
  extra?: ImageExtra
  suggestedWidth?: number
  defaultWidth?: number
}) => {
  const originalImageSize = extra?.imageSize ?? extra?.sizes?.originalImageSize

  // Some images have spurious 0 dimensions
  const originalImageWidth = originalImageSize?.width || defaultWidth // override 0
  suggestedWidth = suggestedWidth ?? originalImageWidth

  return Math.min(originalImageWidth, suggestedWidth)
}

export const capImageWidths = ({
  widths = [],
  extra,
}: {
  widths?: number[]
  extra?: ImageExtra
}) => {
  const capped = widths.map((suggestedWidth) =>
    cropSafeImageWidth({ extra, suggestedWidth }),
  )
  return Array.from(new Set(capped))
}
