import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import Image from '~/library/Article/Image'
import { FC, ProductData } from '~/types'
import BuyNow from './BuyNow'
import StarRating from './StarRating'
import { renderBody } from './helper'

const Wrapper = styled.div`
  margin: 30px 0;
  padding-bottom: 30px;
  clear: both;
  border-bottom: 8px solid ${({ theme }) => theme.greyContrast};

  p {
    line-height: 28px;
    margin: 20px 0;
    font-size: 16px;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    }
  }

  img {
    object-fit: contain;
  }

  @media ${device.tablet} {
    img {
      max-width: 300px;
    }
  }
`

const ProductSpecWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${device.tablet} {
    flex-direction: row;
  }
`

const ImageWrapper = styled.div`
  @media ${device.tablet} {
    margin-right: 24px;
  }
`

const Title = styled.h2`
  margin: 0 0 10px;
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
`

const BestFor = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  align-items: baseline;
  font-weight: normal;

  @media ${device.mobileM} {
    flex-direction: row;
  }
`

const Price = styled.div`
  font-weight: bold;
  font-size: 18px;
  font-style: normal;

  P {
    margin: 0;
  }
`
const Brand = styled.a`
  font-size: 12px;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryColor};
`
const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`
const ProductSpecs = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ProductMeta = styled.aside`
  line-height: ${({ theme }) =>
    /^(The Scotsman|Yorkshire Post)$/.test(theme.name) ? '20px' : '22px'};
  letter-spacing: 0;
`

const BuyMeta = styled.aside`
  display: none;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

const BuyMetaMobile = styled.aside`
  display: none;

  @media ${device.maxTablet} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

const Label = styled.span`
  font-weight: bold;
  padding-right: 5px;
`

const ProsAndCons = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  p {
    margin: 0;
  }
  &.pros {
    margin-top: 8px;
  }
`

const KeySpecs = styled.div`
  font-weight: bold;
`

const ProductBody = styled.div`
  margin-top: 40px;
  }
`

type ProductProps = {
  className?: string
  product: ProductData
  rating?: string
  index: number
}

const Product: FC<ProductProps> = ({
  className = '',
  product,
  rating = 0,
  index,
}) => {
  if (!product || !product.data) return null

  const {
    title,
    price,
    image,
    body,
    vendorLink: href,
    vendorName,
    bestFor,
    pros,
    cons,
    markup,
    spec,
  } = product.data
  image.data.height = 348
  image.data.width = 620

  const specs = spec?.map((spec) =>
    spec.key !== '' && spec.value !== ''
      ? [` ${spec.key}: ${spec.value}; `]
      : [],
  )
  const ratingNumber = Number(rating)

  return (
    <Wrapper className={className}>
      <ProductSpecWrapper>
        {image && (
          <ImageWrapper>
            <Image
              data={image.data}
              srcSet={[320, 640, 990]}
              className={className}
              hasCaption={false}
              isProductImage
            />
          </ImageWrapper>
        )}
        <ProductDetails>
          <ProductMeta>
            <a id={`product-${index}`} />
            {title && <Title>{title}</Title>}
            {bestFor && <BestFor>{`Best For ${bestFor}`}</BestFor>}
            <ProductSpecs>
              {ratingNumber !== 0 && (
                <StarRating rating={ratingNumber} withoutPercentage />
              )}
              {price && (
                <Price>
                  <p>£{price}</p>
                </Price>
              )}
            </ProductSpecs>
            {vendorName && (
              <Brand href={href} rel="nofollow" target="_blank">
                {vendorName}
              </Brand>
            )}
          </ProductMeta>
          <BuyMeta>
            <BuyNow href={href} />
          </BuyMeta>
        </ProductDetails>
      </ProductSpecWrapper>
      <BuyMetaMobile>
        <BuyNow href={href} />
      </BuyMetaMobile>
      {specs?.[0] && specs?.[0]?.length > 0 && (
        <KeySpecs>
          <p>
            <span>Key specs - </span>
            {specs}
          </p>
        </KeySpecs>
      )}
      {pros && !pros.includes('') && pros.length > 0 && (
        <ProsAndCons className="pros">
          <Label>Pros:</Label>
          <p>{`${pros.join(', ')}`}</p>
        </ProsAndCons>
      )}
      {cons && !cons.includes('') && cons.length > 0 && (
        <ProsAndCons className="cons">
          <Label>Cons:</Label>
          <p>{`${cons.join(', ')}`}</p>
        </ProsAndCons>
      )}
      {body && <ProductBody>{renderBody(body)}</ProductBody>}
      {markup && (
        <ProductBody
          dangerouslySetInnerHTML={{
            __html: markup,
          }}
        ></ProductBody>
      )}
    </Wrapper>
  )
}

export default Product
